import lodash from "lodash";

import {
  getObjectData,
  setObjectData,
} from "../../../../../../utils/helpers.DynamicForm";

const pain_flatmap = [
  [
    "pain_patientExperiencePainVal",
    "patientExperiencePain.isPatientExperiencePain",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainCommentVal",
    "patientExperiencePain.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainAcuteVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painAcute.isPainAcute",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainAcuteCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painAcute.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronic",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubDescribeActiveVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronicSubAnswer.describe.active",
    ["2023"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubDescribeHistoryOfVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronicSubAnswer.describe.historyOf",
    ["2023"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubDescribeRuleOutVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronicSubAnswer.describe.ruleOut",
    ["2023"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubDescribeCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronicSubAnswer.describe.comment",
    ["2023"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubWhereVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronicSubAnswer.where",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubRateYourPainVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronicSubAnswer.rateYourPain",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubFrequencyOccasionalVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronicSubAnswer.frequency.occasional",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubFrequencyOneOrMoreTimesVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronicSubAnswer.frequency.oneOrMoreTimes",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubFrequencyAllOfTheTimeVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronicSubAnswer.frequency.allOfTheTime",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubFrequencyCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painChronic.isPainChronicSubAnswer.frequency.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainManagementTimingVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painManagementTiming.isPainManagementTiming",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainManagementTimingCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painManagementTiming.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painManagementTiming.isPainManagementTimingSubAnswer.isPatientResponding.isPatientResponding",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.painManagementTiming.isPainManagementTimingSubAnswer.isPatientResponding.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpioidMedicationVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedication",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpioidMedicationCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubDrugsTakeVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.drugsTake",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubToleranceVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.tolerance.isTolerance",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubToleranceCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.tolerance.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubWithdrawalVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.withdrawal.isWithdrawal",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubWithdrawalCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.withdrawal.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.increasedUsage.isIncreasedUsage",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.increasedUsage.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.unsuccessEffort.isUnsuccessEffort",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.unsuccessEffort.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubExcessTimeVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.excessTimeSpent.isExcessTimeSpent",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubExcessTimeCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.excessTimeSpent.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.patientKnowledge.isPatientKnowledge",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.patientKnowledge.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.psychologicalProblem.isPsychologicalProblem",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.psychologicalProblem.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubPatientAdvisedVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.patientAdvised.isPatientAdvised",
    ["2023"],
  ],
  [
    "pain_patientExperiencePainSubPatientAdvisedCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.patientAdvised.comment",
    ["2023"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubNaloAvailVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.naloxoneAvailability.hasNaloxone",
    ["2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubNaloAvailCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.naloxoneAvailability.comment",
    ["2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.patientAdvised.isPatientAdvised",
    ["2024", "2025"],
  ],
  [
    "pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedCommentVal",
    "patientExperiencePain.isPatientExperiencePainSubAnswer.opioidMedication.isOpioidMedicationSubAnswer.patientAdvised.comment",
    ["2024", "2025"],
  ],
];

const pain_optionsTreeMapObject2023 = {
  pain_patientExperiencePainSubPainChronicSubDescribeActiveVal: "active",
  pain_patientExperiencePainSubPainChronicSubDescribeHistoryOfVal: "historyOf",
  pain_patientExperiencePainSubPainChronicSubDescribeRuleOutVal: "ruleOut",

  pain_patientExperiencePainSubPainChronicSubFrequencyOccasionalVal:
    "occasional",
  pain_patientExperiencePainSubPainChronicSubFrequencyOneOrMoreTimesVal:
    "oneOrMoreTimes",
  pain_patientExperiencePainSubPainChronicSubFrequencyAllOfTheTimeVal:
    "allOfTheTime",
};

const pain_optionsTreeMapObject2024 = {
  pain_patientExperiencePainSubPainChronicSubFrequencyOccasionalVal:
    "occasional",
  pain_patientExperiencePainSubPainChronicSubFrequencyOneOrMoreTimesVal:
    "oneOrMoreTimes",
  pain_patientExperiencePainSubPainChronicSubFrequencyAllOfTheTimeVal:
    "allOfTheTime",
};

const painOptionsTreeWrappers2023 = [
  [
    "pain_patientExperiencePainSubPainChronicSubDescribe",
    [
      "pain_patientExperiencePainSubPainChronicSubDescribeActiveVal",
      "pain_patientExperiencePainSubPainChronicSubDescribeHistoryOfVal",
      "pain_patientExperiencePainSubPainChronicSubDescribeRuleOutVal",
    ],
  ],
  [
    "pain_patientExperiencePainSubPainChronicSubFrequency",
    [
      "pain_patientExperiencePainSubPainChronicSubFrequencyOccasionalVal",
      "pain_patientExperiencePainSubPainChronicSubFrequencyOneOrMoreTimesVal",
      "pain_patientExperiencePainSubPainChronicSubFrequencyAllOfTheTimeVal",
    ],
  ],
];

const painOptionsTreeWrappers2024 = [
  [
    "pain_patientExperiencePainSubPainChronicSubFrequency",
    [
      "pain_patientExperiencePainSubPainChronicSubFrequencyOccasionalVal",
      "pain_patientExperiencePainSubPainChronicSubFrequencyOneOrMoreTimesVal",
      "pain_patientExperiencePainSubPainChronicSubFrequencyAllOfTheTimeVal",
    ],
  ],
];

export const getPainData = (apiData, year) => {
  let nestedChildsData = {};
  let flattenData = {};

  let flatmap = [];

  pain_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  let treeWrappers = getTreeWrapperByYear(year);

  flatmap.forEach((questionPair) => {
    nestedChildsData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  treeWrappers.forEach((pointerLabels) => {
    flattenData[pointerLabels[0]] =
      getWrapper(nestedChildsData, pointerLabels[1], year) || "";
  });

  const pain_patientExperiencePain = getOptionsSelectedYesNo(
    nestedChildsData["pain_patientExperiencePainVal"],
  );
  const pain_patientExperiencePainComment =
    nestedChildsData["pain_patientExperiencePainCommentVal"];

  const pain_patientExperiencePainSubPainAcute = getOptionsSelectedYesNo(
    nestedChildsData["pain_patientExperiencePainSubPainAcuteVal"],
  );

  const pain_patientExperiencePainSubPainAcuteComment =
    nestedChildsData["pain_patientExperiencePainSubPainAcuteCommentVal"];

  const pain_patientExperiencePainSubPainChronic = getOptionsSelectedYesNo(
    nestedChildsData["pain_patientExperiencePainSubPainChronicVal"],
  );

  const pain_patientExperiencePainSubPainChronicComment =
    nestedChildsData["pain_patientExperiencePainSubPainChronicCommentVal"];

  const pain_patientExperiencePainSubPainChronicSubDescribeComment =
    nestedChildsData[
      "pain_patientExperiencePainSubPainChronicSubDescribeCommentVal"
    ];

  const pain_patientExperiencePainSubPainChronicSubWhere =
    nestedChildsData["pain_patientExperiencePainSubPainChronicSubWhereVal"];

  const pain_patientExperiencePainSubPainChronicSubRateYourPain =
    nestedChildsData[
      "pain_patientExperiencePainSubPainChronicSubRateYourPainVal"
    ];

  const pain_patientExperiencePainSubPainChronicSubFrequencyComment =
    nestedChildsData[
      "pain_patientExperiencePainSubPainChronicSubFrequencyCommentVal"
    ];

  const pain_patientExperiencePainSubPainManagementTiming =
    getOptionsSelectedYesNo(
      nestedChildsData["pain_patientExperiencePainSubPainManagementTimingVal"],
    );

  const pain_patientExperiencePainSubPainManagementTimingComment =
    nestedChildsData[
      "pain_patientExperiencePainSubPainManagementTimingCommentVal"
    ];

  const pain_patientExperiencePainSubPainManagementTimingSubIsPatientResponding =
    getOptionsSelectedYesNo(
      nestedChildsData[
        "pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingVal"
      ],
    );

  const pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingComment =
    nestedChildsData[
      "pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingCommentVal"
    ];

  const pain_patientExperiencePainSubOpioidMedication = getOptionsSelectedYesNo(
    nestedChildsData["pain_patientExperiencePainSubOpioidMedicationVal"],
  );

  const pain_patientExperiencePainSubOpioidMedicationComment =
    nestedChildsData["pain_patientExperiencePainSubOpioidMedicationCommentVal"];

  const pain_patientExperiencePainSubOpoidMedicationSubDrugsTake =
    nestedChildsData[
      "pain_patientExperiencePainSubOpoidMedicationSubDrugsTakeVal"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubTolerance =
    getOptionsSelectedYesNo(
      nestedChildsData[
        "pain_patientExperiencePainSubOpoidMedicationSubToleranceVal"
      ],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubToleranceComment =
    nestedChildsData[
      "pain_patientExperiencePainSubOpoidMedicationSubToleranceCommentVal"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubWithdrawal =
    getOptionsSelectedYesNo(
      nestedChildsData[
        "pain_patientExperiencePainSubOpoidMedicationSubWithdrawalVal"
      ],
    );
  const pain_patientExperiencePainSubOpoidMedicationSubWithdrawalComment =
    nestedChildsData[
      "pain_patientExperiencePainSubOpoidMedicationSubWithdrawalCommentVal"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsage =
    getOptionsSelectedYesNo(
      nestedChildsData[
        "pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageVal"
      ],
    );
  const pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageComment =
    nestedChildsData[
      "pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageCommentVal"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffort =
    getOptionsSelectedYesNo(
      nestedChildsData[
        "pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortVal"
      ],
    );
  const pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortComment =
    nestedChildsData[
      "pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortCommentVal"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubExcessTime =
    getOptionsSelectedYesNo(
      nestedChildsData[
        "pain_patientExperiencePainSubOpoidMedicationSubExcessTimeVal"
      ],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubExcessTimeComment =
    nestedChildsData[
      "pain_patientExperiencePainSubOpoidMedicationSubExcessTimeCommentVal"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledge =
    getOptionsSelectedYesNo(
      nestedChildsData[
        "pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeVal"
      ],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeComment =
    nestedChildsData[
      "pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeCommentVal"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblem =
    getOptionsSelectedYesNo(
      nestedChildsData[
        "pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemVal"
      ],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemComment =
    nestedChildsData[
      "pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemCommentVal"
    ];

  // demo.race.other - nested path
  // demo_race_otherVal - nestedChildsData
  // demo_race - flattenData

  // demo.race.comment - nested path
  // demo_raceCommentVal - nestedChildsData
  // demo_raceComment - flattenData
  flattenData = {
    ...flattenData,
    pain_patientExperiencePain: pain_patientExperiencePain || "",
    pain_patientExperiencePainSubPainAcute:
      pain_patientExperiencePainSubPainAcute || "",
    pain_patientExperiencePainComment: pain_patientExperiencePainComment || "",
    pain_patientExperiencePainSubPainAcuteComment:
      pain_patientExperiencePainSubPainAcuteComment || "",
    pain_patientExperiencePainSubPainChronic:
      pain_patientExperiencePainSubPainChronic || "",
    pain_patientExperiencePainSubPainChronicComment:
      pain_patientExperiencePainSubPainChronicComment || "",

    pain_patientExperiencePainSubPainChronicSubDescribeComment:
      pain_patientExperiencePainSubPainChronicSubDescribeComment || "",
    pain_patientExperiencePainSubPainChronicSubWhere:
      pain_patientExperiencePainSubPainChronicSubWhere || "",
    pain_patientExperiencePainSubPainChronicSubRateYourPain:
      pain_patientExperiencePainSubPainChronicSubRateYourPain || "",
    pain_patientExperiencePainSubPainChronicSubFrequencyComment:
      pain_patientExperiencePainSubPainChronicSubFrequencyComment || "",
    pain_patientExperiencePainSubPainManagementTiming:
      pain_patientExperiencePainSubPainManagementTiming || "",
    pain_patientExperiencePainSubPainManagementTimingComment:
      pain_patientExperiencePainSubPainManagementTimingComment || "",
    pain_patientExperiencePainSubPainManagementTimingSubIsPatientResponding:
      pain_patientExperiencePainSubPainManagementTimingSubIsPatientResponding ||
      "",
    pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingComment:
      pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingComment ||
      "",
    pain_patientExperiencePainSubOpioidMedication:
      pain_patientExperiencePainSubOpioidMedication || "",
    pain_patientExperiencePainSubOpioidMedicationComment:
      pain_patientExperiencePainSubOpioidMedicationComment || "",
    pain_patientExperiencePainSubOpoidMedicationSubDrugsTake:
      pain_patientExperiencePainSubOpoidMedicationSubDrugsTake || "",
    pain_patientExperiencePainSubOpoidMedicationSubTolerance:
      pain_patientExperiencePainSubOpoidMedicationSubTolerance || "",
    pain_patientExperiencePainSubOpoidMedicationSubToleranceComment:
      pain_patientExperiencePainSubOpoidMedicationSubToleranceComment || "",
    pain_patientExperiencePainSubOpoidMedicationSubWithdrawal:
      pain_patientExperiencePainSubOpoidMedicationSubWithdrawal || "",
    pain_patientExperiencePainSubOpoidMedicationSubWithdrawalComment:
      pain_patientExperiencePainSubOpoidMedicationSubWithdrawalComment || "",
    pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsage:
      pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsage || "",
    pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageComment:
      pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageComment ||
      "",
    pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffort:
      pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffort || "",
    pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortComment:
      pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortComment ||
      "",
    pain_patientExperiencePainSubOpoidMedicationSubExcessTime:
      pain_patientExperiencePainSubOpoidMedicationSubExcessTime || "",
    pain_patientExperiencePainSubOpoidMedicationSubExcessTimeComment:
      pain_patientExperiencePainSubOpoidMedicationSubExcessTimeComment || "",
    pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledge:
      pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledge || "",
    pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeComment:
      pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeComment ||
      "",
    pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblem:
      pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblem || "",
    pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemComment:
      pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemComment ||
      "",
  };
  /*

    common fields for all years

    tempdb

    if(2023){

      tempdb
    }else if(2024){

      tempdb
    }


    */

  if (year === "2023") {
    const pain_patientExperiencePainSubPatientAdvised = getOptionsSelectedYesNo(
      nestedChildsData["pain_patientExperiencePainSubPatientAdvisedVal"],
    );
    const pain_patientExperiencePainSubPatientAdvisedComment =
      nestedChildsData["pain_patientExperiencePainSubPatientAdvisedCommentVal"];

    flattenData = {
      ...flattenData,
      pain_patientExperiencePainSubPatientAdvised,
      pain_patientExperiencePainSubPatientAdvisedComment,
    };
  } else if (year === "2024" || year === "2025") {
    const pain_patientExperiencePainSubOpoidMedicationSubNaloAvail =
      getOptionsSelectedYesNo(
        nestedChildsData[
          "pain_patientExperiencePainSubOpoidMedicationSubNaloAvailVal"
        ],
      );
    const pain_patientExperiencePainSubOpoidMedicationSubNaloAvailComment =
      nestedChildsData[
        "pain_patientExperiencePainSubOpoidMedicationSubNaloAvailCommentVal"
      ];
    const pain_patientExperiencePainSubOpoidMedicationSubPatientAdvised =
      getOptionsSelectedYesNo(
        nestedChildsData[
          "pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedVal"
        ],
      );
    const pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedComment =
      nestedChildsData[
        "pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedCommentVal"
      ];

    flattenData = {
      ...flattenData,
      pain_patientExperiencePainSubOpoidMedicationSubNaloAvail,
      pain_patientExperiencePainSubOpoidMedicationSubNaloAvailComment,
      pain_patientExperiencePainSubOpoidMedicationSubPatientAdvised,
      pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedComment,
    };
  }

  return flattenData;
};

export const setPainData = (formData, year) => {
  let flattenData = {};

  // let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  treeWrappers.forEach((pointerLabels) => {
    flattenData = {
      ...flattenData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1], year),
    };
  });

  let payloadData = {};

  const pain_patientExperiencePainVal = setOptionsSelectedYesNo(
    formData["pain_patientExperiencePain"],
  );
  const pain_patientExperiencePainCommentVal =
    formData["pain_patientExperiencePainComment"];

  const pain_patientExperiencePainSubPainAcuteVal = setOptionsSelectedYesNo(
    formData["pain_patientExperiencePainSubPainAcute"],
  );

  const pain_patientExperiencePainSubPainAcuteCommentVal =
    formData["pain_patientExperiencePainSubPainAcuteComment"];

  const pain_patientExperiencePainSubPainChronicVal = setOptionsSelectedYesNo(
    formData["pain_patientExperiencePainSubPainChronic"],
  );

  const pain_patientExperiencePainSubPainChronicCommentVal =
    formData["pain_patientExperiencePainSubPainChronicComment"];

  const pain_patientExperiencePainSubPainChronicSubDescribeCommentVal =
    formData["pain_patientExperiencePainSubPainChronicSubDescribeComment"];

  const pain_patientExperiencePainSubPainChronicSubWhereVal =
    formData["pain_patientExperiencePainSubPainChronicSubWhere"];

  const pain_patientExperiencePainSubPainChronicSubRateYourPainVal =
    formData["pain_patientExperiencePainSubPainChronicSubRateYourPain"];

  const pain_patientExperiencePainSubPainChronicSubFrequencyCommentVal =
    formData["pain_patientExperiencePainSubPainChronicSubFrequencyComment"];

  const pain_patientExperiencePainSubPainManagementTimingVal =
    setOptionsSelectedYesNo(
      formData["pain_patientExperiencePainSubPainManagementTiming"],
    );

  const pain_patientExperiencePainSubPainManagementTimingCommentVal =
    formData["pain_patientExperiencePainSubPainManagementTimingComment"];

  const pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingVal =
    setOptionsSelectedYesNo(
      formData[
        "pain_patientExperiencePainSubPainManagementTimingSubIsPatientResponding"
      ],
    );

  const pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingCommentVal =
    formData[
      "pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingComment"
    ];

  const pain_patientExperiencePainSubOpioidMedicationVal =
    setOptionsSelectedYesNo(
      formData["pain_patientExperiencePainSubOpioidMedication"],
    );

  const pain_patientExperiencePainSubOpioidMedicationCommentVal =
    formData["pain_patientExperiencePainSubOpioidMedicationComment"];

  const pain_patientExperiencePainSubOpoidMedicationSubDrugsTakeVal =
    formData["pain_patientExperiencePainSubOpoidMedicationSubDrugsTake"];

  const pain_patientExperiencePainSubOpoidMedicationSubToleranceVal =
    setOptionsSelectedYesNo(
      formData["pain_patientExperiencePainSubOpoidMedicationSubTolerance"],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubToleranceCommentVal =
    formData["pain_patientExperiencePainSubOpoidMedicationSubToleranceComment"];

  const pain_patientExperiencePainSubOpoidMedicationSubWithdrawalVal =
    setOptionsSelectedYesNo(
      formData["pain_patientExperiencePainSubOpoidMedicationSubWithdrawal"],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubWithdrawalCommentVal =
    formData[
      "pain_patientExperiencePainSubOpoidMedicationSubWithdrawalComment"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageVal =
    setOptionsSelectedYesNo(
      formData["pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsage"],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageCommentVal =
    formData[
      "pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageComment"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortVal =
    setOptionsSelectedYesNo(
      formData[
        "pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffort"
      ],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortCommentVal =
    formData[
      "pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortComment"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubExcessTimeVal =
    setOptionsSelectedYesNo(
      formData["pain_patientExperiencePainSubOpoidMedicationSubExcessTime"],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubExcessTimeCommentVal =
    formData[
      "pain_patientExperiencePainSubOpoidMedicationSubExcessTimeComment"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeVal =
    setOptionsSelectedYesNo(
      formData[
        "pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledge"
      ],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeCommentVal =
    formData[
      "pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeComment"
    ];

  const pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemVal =
    setOptionsSelectedYesNo(
      formData[
        "pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblem"
      ],
    );

  const pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemCommentVal =
    formData[
      "pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemComment"
    ];

  flattenData = {
    ...flattenData,
    pain_patientExperiencePainVal,
    pain_patientExperiencePainSubPainAcuteVal,
    pain_patientExperiencePainCommentVal,
    pain_patientExperiencePainSubPainAcuteCommentVal,
    pain_patientExperiencePainSubPainChronicVal,
    pain_patientExperiencePainSubPainChronicCommentVal,
    pain_patientExperiencePainSubPainChronicSubDescribeCommentVal,
    pain_patientExperiencePainSubPainChronicSubWhereVal,
    pain_patientExperiencePainSubPainChronicSubRateYourPainVal,
    pain_patientExperiencePainSubPainChronicSubFrequencyCommentVal,
    pain_patientExperiencePainSubPainManagementTimingVal,
    pain_patientExperiencePainSubPainManagementTimingCommentVal,
    pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingVal,
    pain_patientExperiencePainSubPainManagementTimingSubIsPatientRespondingCommentVal,
    pain_patientExperiencePainSubOpioidMedicationVal,
    pain_patientExperiencePainSubOpioidMedicationCommentVal,
    pain_patientExperiencePainSubOpoidMedicationSubDrugsTakeVal,
    pain_patientExperiencePainSubOpoidMedicationSubToleranceVal,
    pain_patientExperiencePainSubOpoidMedicationSubToleranceCommentVal,
    pain_patientExperiencePainSubOpoidMedicationSubWithdrawalVal,
    pain_patientExperiencePainSubOpoidMedicationSubWithdrawalCommentVal,
    pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageVal,
    pain_patientExperiencePainSubOpoidMedicationSubIncreasedUsageCommentVal,
    pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortVal,
    pain_patientExperiencePainSubOpoidMedicationSubUnsuccessEffortCommentVal,
    pain_patientExperiencePainSubOpoidMedicationSubExcessTimeVal,
    pain_patientExperiencePainSubOpoidMedicationSubExcessTimeCommentVal,
    pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeVal,
    pain_patientExperiencePainSubOpoidMedicationSubPatientKnowledgeCommentVal,
    pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemVal,
    pain_patientExperiencePainSubOpoidMedicationSubPsychologicalProblemCommentVal,
  };

  if (year === "2023") {
    const pain_patientExperiencePainSubPatientAdvisedVal =
      setOptionsSelectedYesNo(
        formData["pain_patientExperiencePainSubPatientAdvised"],
      );
    const pain_patientExperiencePainSubPatientAdvisedCommentVal =
      formData["pain_patientExperiencePainSubPatientAdvisedComment"];
    flattenData = {
      ...flattenData,
      pain_patientExperiencePainSubPatientAdvisedVal,
      pain_patientExperiencePainSubPatientAdvisedCommentVal,
    };
  } else if (year === "2024" || year === "2025") {
    const pain_patientExperiencePainSubOpoidMedicationSubNaloAvailVal =
      setOptionsSelectedYesNo(
        formData["pain_patientExperiencePainSubOpoidMedicationSubNaloAvail"],
      );
    const pain_patientExperiencePainSubOpoidMedicationSubNaloAvailCommentVal =
      formData[
        "pain_patientExperiencePainSubOpoidMedicationSubNaloAvailComment"
      ];
    const pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedVal =
      setOptionsSelectedYesNo(
        formData[
          "pain_patientExperiencePainSubOpoidMedicationSubPatientAdvised"
        ],
      );
    const pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedCommentVal =
      formData[
        "pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedComment"
      ];

    flattenData = {
      ...flattenData,
      pain_patientExperiencePainSubOpoidMedicationSubNaloAvailVal,
      pain_patientExperiencePainSubOpoidMedicationSubNaloAvailCommentVal,
      pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedVal,
      pain_patientExperiencePainSubOpoidMedicationSubPatientAdvisedCommentVal,
    };
  }

  let flatmap = [];

  pain_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  flatmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], flattenData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getTreeMapperByYear = (year) => {
  let temp = {};
  if (year === "2023") {
    temp = { ...pain_optionsTreeMapObject2023 };
  } else if (year === "2024" || year === "2025") {
    temp = { ...pain_optionsTreeMapObject2024 };
  }

  return temp;
};

const getTreeWrapperByYear = (year) => {
  let temp = [];
  if (year === "2023") {
    temp = [...painOptionsTreeWrappers2023];
  } else if (year === "2024" || year === "2025") {
    temp = [...painOptionsTreeWrappers2024];
  }

  return temp;
};
const getWrapper = (tempData, keyArray, year) => {
  let temp = getOptionsSelected(tempData, keyArray, year);
  return temp;
};

const setWrapper = (tempData, keyArray, year) => {
  let temp = setOptionsSelected(tempData, keyArray, year);
  return temp;
};

const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const setOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelected = (
  optionObject,
  optionArray,
  year,
  multiSelect = false,
) => {
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption;
  let treeMappers = getTreeMapperByYear(year);

  if (multiSelect) {
    selectedOption = selectedVariable.map((variable) => {
      return treeMappers[variable];
    });
  } else {
    selectedOption = treeMappers[selectedVariable[0]];
  }
  return selectedOption;
};

const setOptionsSelected = (
  optionsSelected,
  optionArray,
  year,
  multiSelect = false,
) => {
  let temp = {};

  let treeMappers = getTreeMapperByYear(year);

  if (multiSelect) {
    optionArray.forEach((option) => {
      temp[option] = optionsSelected.includes(treeMappers[option]) ? "1" : "";
    });
  } else {
    optionArray.forEach((option) => {
      temp[option] = treeMappers[option] === optionsSelected ? "1" : "";
    });
  }
  return temp;
};

export const getSubTree = (optionName, optionValue, year) => {
  let temp;
  let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeMapperByYear(year);

  const tempIndex = lodash.findIndex(treeWrappers, function (optTree) {
    return optTree[0] === optionName;
  });

  const tempTreeNodes = treeWrappers?.[tempIndex]
    ? treeWrappers[tempIndex][1]
    : [];

  tempTreeNodes.forEach((node, index) => {
    if (treeMappers[node] === optionValue) {
      temp = node;
    }
  });

  temp = temp && temp !== "" ? temp.substring(0, temp.length - 3) : "";
  temp = temp && temp !== "" ? `${temp}Sub` : "";

  return temp;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let treeKeys = Object.keys(formData);
  treeKeys.forEach((node) => {
    if (node.includes(subTreeRegex)) {
      delete formData[node];
    }
  });
  return formData;
};
