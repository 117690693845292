import {
  getObjectData,
  // getOptionsSelected,
  // getOptionsSelectedYesNo,
  setObjectData,
  // setOptionsSelected,
  // setOptionsSelectedYesNo,
} from "../../../../../../utils/helpers.DynamicForm";
import lodash from "lodash";

const allergies_hashmap = [
  ["allergy_allergiesVal", "allergies.allergies", ["2023", "2024", "2025"]],
  [
    "allergy_allergiesDatatableVal",
    "allergies.allergiesSubAnswers",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_allergiesCommentVal",
    "allergies.comment",
    ["2023", "2024", "2025"],
  ],

  [
    "allergy_medicDatatableVal",
    "medications.medication",
    ["2023", "2024", "2025"],
  ],
  ["medicationsComment", "medications.comment", ["2023", "2024", "2025"]],

  [
    "allergy_counterMedic",
    "counterMedications.counterMedications",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_counterMedicDatatableVal",
    "counterMedications.counterMedicationsSubAnswers",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_counterMedicCommentVal",
    "counterMedications.comment",
    ["2023", "2024", "2025"],
  ],

  [
    "allergy_longTermUsageNoneVal",
    "longTermUse.none",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubAsaVal",
    "longTermUse.longTermUseSubAnswers.ASA",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubSteroidsVal",
    "longTermUse.longTermUseSubAnswers.steroids",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubSteroidsSubTypeSys",
    "longTermUse.longTermUseSubAnswers.steroidsSubAnswers.systemic",
    ["2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubSteroidsSubTypeInhaled",
    "longTermUse.longTermUseSubAnswers.steroidsSubAnswers.inhaled",
    ["2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubSteroidsSubTypeComment",
    "longTermUse.longTermUseSubAnswers.steroidsSubAnswers.comment",
    ["2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubInsulinVal",
    "longTermUse.longTermUseSubAnswers.insulin",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubAntiCoagVal",
    "longTermUse.longTermUseSubAnswers.anticoagulants",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubnsaidVal",
    "longTermUse.longTermUseSubAnswers.nsaid",
    ["2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubantiplateletVal",
    "longTermUse.longTermUseSubAnswers.antiplatelet",
    ["2024", "2025"],
  ],

  [
    "allergy_longTermUsageNoneSubAntiCoagSubDescCoumadinVal",
    "longTermUse.longTermUseSubAnswers.anticoagulantsSubAnswers.coumadin",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubAntiCoagSubDescThrombinVal",
    "longTermUse.longTermUseSubAnswers.anticoagulantsSubAnswers.thrombin",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubAntiCoagSubDescPalvixVal",
    "longTermUse.longTermUseSubAnswers.anticoagulantsSubAnswers.palvix",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubAntiCoagSubDescFactorXaVal",
    "longTermUse.longTermUseSubAnswers.anticoagulantsSubAnswers.factorXa",
    ["2023", "2024", "2025"],
  ],

  [
    "allergy_longTermUsageNoneSubAntiCoagSubDescOtherVal",
    "longTermUse.longTermUseSubAnswers.anticoagulantsSubAnswers.other",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc",
    "longTermUse.longTermUseSubAnswers.anticoagulantsSubAnswers.otherSubAnswer.describe",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubAntiCoagSubDescCommentVal",
    "longTermUse.longTermUseSubAnswers.anticoagulantsSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubStatinVal",
    "longTermUse.longTermUseSubAnswers.statins",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubBiphosVal",
    "longTermUse.longTermUseSubAnswers.biphosphonate",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubOtherVal",
    "longTermUse.longTermUseSubAnswers.other",
    ["2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneSubOtherSubAnswer",
    "longTermUse.longTermUseSubAnswers.otherSubAnswers.describe",
    ["2024", "2025"],
  ],
  [
    "allergy_longTermUsageNoneComment",
    "longTermUse.comment",
    ["2023", "2024", "2025"],
  ],

  [
    "allergy_medicSubTakeMedicVal",
    "medicationCompliance.medicationComplianceSubAnswers.takeYourMedicine.takeYourMedicine",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_medicSubTakeMedicCommentVal",
    "medicationCompliance.medicationComplianceSubAnswers.takeYourMedicine.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_enoughMoney",
    "medicationCompliance.medicationComplianceSubAnswers.financialAbilityForMedicine.hasEnoughMoney",
    ["2024", "2025"],
  ],
  [
    "allergy_enoughMoneyComment",
    "medicationCompliance.medicationComplianceSubAnswers.financialAbilityForMedicine.comment",
    ["2024", "2025"],
  ],
  [
    "allergy_medicSubAttenMedicVal",
    "medicationCompliance.medicationComplianceSubAnswers.attentionToYourMedication.attentionToYourMedication",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_medicSubAttenMedicCommentVal",
    "medicationCompliance.medicationComplianceSubAnswers.attentionToYourMedication.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_medicSubLongTermVal",
    "medicationCompliance.medicationComplianceSubAnswers.longtermBenefit.longtermBenefit",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_medicSubLongTermCommentVal",
    "medicationCompliance.medicationComplianceSubAnswers.longtermBenefit.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_medicSubStopMedicVal",
    "medicationCompliance.medicationComplianceSubAnswers.stopTakingMedicine.stopTakingMedicine",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_medicSubStopMedicCommentVal",
    "medicationCompliance.medicationComplianceSubAnswers.stopTakingMedicine.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_medicSubFeelWorseVal",
    "medicationCompliance.medicationComplianceSubAnswers.feelWorse.feelWorse",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_medicSubFeelWorseCommentVal",
    "medicationCompliance.medicationComplianceSubAnswers.feelWorse.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_medicSubForgotRefillVal",
    "medicationCompliance.medicationComplianceSubAnswers.forgetToRefill.forgetToRefill",
    ["2023", "2024", "2025"],
  ],
  [
    "allergy_medicSubForgotRefillCommentVal",
    "medicationCompliance.medicationComplianceSubAnswers.forgetToRefill.comment",
    ["2023", "2024", "2025"],
  ],

  [
    "allergies_RecommendationsmedicAdherenceAllergy",
    "recommendations.recommendationSubAnswers[0].medicationAdherence",
    ["2023"],
  ],
  [
    "allergies_RecommendationssideEffectsAllergy",
    "recommendations.recommendationSubAnswers[1].sideEffects",
    ["2023"],
  ],
  [
    "allergies_RecommendationsotherAllergy",
    "recommendations.recommendationSubAnswers[2].other",
    ["2023"],
  ],
  [
    "allergies_Recommendations2otherAllergy2",
    "recommendations.recommendationSubAnswers[5].other",
    ["2024", "2025"],
  ],
  [
    "allergies_Recommendations2otherAllergy2Comment",
    "recommendations.recommendationSubAnswers[5].comment",
    ["2024", "2025"],
  ],

  [
    "allergies_RecommendationsmedicAdherenceAllergyComment",
    "recommendations.recommendationSubAnswers[0].comment",
    ["2023"],
  ],
  [
    "allergies_RecommendationssideEffectsAllergyComment",
    "recommendations.recommendationSubAnswers[1].comment",
    ["2023"],
  ],
  [
    "allergies_RecommendationsotherAllergyComment",
    "recommendations.recommendationSubAnswers[2].comment",
    ["2023"],
  ],

  [
    "allergies_Recommendations2medicAdherenceAllergy",
    "recommendations.recommendationSubAnswers[0].medicationAdherence",
    ["2024", "2025"],
  ],
  [
    "allergies_Recommendations2sideEffectsAllergy",
    "recommendations.recommendationSubAnswers[1].sideEffects",
    ["2024", "2025"],
  ],
  [
    "allergies_Recommendations2caseManagRefFollowupAllergy",
    "recommendations.recommendationSubAnswers[2].caseManagementReferral",
    ["2024", "2025"],
  ],
  [
    "allergies_Recommendations2followupWithClinicianSpecialistsAllergy",
    "recommendations.recommendationSubAnswers[3].followUpWithClinician",
    ["2024", "2025"],
  ],
  [
    "allergies_Recommendations2memberDurableMedicalEquipmentAllergy",
    "recommendations.recommendationSubAnswers[4].memberToUseDME",
    ["2024", "2025"],
  ],
  [
    "allergies_Recommendations2caseManagRefFollowupAllergyComment",
    "recommendations.recommendationSubAnswers[2].comment",
    ["2024", "2025"],
  ],
  [
    "allergies_Recommendations2followupWithClinicianSpecialistsAllergyComment",
    "recommendations.recommendationSubAnswers[3].comment",
    ["2024", "2025"],
  ],
  [
    "allergies_Recommendations2memberDurableMedicalEquipmentAllergyComment",
    "recommendations.recommendationSubAnswers[4].comment",
    ["2024", "2025"],
  ],

  [
    "allergies_Recommendations2medicAdherenceAllergyComment",
    "recommendations.recommendationSubAnswers[0].comment",
    ["2024", "2025"],
  ],
  [
    "allergies_Recommendations2sideEffectsAllergyComment",
    "recommendations.recommendationSubAnswers[1].comment",
    ["2024", "2025"],
  ],

  [
    "allergy_longTermUsageNoneSubComment",
    "longTermUse.longTermUseSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],

  [
    "educatedOnImportance",
    "educatedOnImportance.educatedOnImportance",
    ["2023", "2024", "2025"],
  ],
  [
    "educatedOnImportanceCommentVal",
    "educatedOnImportance.comment",
    ["2023", "2024", "2025"],
  ],
];

const allergies_optionsTreeMapObject2023 = {
  allergy_longTermUsageNoneSubAsaVal: "asa",
  allergy_longTermUsageNoneSubSteroidsVal: "steroids",
  allergy_longTermUsageNoneSubInsulinVal: "insulin",
  allergy_longTermUsageNoneSubAntiCoagVal: "anticoagulants",
  allergy_longTermUsageNoneSubStatinVal: "statins",
  allergy_longTermUsageNoneSubBiphosVal: "biphosphonate",

  allergy_longTermUsageNoneSubAntiCoagSubDescCoumadinVal: "coumadin",
  allergy_longTermUsageNoneSubAntiCoagSubDescThrombinVal: "thrombin",
  allergy_longTermUsageNoneSubAntiCoagSubDescPalvixVal: "plavix",
  allergy_longTermUsageNoneSubAntiCoagSubDescFactorXaVal: "factorXa",
  allergy_longTermUsageNoneSubAntiCoagSubDescOtherVal: "other",
};

const allergies_optionsTreeWrappers2023 = [
  [
    "allergy_longTermUsageNoneSub",
    [
      "allergy_longTermUsageNoneSubAsaVal",
      "allergy_longTermUsageNoneSubSteroidsVal",
      "allergy_longTermUsageNoneSubInsulinVal",
      "allergy_longTermUsageNoneSubAntiCoagVal",
      "allergy_longTermUsageNoneSubStatinVal",
      "allergy_longTermUsageNoneSubBiphosVal",
    ],
  ],
  [
    "allergy_longTermUsageNoneSubAntiCoagSubDesc",
    [
      "allergy_longTermUsageNoneSubAntiCoagSubDescCoumadinVal",
      "allergy_longTermUsageNoneSubAntiCoagSubDescThrombinVal",
      "allergy_longTermUsageNoneSubAntiCoagSubDescPalvixVal",
      "allergy_longTermUsageNoneSubAntiCoagSubDescFactorXaVal",
      "allergy_longTermUsageNoneSubAntiCoagSubDescOtherVal",
    ],
  ],
];

const allergies_optionsTreeMapObject2024 = {
  allergy_longTermUsageNoneSubAsaVal: "asa",
  allergy_longTermUsageNoneSubSteroidsVal: "steroids",
  allergy_longTermUsageNoneSubInsulinVal: "insulin",
  allergy_longTermUsageNoneSubAntiCoagVal: "anticoagulants",
  allergy_longTermUsageNoneSubStatinVal: "statins",
  allergy_longTermUsageNoneSubBiphosVal: "biphosphonate",
  allergy_longTermUsageNoneSubnsaidVal: "steroidalAntiInf",
  allergy_longTermUsageNoneSubantiplateletVal: "antiplatelet",
  allergy_longTermUsageNoneSubOtherVal: "other",
  allergy_longTermUsageNoneSubAntiCoagSubDescCoumadinVal: "coumadin",
  allergy_longTermUsageNoneSubAntiCoagSubDescThrombinVal: "thrombin",
  allergy_longTermUsageNoneSubAntiCoagSubDescPalvixVal: "plavix",
  allergy_longTermUsageNoneSubAntiCoagSubDescFactorXaVal: "factorXa",
  allergy_longTermUsageNoneSubAntiCoagSubDescOtherVal: "other",
  allergy_longTermUsageNoneSubSteroidsSubTypeSys: "systemic",
  allergy_longTermUsageNoneSubSteroidsSubTypeInhaled: "inhaled",
};

const allergies_optionsTreeWrappers2024 = [
  [
    "allergy_longTermUsageNoneSub",
    [
      "allergy_longTermUsageNoneSubAsaVal",
      "allergy_longTermUsageNoneSubSteroidsVal",
      "allergy_longTermUsageNoneSubInsulinVal",
      "allergy_longTermUsageNoneSubAntiCoagVal",
      "allergy_longTermUsageNoneSubnsaidVal",
      "allergy_longTermUsageNoneSubantiplateletVal",
      "allergy_longTermUsageNoneSubStatinVal",
      "allergy_longTermUsageNoneSubBiphosVal",
      "allergy_longTermUsageNoneSubOtherVal",
    ],
  ],
  [
    "allergy_longTermUsageNoneSubAntiCoagSubDesc",
    [
      "allergy_longTermUsageNoneSubAntiCoagSubDescCoumadinVal",
      "allergy_longTermUsageNoneSubAntiCoagSubDescThrombinVal",
      "allergy_longTermUsageNoneSubAntiCoagSubDescPalvixVal",
      "allergy_longTermUsageNoneSubAntiCoagSubDescFactorXaVal",
      "allergy_longTermUsageNoneSubAntiCoagSubDescOtherVal",
    ],
  ],
  [
    "allergy_longTermUsageNoneSubSteroidsSubType",
    [
      "allergy_longTermUsageNoneSubSteroidsSubTypeSys",
      "allergy_longTermUsageNoneSubSteroidsSubTypeInhaled",
    ],
  ],
];

export const getAllergiesData = (apiData, year) => {
  let tempDbData = {};

  let flatmap = [];

  allergies_hashmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  flatmap.forEach((questionPair) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  let treeWrappers = getTreeWrapperByYear(year);

  treeWrappers.forEach((pointerLabels) => {
    tempDbData[pointerLabels[0]] =
      getWrapper(tempDbData, pointerLabels[1], year) || "";
  });

  const allergy_allergies = getOptionsSelectedYesNo(
    tempDbData["allergy_allergiesVal"],
  );
  const allergy_allergiesDatatable =
    tempDbData["allergy_allergiesDatatableVal"];
  const allergy_allergiesComment = tempDbData["allergy_allergiesCommentVal"];
  const allergy_medicDatatable = tempDbData["allergy_medicDatatableVal"];
  const medicationsComment = tempDbData["medicationsComment"];

  const allergy_counterMedic = getOptionsSelectedYesNo(
    tempDbData["allergy_counterMedic"],
  );
  const allergy_counterMedicDatatable =
    tempDbData["allergy_counterMedicDatatableVal"];
  const allergy_counterMedicComment =
    tempDbData["allergy_counterMedicCommentVal"];
  const allergy_longTermUsageNone = getOptionsSelectedYesNo(
    tempDbData["allergy_longTermUsageNoneVal"],
  );
  const allergy_longTermUsageNoneComment =
    tempDbData["allergy_longTermUsageNoneComment"];
  const allergy_longTermUsageNoneSubAntiCoagSubDescComment =
    tempDbData["allergy_longTermUsageNoneSubAntiCoagSubDescCommentVal"];
  const allergy_medicSubTakeMedic = getOptionsSelectedYesNo(
    tempDbData["allergy_medicSubTakeMedicVal"],
  );
  const allergy_medicSubTakeMedicComment =
    tempDbData["allergy_medicSubTakeMedicCommentVal"];
  const allergy_medicSubAttenMedic = getOptionsSelectedYesNo(
    tempDbData["allergy_medicSubAttenMedicVal"],
  );
  const allergy_medicSubAttenMedicComment =
    tempDbData["allergy_medicSubAttenMedicCommentVal"];
  const allergy_medicSubLongTerm = getOptionsSelectedYesNo(
    tempDbData["allergy_medicSubLongTermVal"],
  );
  const allergy_medicSubLongTermComment =
    tempDbData["allergy_medicSubLongTermCommentVal"];
  const allergy_medicSubStopMedic = getOptionsSelectedYesNo(
    tempDbData["allergy_medicSubStopMedicVal"],
  );
  const allergy_medicSubStopMedicComment =
    tempDbData["allergy_medicSubStopMedicCommentVal"];
  const allergy_medicSubFeelWorse = getOptionsSelectedYesNo(
    tempDbData["allergy_medicSubFeelWorseVal"],
  );
  const allergy_medicSubFeelWorseComment =
    tempDbData["allergy_medicSubFeelWorseCommentVal"];
  const allergy_medicSubForgotRefill = getOptionsSelectedYesNo(
    tempDbData["allergy_medicSubForgotRefillVal"],
  );
  const allergy_medicSubForgotRefillComment =
    tempDbData["allergy_medicSubForgotRefillCommentVal"];

  const allergies_RecommendationsmedicAdherenceAllergyComment =
    tempDbData["allergies_RecommendationsmedicAdherenceAllergyComment"];

  const allergies_RecommendationssideEffectsAllergyComment =
    tempDbData["allergies_RecommendationssideEffectsAllergyComment"];

  const allergies_RecommendationsotherAllergyComment =
    tempDbData["allergies_RecommendationsotherAllergyComment"];

  const allergies_Recommendations2medicAdherenceAllergyComment =
    tempDbData["allergies_Recommendations2medicAdherenceAllergyComment"];

  const allergies_Recommendations2sideEffectsAllergyComment =
    tempDbData["allergies_Recommendations2sideEffectsAllergyComment"];

  const educatedOnImportance = getSelectedCheckboxes(
    [tempDbData["educatedOnImportance"]],
    ["educateImportance"],
  );

  const educatedOnImportanceComment =
    tempDbData["educatedOnImportanceCommentVal"];

  const allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc =
    tempDbData["allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc"];
  const allergy_longTermUsageNoneSubComment =
    tempDbData["allergy_longTermUsageNoneSubComment"];

  const allergy_longTermUsageNoneSubOtherSubAnswer =
    tempDbData["allergy_longTermUsageNoneSubOtherSubAnswer"];

  tempDbData = {
    ...tempDbData,
    allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc,
    allergy_allergies: allergy_allergies || [],
    allergy_allergiesDatatable: allergy_allergiesDatatable || "",
    allergy_allergiesComment: allergy_allergiesComment || "",
    allergy_medicDatatable: allergy_medicDatatable || "",
    medicationsComment: medicationsComment || "",
    allergy_counterMedic: allergy_counterMedic,
    allergy_counterMedicDatatable: allergy_counterMedicDatatable || "",
    allergy_counterMedicComment: allergy_counterMedicComment || "",
    allergy_longTermUsageNone: allergy_longTermUsageNone || [],
    allergy_longTermUsageNoneComment: allergy_longTermUsageNoneComment || "",
    allergy_longTermUsageNoneSubAntiCoagSubDescComment:
      allergy_longTermUsageNoneSubAntiCoagSubDescComment || "",
    allergy_medicSubTakeMedic: allergy_medicSubTakeMedic || [],
    allergy_medicSubTakeMedicComment: allergy_medicSubTakeMedicComment || "",
    allergy_medicSubAttenMedic: allergy_medicSubAttenMedic || [],
    allergy_medicSubAttenMedicComment: allergy_medicSubAttenMedicComment || "",
    allergy_medicSubLongTerm: allergy_medicSubLongTerm || [],
    allergy_medicSubLongTermComment: allergy_medicSubLongTermComment || "",
    allergy_medicSubStopMedic: allergy_medicSubStopMedic || [],
    allergy_medicSubStopMedicComment: allergy_medicSubStopMedicComment || "",
    allergy_medicSubFeelWorse: allergy_medicSubFeelWorse || [],
    allergy_medicSubFeelWorseComment: allergy_medicSubFeelWorseComment || "",
    allergy_medicSubForgotRefill: allergy_medicSubForgotRefill || [],
    allergy_medicSubForgotRefillComment:
      allergy_medicSubForgotRefillComment || "",
    allergies_RecommendationsmedicAdherenceAllergyComment:
      allergies_RecommendationsmedicAdherenceAllergyComment || "",
    allergies_RecommendationssideEffectsAllergyComment:
      allergies_RecommendationssideEffectsAllergyComment || "",
    allergies_Recommendations2medicAdherenceAllergyComment:
      allergies_Recommendations2medicAdherenceAllergyComment || "",
    allergies_Recommendations2sideEffectsAllergyComment:
      allergies_Recommendations2sideEffectsAllergyComment || "",

    allergies_RecommendationsotherAllergyComment:
      allergies_RecommendationsotherAllergyComment || "",
    educatedOnImportance: educatedOnImportance || "",
    educatedOnImportanceComment: educatedOnImportanceComment || "",

    allergy_longTermUsageNoneSubComment,
    allergy_longTermUsageNoneSubOtherSubAnswer,
  };

  if (year === "2024" || year === "2025") {
    const allergy_enoughMoney = getOptionsSelectedYesNo(
      tempDbData["allergy_enoughMoney"],
    );
    const allergy_enoughMoneyComment = tempDbData["allergy_enoughMoneyComment"];

    const allergies_Recommendations2medicAdherenceAllergyComment =
      tempDbData["allergies_Recommendations2medicAdherenceAllergyComment"];

    const allergies_Recommendations2sideEffectsAllergyComment =
      tempDbData["allergies_Recommendations2sideEffectsAllergyComment"];
    const allergies_Recommendations2memberDurableMedicalEquipmentAllergyComment =
      tempDbData[
        "allergies_Recommendations2memberDurableMedicalEquipmentAllergyComment"
      ];
    const allergies_Recommendations2followupWithClinicianSpecialistsAllergyComment =
      tempDbData[
        "allergies_Recommendations2followupWithClinicianSpecialistsAllergyComment"
      ];
    const allergies_Recommendations2caseManagRefFollowupAllergyComment =
      tempDbData[
        "allergies_Recommendations2caseManagRefFollowupAllergyComment"
      ];
    const allergies_Recommendations2otherAllergy2Comment =
      tempDbData["allergies_Recommendations2otherAllergy2Comment"];

    const allergies_Recommendations2 = getSelectedCheckboxesOther(
      [
        tempDbData["allergies_Recommendations2medicAdherenceAllergy"],
        tempDbData["allergies_Recommendations2sideEffectsAllergy"],
        tempDbData["allergies_Recommendations2caseManagRefFollowupAllergy"],
        tempDbData[
          "allergies_Recommendations2followupWithClinicianSpecialistsAllergy"
        ],
        tempDbData[
          "allergies_Recommendations2memberDurableMedicalEquipmentAllergy"
        ],
        tempDbData["allergies_Recommendations2otherAllergy2"],
      ],
      [
        "medicAdherenceAllergy",
        "sideEffectsAllergy",
        "caseManagRefFollowupAllergy",
        "followupWithClinicianSpecialistsAllergy",
        "memberDurableMedicalEquipmentAllergy",
        "otherAllergy2",
      ],
    );
    const allergy_longTermUsageNoneSubSteroidsSubTypeComment =
      tempDbData["allergy_longTermUsageNoneSubSteroidsSubTypeComment"];

    tempDbData = {
      ...tempDbData,

      allergy_enoughMoney,
      allergies_Recommendations2,
      allergies_Recommendations2sideEffectsAllergyComment,
      allergies_Recommendations2medicAdherenceAllergyComment,
      allergy_enoughMoneyComment,
      allergies_Recommendations2otherAllergy2Comment,
      allergies_Recommendations2memberDurableMedicalEquipmentAllergyComment,
      allergies_Recommendations2followupWithClinicianSpecialistsAllergyComment,
      allergies_Recommendations2caseManagRefFollowupAllergyComment,
      allergy_longTermUsageNoneSubSteroidsSubTypeComment,
    };
  } else if (year === "2023") {
    const allergies_RecommendationsmedicAdherenceAllergyComment =
      tempDbData["allergies_RecommendationsmedicAdherenceAllergyComment"];

    const allergies_RecommendationssideEffectsAllergyComment =
      tempDbData["allergies_RecommendationssideEffectsAllergyComment"];

    const allergies_RecommendationsotherAllergyComment =
      tempDbData["allergies_RecommendationsotherAllergyComment"];

    const allergies_RecommendationsmemberDurableMedicalEquipmentAllergyComment =
      tempDbData[
        "allergies_RecommendationsmemberDurableMedicalEquipmentAllergyComment"
      ];
    const allergies_RecommendationsfollowupWithClinicianSpecialistsAllergyComment =
      tempDbData[
        "allergies_RecommendationsfollowupWithClinicianSpecialistsAllergyComment"
      ];
    const allergies_RecommendationscaseManagRefFollowupAllergyComment =
      tempDbData["allergies_RecommendationscaseManagRefFollowupAllergyComment"];
    const allergies_Recommendations = getSelectedCheckboxesOther(
      [
        tempDbData["allergies_RecommendationsmedicAdherenceAllergy"],
        tempDbData["allergies_RecommendationssideEffectsAllergy"],
        tempDbData["allergies_RecommendationsotherAllergy"],
      ],

      ["medicAdherenceAllergy", "sideEffectsAllergy", "otherAllergy"],
    );
    tempDbData = {
      ...tempDbData,

      allergies_Recommendations,

      allergies_RecommendationsotherAllergyComment,
      allergies_RecommendationsmemberDurableMedicalEquipmentAllergyComment,
      allergies_RecommendationsfollowupWithClinicianSpecialistsAllergyComment,
      allergies_RecommendationscaseManagRefFollowupAllergyComment,
      allergies_RecommendationsmedicAdherenceAllergyComment,
      allergies_RecommendationssideEffectsAllergyComment,
    };
  }

  return tempDbData;
};

export const setAllergiesData = (formData, year) => {
  let tempData = {};
  let treeWrappers = getTreeWrapperByYear(year);
  treeWrappers.forEach((pointerLabels) => {
    tempData = {
      ...tempData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1], year),
    };
  });

  let payloadData = {};

  const allergy_allergiesVal = setOptionsSelectedYesNo(
    formData["allergy_allergies"],
  );
  const allergy_allergiesDatatableVal = formData["allergy_allergiesDatatable"];
  const allergy_allergiesCommentVal = formData["allergy_allergiesComment"];
  const allergy_medicDatatableVal = formData["allergy_medicDatatable"];
  const medicationsComment = formData["medicationsComment"];
  const allergy_counterMedic = setOptionsSelectedYesNo(
    formData["allergy_counterMedic"],
  );

  const allergy_counterMedicDatatableVal =
    formData["allergy_counterMedicDatatable"];
  const allergy_counterMedicCommentVal =
    formData["allergy_counterMedicComment"];
  const allergy_longTermUsageNoneVal = setOptionsSelectedYesNo(
    formData["allergy_longTermUsageNone"],
  );
  const allergy_longTermUsageNoneComment =
    formData["allergy_longTermUsageNoneComment"];
  const allergy_longTermUsageNoneSubAntiCoagSubDescCommentVal =
    formData["allergy_longTermUsageNoneSubAntiCoagSubDescComment"];
  const allergy_medicSubTakeMedicVal = setOptionsSelectedYesNo(
    formData["allergy_medicSubTakeMedic"],
  );
  const allergy_medicSubTakeMedicCommentVal =
    formData["allergy_medicSubTakeMedicComment"];
  const allergy_medicSubAttenMedicVal = setOptionsSelectedYesNo(
    formData["allergy_medicSubAttenMedic"],
  );
  const allergy_medicSubAttenMedicCommentVal =
    formData["allergy_medicSubAttenMedicComment"];
  const allergy_medicSubLongTermVal = setOptionsSelectedYesNo(
    formData["allergy_medicSubLongTerm"],
  );
  const allergy_medicSubLongTermCommentVal =
    formData["allergy_medicSubLongTermComment"];
  const allergy_medicSubStopMedicVal = setOptionsSelectedYesNo(
    formData["allergy_medicSubStopMedic"],
  );
  const allergy_medicSubStopMedicCommentVal =
    formData["allergy_medicSubStopMedicComment"];
  const allergy_medicSubFeelWorseVal = setOptionsSelectedYesNo(
    formData["allergy_medicSubFeelWorse"],
  );
  const allergy_medicSubFeelWorseCommentVal =
    formData["allergy_medicSubFeelWorseComment"];
  const allergy_medicSubForgotRefillVal = setOptionsSelectedYesNo(
    formData["allergy_medicSubForgotRefill"],
  );
  const allergy_medicSubForgotRefillCommentVal =
    formData["allergy_medicSubForgotRefillComment"];

  const educatedOnImportanceCommentVal =
    formData["educatedOnImportanceComment"];

  const educatedSelected = formData["educatedOnImportance"];
  const educatedOnImportance = setSelectedCheckboxes(
    educatedSelected,
    ["educatedOnImportance"],
    ["educateImportance"],
  );
  const allergies_RecommendationsSelected =
    formData["allergies_Recommendations"];

  const allergies_RecommendationsSelected2 =
    formData["allergies_Recommendations2"];

  const allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc =
    formData["allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc"];

  const allergy_longTermUsageNoneSubComment =
    formData["allergy_longTermUsageNoneSubComment"];

  const allergy_longTermUsageNoneSubOtherSubAnswer =
    formData["allergy_longTermUsageNoneSubOtherSubAnswer"];

  const allergy_longTermUsageNoneSubSteroidsSubTypeComment =
    formData["allergy_longTermUsageNoneSubSteroidsSubTypeComment"];

  tempData = {
    ...tempData,
    allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc,

    allergy_allergiesVal: allergy_allergiesVal || "",
    allergy_allergiesDatatableVal: allergy_allergiesDatatableVal || "",
    allergy_allergiesCommentVal: allergy_allergiesCommentVal || "",
    allergy_medicDatatableVal: allergy_medicDatatableVal || "",
    medicationsComment: medicationsComment || "",
    allergy_counterMedic: allergy_counterMedic || "",
    allergy_counterMedicDatatableVal: allergy_counterMedicDatatableVal || "",
    allergy_counterMedicCommentVal: allergy_counterMedicCommentVal || "",
    allergy_longTermUsageNoneVal: allergy_longTermUsageNoneVal || "",
    allergy_longTermUsageNoneComment: allergy_longTermUsageNoneComment || "",
    allergy_longTermUsageNoneSubAntiCoagSubDescCommentVal:
      allergy_longTermUsageNoneSubAntiCoagSubDescCommentVal || "",
    allergy_medicSubTakeMedicVal: allergy_medicSubTakeMedicVal || "",
    allergy_medicSubTakeMedicCommentVal:
      allergy_medicSubTakeMedicCommentVal || "",
    allergy_medicSubAttenMedicVal: allergy_medicSubAttenMedicVal || "",
    allergy_medicSubAttenMedicCommentVal:
      allergy_medicSubAttenMedicCommentVal || "",
    allergy_medicSubLongTermVal: allergy_medicSubLongTermVal || "",
    allergy_medicSubLongTermCommentVal:
      allergy_medicSubLongTermCommentVal || "",
    allergy_medicSubStopMedicVal: allergy_medicSubStopMedicVal || "",
    allergy_medicSubStopMedicCommentVal:
      allergy_medicSubStopMedicCommentVal || "",
    allergy_medicSubFeelWorseVal: allergy_medicSubFeelWorseVal || "",
    allergy_medicSubFeelWorseCommentVal:
      allergy_medicSubFeelWorseCommentVal || "",
    allergy_medicSubForgotRefillVal: allergy_medicSubForgotRefillVal || "",
    allergy_medicSubForgotRefillCommentVal:
      allergy_medicSubForgotRefillCommentVal || "",
    // allergy_recMedicAdherenceVal: allergy_recMedicAdherenceVal || "",
    // allergy_recSideEffectsVal: allergy_recSideEffectsVal || "",
    // allergy_recOtherVal: allergy_recOtherVal || "",

    educatedOnImportance: educatedOnImportance || "",
    educatedOnImportanceCommentVal: educatedOnImportanceCommentVal || "",
    // ...allergies_Recommendations,
    allergy_longTermUsageNoneSubComment,
    allergy_longTermUsageNoneSubSteroidsSubTypeComment,
    // allergies_RecommendationsmedicAdherenceAllergyComment:
    //   allergies_RecommendationsmedicAdherenceAllergyComment || "",

    // allergies_RecommendationssideEffectsAllergyComment:
    //   allergies_RecommendationssideEffectsAllergyComment || "",

    //   allergies_Recommendations2medicAdherenceAllergyComment:
    //   allergies_Recommendations2medicAdherenceAllergyComment || "",

    // allergies_Recommendations2sideEffectsAllergyComment:
    //   allergies_Recommendations2sideEffectsAllergyComment || "",

    // allergies_RecommendationsotherAllergyComment:
    //   allergies_RecommendationsotherAllergyComment || "",

    allergy_longTermUsageNoneSubOtherSubAnswer,
    //   allergy_enoughMoney,
    //   allergy_enoughMoneyComment,
    //   allergies_Recommendations2otherAllergy2Comment,
    // allergies_RecommendationsmemberDurableMedicalEquipmentAllergyComment,
    // allergies_RecommendationsfollowupWithClinicianSpecialistsAllergyComment,
    // allergies_RecommendationscaseManagRefFollowupAllergyComment,
    // allergies_Recommendations2memberDurableMedicalEquipmentAllergyComment,
    // allergies_Recommendations2followupWithClinicianSpecialistsAllergyComment,
    // allergies_Recommendations2caseManagRefFollowupAllergyComment,
  };

  if (year === "2023") {
    const allergies_RecommendationsmedicAdherenceAllergyComment =
      formData["allergies_RecommendationsmedicAdherenceAllergyComment"];

    const allergies_RecommendationssideEffectsAllergyComment =
      formData["allergies_RecommendationssideEffectsAllergyComment"];

    const allergies_RecommendationsotherAllergyComment =
      formData["allergies_RecommendationsotherAllergyComment"];

    const allergies_RecommendationsmemberDurableMedicalEquipmentAllergyComment =
      formData[
        "allergies_RecommendationsmemberDurableMedicalEquipmentAllergyComment"
      ];
    const allergies_RecommendationsfollowupWithClinicianSpecialistsAllergyComment =
      formData[
        "allergies_RecommendationsfollowupWithClinicianSpecialistsAllergyComment"
      ];
    const allergies_RecommendationscaseManagRefFollowupAllergyComment =
      formData["allergies_RecommendationscaseManagRefFollowupAllergyComment"];
    const allergies_Recommendations = setSelectedCheckboxesOther(
      allergies_RecommendationsSelected,

      [
        "allergies_RecommendationsmedicAdherenceAllergy",
        "allergies_RecommendationssideEffectsAllergy",
        "allergies_RecommendationsotherAllergy",
      ],
      ["medicAdherenceAllergy", "sideEffectsAllergy", "otherAllergy"],
    );

    tempData = {
      ...tempData,

      ...allergies_Recommendations,
      allergies_RecommendationsmedicAdherenceAllergyComment,
      allergies_RecommendationssideEffectsAllergyComment,

      allergies_RecommendationsotherAllergyComment,
      allergies_RecommendationsmemberDurableMedicalEquipmentAllergyComment,
      allergies_RecommendationsfollowupWithClinicianSpecialistsAllergyComment,
      allergies_RecommendationscaseManagRefFollowupAllergyComment,
    };
  } else if (year === "2024" || year === "2025") {
    const allergy_enoughMoney = setOptionsSelectedYesNo(
      formData["allergy_enoughMoney"],
    );
    const allergy_enoughMoneyComment = formData["allergy_enoughMoneyComment"];

    const allergies_Recommendations2medicAdherenceAllergyComment =
      formData["allergies_Recommendations2medicAdherenceAllergyComment"];

    const allergies_Recommendations2sideEffectsAllergyComment =
      formData["allergies_Recommendations2sideEffectsAllergyComment"];
    const allergies_Recommendations2memberDurableMedicalEquipmentAllergyComment =
      formData[
        "allergies_Recommendations2memberDurableMedicalEquipmentAllergyComment"
      ];
    const allergies_Recommendations2followupWithClinicianSpecialistsAllergyComment =
      formData[
        "allergies_Recommendations2followupWithClinicianSpecialistsAllergyComment"
      ];
    const allergies_Recommendations2caseManagRefFollowupAllergyComment =
      formData["allergies_Recommendations2caseManagRefFollowupAllergyComment"];
    const allergies_Recommendations2otherAllergy2Comment =
      formData["allergies_Recommendations2otherAllergy2Comment"];

    const allergies_Recommendations2 = setSelectedCheckboxesOther(
      allergies_RecommendationsSelected2,
      [
        "allergies_Recommendations2medicAdherenceAllergy",
        "allergies_Recommendations2sideEffectsAllergy",
        "allergies_Recommendations2caseManagRefFollowupAllergy",
        "allergies_Recommendations2followupWithClinicianSpecialistsAllergy",
        "allergies_Recommendations2memberDurableMedicalEquipmentAllergy",
        "allergies_Recommendations2otherAllergy2",
      ],
      [
        "medicAdherenceAllergy",
        "sideEffectsAllergy",
        "caseManagRefFollowupAllergy",
        "followupWithClinicianSpecialistsAllergy",
        "memberDurableMedicalEquipmentAllergy",
        "otherAllergy2",
      ],
    );
    tempData = {
      ...tempData,
      ...allergies_Recommendations2,
      allergy_enoughMoney,
      allergy_enoughMoneyComment,
      allergies_Recommendations2otherAllergy2Comment,
      allergies_Recommendations2sideEffectsAllergyComment,
      allergies_Recommendations2medicAdherenceAllergyComment,
      allergies_Recommendations2memberDurableMedicalEquipmentAllergyComment,
      allergies_Recommendations2followupWithClinicianSpecialistsAllergyComment,
      allergies_Recommendations2caseManagRefFollowupAllergyComment,
    };
  }

  let flatmap = [];

  allergies_hashmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  flatmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
    //}
  });

  // allergies_hashmap.forEach((questionPair) => {
  //   // if (tempData[questionPair[0]] !== "") {
  //   setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
  //   // }
  // });

  return payloadData;
};

const getTreeMapperByYear = (year) => {
  let temp = {};
  if (year === "2023") {
    temp = { ...allergies_optionsTreeMapObject2023 };
  } else if (year === "2024" || year === "2025") {
    temp = { ...allergies_optionsTreeMapObject2024 };
  }

  return temp;
};

const getTreeWrapperByYear = (year) => {
  let temp = [];
  if (year === "2023") {
    temp = [...allergies_optionsTreeWrappers2023];
  } else if (year === "2024" || year === "2025") {
    temp = [...allergies_optionsTreeWrappers2024];
  }

  return temp;
};

const getWrapper = (tempData, keyArray, year) => {
  let temp = getOptionsSelected(tempData, keyArray, year);
  return temp;
};

const setWrapper = (tempData, keyArray, year) => {
  let temp = setOptionsSelected(tempData, keyArray, year);
  return temp;
};

const setOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue?.includes("yes")) {
    selectedValue = "1";
  } else if (optionValue?.includes("no")) {
    selectedValue = "2";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};
const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getSelectedCheckboxes = (optionKeyArr, optionValueArr) => {
  let temp = [];

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      optionValueArr[index] && temp.push(optionValueArr[index]);
    }
  });

  return temp;
};

const setSelectedCheckboxes = (optionArr, optionKeyArr, optionValueArr) => {
  let temp = {};

  optionValueArr?.forEach((ele, index) => {
    if (optionArr?.includes(ele)) {
      temp[optionKeyArr[index]] = "1";
    }
  });

  const keys = Object.keys(temp);
  if (keys.length === 1 && temp[keys[0]] === "1") {
    return "1";
  } else {
    return "2";
  }
};

export const getSelectedCheckboxesOther = (optionKeyArr, optionValueArr) => {
  let temp = [];

  optionKeyArr?.forEach((ele, index) => {
    if (ele === "1") {
      optionValueArr[index] && temp.push(optionValueArr[index]);
    }
  });

  return temp;
};

export const setSelectedCheckboxesOther = (
  optionArr,
  optionKeyArr,
  optionValueArr,
) => {
  let temp = {};

  optionValueArr.forEach((ele, index) => {
    if (optionArr?.includes(ele)) {
      temp[optionKeyArr[index]] = "1";
    }
  });

  return temp;
};

export const getOptionsSelected = (optionObject, optionArray, year) => {
  let treeMappers = getTreeMapperByYear(year);
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption = selectedVariable.map((variable) => {
    return treeMappers[variable];
  });

  return selectedOption;
};

export const setOptionsSelected = (optionsSelected, optionArray, year) => {
  let temp = {};
  let treeMappers = getTreeMapperByYear(year);

  optionArray.forEach((option) => {
    temp[option] =
      optionsSelected &&
      optionsSelected.length > 0 &&
      optionsSelected.includes(treeMappers[option])
        ? "1"
        : "";
  });
  return temp;
};

export const getSubTree = (optionName, optionValue, year) => {
  let temp;
  let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  const tempIndex = lodash.findIndex(treeWrappers, function (optTree) {
    return optTree[0] === optionName;
  });

  const tempTreeNodes = treeWrappers?.[tempIndex]
    ? treeWrappers[tempIndex][1]
    : [];

  tempTreeNodes.forEach((node, index) => {
    if (treeMappers[node] === optionValue) {
      temp = node;
    }
  });

  temp = temp && temp !== "" ? temp.substring(0, temp.length - 3) : "";
  temp = temp && temp !== "" ? `${temp}Sub` : "";

  return temp;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let treeKeys = Object.keys(formData);
  treeKeys.forEach((node) => {
    if (node.includes(subTreeRegex)) {
      delete formData[node];
    }
  });
  return formData;
};
