import {
  createContext,
  useContext,
  useEffect,
  useState,
  useLocation,
} from "react";
import toast from "react-hot-toast";
import { userCategoriesList } from "../components/Dashboard/ReactDND/userCategories";
import AlertContext from "./AlertContext";
import Loader from "../components/Loader/Loader";
import { getApi, postApi, putApi } from "../utils/axiosApiWrapper";
import _ from "lodash";
import Labels from "../resource_files/Labels.resx";
//import Labels from "../../../../resource_files/Labels.resx";
//import { initialWordList } from "../../../../components/Dashboard/DynamicFormMUI/staticForm";
import { initialWordList } from "../components/Dashboard/DynamicFormMUI/staticForm";
//import { setRosData2024 } from "./Sections/ROS/reviewOfSystemMapping2024";
import { setRosData2024 } from "../components/Dashboard/MemberList/Fill_HRA/Sections/ROS/reviewOfSystemMapping2024";
import { setRosData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/ROS/reviewOfSystemMapping";
import { setPainData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Pain/painMapping";
import { setCovidScreeningData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/CovidScreening/covidScreeningMapping";
import { setSelfAssessData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/SelfAssessment/selfAssessmentMapping";
import { setfamilyData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/FamilyHistory/familyHistoryMapping";
import { setMedicalData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/MedicalHistory/medicalHistoryMapping";
import { setActivitiesData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Activities/activitiesMapping";
import { setVitalData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Vital/vitalMapping";
import { setMinicogData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Minicog/minicogMapping";
import { setScreeningsData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Screenings/screeningsMapping";
import { setExamReviewData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/ExamReview/examReviewMapping";
import { setHomeSafetyData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/HomeSafety/homeSafetyMapping";
import { setPreventiveData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/preventiveCare/preventiveMapping";
import { setAllergiesData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Allergies/allergiesMapping";
import { setDemographicsData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Demographics/demographicsMapping";
const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    email: "",
    lastName: "",
    firstName: "",
    roleId: "",
    roleName: "",
    homePage: "",
    signatureStatus: "",
    _id: "",
  });
  const [sidebarData, setSidebarData] = useState([]);
  const [clientData, setClientData] = useState();
  const [assessmentData, setAssessmentData] = useState(null);
  const [profileImg, setProfileImg] = useState("");
  const [getAssessmentData, setGetAssessmentData] = useState(null);
  // const [getAssessmentData, setGetAssessmentData] = useState(
  //   () => async () => {},
  // );
  const [programTypesOptions, setProgramTypesOptions] = useState([]);
  const [lobTypesOptions, setLobTypesOptions] = useState([]);
  const [lobOptionData, setLobOptionData] = useState([]);
  const [hasLoggedIn, setHasLoggedIn] = useState(true);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hraStatusData, setHraStatusData] = useState([]);
  const [clinicianDataList, setClinicianDataList] = useState([]);
  const [visitTypeOptionsData, setVisitTypeOptionsData] = useState([]);

  const [_memberIdForQueryForm, _setMemberIdForQueryForm] = useState("");
  const [memberIdForFormNames, setMemberIdForFormNames] = useState();
  const [memberDatasForNames, setMemberDatasForNames] = useState({});
  const [apiResponseData, setApiResponseData] = useState(null);
  const [selectedClientIdContext, setSelectedClientIdContext] = useState("");
  const [selectedMultipleRowId, setSelectedMultipleRowId] = useState("");
  const [password, setPassword] = useState(""); // State to store the password

  const alertContext = useContext(AlertContext);

  const storedScreeningTypes = localStorage.getItem("clientScreening");

  const [autoSaveRosData, setAutoSaveRosData] = useState({});
  const [autoSavePainData, setAutoSavePainData] = useState({});
  const [autoSaveCovidScreeningData, setAutoSaveCovidScreeningData] = useState(
    {},
  );
  const [autoSaveSelfAssessmentData, setAutoSaveSelfAssessmentData] = useState(
    {},
  );
  const [autoSaveFamilyHistoryData, setAutoSaveFamilyHistoryData] = useState(
    {},
  );
  const [autoSaveMedicalHistory, setAutoSaveMedicalHistory] = useState({});
  const [autoSaveActivitiesData, setAutoSaveActivitiesData] = useState({});
  const [autoSaveVitalData, setAutoSaveVitalData] = useState({});
  const [autoSaveMinicogData, setAutoSaveMiniCogData] = useState({});
  const [autoSaveScreeningNeeded, setAutoSaveScreeningNeeded] = useState({});
  const [autoSaveExamReviewData, setAutoSaveExamReviewData] = useState({});
  const [autoSaveHomeSafetyData, setAutoSaveHomeSafety] = useState({});
  const [autoSavePatientData, setAutoSavePatientData] = useState({});
  const [autoSavePreventiveCareData, setAutoSavePreventiveCareData] = useState(
    {},
  );
  const [autoSaveAllergiesData, setAutoSaveAllergiesData] = useState({});
  const [autoSaveDemographicsData, setAutoSaveDemographicsData] = useState({});
  const [autoSavePrevDocData, setAutoSavePrevDocData] = useState({});
  const [autoSavePedsDemographicsData, setAutoSavePedsDemographicsData] =
    useState({});
  const [autoSavePedsAssessInfo, setAutoSavePedsAssessInfo] = useState({});
  const [autoSavePedsPreventativeCare, setAutoSavePedsPreventativeCare] =
    useState({});
  const [
    autoSavePedsAssessmentofDiagnosis,
    setAutoSavePedsAssessmentofDiagnosis,
  ] = useState({});
  const [autoSavePedsSocialSafty, setAutoSavePedsSocialSafty] = useState({});
  const [autoSavePedsVital, setAutoSavePedsVital] = useState({});
  const [autoSavePedsPatientSumm, setAutoSavePedsPatientSumm] = useState({});

  const [autoSavePedsVoc, setAutoSavePedsVoc] = useState({});
  const [autoSavePedsCovidScreening, setAutoSavePedsCovidScreening] = useState(
    {},
  );

  const [autoSavepedsallergiesRowData, setAutoSavePedsAllergiesRowData] =
    useState({});
  const [autoSavefirstLevelCommentObj, setAutoSavePedsFirstLevelCommentObj] =
    useState({});
  const [
    autoSavecounterMedicationsRowData,
    setAutoSavePedsCounterMedicationsRowData,
  ] = useState();
  const [
    autoSavecounterMedicationsRowDataQues15,
    setAutoSavePedsCounterMedicationsRowDataQues15,
  ] = useState();
  const [
    autoSavesecondLevelTextFieldAnswer,
    setAutoSavePedsSecondLevelTextFieldAnswer,
  ] = useState({});
  const [autoSavesecondLevelCommentObj, setAutoSavePedsSecondLevelCommentObj] =
    useState({});
  const [currSection, setCurrSection] = useState(0);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [sideBarSectionDisable, setSideBarSectionDisable] = useState(undefined);
  const [schedulerDataList, setSchedulerDataList] = useState([]);
  const [clinicianPrimary, setClinicianPrimary] = useState(null);
  const [dateOfRefNurse, setDateOfRefNurse] = useState("");
  const [adminPrimary, setAdminPrimary] = useState(null);
  const [dateOfRefAdmin, setDateOfRefAdmin] = useState("");
  const [userClients, setUserClients] = useState(null); // Add userClients to the context

  const [selectedClientId, setSelectedClientId] = useState(null);
  const [clientPlan, setClientPlan] = useState(null);
  const [clientYear, setClientYear] = useState(null);
  const [activeNav, setActiveNav] = useState(0); // Add activeNav here
  const [clientPlanLabel, setClientPlanLabel] = useState(null);

  const storedPlan = clientPlan;
  const storedYear = clientYear || sessionStorage.getItem("clientYear");

  const storedPlanId = selectedClientId || "";
  const sectionMap2023 = {
    1: ["demographics", "Demographics"],
    2: ["previously", "Previously Documented Conditions"],
    3: ["covid", "Covid Screening"],
    4: ["self", "Self-Assessment and Social History"],
    5: ["activities", "Activities of Daily Living"],
    6: ["medical", "Medical History"],
    7: ["family", "Family History"],
    8: ["preventive", "Preventive Care"],
    9: ["allergies", "Allergies / Medications"],
    10: ["review", "Review Of Systems And Diagnoses"],
    11: ["pain", "Pain"],
    12: ["vital", "Vital Signs"],
    13: ["exam", "Exam Review"],
    14: ["screenings", "Screening Needed"],
    15: ["mini", "Mini - Cog"],
    16: ["home", "Home Safety & Personal Goals"],
    17: ["patient", "Patient Summary"],
  };

  const sectionMap2024 = {
    1: ["demographics", "Demographics"],
    2: ["previously", "Previously Documented Conditions"],
    3: ["covid", "Covid Screening"],
    4: ["self", "Self-Assessment and Social History"],
    5: ["activities", "Activities of Daily Living"],
    6: ["medical", "Medical History"],
    7: ["family", "Family History"],
    8: ["preventive", "Preventive Care"],
    9: ["allergies", "Allergies / Medications"],
    10: ["review", "Assessment Of Diagnoses"],
    11: ["pain", "Pain"],
    12: ["vital", "Vital Signs"],
    13: ["exam", "Exam Review"],
    14: ["screenings", "Screening Needed"],
    15: ["mini", "Mini - Cog"],
    16: ["home", "Home Safety & Personal Goals"],
    17: ["patient", "Patient Summary"],
  };
  const sectionMap2025 = {
    1: ["demographics", "Demographics"],
    2: ["previously", "Previously Documented Conditions"],
    3: ["covid", "Covid Screening"],
    4: ["self", "Self-Assessment and Social History"],
    5: ["activities", "Activities of Daily Living"],
    6: ["medical", "Medical History"],
    7: ["family", "Family History"],
    8: ["preventive", "Preventive Care"],
    9: ["allergies", "Allergies / Medications"],
    10: ["review", "Assessment Of Diagnoses"],
    11: ["pain", "Pain"],
    12: ["vital", "Vital Signs"],
    13: ["exam", "Exam Review"],
    14: ["screenings", "Screening Needed"],
    15: ["mini", "Mini - Cog"],
    16: ["home", "Home Safety & Personal Goals"],
    17: ["patient", "Patient Summary"],
  };
  const updateAssessmentData = (data) => {
    setAssessmentData(data);
  };
  const getMaskedPassword = () => {
    return password.replace(/./g, "•"); // Replace each character with a dot
  };
  const roleId = sessionStorage.getItem("roleId");
  const updateRoles = (newRoles) => {
    setRoles(newRoles);
  };

  const getAssessment = (data, memberIdForQueryForm) => {
    // console.log(data,"dataaa")
    setGetAssessmentData(data?.data?.data);
    if (memberIdForQueryForm) {
      _setMemberIdForQueryForm(memberIdForQueryForm);
    }
    setMemberIdForFormNames(data?.data?.data?.memberId);
    setMemberDatasForNames(data?.data?.data);
  };
  const [currentColor, setCurrentColor] = useState(
    localStorage.getItem("themeColor") || "#1076BC",
  );
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [selectedTimeZoneUtc, setSelectedTimeZoneUtc] = useState("UTC");
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    JSON.parse(localStorage.getItem("selectedTimeZone")) || {
      value: "Eastern Standard Time",
      abbr: "EST",
      offset: -5,
      isdst: false,
      text: "(UTC-05:00) Eastern Time (US & Canada)",
      utc: ["America/New_York"],
      isEnabled: true,
    },
  );

  /* HRA status options */
  const fetchHRAStatusOptions = async () => {
    try {
      const response = await getApi("/hra-status");
      const data = await response.data.data;
      setHraStatusData(data);
    } catch (error) {
      return;
    }
  };

  /* fetch all the datas based on the plan id */
  const fetchApiResponseData = async () => {
    if (!_.isEmpty(storedPlanId)) {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: storedPlanId,
      });
      setApiResponseData(response?.data?.data);
    }
  };

  /* visit type */
  const fetchVisitTypeOptions = async () => {
    try {
      const response = await getApi("/visitTypes/get-visit-types");
      const data = await response.data.data;
      setVisitTypeOptionsData(data);
    } catch (error) {
      return;
    }
  };

  /* fetch all lob data */
  const fetchLobData = async () => {
    try {
      const response = await getApi("/lobTypes/list");
      const responseData = await response.data.data;

      if (responseData && Array.isArray(responseData)) {
        const lobTypesOptions = responseData.map((lobType) => lobType.lobName);
        setLobOptionData(lobTypesOptions);
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  /* get all clinician data */
  const fetchAllCliniciansData = async () => {
    if (!_.isEmpty(storedPlanId)) {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: storedPlanId,
      });
      const clientPlanFromLocalStorage = clientPlan;

      if (!_.isNull(response?.data?.data)) {
        if (!_.isNull(response?.data?.data[0])) {
          const clinicianList =
            (response?.data?.data || [] || [null]).find((obj) => obj.clinician)
              ?.clinician || [];
          setClinicianDataList(clinicianList);
        }
      }
    }
  };

  const fetchAllSchedulerDataForScheduler = async () => {
    try {
      const response = await postApi("/users/get-users-by-roleId", {
        planId: storedPlanId,
        roleId: "3,4",
      });
      setSchedulerDataList(response?.data?.data);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const storedThemeColor = localStorage.getItem("themeColor");
    setCurrentColor(storedThemeColor || "#1076BC");
    const root = document.documentElement;
    root.style.setProperty("--mainTheme", storedThemeColor || "#1076BC");
  }, []);

  useEffect(() => {
    if (!_.isUndefined(storedPlan) && !_.isEmpty(storedPlanId)) {
      fetchLobData();
      fetchApiResponseData();
      fetchHRAStatusOptions();
      fetchAllCliniciansData();
      fetchVisitTypeOptions();
      fetchAllSchedulerDataForScheduler();
    }
  }, []);
  let userCategories = userCategoriesList;

  const handleBadgeClick = () => {
    setIsDialogOpen(true);
    setClientData({});
    setClientPlan("");
    setClientYear("");
    localStorage.removeItem("clientData");
    localStorage.removeItem("clientYear");
    localStorage.removeItem("clientPlan");
    localStorage.removeItem("clientScreening");
    localStorage.removeItem("isAllSelected");
  };
  const handleClientDataSubmit = () => {
    const client = clientData ? clientData?.clientName : null;
    const age = clientData ? clientData?.ageGroup : null;
    if (client && age) {
      const newValue = {
        clientName: client,
        ageGroup: age,
        isSelected: true,
      };

      setClientData(() => newValue);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleClientDataChange = (e, multiVal = null) => {
    if (multiVal[0] === "dropDownInput") {
      const { value } = e.target;
      if (multiVal[1] === "clients") {
        let newUserCategories = userCategories.clients.map((el) => {
          if (value.includes(el[0])) {
            let temp = {
              ...clientData,
            };
            const newValue = { clientName: value, ...temp };
            setClientData(() => newValue);
            return [el[0], true];
          }
          return [el[0], false];
        });
        userCategories = newUserCategories;
      } else if (multiVal[1] === "ageGroup") {
        let newUserCategories = userCategories.ageGroup.map((el) => {
          if (value.includes(el[0])) {
            const newValue = {
              ageGroup: value,
              ...clientData,
            };
            setClientData(() => newValue);
            return [el[0], true];
          }
          return [el[0], false];
        });
        userCategories = newUserCategories;
      }
    }
  };

  const handleSelectionDataSubmit = () => {
    // const plan = clientPlan;
    // const year = clientYear;
    const plan = clientData ? clientData.plan : storedPlan;
    const year = clientData ? clientData.year : storedYear;
    const screeningTypes = clientData
      ? clientData.screeningTypes
      : storedScreeningTypes;

    if (plan && year && screeningTypes) {
      const newValue = {
        plan: plan,
        year: year,
        screeningTypes: screeningTypes,
        isAllSelected: true,
      };
      localStorage.setItem("isAllSelected", true);
      setClientData(newValue);
    }
    if (plan && year && screeningTypes && isDialogOpen === true) {
      const newValue = {
        plan: plan,
        year: year,
        screeningTypes: screeningTypes,
        isAllSelected: true,
      };
      setClientData(newValue);
      setIsDialogOpen(false);
    }
  };

  const handleSelectionDataChange = (value, multiVal = null) => {
    if (multiVal && multiVal[0] === "dropDownInput") {
      let newUserCategories;

      if (multiVal[1] === "plan") {
        newUserCategories = userCategories.plan.map((el) => [
          el[0],
          value === el[0],
        ]);
        setClientData((prevData) => ({ ...prevData, plan: value }));
      } else if (multiVal[1] === "year") {
        newUserCategories = userCategories.year.map((el) => [
          el[0],
          value === el[0],
        ]);
        setClientData((prevData) => ({ ...prevData, year: value }));
      } else if (multiVal[1] === "screeningTypes") {
        newUserCategories = userCategories.screeningTypes.map((el) => [
          el[0],
          value === el[0],
        ]);
        setClientData((prevData) => ({ ...prevData, screeningTypes: value }));
      }

      setClientData((prevData) => ({
        ...prevData,
        year: value,
        screeningTypes: "All",
      }));
      userCategories = newUserCategories;
    }
  };

  // useEffect(() => {

  //     const fetchProgramTypes = async () => {
  //       if(alertContext.filterDialog == true){
  //       try {
  //         const response = await getApi("/programTypes/list");

  //         if (response.status === 200) {
  //           const responseData = await response.data.data;

  //           if (responseData && Array.isArray(responseData)) {
  //             const options = responseData.map(
  //               (programType) => programType.programName,
  //             );
  //             setProgramTypesOptions(options);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error in fetching options:", error);
  //       }
  //     }
  //     };

  //     fetchProgramTypes();

  // }, [alertContext.filterDialog]);

  // useEffect(() => {
  //   if (lobTypesOptions.length === 0) {
  //     const fetchLobTypes = async () => {
  //       try {
  //         const response = await getApi("/lobTypes/list");

  //         if (response.status === 200) {
  //           const responseData = await response.data.data;

  //           if (responseData && Array.isArray(responseData)) {
  //             const options = responseData.map((lobType) => lobType.lobName);
  //             setLobTypesOptions(options);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error in fetching options:", error);
  //       }
  //     };

  //     fetchLobTypes();
  //   }
  // }, [lobTypesOptions, setLobTypesOptions]);
  //const location = useLocation();
  const assessmentId = sessionStorage.getItem("assessmentId");
  // location?.state?.assessmentId
  //   ? location?.state?.assessmentId
  //   : sessionStorage.getItem("assessmentId");
  const tabId = sessionStorage.getItem("tabId"); //location?.state?.tabId
  // ? location?.state?.tabId
  // : sessionStorage.getItem("tabId");
  // const clientPlan = clientPlan || storedPlan;
  // const clientYear = clientYear || storedYear;
  const updateHraStatusToInProgress = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 2,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        // setSignSubmitDialog(false);
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        // toast.remove();
        // toast.success("Assessment Updated Successfully", {});
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage(response.data.message);
        // fetchMemberData(storedPlan, storedYear);
      } else {
        return;
      }
    } catch (error) {
      return;
    }

    // setIsCancelAppointmentAlertOpen(false);
    // } else {
    //   console.error("Selected HRA status not found in data array");
    // }
  };
  const handlePatientSave = async (providerSignature) => {
    try {
      const formDataCamelCase = {};

      let tempData = autoSavePatientData;

      const patientData = {};

      const visitType = {
        isVisitType:
          tempData?.visitTypeSaveBtn === "0" ? "" : tempData?.visitTypeSaveBtn,
        // isVisitType: tempData?.visitTypeSaveBtn
        //   ? tempData?.visitTypeSaveBtn
        //   : "",
        consentedToVideo:
          tempData?.visitTypeSaveBtn == 2
            ? tempData?.consentedToVideoSaveBtn
              ? tempData?.consentedToVideoSaveBtn
              : ""
            : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = tempData?.assessorsCommentsSaveBtn
        ? tempData?.assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = tempData?.memberAcknowledgementSaveBtn
        ? tempData?.memberAcknowledgementSaveBtn
        : "";

      patientData.acceptDisclosure = tempData?.acceptDisclosureSaveBtn
        ? tempData?.acceptDisclosureSaveBtn
        : "";
      patientData.evaluationDate = tempData?.evaluationDate;
      patientData.examFinishDate = tempData?.examFinishDateSaveBtn
        ? tempData?.examFinishDateSaveBtn
        : tempData?.examFinishDate
        ? tempData?.examFinishDate
        : "";

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature
              ? providerSignature
              : tempData?.providerSignAllDashboard
              ? tempData?.providerSignAllDashboard
              : "",
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      setCurrSection(0);
      if (response?.data?.code === 406 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (response.status == 404 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (
        response.status === 200 &&
        response?.data?.message == "Assessment updated successfully."
      ) {
        toast.remove();
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
        const roleId = sessionStorage.getItem("roleId");
      }
      // else {
      //   // setToasterOpen(true);
      //   // setToasterSeverity(Labels.ERROR);
      //   // setToasterMessage("Error saving data");

      // }
    } catch (error) {
      // setToasterOpen(true);
      // setToasterSeverity(Labels.ERROR);
      // setToasterMessage("Error saving data");
      return;
    }
  };

  const handlePatientSave2024 = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      // Object.keys(formData).forEach((key) => {
      //   const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
      //   formDataCamelCase[camelCaseKey] = formData[key];
      // });
      // let tempData = patientSummaryRef.current.getPayloadData();
      // if (!Boolean(tempData)) {
      let tempData = autoSavePatientData;

      const patientData = {};

      const visitType = {
        isVisitType:
          tempData?.visitTypeSaveBtn === "0" ? "" : tempData?.visitTypeSaveBtn,
        // isVisitType: tempData?.visitTypeSaveBtn
        //   ? tempData?.visitTypeSaveBtn
        //   : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = tempData?.assessorsCommentsSaveBtn
        ? tempData?.assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = tempData?.memberAcknowledgementSaveBtn
        ? tempData?.memberAcknowledgementSaveBtn
        : "";

      patientData.acceptDisclosure = tempData?.acceptDisclosureSaveBtn
        ? tempData?.acceptDisclosureSaveBtn
        : "";

      patientData.memberConsentedToCompleteVirtualVisit =
        tempData?.memberConsentedToCompleteVirtualVisitSaveBtn
          ? tempData?.memberConsentedToCompleteVirtualVisitSaveBtn
          : "";

      patientData.membersIdentityConfirmedWithID =
        tempData?.membersIdentityConfirmedWithIDSaveBtn
          ? tempData?.membersIdentityConfirmedWithIDSaveBtn
          : "";

      patientData.comment = tempData?.commentSaveBtn
        ? tempData?.commentSaveBtn
        : "";

      patientData.evaluationDate = tempData?.evaluationDate;
      patientData.examFinishDate = tempData?.examFinishDateSaveBtn
        ? tempData?.examFinishDateSaveBtn
        : tempData?.examFinishDate
        ? tempData?.examFinishDate
        : "";

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature
              ? providerSignature
              : tempData?.providerSignAllDashboard
              ? tempData?.providerSignAllDashboard
              : "",
          },
        },
      };
      // if (tabId == 1) {
      //   updateHraStatusToInProgress();
      // }
      const response = await putApi("/hra/assessment", payload);
      setCurrSection(0);
      if (response?.data?.code === 406 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (response.status == 404 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (
        response.status === 200 &&
        response?.data?.message == "Assessment updated successfully."
      ) {
        toast.remove();
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
        const roleId = sessionStorage.getItem("roleId");
      } else {
        // setToasterOpen(true);
        // setToasterSeverity(Labels.ERROR);
        // setToasterMessage("Error saving data");
      }
    } catch (error) {
      // setToasterOpen(true);
      // setToasterSeverity(Labels.ERROR);
      // setToasterMessage("Error saving data");
      console.log(error);
    }
  };

  const handleSave = () => {
    if (clientYear === "2024" || clientYear === "2025") {
      handlePatientSave2024();
    } else {
      handlePatientSave();
    }
  };
  //for fill Hra
  const saveSectionFormData = async (sectionId) => {
    try {
      let payload = {};
      let assessId = assessmentId;

      let sectionMap = {};
      if (clientYear === "2023") {
        sectionMap = { ...sectionMap2023 };
      } else if (clientYear === "2024") {
        sectionMap = { ...sectionMap2024 };
      } else if (clientYear === "2025") {
        sectionMap = { ...sectionMap2025 };
      }

      let sectionData = sectionMap[sectionId];
      let sectionName = sectionData[1];
      let tempData = {};
      if (sectionId === 1) {
        // tempData = demographicsRef?.current?.getPayloadData(); //getSectionFormData(sectionData);
        // if (!Boolean(tempData)) {
        tempData = autoSaveDemographicsData;
        // }
      } else if (sectionId === 4) {
        // tempData = selfAssessRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setSelfAssessData(autoSaveSelfAssessmentData, clientYear);
        // }
      } else if (sectionId === 7) {
        // tempData = familyHistoryRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setfamilyData(autoSaveFamilyHistoryData, clientYear);
        //}
        const subAnswers = tempData?.familyHistory?.familyHistorySubAnswers;
        for (let i = 0; i < subAnswers.length; i++) {
          delete subAnswers[i]["id"];
        }
      } else if (sectionId === 6) {
        // tempData = medicalRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setMedicalData(autoSaveMedicalHistory, clientYear);
        //}
        const subAnswers = tempData?.specialists?.specialistsSubAnswers;
        for (let i = 0; i < subAnswers.length; i++) {
          delete subAnswers[i]["id"];
        }
      } else if (sectionId === 10) {
        // tempData = rosRef?.current?.getPayloadData();

        // if (!Boolean(tempData)) {
        if (clientYear === "2024" || clientYear === "2025") {
          tempData = setRosData2024(autoSaveRosData);
        } else {
          tempData = setRosData(autoSaveRosData);
        }
        //}
      } else if (sectionId === 14) {
        // tempData = screeningsRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setScreeningsData(autoSaveScreeningNeeded, clientYear);
        // }
      } else if (sectionId === 13) {
        // tempData = examReviewRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setExamReviewData(autoSaveExamReviewData, clientYear);
        // }
      } else if (sectionId === 15) {
        // tempData = minicogRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setMinicogData(autoSaveMinicogData, clientYear);
        //  }
        const subAnswers = tempData?.threeWordRecall;

        if (
          (clientYear === "2024" || clientYear === "2025") &&
          subAnswers &&
          typeof subAnswers.wordList === "string"
        ) {
          subAnswers.wordList = { ...initialWordList };
          if (subAnswers["0"]) {
            delete subAnswers["0"];
          }
        }

        payload = {
          id: assessId,
          year: clientYear,
          data: {
            name: "Mini - Cog",
            data: {
              ...tempData,
            },
          },
        };
      } else if (sectionId === 16) {
        // tempData = homeSafetyRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setHomeSafetyData(autoSaveHomeSafetyData, clientYear);
        // }
      } else if (sectionId === 3) {
        // tempData = covidScreeningRef?.current?.getPayloadData();

        // if (!Boolean(tempData)) {
        tempData = setCovidScreeningData(
          autoSaveCovidScreeningData,
          clientYear,
        );
        // }
      } else if (sectionId === 5) {
        // tempData = activitiesRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        if (clientYear == "2024" || clientYear == "2025") {
          tempData = autoSaveActivitiesData;
        } else {
          tempData = setActivitiesData(autoSaveActivitiesData, clientYear);
        }
        //}
      } else if (sectionId === 11) {
        // tempData = painRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setPainData(autoSavePainData, clientYear);
        // }
      } else if (sectionId === 12) {
        // tempData = vitalRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setVitalData(autoSaveVitalData, clientYear);
        //}
      } else if (sectionId === 9) {
        // tempData = allergiesRef?.current?.getPayloadData();
        // //setAllergiesData
        // if (!Boolean(tempData)) {
        tempData = setAllergiesData(autoSaveAllergiesData, clientYear);
        // }
        const subAnswers = tempData?.allergies?.allergiesSubAnswers;
        for (let i = 0; i < subAnswers?.length; i++) {
          delete subAnswers[i]["id"];
        }

        const subAnswersMed = tempData?.medications?.medication;
        for (let i = 0; i < subAnswersMed?.length; i++) {
          delete subAnswersMed[i]["id"];
          if (clientYear === "2024" || clientYear === "2025") {
            delete subAnswersMed[i]["prescribingPhysician"];
          }
        }
        const subAnswersCounterMed =
          tempData?.counterMedications?.counterMedicationsSubAnswers;
        for (let i = 0; i < subAnswersCounterMed?.length; i++) {
          delete subAnswersCounterMed[i]["id"];
        }
      } else if (sectionId === 17) {
        handleSave();
      } else if (sectionId === 2) {
        // tempData = previouslyRef?.current?.getPayloadData();

        // if (!Boolean(tempData)) {
        tempData = autoSavePrevDocData; //setAllergiesData(autoSaveAllergiesData, clientYear);
        // }
        if (clientYear === "2023") {
          delete tempData.suspectCodes;
        }

        const view =
          tempData?.viewSubAnswers?.length > 0
            ? "1"
            : "2" || tempData?.suspectCodes?.length > 0
            ? "1"
            : "2";
        tempData = {
          ...tempData,
          view,
        };
        payload = {
          id: assessId,
          year: clientYear,
          data: {
            name: sectionName,
            data: {
              ...tempData,
            },
          },
        };
      } else if (sectionId === 8) {
        // tempData = preventiveRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setPreventiveData(autoSavePreventiveCareData, clientYear);
        // }
        const subAnswersMed =
          tempData?.preventiveCare?.pastThreeYearsSubAnswers;
        for (let i = 0; i < subAnswersMed?.length; i++) {
          if (clientYear === "2024" || clientYear === "2025") {
            delete subAnswersMed[i]["educationCompleted"];
          }
        }
        if (clientYear === "2024" || clientYear === "2025") {
          delete tempData?.recommendations;
        }
      }

      payload = {
        id: assessId,
        year: clientYear,
        data: {
          name: sectionName,
          data: {
            ...tempData,
          },
        },
      };

      if (sectionId !== 17) {
        const response = await putApi("/hra/assessment", payload);

        if (tabId == 1) {
          updateHraStatusToInProgress();
        }
        setCurrSection(0);
        return response;
      }
    } catch (error) {
      return;
    }
  };

  // for peds form
  const onSavepedsDemographicsData = async (
    demographicsFormStates = autoSavePedsDemographicsData,
  ) => {
    const updatedData = Object?.fromEntries(
      Object?.entries(demographicsFormStates)?.map(([key, value]) => [
        key?.replace(/^demograph_/, ""),
        value,
      ]),
    );

    const demographicsFormState = updatedData;
    const payload = {
      plan: demographicsFormState?.plan ? demographicsFormState.plan : "",
      program: demographicsFormState.program
        ? demographicsFormState.program
        : "",
      lob: demographicsFormState.lob ? demographicsFormState.lob : "",
      region: demographicsFormState.region ? demographicsFormState.region : "",
      aligned: demographicsFormState.aligned
        ? demographicsFormState.aligned
        : "",
      name: demographicsFormState.firstName
        ? demographicsFormState.firstName
        : "",
      gender: demographicsFormState.genderAtBirth
        ? demographicsFormState.genderAtBirth
        : "",
      currentGender: {
        gender: demographicsFormState.currentGender
          ? demographicsFormState.currentGender
          : "", //"",
        comment: demographicsFormState.currentGenderComment
          ? demographicsFormState.currentGenderComment
          : "",
      }, //physicianComment
      uniqueIdentificationNo: demographicsFormState.uniqueId
        ? demographicsFormState.uniqueId
        : "",
      physicianComment: demographicsFormState.pNameComment
        ? demographicsFormState.pNameComment
        : "",
      medicaidId: demographicsFormState.medicaidId
        ? demographicsFormState.medicaidId
        : "",
      medicareId: demographicsFormState.medicareId
        ? demographicsFormState.medicareId
        : "",
      pFlag: demographicsFormState.pFlag ? demographicsFormState.pFlag[0] : "",
      genderAtBirth: demographicsFormState.genderAtBirth
        ? demographicsFormState.genderAtBirth
        : "",
      addressOne: demographicsFormState.address
        ? demographicsFormState.address
        : "",
      addressTwo: demographicsFormState.addressTwo
        ? demographicsFormState.addressTwo
        : "",
      addressThree: demographicsFormState.addressThree
        ? demographicsFormState.addressThree
        : "",
      city: demographicsFormState.city ? demographicsFormState.city : "",
      state: demographicsFormState.state ? demographicsFormState.state : "",
      zip: demographicsFormState.zip ? demographicsFormState.zip : "",
      mAddressOne: demographicsFormState.mAddressOne
        ? demographicsFormState.mAddressOne
        : "",
      mAddressTwo: demographicsFormState.mAddTwo
        ? demographicsFormState.mAddTwo
        : "",
      mAddressThree: demographicsFormState.mAddressThree
        ? demographicsFormState.mAddressThree
        : "",
      mCity: demographicsFormState.mCity ? demographicsFormState.mCity : "",
      mState: demographicsFormState.mState ? demographicsFormState.mState : "",
      mZip: demographicsFormState.mZip ? demographicsFormState.mZip : "",
      dob: demographicsFormState.dob ? demographicsFormState.dob : "",
      age: demographicsFormState.age ? demographicsFormState.age : "",
      maritalStatus: demographicsFormState.mStatus
        ? demographicsFormState.mStatus
        : "",
      memberIdentificationNo: demographicsFormState.memberId
        ? demographicsFormState.memberId
        : "",

      hicn: demographicsFormState.hicn ? demographicsFormState.hicn : "",
      email: demographicsFormState.email ? demographicsFormState.email : "",
      phoneNumber: demographicsFormState.primary
        ? demographicsFormState.primary
        : "",
      cellNumber: demographicsFormState.secondary
        ? demographicsFormState.secondary
        : "",
      alternateContactNumber: demographicsFormState.aContact
        ? demographicsFormState.aContact
        : "",
      emergencyContact: demographicsFormState.ename
        ? demographicsFormState.ename
        : "",
      ePhoneNumber: demographicsFormState.eNo ? demographicsFormState.eNo : "",
      primaryCarePhysician: demographicsFormState.pName
        ? demographicsFormState.pName
        : "",
      pPhoneNumber: demographicsFormState.pPhone
        ? demographicsFormState.pPhone
        : "",
      guardianName: demographicsFormState.guardianName
        ? demographicsFormState.guardianName
        : "",
      relationshipToChild: demographicsFormState.relationshipToChild
        ? demographicsFormState.relationshipToChild
        : "",
      gPhoneNumber: demographicsFormState.gPhoneNumber
        ? demographicsFormState.gPhoneNumber
        : "",
      gCellNumber: demographicsFormState.gCellNumber
        ? demographicsFormState.gCellNumber
        : "",
      gEmail: demographicsFormState.gEmail ? demographicsFormState.gEmail : "",
      pcpAddress: demographicsFormState.pAddOne
        ? demographicsFormState.pAddOne
        : "",
      pcpCity: demographicsFormState.pCity ? demographicsFormState.pCity : "",
      pcpState: demographicsFormState.pState
        ? demographicsFormState.pState
        : "",
      pcpZip: demographicsFormState.pZip ? demographicsFormState.pZip : "",
      pcpCountry: demographicsFormState.pcpcounty
        ? demographicsFormState.pcpcounty
        : "",
      faxNumber: demographicsFormState.pFax ? demographicsFormState.pFax : "",
      pcpNpi: demographicsFormState.pcpNpi ? demographicsFormState.pcpNpi : "",
      officeId: demographicsFormState.pId ? demographicsFormState.pId : "",
      officeName: demographicsFormState.pOfcName
        ? demographicsFormState.pOfcName
        : "",
      physicianComment: demographicsFormState.pNameComment
        ? demographicsFormState.pNameComment
        : "",

      culturalReligious: {
        isCulturalReligious: demographicsFormState.religious
          ? demographicsFormState.religious[0]
          : "",
        yesSubAnswer: {
          comments: demographicsFormState.religiousSubDesc
            ? demographicsFormState.religiousSubDesc
            : "",
        },
        comment: demographicsFormState?.religiousComment
          ? demographicsFormState.religiousComment || ""
          : "",
      },

      race: {
        caucasian: demographicsFormState?.race
          ? demographicsFormState?.race.includes("caucasian")
            ? "1"
            : ""
          : "",
        africanAmerican: demographicsFormState?.race
          ? demographicsFormState?.race.includes("africanAmerican")
            ? "1"
            : ""
          : "",
        asian: demographicsFormState?.race
          ? demographicsFormState?.race.includes("asian")
            ? "1"
            : ""
          : "",
        latino: demographicsFormState?.race
          ? demographicsFormState?.race.includes("latino")
            ? "1"
            : ""
          : "",
        nativeAmerican: demographicsFormState?.race
          ? demographicsFormState?.race.includes("nativeAmerican")
            ? "1"
            : ""
          : "",
        nativeHawaiian: demographicsFormState?.race
          ? demographicsFormState?.race.includes("nativeHawaiian")
            ? "1"
            : ""
          : "",
        alaskanNative: demographicsFormState?.race
          ? demographicsFormState?.race.includes("alaskanNative")
            ? "1"
            : ""
          : "",
        other: demographicsFormState?.race
          ? demographicsFormState?.race.includes("other")
            ? "1"
            : ""
          : "",
        otherSubAnswer: {
          describe: demographicsFormState?.raceOtherSubDesc
            ? demographicsFormState?.raceOtherSubDesc
            : "",
        },
        comment: demographicsFormState?.raceComment
          ? demographicsFormState.raceComment || ""
          : "",
      },

      patientEthnicity: {
        hispanic: demographicsFormState?.patientEthnic
          ? demographicsFormState?.patientEthnic.includes("hispanic")
            ? "1"
            : ""
          : "",
        nonHispanic: demographicsFormState?.patientEthnic
          ? demographicsFormState?.patientEthnic.includes("nonHispanic")
            ? "1"
            : ""
          : "",
        otherethnicity: demographicsFormState?.patientEthnic
          ? demographicsFormState?.patientEthnic.includes("otherethnicity")
            ? "1"
            : ""
          : "",
        prefernot: demographicsFormState?.patientEthnic
          ? demographicsFormState?.patientEthnic.includes("prefernot")
            ? "1"
            : ""
          : "",
        comment: demographicsFormState?.patientEthnicComment
          ? demographicsFormState.patientEthnicComment || ""
          : "",
      },

      preferredLang: {
        english: demographicsFormState?.prefLang
          ? demographicsFormState?.prefLang.includes("english")
            ? "1"
            : ""
          : "",
        other: demographicsFormState?.prefLang
          ? demographicsFormState?.prefLang.includes("other")
            ? "1"
            : ""
          : "",
        otherSubAnswer: {
          african: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("african")
              ? "1"
              : ""
            : "",
          arabic: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("arabic")
              ? "1"
              : ""
            : "",
          chinese: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("chinese")
              ? "1"
              : ""
            : "",
          french: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("french")
              ? "1"
              : ""
            : "",
          frenchCreole: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("frenchCreole")
              ? "1"
              : ""
            : "",
          german: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("german")
              ? "1"
              : ""
            : "",
          greek: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("greek")
              ? "1"
              : ""
            : "",
          gujarati: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("gujarati")
              ? "1"
              : ""
            : "",
          hebrew: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("hebrew")
              ? "1"
              : ""
            : "",
          hindi: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("hindi")
              ? "1"
              : ""
            : "",
          hungarian: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("hungarian")
              ? "1"
              : ""
            : "",
          italian: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("italian")
              ? "1"
              : ""
            : "",
          japanese: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("japanese")
              ? "1"
              : ""
            : "",
          korean: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("korean")
              ? "1"
              : ""
            : "",
          persian: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("persian")
              ? "1"
              : ""
            : "",
          polish: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("polish")
              ? "1"
              : ""
            : "",
          portuguese: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("portuguese")
              ? "1"
              : ""
            : "",
          russian: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("russian")
              ? "1"
              : ""
            : "",
          scandinavian: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("scandinavian")
              ? "1"
              : ""
            : "",
          serbo: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("serbo")
              ? "1"
              : ""
            : "",
          spanish: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("spanish")
              ? "1"
              : ""
            : "",
          tagalog: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("tagalog")
              ? "1"
              : ""
            : "",
          urdu: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("urdu")
              ? "1"
              : ""
            : "",
          vietnamese: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("vietnamese")
              ? "1"
              : ""
            : "",
          yiddish: demographicsFormState?.prefLangOtherSub
            ? demographicsFormState?.prefLangOtherSub.includes("yiddish")
              ? "1"
              : ""
            : "",
          comment: demographicsFormState?.prefLangOtherSubComment
            ? demographicsFormState.prefLangOtherSubComment || ""
            : "",
        },
        comment: demographicsFormState?.prefLangComment
          ? demographicsFormState.prefLangComment || ""
          : "",
      },
    };
    let payload2024 = {};
    if (clientYear == "2024") {
      payload2024 = payload;
    }

    try {
      const _payload = {
        id: assessmentId,
        data: {
          name: "Child Demographics",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };
  let payload = {};
  const getpayloadByQuestionId = (questionNumber, answerObj) => {
    const firstLevelCommentObj = autoSavefirstLevelCommentObj;
    const secondLevelCommentObj = autoSavesecondLevelCommentObj;
    const secondLevelTextFieldAnswer = autoSavesecondLevelTextFieldAnswer;
    const counterMedicationsRowData = autoSavecounterMedicationsRowData;
    const counterMedicationsRowDataQues15 =
      autoSavecounterMedicationsRowDataQues15;
    const allergiesRowData = autoSavepedsallergiesRowData;

    if (
      questionNumber == 1 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 1)
    ) {
      payload = {
        ...payload,
        legalGuradian: {
          legalGuradianPermission: answerObj?.legalGuradian
            ? answerObj?.legalGuradian
            : "",
          // == "yes"
          //   ? "1"
          //   : answerObj.legalGuradian == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 2 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 2)
    ) {
      payload = {
        ...payload,
        legalRepresentative: {
          authorizedLegalRepresentative: answerObj?.legalRepresentative
            ? answerObj?.legalRepresentative
            : "",
          //  == "yes"
          //   ? "1"
          //   : answerObj.legalRepresentative == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 3 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 3)
    ) {
      payload = {
        ...payload,
        fosterCare: {
          fosterCareProgram: answerObj?.fosterCare ? answerObj?.fosterCare : "",
          // == "yes"
          //   ? "1"
          //   : answerObj.fosterCare == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 4 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 4)
    ) {
      payload = {
        ...payload,
        preferredLanguage: {
          english: answerObj?.preferredLanguage == "english" ? "1" : "2", //"Y or N",
          other: answerObj?.preferredLanguage == "other" ? "1" : "2", //"Y or N",
          otherSubAnswer: {
            africanLanguages:
              answerObj?.demograph_prefLangOtherSub == "africanLanguages"
                ? "1"
                : "2",
            arabic:
              answerObj?.demograph_prefLangOtherSub == "arabic" ? "1" : "2",
            chinese:
              answerObj?.demograph_prefLangOtherSub == "chinese" ? "1" : "2",
            french:
              answerObj?.demograph_prefLangOtherSub == "french" ? "1" : "2",
            frenchCreole:
              answerObj?.demograph_prefLangOtherSub == "frenchCreole" //"frenchCrelo"
                ? "1"
                : "2",
            german:
              answerObj?.demograph_prefLangOtherSub == "german" ? "1" : "2",
            greek: answerObj?.demograph_prefLangOtherSub == "greek" ? "1" : "2",
            gujarati:
              answerObj?.demograph_prefLangOtherSub == "gujarati" ? "1" : "2",
            hebrew:
              answerObj?.demograph_prefLangOtherSub == "hebrew" ? "1" : "2",
            hindi: answerObj?.demograph_prefLangOtherSub == "hindi" ? "1" : "2",
            hungarian:
              answerObj?.demograph_prefLangOtherSub == "hungarian" ? "1" : "2",
            italian:
              answerObj?.demograph_prefLangOtherSub == "italian" ? "1" : "2",
            japanese:
              answerObj?.demograph_prefLangOtherSub == "japanese" ? "1" : "2",
            korean:
              answerObj?.demograph_prefLangOtherSub == "korean" ? "1" : "2",
            persian:
              answerObj?.demograph_prefLangOtherSub == "persian" ? "1" : "2",
            polish:
              answerObj?.demograph_prefLangOtherSub == "polish" ? "1" : "2",
            portuguese:
              answerObj?.demograph_prefLangOtherSub == "portuguese" ? "1" : "2",
            russian:
              answerObj?.demograph_prefLangOtherSub == "russian" ? "1" : "2",
            scandinavianLanguages:
              answerObj?.demograph_prefLangOtherSub == "scandinavianLanguages"
                ? "1"
                : "2",
            serboCroatian:
              answerObj?.demograph_prefLangOtherSub == "serboCroatian"
                ? "1"
                : "2",
            spanish:
              answerObj?.demograph_prefLangOtherSub == "spanish" ? "1" : "2",
            comment: "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 5 ||
      (firstLevelCommentObj[questionNumber - 1] && questionNumber == 5)
    ) {
      payload = {
        ...payload,
        race: {
          caucasian: answerObj?.race == "caucasian" ? "1" : "2",
          africanAmerican: answerObj?.race == "africanAmerican" ? "1" : "2",
          asian: answerObj?.race == "asian" ? "1" : "2",
          latino: answerObj?.race == "latino" ? "1" : "2",
          nativeAmerican: answerObj?.race == "nativeAmerican" ? "1" : "2",
          nativeHawaiian: answerObj?.race == "nativeHawaiian" ? "1" : "2",
          alaskanNative: answerObj?.race == "alaskanNative" ? "1" : "2",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 7 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 7)
    ) {
      payload = {
        ...payload,
        childExperienced: {
          childWitnessedEvent: answerObj?.childExperienced
            ? answerObj?.childExperienced
            : "",
          // == "yes"
          //   ? "1"
          //   : answerObj?.childExperienced == "no"
          //   ? "2"
          //   : "",
          childWitnessedEventYesSubAnswer: {
            event: {
              carAccident:
                answerObj?.Whatwastheevent == "carAccident" ? "1" : "2", //"Y or N",
              fire: answerObj?.Whatwastheevent == "fire" ? "1" : "2", //"Y or N",
              storm: answerObj?.Whatwastheevent == "storm" ? "1" : "2", //"Y or N",
              physicalIllness:
                answerObj?.Whatwastheevent == "physicalIllness" ? "1" : "2", //"Y or N",
              sexualAssualt:
                answerObj?.Whatwastheevent == "sexualAssualt" ? "1" : "2", //"Y or N",
              comment: secondLevelCommentObj.Whatwastheevent
                ? secondLevelCommentObj.Whatwastheevent
                : "",
            },
            describe: answerObj?.describe ? answerObj?.describe : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 8 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 8)
    ) {
      payload = {
        ...payload,
        memberAgeEvent: {
          specialAge: answerObj?.memberAgeEvent
            ? answerObj?.memberAgeEvent
            : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 9 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 9)
    ) {
      payload = {
        ...payload,
        childReportComplaints: {
          notTrue: answerObj?.childReportComplaints == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childReportComplaints == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childReportComplaints == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue:
            answerObj?.childReportComplaints == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 10 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 10)
    ) {
      payload = {
        ...payload,
        childAvoidsThings: {
          notTrue: answerObj?.childAvoidsThings == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childAvoidsThings == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childAvoidsThings == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childAvoidsThings == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 11 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 11)
    ) {
      payload = {
        ...payload,
        childStartlesEasily: {
          notTrue: answerObj?.childStartlesEasily == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childStartlesEasily == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childStartlesEasily == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childStartlesEasily == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 12 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 12)
    ) {
      payload = {
        ...payload,
        childGetsUpset: {
          notTrue: answerObj?.childGetsUpset == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue: answerObj?.childGetsUpset == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childGetsUpset == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childGetsUpset == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 13 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 13)
    ) {
      payload = {
        ...payload,
        childMedicine: {
          childMedicinePrescribed: answerObj?.childMedicine
            ? answerObj?.childMedicine
            : "", //== "yes" ? "1" : "2",
          childMedicinePrescribedYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", //== "yes" ? "1" : "2", //"Y or N",
              comment:
                secondLevelCommentObj.medicine_prescribed_condition_that_has_lasted
                  ? secondLevelCommentObj.medicine_prescribed_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 15 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 15)
    ) {
      payload = {
        ...payload,
        counterMedications: {
          supplements: answerObj?.counterMedications
            ? answerObj?.counterMedications
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.counterMedications == "no"
          //   ? "N"
          //   : "", //"Y or N",
          supplementsYesSubAnswer: counterMedicationsRowDataQues15?.map(
            (row) => {
              return {
                prescription: row?.description || "",
                doseUnits: row?.doseOrUnits || "",
                route: row?.route || "",
                reason: row?.reasons || "",
                status: row?.status || "",
                frequency: row?.frequency || "",
                // comment: "",
              };
            },
          ),
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 16 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 16)
    ) {
      payload = {
        ...payload,
        childNeedMedicalCare: {
          childMedicalCare: answerObj?.childNeedMedicalCare
            ? answerObj?.childNeedMedicalCare
            : "", //== "yes" ? "Y" : "N",
          childMedicalCareYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", //== "yes" ? "Y" : "N", //"Y or N",
              comment:
                secondLevelCommentObj.medical_care_condition_that_has_lasted
                  ? secondLevelCommentObj.medical_care_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 17 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 17)
    ) {
      payload = {
        ...payload,
        childAbility: {
          childAbilityLimited: answerObj?.childAbility
            ? answerObj?.childAbility
            : "", // == "yes" ? "Y" : "N",
          childAbilityLimitedYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", // == "yes" ? "Y" : "N", //"Y or N",
              comment:
                secondLevelCommentObj.childAbility_condition_that_has_lasted
                  ? secondLevelCommentObj.childAbility_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 18 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 18)
    ) {
      payload = {
        ...payload,
        childNeed: {
          childNeedTherapy: answerObj?.childNeed ? answerObj?.childNeed : "", //== "yes" ? "Y" : "N",
          childNeedTherapyYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", // == "yes" ? "Y" : "N", //"Y or N",
              comment: secondLevelCommentObj.childNeed_condition_that_has_lasted
                ? secondLevelCommentObj.childNeed_condition_that_has_lasted
                : "",
            },
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 19 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 19)
    ) {
      payload = {
        ...payload,
        childDevelopment: {
          childDevelopmentCounseling: answerObj?.childDevelopment
            ? answerObj?.childDevelopment
            : "", //== "yes" ? "Y" : "N",
          childDevelopmentCounselingYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 20 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 20)
    ) {
      payload = {
        ...payload,
        childReceiveSupport: {
          nursingCare:
            answerObj?.childReceiveSupport == "nursingCare" ? "1" : "2",
          personalCareAttendant:
            answerObj?.childReceiveSupport == "personalCareAttendant"
              ? "1"
              : "2",
          homeHealthAide:
            answerObj?.childReceiveSupport == "homeHealthAide" ? "1" : "2",
          no: answerObj?.childReceiveSupport == "no" ? "1" : "2",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 21 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 21)
    ) {
      payload = {
        ...payload,
        childMedicalCheckup: {
          childMedicalCheckup: answerObj?.childMedicalCheckup
            ? answerObj?.childMedicalCheckup
            : "",
          //  == "Y"
          //   ? "1"
          //   : answerObj.childMedicalCheckup == "N"
          //   ? "2"
          //   : answerObj.childMedicalCheckup == "DoesnotKnow"
          //   ? "3"
          //   : "", //  "Y or N or DoesnotKnow",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 22 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 22)
    ) {
      payload = {
        ...payload,
        childsHeightWeight: {
          childsHeightWeight: answerObj?.childsHeightWeight
            ? answerObj?.childsHeightWeight
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.childsHeightWeight == "no"
          //   ? "N"
          //   : "", //or N",
          childsHeightWeightYesSubAnswer: {
            childsHeight: {
              feet:
                answerObj?.childsHeightWeight == "1"
                  ? answerObj?.childsHeightfeet
                    ? answerObj?.childsHeightfeet
                    : ""
                  : "", //"",
              inch:
                answerObj?.childsHeightWeight == "1"
                  ? answerObj?.childsHeightInches
                    ? answerObj?.childsHeightInches
                    : ""
                  : "",
            },
            childsWeight:
              answerObj?.childsHeightWeight == "1"
                ? answerObj?.childsWeight
                  ? answerObj?.childsWeight
                  : ""
                : "",
            calculateBmi:
              answerObj?.childsHeightWeight == "1"
                ? answerObj?.calculateBmi
                  ? answerObj?.calculateBmi
                  : ""
                : "",
          },
        },
      };
    } else if (
      questionNumber == 23 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 23)
    ) {
      payload = {
        ...payload,
        describeChildsWeight: {
          childsWeight: answerObj?.describeChildsWeight
            ? answerObj?.describeChildsWeight
            : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 24 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 24)
    ) {
      payload = {
        ...payload,
        femaleChildren: {
          yes: answerObj?.femaleChildren == "yes" ? "1" : "2", // "Y or N",
          no: answerObj?.femaleChildren == "no" ? "1" : "2", //"Y or N",
          doesnotKnow: answerObj?.femaleChildren == "doesnotKnow" ? "1" : "2", //"Y or N","N/A"
          na: answerObj?.femaleChildren == "na" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 25 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 25)
    ) {
      payload = {
        ...payload,
        enoughFoodForFamily: {
          never: answerObj?.enoughFoodForFamily == "never" ? "1" : "2", //"Y or N",
          sometimes: answerObj?.enoughFoodForFamily == "sometimes" ? "1" : "2", //"Y or N",
          always: answerObj?.enoughFoodForFamily == "always" ? "1" : "2", //"Y or N",
          declineToAnswer:
            answerObj?.enoughFoodForFamily == "declineToAnswer" ? "1" : "2",
          // na: answerObj?.enoughFoodForFamily == "na" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 26 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 26)
    ) {
      payload = {
        ...payload,
        communityResources: {
          communityResourcesAvail: answerObj?.communityResources
            ? answerObj?.communityResources
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.communityResources == "no"
          //   ? "N"
          //   : "", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 27 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 27)
    ) {
      payload = {
        ...payload,
        childHaveAnyConditions: {
          asthma: answerObj?.childHaveAnyConditions?.includes("asthma")
            ? "1"
            : "2", //Y or N",
          asthmaSubAnswer: {
            childSeeASpecialist: answerObj?.childSeeASpecialist
              ? answerObj?.childSeeASpecialist
              : "",
            // == "yes"
            //   ? "1"
            //   : answerObj?.childSeeASpecialist == "no"
            //   ? "2"
            //   : "", //"Y or N",
            childSeeASpecialistYesSubAnswer: {
              yesName: secondLevelTextFieldAnswer["ifYesName"]
                ? secondLevelTextFieldAnswer["ifYesName"]
                : "", //"",
            },
            comment: secondLevelCommentObj.childSeeASpecialist
              ? secondLevelCommentObj.childSeeASpecialist
              : "", //"",
          },
          diabetes: answerObj?.childHaveAnyConditions?.includes("diabetes")
            ? "1"
            : "2", //"Y or N",
          diabetesSubAnswer: {
            childsBloodSugar: answerObj?.childsBloodSugar
              ? answerObj?.childsBloodSugar
              : "",
            //  == "yes"
            //   ? "1"
            //   : answerObj?.childSeeASpecialist == "no"
            //   ? "2"
            //   : "",
            comment: secondLevelCommentObj?.childsBloodSugar
              ? secondLevelCommentObj?.childsBloodSugar
              : "",
          },
          sickleCellDisease: answerObj?.childHaveAnyConditions?.includes(
            "sickleCellDisease",
          )
            ? "1"
            : "2", //"Y or N",
          sickleCellDiseaseSubAnswer: {
            hgbAonec: answerObj?.hgbAonec ? answerObj?.hgbAonec : "",
            //  == "yes"
            //   ? "1"
            //   : answerObj?.hgbAonec == "no"
            //   ? "2"
            //   : "", //"Y or N",
            hgbAonecYesSubAnswer: {
              lastHgbAonec: secondLevelTextFieldAnswer["ifYesHgbA1c"]
                ? secondLevelTextFieldAnswer["ifYesHgbA1c"]
                : "", //"",
            },
            comment: secondLevelCommentObj?.hgbAonec
              ? secondLevelCommentObj?.hgbAonec
              : "",
          },
          hemophilia: answerObj?.childHaveAnyConditions?.includes("hemophilia")
            ? "1"
            : "2", //"Y or N",
          hemophiliaSubAnswer: {
            specialDiet: answerObj?.specialDiet ? answerObj?.specialDiet : "",
            specialDietYesSubAnswer: {
              yesDiet: secondLevelTextFieldAnswer["ifYesDiet"]
                ? secondLevelTextFieldAnswer["ifYesDiet"]
                : "", //"",,
            },
            comment: secondLevelCommentObj?.specialDiet
              ? secondLevelCommentObj?.specialDiet
              : "",
          },
          ddAdhd: answerObj?.childHaveAnyConditions?.includes("ddAdhd")
            ? "1"
            : "2", //"Y or N",
          substanceUse: answerObj?.childHaveAnyConditions?.includes(
            "substanceUse",
          )
            ? "1"
            : "2", //"Y or N",
        },
      };
    } else if (
      questionNumber == 28 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 28)
    ) {
      payload = {
        ...payload,
        additionalInformation: {
          additionalInformationChild: answerObj?.additionalInformation
            ? answerObj?.additionalInformation
            : "", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      storedYear == "2024" &&
      (questionNumber == 29 ||
        (firstLevelCommentObj[questionNumber] && questionNumber == 29))
    ) {
      payload = {
        ...payload,
        patientReligiousBelief: {
          isPatientReligiousBelief: answerObj?.patientReligiousBelief
            ? answerObj?.patientReligiousBelief
            : "", //: answerObj?.childNeed ? answerObj?.childNeed : "", //== "yes" ? "Y" : "N",
          isPatientReligiousBeliefSubAnswer: {
            describe: answerObj?.spiritual_beliefs
              ? answerObj?.spiritual_beliefs
              : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
    if (
      questionNumber == 14 &&
      (counterMedicationsRowData?.length >= 1 ||
        (firstLevelCommentObj[questionNumber] && questionNumber == 14))
    ) {
      payload = {
        ...payload,
        medication: {
          prescriptionMedicationList: counterMedicationsRowData?.map((row) => {
            return {
              prescription: row?.description || "",
              doseUnits: row?.doseOrUnits || "",
              route: row?.route || "",
              reason: row?.reasons || "",
              status: row?.status || "",
              frequency: row?.frequency || "",
              comment: firstLevelCommentObj[questionNumber]
                ? firstLevelCommentObj[questionNumber]
                : "",
            };
          }),
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
    if (
      (allergiesRowData?.length >= 1 && questionNumber == 6) ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 6)
    ) {
      const childAllergiesYesSubAnswer = allergiesRowData?.map((allergies) => {
        const obj = {
          substance: allergies?.substance ? allergies?.substance : "",
          reaction: allergies?.reaction ? allergies?.reaction : "",
        };
        return obj;
      });
      payload = {
        ...payload,
        allergies: {
          childAllergies: answerObj?.allergies ? answerObj?.allergies : "", //"1",
          childAllergiesYesSubAnswer:
            answerObj?.allergies == "1" ? childAllergiesYesSubAnswer : [],
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
  };
  const onSavePedsAssesmentInfoData = async (
    answeredJson = autoSavePedsAssessInfo,
  ) => {
    //Object.keys(answeredJson).forEach((key) => {
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29,
    ].forEach((key) => {
      getpayloadByQuestionId(Number(key), answeredJson[key]);
    });
    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,
        data: {
          name: "Assessment Information",
          data: payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);
      setCurrSection(0);
      if (response.status === 200) {
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
      } else {
        // toast.error("Error saving data", {});
      }
    } catch (error) {}
  };

  const onSavePatientSummaryData = async (
    _autoSavePedsPatientSumm = autoSavePedsPatientSumm,
  ) => {
    try {
      const {
        evaluationDate,
        examFinishDate,
        memberAcknowledge,
        assessorComents,
        disclosureStatement,
        visitType,
        consentToVideo,
        driverLicense,
        comment,
        providerSignature,
      } = _autoSavePedsPatientSumm;
      const _payload = {
        assessorsComments: assessorComents ? assessorComents : "",
        memberAcknowledgement: memberAcknowledge == "Y" ? "1" : "2",
        disclosure: disclosureStatement == "Y" ? "1" : "2",
        evaluationDate: evaluationDate,
        examFinishDate: examFinishDate,
        providerSignature: providerSignature ? providerSignature : "",
        visitType: {
          sVisitType:
            visitType === 1
              ? "1"
              : visitType === 2
              ? "2"
              : visitType === "0"
              ? ""
              : visitType,
          consentedToVideo: consentToVideo == "Y" ? "1" : "2",
        },
      };
      let payload2024 = {};
      if (storedYear == "2024") {
        delete _payload.disclosure;
        delete _payload.visitType;
        payload2024 = {
          ..._payload,
          visitType: {
            //driverLicense
            isVisitType: visitType == 1 ? "1" : visitType == 2 ? "2" : "",
            memberConsented: consentToVideo == "Y" ? "1" : "2",
            memberIdentified: driverLicense == "Y" ? "1" : "2",
            comment: comment ? comment : "",
          },
          providerSignature: providerSignature ? providerSignature : "",
          addAddendum: "",
          addendumSignature: "",
        };
      }

      let payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,
        data: {
          name: "Patient Summary",

          data: storedYear == "2024" ? { ...payload2024 } : { ..._payload },
        },
        year: storedYear,
      };

      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  // const onSaveValidationOfConsentData = async (autoSaveValidationOfConsent) => {
  //   try {
  //     const {
  //       legalGuardian,
  //       legalGuardianComment,
  //       shareAssessment,
  //       shareAssessmentComment,
  //       fosterCareProgram,
  //       fosterCareProgramComment,
  //     } = autoSaveValidationOfConsent;

  //     const _payload = {
  //       legalGuardian: legalGuardian,
  //       legalGuardianComment: legalGuardianComment,
  //       shareAssessment: shareAssessment,
  //       shareAssessmentComment: shareAssessmentComment,
  //       fosterCareProgram: fosterCareProgram,
  //       fosterCareProgramComment: fosterCareProgramComment,
  //     };

  //     let payload = {
  //       id: assessmentId,
  //       data: {
  //         name: "Validation of Consent",
  //         data: { ..._payload },
  //       },
  //       year: storedYear,
  //     };

  //     const response = await putApi("/hra/assessment", payload);
  //     setCurrSection(3);
  //     if (response.status === 200) {
  //       toast.success("Assessment Updated Successfully", {});
  //       // navigate(getReroute());
  //       //const roleId = sessionStorage.getItem("roleId");

  //       // else {
  //       //   navigate("/AdminDashboard/MemberList");
  //       // }
  //     } else {
  //       return;
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     return;
  //   }
  // };

  const onSavePedsCovidScreeningData = async (autoSavePedsCovidScreening) => {
    try {
      const {
        covid_dignosedWithCoronaVal,
        covid_dignosedWithCoronaCommentVal,
        covid_highRiskVal,
        covid_highRiskCommentVal,
        covid_symptomsFeverOrChillsVal,
        covid_symptomsCoughVal,
        covid_symptomsShortnessOfBreathVal,
        covid_symptomsFatigueVal,
        covid_symptomsMuscleOrBodyAchesVal,
        covid_symptomsHeadacheVal,
        covid_symptomsLossOfTasteVal,
        covid_symptomsSoreThroatVal,
        covid_symptomsCongestionVal,
        covid_symptomsNauseaVal,
        covid_symptomsDiarrheaVal,
        covid_symptomsFluVal,
        covid_symptomsNoneVal,
        covid_symptomsCommentVal,
      } = autoSavePedsCovidScreening;
      const _payload = {
        covid_dignosedWithCoronaVal: covid_dignosedWithCoronaVal,
        covid_dignosedWithCoronaCommentVal: covid_dignosedWithCoronaCommentVal,
        covid_highRiskVal: covid_highRiskVal,
        covid_highRiskCommentVal: covid_highRiskCommentVal,
        covid_symptomsFeverOrChillsVal: covid_symptomsFeverOrChillsVal,
        covid_symptomsCoughVal: covid_symptomsCoughVal,
        covid_symptomsShortnessOfBreathVal: covid_symptomsShortnessOfBreathVal,
        covid_symptomsFatigueVal: covid_symptomsFatigueVal,
        covid_symptomsMuscleOrBodyAchesVal: covid_symptomsMuscleOrBodyAchesVal,
        covid_symptomsHeadacheVal: covid_symptomsHeadacheVal,
        covid_symptomsLossOfTasteVal: covid_symptomsLossOfTasteVal,
        covid_symptomsSoreThroatVal: covid_symptomsSoreThroatVal,
        covid_symptomsCongestionVal: covid_symptomsCongestionVal,
        covid_symptomsNauseaVal: covid_symptomsNauseaVal,
        covid_symptomsDiarrheaVal: covid_symptomsDiarrheaVal,
        covid_symptomsFluVal: covid_symptomsFluVal,
        covid_symptomsNoneVal: covid_symptomsNoneVal,
        covid_symptomsCommentVal: covid_symptomsCommentVal,
      };
      let payload = {
        id: assessmentId,
        data: {
          name: "Validation of Consent",
          data: { ..._payload },
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", payload);
      setCurrSection(4);
      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
        // navigate(getReroute());
        //const roleId = sessionStorage.getItem("roleId");

        // else {
        //   navigate("/AdminDashboard/MemberList");
        // }
      } else {
        return;
      }
    } catch (error) {
      console.log("error", error);
      return;
    }
  };

  const onSavePedsCovidScreeningDatas = async () => {
    const formData = autoSavePedsCovidScreening;

    const payload = {
      lastSevenDays: {
        coronaVirus: {
          isCoronaVirus: formData?.covid_dignosedWithCorona
            ? formData?.covid_dignosedWithCorona?.[0]
            : "",
          comment: formData?.covid_dignosedWithCoronaComment
            ? formData?.covid_dignosedWithCoronaComment
            : "",
        },
        highRiskTravel: {
          isHighRiskTravel: formData?.covid_highRisk
            ? formData?.covid_highRisk?.[0]
            : "",
          comment: formData?.covid_highRiskComment
            ? formData?.covid_highRiskComment
            : "",
        },
        symptomsSubAnswer: {
          feverOrChills: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("feverOrChills")
              ? "1"
              : ""
            : "",
          cough: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("cough")
              ? "1"
              : ""
            : "",
          shortnessOfBreath: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("shortnessOfBreath")
              ? "1"
              : ""
            : "",
          fatigue: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("fatigue")
              ? "1"
              : ""
            : "",
          muscleOrBodyAches: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("muscleOrBodyAches")
              ? "1"
              : ""
            : "",
          headache: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("headache")
              ? "1"
              : ""
            : "",
          lossOfTaste: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("lossOfTaste")
              ? "1"
              : ""
            : "",
          soreThroat: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("soreThroat")
              ? "1"
              : ""
            : "",
          congestion: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("congestion")
              ? "1"
              : ""
            : "",
          nausea: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("nausea")
              ? "1"
              : ""
            : "",
          diarrhea: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("diarrhea")
              ? "1"
              : ""
            : "",
          flu: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("flu")
              ? "1"
              : ""
            : "",
          none: formData?.covid_symptoms
            ? formData?.covid_symptoms.includes("none")
              ? "1"
              : ""
            : "",
          comment: formData?.covid_symptomsComment
            ? formData?.covid_symptomsComment
            : "",
        },
      },
    };
    let payload2024 = {};
    if (storedYear == "2024") {
      payload2024 = {
        lastSevenDays: {
          coronaVirus: {
            isCoronaVirus: formData?.covid_dignosedWithCorona
              ? formData?.covid_dignosedWithCorona?.[0]
              : "",
            comment: formData?.covid_dignosedWithCoronaComment_toggle
              ? formData?.covid_dignosedWithCoronaComment || ""
              : "",
          },
          highRiskTravel: {
            isHighRiskTravel: formData?.covid_highRisk
              ? formData?.covid_highRisk?.[0]
              : "",
            comment: formData?.covid_highRiskComment_toggle
              ? formData?.covid_highRiskComment
              : "",
          },
          symptomsSubAnswer: {
            feverOrChills: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("feverOrChills")
                ? "1"
                : ""
              : "",
            cough: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("cough")
                ? "1"
                : ""
              : "",
            shortnessOfBreath: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("shortnessOfBreath")
                ? "1"
                : ""
              : "",
            fatigue: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("fatigue")
                ? "1"
                : ""
              : "",
            muscleOrBodyAches: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("muscleOrBodyAches")
                ? "1"
                : ""
              : "",
            headache: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("headache")
                ? "1"
                : ""
              : "",
            lossOfTaste: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("lossOfTaste")
                ? "1"
                : ""
              : "",
            soreThroat: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("soreThroat")
                ? "1"
                : ""
              : "",
            congestion: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("congestion")
                ? "1"
                : ""
              : "",
            nausea: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("nausea")
                ? "1"
                : ""
              : "",
            diarrhea: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("diarrhea")
                ? "1"
                : ""
              : "",
            flu: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("flu")
                ? "1"
                : ""
              : "",
            none: formData?.covid_symptoms
              ? formData?.covid_symptoms.includes("none")
                ? "1"
                : ""
              : "",
            comment: formData?.covid_symptomsComment_toggle
              ? formData?.covid_symptomsComment
              : "",
          },
        },
      };
    }

    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,officeName
        data: {
          name: "Covid Screening",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };

  const onSavePedsPreventativeCareData = async () => {
    const formData = autoSavePedsPreventativeCare?.preventiveFormData;

    const tableData1 = autoSavePedsPreventativeCare?.preventivesRowData || [];
    const updatedTableData1 = tableData1.map((item, index) => ({
      ...item,
      method:
        autoSavePedsPreventativeCare?.currentYearScreeningsWithMethod.includes(
          autoSavePedsPreventativeCare?.defaultScreenings[index],
        )
          ? item.method || ""
          : "N/A",
      date: "2024-02-06",
      method: "Which method",
    }));

    const payload = {
      // ...payload,

      preventiveCare: {
        comment: formData?.inThePastThreeYearsHaveYouHadComment
          ? formData?.inThePastThreeYearsHaveYouHadComment
          : "",
        pastThreeYearsSubAnswers: updatedTableData1,
      },

      // childNeedMedicalCare: {
      //   isChildNeedMedicalCare: formData?.isChildNeedMedicalCare,
      //   isChildNeedMedicalCareSubAnswer:
      //     formData?.isChildNeedMedicalCareSubAnswer,
      // },

      childNeedMedicalCare: {
        isChildNeedMedicalCare: formData?.isChildNeedMedicalCare
          ? formData?.isChildNeedMedicalCare?.includes("1")
            ? "1"
            : formData?.isChildNeedMedicalCare?.includes("2")
            ? "2"
            : ""
          : "",
        isChildNeedMedicalCareSubAnswer: {
          medicalCondition: formData?.isChildNeedMedicalCareSubAnswer
            ? formData?.isChildNeedMedicalCareSubAnswer?.includes(
                "medicalCondition",
              )
              ? "1"
              : ""
            : "",
          behaviouralCondition: formData?.isChildNeedMedicalCareSubAnswer
            ? formData?.isChildNeedMedicalCareSubAnswer?.includes(
                "behaviouralCondition",
              )
              ? "1"
              : ""
            : "",
          healthCondition: formData?.isChildNeedMedicalCareSubAnswer
            ? formData?.isChildNeedMedicalCareSubAnswer?.includes(
                "healthCondition",
              )
              ? "1"
              : ""
            : "",
        },
        comment: "",
      },

      // childSeenByHealthProfessionals: formData?.childSeenHealthProfessionals,

      childSeenByHealthProfessionals: {
        pediatrician: formData?.childSeenHealthProfessionals
          ? formData?.childSeenHealthProfessionals?.includes("pediatrician")
            ? "1"
            : ""
          : "",
        dentist: formData?.childSeenHealthProfessionals
          ? formData?.childSeenHealthProfessionals?.includes("Dentist")
            ? "1"
            : ""
          : "",
        none: formData?.childSeenHealthProfessionals
          ? formData?.childSeenHealthProfessionals?.includes("None")
            ? "1"
            : ""
          : "",
        other: formData?.childSeenHealthProfessionals
          ? formData?.childSeenHealthProfessionals?.includes("Other")
            ? "1"
            : ""
          : "",
      },

      childSeeingAnySpecialists: {
        isChildSeeingAnySpecialists: formData?.areYouCurrentlySeeingAnySpecia
          ? formData?.areYouCurrentlySeeingAnySpecia?.[0]
          : "",
        isChildSeeingAnySpecialistsSubAnswer: [
          {
            medicalSp:
              formData?.areYouCurrentlySeeingAnySpecia?.[0] == "1" ? "ENT" : "",
            specialist:
              formData?.areYouCurrentlySeeingAnySpecia?.[0] == "1" ? "ent" : "",
            for:
              formData?.areYouCurrentlySeeingAnySpecia?.[0] == "1"
                ? "eyes"
                : "",
          },
        ],
        comment: "",
      },

      useSpecializedEquipment: {
        isUseSpecializedEquipment: formData?.childUserDeviceforCommunication
          ? formData?.childUserDeviceforCommunication?.[0]
          : "",
        isUseSpecializedEquipmentSubAnswer: {
          glasses:
            formData?.childUserDeviceforCommunication?.[0] == "1" &&
            formData?.oneTimeScreenForAbdominaleducationProvided?.includes(
              "glasses",
            )
              ? "1"
              : "",
          hearingAides:
            formData?.childUserDeviceforCommunication?.[0] == "1" &&
            formData?.oneTimeScreenForAbdominaleducationProvided?.includes(
              "hearingAides",
            )
              ? "1"
              : "",
          speechDevice:
            formData?.childUserDeviceforCommunication?.[0] == "1" &&
            formData?.oneTimeScreenForAbdominaleducationProvided?.includes(
              "speechDevice",
            )
              ? "1"
              : "",
          communicationBoard:
            formData?.childUserDeviceforCommunication?.[0] == "1" &&
            formData?.oneTimeScreenForAbdominaleducationProvided?.includes(
              "communicationBoard",
            )
              ? "1"
              : "",
          other:
            formData?.childUserDeviceforCommunication?.[0] == "1" &&
            formData?.oneTimeScreenForAbdominaleducationProvided?.includes(
              "other",
            )
              ? "1"
              : "",
        },
        comment: "",
      },

      upToDateOnImmunizations: {
        isUpToDateOnImmunizations: formData?.child_up_to_date_immunizations
          ? formData?.child_up_to_date_immunizations?.[0]
          : "",
        // isUpToDateOnImmunizationsSubAnswer : formData?.childUptoDateImmunizationsComments,
        noUpToDateOnImmunizationsSubAnswer: {
          describe: formData?.childUptoDateImmunizationsComments
            ? formData?.childUptoDateImmunizationsComments
            : "",
        },
        comment: formData?.child_up_to_date_immunizationsComment
          ? formData?.child_up_to_date_immunizationsComment
          : "",
      },

      visitedEmergencyRoom: {
        none: formData?.medical_past12MonthEmerRoom
          ? formData?.medical_past12MonthEmerRoom?.includes("none")
            ? "1"
            : ""
          : "",
        once: formData?.medical_past12MonthEmerRoom
          ? formData?.medical_past12MonthEmerRoom?.includes("1")
            ? "1"
            : ""
          : "",
        twice: formData?.medical_past12MonthEmerRoom
          ? formData?.medical_past12MonthEmerRoom?.includes("2")
            ? "1"
            : ""
          : "",
        thrice: formData?.medical_past12MonthEmerRoom
          ? formData?.medical_past12MonthEmerRoom?.includes("3")
            ? "1"
            : ""
          : "",
        fourTimes: formData?.medical_past12MonthEmerRoom
          ? formData?.medical_past12MonthEmerRoom?.includes("4")
            ? "1"
            : ""
          : "",
        fiveOrMore: formData?.medical_past12MonthEmerRoom
          ? formData?.medical_past12MonthEmerRoom?.includes("5")
            ? "1"
            : ""
          : "",
        oneOrMoreDescribe: formData?.medical_past12MonthEmerRoomOneSubDesc
          ? formData?.medical_past12MonthEmerRoomOneSubDesc
          : "",
        twoOrMoreDescribe: formData?.medical_past12MonthEmerRoomTwoSubDesc
          ? formData?.medical_past12MonthEmerRoomTwoSubDesc
          : "",
        threeOrMoreDescribe: formData?.medical_past12MonthEmerRoomThreeSubDesc
          ? formData?.medical_past12MonthEmerRoomThreeSubDesc
          : "",
        fourOrMoreDescribe: formData?.medical_past12MonthEmerRoomFourSubDesc
          ? formData?.medical_past12MonthEmerRoomFourSubDesc
          : "",
        fiveOrMoreDescribe:
          formData?.medical_past12MonthEmerRoomFiveOrMoreSubDesc
            ? formData?.medical_past12MonthEmerRoomFiveOrMoreSubDesc
            : "",
      },

      recommendations: {
        recommendationsSubAnswers: [
          {
            ageRecommendedImmunizations: formData?.priventative_Recommendations
              ? formData?.priventative_Recommendations?.includes(
                  "DiscussAgeRecommendedImmunizationsWithPCP",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.priventative_Recommendations
              ? formData?.priventative_Recommendations?.includes(
                  "DiscussAgeRecommendedImmunizationsWithPCP",
                )
                ? "Discuss age-recommended immunizations with PCP."
                : ""
              : "",
          },
          {
            followUpAfterEmergencyRoomVisit:
              formData?.priventative_Recommendations
                ? formData?.priventative_Recommendations?.includes(
                    "FollowUpNeededAfterEmergencyRoomVisit",
                  )
                  ? "1"
                  : ""
                : "",
            comment: formData?.priventative_Recommendations
              ? formData?.priventative_Recommendations?.includes(
                  "FollowUpNeededAfterEmergencyRoomVisit",
                )
                ? "Follow-up needed after Emergency Room visit."
                : ""
              : "",
          },
          {
            discussDMENeededWithProvider: formData?.priventative_Recommendations
              ? formData?.priventative_Recommendations?.includes(
                  "DiscussDMENeededWithProvider",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.priventative_Recommendations
              ? formData?.priventative_Recommendations?.includes(
                  "DiscussDMENeededWithProvider",
                )
                ? "Discuss DME (Durable Medical Equipment) needed with provider."
                : ""
              : "",
          },
        ],
      },
    };
    let payload2024 = {};
    if (storedYear == "2024") {
      payload2024 = {
        // ...payload,

        preventiveCare: {
          comment: formData?.inThePastThreeYearsHaveYouHadComment
            ? formData?.inThePastThreeYearsHaveYouHadComment
            : "",
          pastThreeYearsSubAnswers: updatedTableData1,
        },

        // childNeedMedicalCare: {
        //   isChildNeedMedicalCare: formData?.isChildNeedMedicalCare,
        //   isChildNeedMedicalCareSubAnswer:
        //     formData?.isChildNeedMedicalCareSubAnswer,
        // },

        childNeedMedicalCare: {
          isChildNeedMedicalCare: formData?.isChildNeedMedicalCare
            ? formData?.isChildNeedMedicalCare?.includes("1")
              ? "1"
              : formData?.isChildNeedMedicalCare?.includes("2")
              ? "2"
              : ""
            : "",
          isChildNeedMedicalCareSubAnswer: {
            medicalCondition: formData?.isChildNeedMedicalCareSubAnswer
              ? formData?.isChildNeedMedicalCareSubAnswer?.includes(
                  "medicalCondition",
                )
                ? "1"
                : ""
              : "",
            behaviouralCondition: formData?.isChildNeedMedicalCareSubAnswer
              ? formData?.isChildNeedMedicalCareSubAnswer?.includes(
                  "behaviouralCondition",
                )
                ? "1"
                : ""
              : "",
            healthCondition: formData?.isChildNeedMedicalCareSubAnswer
              ? formData?.isChildNeedMedicalCareSubAnswer?.includes(
                  "healthCondition",
                )
                ? "1"
                : ""
              : "",
          },
          comment: formData?.isChildNeedMedicalCareComment
            ? formData?.isChildNeedMedicalCareComment
            : "",
        },

        // childSeenByHealthProfessionals: formData?.childSeenHealthProfessionals,

        childSeenByHealthProfessionals: {
          pediatrician: formData?.childSeenHealthProfessionals
            ? formData?.childSeenHealthProfessionals?.includes("pediatrician")
              ? "1"
              : ""
            : "",
          dentist: formData?.childSeenHealthProfessionals
            ? formData?.childSeenHealthProfessionals?.includes("dentist")
              ? "1"
              : ""
            : "",
          none: formData?.childSeenHealthProfessionals
            ? formData?.childSeenHealthProfessionals?.includes("none")
              ? "1"
              : ""
            : "",
          other: formData?.childSeenHealthProfessionals
            ? formData?.childSeenHealthProfessionals?.includes("other")
              ? "1"
              : ""
            : "",
          comment: formData?.childSeenHealthProfessionalsComment
            ? formData?.childSeenHealthProfessionalsComment
            : "",
        },

        childSeeingAnySpecialists: {
          isChildSeeingAnySpecialists: formData?.areYouCurrentlySeeingAnySpecia
            ? formData?.areYouCurrentlySeeingAnySpecia?.includes("1")
              ? "1"
              : formData?.areYouCurrentlySeeingAnySpecia?.includes("2")
              ? "2"
              : ""
            : "",
          isChildSeeingAnySpecialistsSubAnswer: [
            {
              medicalSp:
                formData?.areYouCurrentlySeeingAnySpecia?.[0] == "1"
                  ? "ENT"
                  : "",
              specialist:
                formData?.areYouCurrentlySeeingAnySpecia?.[0] == "1"
                  ? "ent"
                  : "",
              for:
                formData?.areYouCurrentlySeeingAnySpecia?.[0] == "1"
                  ? "eyes"
                  : "",
            },
          ],
          comment: formData?.areYouCurrentlySeeingAnySpeciaComment
            ? formData?.areYouCurrentlySeeingAnySpeciaComment
            : "",
        },

        useSpecializedEquipment: {
          isUseSpecializedEquipment: formData?.childUserDeviceforCommunication
            ? formData?.childUserDeviceforCommunication?.includes("1")
              ? "1"
              : formData?.childUserDeviceforCommunication?.includes("2")
              ? "2"
              : ""
            : "",
          isUseSpecializedEquipmentSubAnswer: {
            glasses:
              formData?.childUserDeviceforCommunication?.[0] == "1" &&
              formData?.oneTimeScreenForAbdominaleducationProvided?.includes(
                "glasses",
              )
                ? "1"
                : "",
            hearingAides:
              formData?.childUserDeviceforCommunication?.[0] == "1" &&
              formData?.oneTimeScreenForAbdominaleducationProvided?.includes(
                "hearingAides",
              )
                ? "1"
                : "",
            speechDevice:
              formData?.childUserDeviceforCommunication?.[0] == "1" &&
              formData?.oneTimeScreenForAbdominaleducationProvided?.includes(
                "speechDevice",
              )
                ? "1"
                : "",
            communicationBoard:
              formData?.childUserDeviceforCommunication?.[0] == "1" &&
              formData?.oneTimeScreenForAbdominaleducationProvided?.includes(
                "communicationBoard",
              )
                ? "1"
                : "",
            other:
              formData?.childUserDeviceforCommunication?.[0] == "1" &&
              formData?.oneTimeScreenForAbdominaleducationProvided?.includes(
                "other",
              )
                ? "1"
                : "",
          },
          comment: formData?.childUserDeviceforCommunicationComment
            ? formData?.childUserDeviceforCommunicationComment
            : "",
        },

        upToDateOnImmunizations: {
          isUpToDateOnImmunizations: formData?.child_up_to_date_immunizations
            ? formData?.child_up_to_date_immunizations?.includes("1")
              ? "1"
              : formData?.child_up_to_date_immunizations?.includes("2")
              ? "2"
              : ""
            : "",
          // isUpToDateOnImmunizationsSubAnswer : formData?.childUptoDateImmunizationsComments,
          noUpToDateOnImmunizationsSubAnswer: {
            describe: formData?.childUptoDateImmunizationsComments
              ? formData?.childUptoDateImmunizationsComments
              : "",
          },
          comment: formData?.child_up_to_date_immunizationsComment
            ? formData?.child_up_to_date_immunizationsComment
            : "",
        },

        visitedEmergencyRoom: {
          none: formData?.medical_past12MonthEmerRoom
            ? formData?.medical_past12MonthEmerRoom?.includes("none")
              ? "1"
              : ""
            : "",
          once: formData?.medical_past12MonthEmerRoom
            ? formData?.medical_past12MonthEmerRoom?.includes("1")
              ? "1"
              : ""
            : "",
          twice: formData?.medical_past12MonthEmerRoom
            ? formData?.medical_past12MonthEmerRoom?.includes("2")
              ? "1"
              : ""
            : "",
          thrice: formData?.medical_past12MonthEmerRoom
            ? formData?.medical_past12MonthEmerRoom?.includes("3")
              ? "1"
              : ""
            : "",
          fourTimes: formData?.medical_past12MonthEmerRoom
            ? formData?.medical_past12MonthEmerRoom?.includes("4")
              ? "1"
              : ""
            : "",
          fiveOrMore: formData?.medical_past12MonthEmerRoom
            ? formData?.medical_past12MonthEmerRoom?.includes("5")
              ? "1"
              : ""
            : "",
          oneOrMoreDescribe: formData?.medical_past12MonthEmerRoomOneSubDesc
            ? formData?.medical_past12MonthEmerRoomOneSubDesc
            : "",
          twoOrMoreDescribe: formData?.medical_past12MonthEmerRoomTwoSubDesc
            ? formData?.medical_past12MonthEmerRoomTwoSubDesc
            : "",
          threeOrMoreDescribe: formData?.medical_past12MonthEmerRoomThreeSubDesc
            ? formData?.medical_past12MonthEmerRoomThreeSubDesc
            : "",
          fourOrMoreDescribe: formData?.medical_past12MonthEmerRoomFourSubDesc
            ? formData?.medical_past12MonthEmerRoomFourSubDesc
            : "",
          fiveOrMoreDescribe:
            formData?.medical_past12MonthEmerRoomFiveOrMoreSubDesc
              ? formData?.medical_past12MonthEmerRoomFiveOrMoreSubDesc
              : "",
          comment: formData?.medical_past12MonthEmerRoomComment
            ? formData?.medical_past12MonthEmerRoomComment
            : "",
        },

        recommendations: {
          recommendationsSubAnswers: [
            {
              ageRecommendedImmunizations:
                formData?.priventative_Recommendations
                  ? formData?.priventative_Recommendations?.includes(
                      "DiscussAgeRecommendedImmunizationsWithPCP",
                    )
                    ? "1"
                    : ""
                  : "",
              comment:
                formData?.priventative_RecommendationsDiscussAgeRecommendedImmunizationsWithPCPComment
                  ? formData?.priventative_RecommendationsDiscussAgeRecommendedImmunizationsWithPCPComment
                  : "",
            },
            {
              followUpAfterEmergencyRoomVisit:
                formData?.priventative_Recommendations
                  ? formData?.priventative_Recommendations?.includes(
                      "FollowUpNeededAfterEmergencyRoomVisit",
                    )
                    ? "1"
                    : ""
                  : "",
              comment:
                formData?.priventative_RecommendationsFollowUpNeededAfterEmergencyRoomVisitComment
                  ? formData?.priventative_RecommendationsFollowUpNeededAfterEmergencyRoomVisitComment
                  : "",
            },
            {
              discussDMENeededWithProvider:
                formData?.priventative_Recommendations
                  ? formData?.priventative_Recommendations?.includes(
                      "DiscussDMENeededWithProvider",
                    )
                    ? "1"
                    : ""
                  : "",
              comment:
                formData?.priventative_RecommendationsDiscussDMENeededWithProviderComment
                  ? formData?.priventative_RecommendationsDiscussDMENeededWithProviderComment
                  : "",
            },
          ],
        },
      };
    }

    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,officeName
        data: {
          name: "Preventive Care",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };

  const onSavePedsAllergiesData = async () => {
    const formData = autoSavepedsallergiesRowData;

    const tableData1 = (formData?.allergy_allergiesDatatable || [{}]).map(
      ({ id, ...data }) => ({
        reaction: "",
        substance: "",
        ...data,
      }),
    );
    const tableData2 = (formData?.allergy_medicDatatable || [{}]).map(
      ({ id, prescribingPhysician, ...data }) => ({
        status: {
          taking: "2",
          notTaking: "2",
        },
        frequency: "",
        route: "",
        doseOrUnits: "",
        labelName: "",
        ...data,
      }),
    );

    const tableData3 = (formData?.allergy_counterMedicDatatable || [{}]).map(
      ({ id, ...data }) => ({
        date: "",
        description: "",
        doseOrUnits: "",
        route: "",
        frequency: "",
        ...data,
      }),
    );

    const payload = {
      allergies: {
        allergies: formData?.allergy_allergies
          ? formData?.allergy_allergies?.[0]
          : "",
        allergiesSubAnswers: tableData1 ? tableData1 : "",
        comment: formData?.allergy_allergiesComment
          ? formData?.allergy_allergiesComment
          : "",
      },
      medications: {
        medication: tableData2 ? tableData2 : "",
        comment: formData?.medicationsComment
          ? formData?.medicationsComment
          : "",
      },
      counterMedications: {
        counterMedications: formData?.allergy_counterMedic
          ? formData?.allergy_counterMedic?.[0]
          : "",
        counterMedicationsSubAnswers: tableData3 ? tableData3 : "",
        comment: formData?.allergy_counterMedicComment
          ? formData?.allergy_counterMedicComment
          : "",
      },
      // longTermUse: {
      //   none: formData?.allergy_longTermUsageNone ? formData?.allergy_longTermUsageNone?.[0] : "",
      //   longTermUse: formData?.allergy_longTermUsageNone ? formData?.allergy_longTermUsageNone?.[0] : "",
      //   longTermUseSubAnswers: {
      //     longTermUseSubAnswers : formData?.allergy_longTermUsageNoneSub ? formData?.allergy_longTermUsageNoneSub?.[0] :  '',
      //   },
      //   steroidsSubAnswers:
      //   formData?.allergy_longTermUsageNoneSubSteroidsSubType ? formData?.allergy_longTermUsageNoneSubSteroidsSubType?.[0] : "",
      //   anticoagulantsSubAnswers:
      //     formData?.allergy_longTermUsageNoneSubAntiCoagSubDesc ? formData?.allergy_longTermUsageNoneSubAntiCoagSubDesc : '',
      //   otherSubAnswer: {
      //     describe: formData?.allergy_longTermUsageNoneSubOtherSubAnswer ? formData?.allergy_longTermUsageNoneSubOtherSubAnswer : '',
      //   },
      //   comment: formData?.allergy_longTermUsageNoneComment ? formData?.allergy_longTermUsageNoneComment : '',
      // },
      longTermUse: {
        none: formData?.allergy_longTermUsageNone?.[0] === "1" ? "1" : "2",
        longTermUseSubAnswers: {
          ASA: formData?.allergy_longTermUsageNoneSub
            ? formData?.allergy_longTermUsageNoneSub.includes("asa")
              ? "1"
              : ""
            : "",
          steroids: formData?.allergy_longTermUsageNoneSub
            ? formData?.allergy_longTermUsageNoneSub.includes("steroids")
              ? "1"
              : ""
            : "",
          steroidsSubAnswers: {
            systemic: formData?.allergy_longTermUsageNoneSubSteroidsSubType
              ? formData?.allergy_longTermUsageNoneSubSteroidsSubType.includes(
                  "systemic",
                )
                ? "1"
                : ""
              : "",
            inhaled: formData?.allergy_longTermUsageNoneSubSteroidsSubType
              ? formData?.allergy_longTermUsageNoneSubSteroidsSubType.includes(
                  "inhaled",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.allergy_longTermUsageNoneComment
              ? formData?.allergy_longTermUsageNoneComment
              : "",
          },
          insulin: formData?.allergy_longTermUsageNoneSub
            ? formData?.allergy_longTermUsageNoneSub.includes("insulin")
              ? "1"
              : ""
            : "",
          anticoagulants: formData.allergy_longTermUsageNoneSub
            ? formData.allergy_longTermUsageNoneSub.includes("anticoagulants")
              ? "1"
              : ""
            : "",
          anticoagulantsSubAnswers: {
            coumadin: formData.allergy_longTermUsageNoneSubAntiCoagSubDesc
              ? formData.allergy_longTermUsageNoneSubAntiCoagSubDesc.includes(
                  "coumadin",
                )
                ? "1"
                : ""
              : "",
            thrombin: formData.allergy_longTermUsageNoneSubAntiCoagSubDesc
              ? formData.allergy_longTermUsageNoneSubAntiCoagSubDesc.includes(
                  "thrombin",
                )
                ? "1"
                : ""
              : "",
            palvix: formData.allergy_longTermUsageNoneSubAntiCoagSubDesc
              ? formData.allergy_longTermUsageNoneSubAntiCoagSubDesc.includes(
                  "palvix",
                )
                ? "1"
                : ""
              : "",
            factorXa: formData.allergy_longTermUsageNoneSubAntiCoagSubDesc
              ? formData.allergy_longTermUsageNoneSubAntiCoagSubDesc.includes(
                  "factorXa",
                )
                ? "1"
                : ""
              : "",
            other: formData.allergy_longTermUsageNoneSubAntiCoagSubDesc
              ? formData.allergy_longTermUsageNoneSubAntiCoagSubDesc.includes(
                  "other",
                )
                ? "1"
                : ""
              : "",
            otherSubAnswer: {
              describe:
                formData?.allergy_longTermUsageNoneSubOtherSubAnswer || "",
            },
            comment: "",
          },
          statins: formData.allergy_longTermUsageNoneSub
            ? formData.allergy_longTermUsageNoneSub.includes("statins")
              ? "1"
              : ""
            : "",
          biphosphonate: formData.allergy_longTermUsageNoneSub
            ? formData.allergy_longTermUsageNoneSub.includes("biphosphonate")
              ? "1"
              : ""
            : "",
          nsaid: formData.allergy_longTermUsageNoneSub
            ? formData.allergy_longTermUsageNoneSub.includes("nsaid")
              ? "1"
              : ""
            : "",
          antiplatelet: formData.allergy_longTermUsageNoneSub
            ? formData.allergy_longTermUsageNoneSub.includes("antiplatelet")
              ? "1"
              : ""
            : "",
          other: formData.allergy_longTermUsageNoneSub
            ? formData.allergy_longTermUsageNoneSub.includes("other")
              ? "1"
              : ""
            : "",
          otherSubAnswers: {
            describe: "",
          },
          comment: "",
        },
        comment: formData?.allergy_longTermUsageNoneComment || "",
      },

      medicationCompliance: {
        medicationComplianceSubAnswers: {
          financialAbilityForMedicine: {
            hasEnoughMoney: formData?.allergy_enoughMoney
              ? formData?.allergy_enoughMoney?.[0]
              : "",
            comment: formData?.allergy_enoughMoneyComment
              ? formData?.allergy_enoughMoneyComment
              : "",
          },
          takeYourMedicine: {
            takeYourMedicine: formData?.allergy_medicSubTakeMedic
              ? formData?.allergy_medicSubTakeMedic?.[0]
              : "",
            comment: formData?.allergy_medicSubTakeMedicComment
              ? formData?.allergy_medicSubTakeMedicComment
              : "",
          },
          attentionToYourMedication: {
            attentionToYourMedication: formData?.allergy_medicSubAttenMedic
              ? formData?.allergy_medicSubAttenMedic?.[0]
              : "",
            comment: formData?.allergy_medicSubAttenMedicComment
              ? formData?.allergy_medicSubAttenMedicComment
              : "",
          },
          longtermBenefit: {
            longtermBenefit: formData?.allergy_medicSubLongTerm
              ? formData?.allergy_medicSubLongTerm?.[0]
              : "",
            comment: formData?.allergy_medicSubLongTermComment
              ? formData?.allergy_medicSubLongTermComment
              : "",
          },
          stopTakingMedicine: {
            stopTakingMedicine: formData?.allergy_medicSubStopMedic
              ? formData?.allergy_medicSubStopMedic?.[0]
              : "",
            comment: formData?.allergy_medicSubStopMedicComment
              ? formData?.allergy_medicSubStopMedicComment
              : "",
          },
          feelWorse: {
            feelWorse: formData?.allergy_medicSubFeelWorse
              ? formData?.allergy_medicSubFeelWorse?.[0]
              : "",
            comment: formData?.allergy_medicSubFeelWorseComment
              ? formData?.allergy_medicSubFeelWorseComment
              : "",
          },
          forgetToRefill: {
            forgetToRefill: formData?.allergy_medicSubForgotRefill
              ? formData?.allergy_medicSubForgotRefill?.[0]
              : "",
            comment: formData?.allergy_medicSubForgotRefillComment
              ? formData?.allergy_medicSubForgotRefillComment
              : "",
          },
        },
      },

      childLimited: {
        isChildLimited: formData?.childAbility
          ? formData?.childAbility?.[0]
          : "",
        isChildLimitedSubAnswers: {
          isDueToMedicalCondition: formData?.condition_that_has_lasted
            ? formData?.condition_that_has_lasted?.[0]
            : "",
          conditionExpectedToLast12Months: {
            isConditionExpectedToLast12Months:
              formData?.condition_that_has_lasted
                ? formData?.condition_that_has_lasted?.[0]
                : "",
            isConditionExpectedToLast12MonthsSubAnswer: {
              explain: formData?.Explain ? formData?.Explain : "",
            },
          },
        },
      },

      recommendations: {
        recommendationSubAnswers: formData?.allergies_Recommendations2
          ? formData?.allergies_Recommendations2
          : [],
      },
      educatedOnImportance: {
        educatedOnImportance: formData?.educatedOnImportance
          ? formData?.educatedOnImportance?.[0]
          : "",
        comment: formData?.educatedOnImportanceComment
          ? formData?.educatedOnImportanceComment
          : "",
      },
    };
    let payload2024 = {};
    if (storedYear == "2024") {
      payload2024 = {
        allergies: {
          allergies: formData?.allergy_allergies
            ? formData?.allergy_allergies.includes("1")
              ? "1"
              : formData?.allergy_allergies.includes("2")
              ? "2"
              : ""
            : "",
          allergiesSubAnswers: tableData1 ? tableData1 : "",
          comment: formData?.allergy_allergiesComment
            ? formData?.allergy_allergiesComment
            : "",
        },
        medications: {
          medication: tableData2 ? tableData2 : "",
          comment: formData?.medicationsComment
            ? formData?.medicationsComment
            : "",
        },
        counterMedications: {
          counterMedications: formData?.allergy_counterMedic
            ? formData?.allergy_counterMedic?.[0]
            : "",
          counterMedicationsSubAnswers: tableData3 ? tableData3 : "",
          comment: formData?.allergy_counterMedicComment
            ? formData?.allergy_counterMedicComment
            : "",
        },
        // longTermUse: {
        //   none: formData?.allergy_longTermUsageNone ? formData?.allergy_longTermUsageNone?.[0] : "",
        //   longTermUse: formData?.allergy_longTermUsageNone ? formData?.allergy_longTermUsageNone?.[0] : "",
        //   longTermUseSubAnswers: {
        //     longTermUseSubAnswers : formData?.allergy_longTermUsageNoneSub ? formData?.allergy_longTermUsageNoneSub?.[0] :  '',
        //   },
        //   steroidsSubAnswers:
        //   formData?.allergy_longTermUsageNoneSubSteroidsSubType ? formData?.allergy_longTermUsageNoneSubSteroidsSubType?.[0] : "",
        //   anticoagulantsSubAnswers:
        //     formData?.allergy_longTermUsageNoneSubAntiCoagSubDesc ? formData?.allergy_longTermUsageNoneSubAntiCoagSubDesc : '',
        //   otherSubAnswer: {
        //     describe: formData?.allergy_longTermUsageNoneSubOtherSubAnswer ? formData?.allergy_longTermUsageNoneSubOtherSubAnswer : '',
        //   },
        //   comment: formData?.allergy_longTermUsageNoneComment ? formData?.allergy_longTermUsageNoneComment : '',
        // },
        longTermUse: {
          none: formData?.allergy_longTermUsageNone?.[0] === "1" ? "1" : "2",
          longTermUseSubAnswers: {
            ASA: formData?.allergy_longTermUsageNoneSub
              ? formData?.allergy_longTermUsageNoneSub.includes("asa")
                ? "1"
                : ""
              : "",
            steroids: formData?.allergy_longTermUsageNoneSub
              ? formData?.allergy_longTermUsageNoneSub.includes("steroids")
                ? "1"
                : ""
              : "",
            steroidsSubAnswers: {
              systemic: formData?.allergy_longTermUsageNoneSubSteroidsSubType
                ? formData?.allergy_longTermUsageNoneSubSteroidsSubType.includes(
                    "systemic",
                  )
                  ? "1"
                  : ""
                : "",
              inhaled: formData?.allergy_longTermUsageNoneSubSteroidsSubType
                ? formData?.allergy_longTermUsageNoneSubSteroidsSubType.includes(
                    "inhaled",
                  )
                  ? "1"
                  : ""
                : "",
              comment: formData?.allergy_longTermUsageNoneComment
                ? formData?.allergy_longTermUsageNoneComment
                : "",
            },
            insulin: formData?.allergy_longTermUsageNoneSub
              ? formData?.allergy_longTermUsageNoneSub.includes("insulin")
                ? "1"
                : ""
              : "",
            anticoagulants: formData.allergy_longTermUsageNoneSub
              ? formData.allergy_longTermUsageNoneSub.includes("anticoagulants")
                ? "1"
                : ""
              : "",
            anticoagulantsSubAnswers: {
              coumadin: formData.allergy_longTermUsageNoneSubAntiCoagSubDesc
                ? formData.allergy_longTermUsageNoneSubAntiCoagSubDesc.includes(
                    "coumadin",
                  )
                  ? "1"
                  : ""
                : "",
              thrombin: formData.allergy_longTermUsageNoneSubAntiCoagSubDesc
                ? formData.allergy_longTermUsageNoneSubAntiCoagSubDesc.includes(
                    "thrombin",
                  )
                  ? "1"
                  : ""
                : "",
              palvix: formData.allergy_longTermUsageNoneSubAntiCoagSubDesc
                ? formData.allergy_longTermUsageNoneSubAntiCoagSubDesc.includes(
                    "palvix",
                  )
                  ? "1"
                  : ""
                : "",
              factorXa: formData.allergy_longTermUsageNoneSubAntiCoagSubDesc
                ? formData.allergy_longTermUsageNoneSubAntiCoagSubDesc.includes(
                    "factorXa",
                  )
                  ? "1"
                  : ""
                : "",
              other: formData.allergy_longTermUsageNoneSubAntiCoagSubDesc
                ? formData.allergy_longTermUsageNoneSubAntiCoagSubDesc.includes(
                    "other",
                  )
                  ? "1"
                  : ""
                : "",
              otherSubAnswer: {
                describe:
                  formData?.allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc ||
                  "",
              },
              comment: "",
            },
            statins: formData.allergy_longTermUsageNoneSub
              ? formData.allergy_longTermUsageNoneSub.includes("statins")
                ? "1"
                : ""
              : "",
            biphosphonate: formData.allergy_longTermUsageNoneSub
              ? formData.allergy_longTermUsageNoneSub.includes("biphosphonate")
                ? "1"
                : ""
              : "",
            nsaid: formData.allergy_longTermUsageNoneSub
              ? formData.allergy_longTermUsageNoneSub.includes("nsaid")
                ? "1"
                : ""
              : "",
            antiplatelet: formData.allergy_longTermUsageNoneSub
              ? formData.allergy_longTermUsageNoneSub.includes("antiplatelet")
                ? "1"
                : ""
              : "",
            other: formData.allergy_longTermUsageNoneSub
              ? formData.allergy_longTermUsageNoneSub.includes("other")
                ? "1"
                : ""
              : "",
            // otherSubAnswers: {
            //   describe:
            //     formData?.allergy_longTermUsageNoneSubOtherSubAnswer || "",
            // },
            comment: "",
          },
          comment: formData?.allergy_longTermUsageNoneComment || "",
        },

        medicationCompliance: {
          medicationComplianceSubAnswers: {
            financialAbilityForMedicine: {
              hasEnoughMoney: formData?.allergy_enoughMoney
                ? formData?.allergy_enoughMoney?.[0]
                : "",
              comment: formData?.allergy_enoughMoneyComment
                ? formData?.allergy_enoughMoneyComment
                : "",
            },
            takeYourMedicine: {
              takeYourMedicine: formData?.allergy_medicSubTakeMedic
                ? formData?.allergy_medicSubTakeMedic.includes("1")
                  ? "1"
                  : formData?.allergy_medicSubTakeMedic.includes("2")
                  ? "2"
                  : ""
                : "",
              comment: formData?.allergy_medicSubTakeMedicComment
                ? formData?.allergy_medicSubTakeMedicComment
                : "",
            },
            attentionToYourMedication: {
              attentionToYourMedication: formData?.allergy_medicSubAttenMedic
                ? formData?.allergy_medicSubAttenMedic.includes("1")
                  ? "1"
                  : formData?.allergy_medicSubAttenMedic.includes("2")
                  ? "2"
                  : ""
                : "",
              comment: formData?.allergy_medicSubAttenMedicComment
                ? formData?.allergy_medicSubAttenMedicComment
                : "",
            },
            longtermBenefit: {
              longtermBenefit: formData?.allergy_medicSubLongTerm
                ? formData?.allergy_medicSubLongTerm.includes("1")
                  ? "1"
                  : formData?.allergy_medicSubLongTerm.includes("2")
                  ? "2"
                  : ""
                : "",
              comment: formData?.allergy_medicSubLongTermComment
                ? formData?.allergy_medicSubLongTermComment
                : "",
            },
            stopTakingMedicine: {
              stopTakingMedicine: formData?.allergy_medicSubStopMedic
                ? formData?.allergy_medicSubStopMedic.includes("1")
                  ? "1"
                  : formData?.allergy_medicSubStopMedic.includes("2")
                  ? "2"
                  : ""
                : "",
              comment: formData?.allergy_medicSubStopMedicComment
                ? formData?.allergy_medicSubStopMedicComment
                : "",
            },
            feelWorse: {
              feelWorse: formData?.allergy_medicSubFeelWorse
                ? formData?.allergy_medicSubFeelWorse.includes("1")
                  ? "1"
                  : formData?.allergy_medicSubFeelWorse.includes("2")
                  ? "2"
                  : ""
                : "",
              comment: formData?.allergy_medicSubFeelWorseComment
                ? formData?.allergy_medicSubFeelWorseComment
                : "",
            },
            forgetToRefill: {
              forgetToRefill: formData?.allergy_medicSubForgotRefill
                ? formData?.allergy_medicSubForgotRefill.includes("1")
                  ? "1"
                  : formData?.allergy_medicSubForgotRefill.includes("2")
                  ? "2"
                  : ""
                : "",
              comment: formData?.allergy_medicSubForgotRefillComment
                ? formData?.allergy_medicSubForgotRefillComment
                : "",
            },
          },
        },

        childLimited: {
          isChildLimited: formData?.childAbility
            ? formData?.childAbility?.[0]
            : "",
          isChildLimitedSubAnswers: {
            isDueToMedicalCondition: formData?.condition_that_has_lasted
              ? formData?.condition_that_has_lasted?.[0]
              : "",
            conditionExpectedToLast12Months: {
              isConditionExpectedToLast12Months:
                formData?.condition_that_has_lasted
                  ? formData?.condition_that_has_lasted?.[0]
                  : "",
              isConditionExpectedToLast12MonthsSubAnswer: {
                // explain: formData?.Explain ? formData?.Explain : '',
              },
            },
          },
        },

        // recommendations: {
        //   recommendationSubAnswers: formData?.allergies_Recommendations2
        //     ? formData?.allergies_Recommendations2.reduce((acc, item) => {
        //         if (recommendationMap[item]) acc.push(recommendationMap[item]);
        //         return acc;
        //       }, [])
        //     : [],
        // },
        recommendations: {
          recommendationSubAnswers: [
            {
              medicationAdherence: formData?.allergies_Recommendations2
                ? formData?.allergies_Recommendations2?.includes(
                    "medicAdherenceAllergy",
                  )
                  ? "1"
                  : ""
                : "",
              comment:
                formData?.allergies_Recommendations2medicAdherenceAllergyComment
                  ? formData?.allergies_Recommendations2medicAdherenceAllergyComment
                  : "",
            },
            {
              sideEffects: formData?.allergies_Recommendations2
                ? formData?.allergies_Recommendations2?.includes(
                    "sideEffectsAllergy",
                  )
                  ? "1"
                  : ""
                : "",
              comment:
                formData?.allergies_Recommendations2sideEffectsAllergyComment
                  ? formData?.allergies_Recommendations2sideEffectsAllergyComment
                  : "",
            },
            {
              caseManagementReferral: formData?.allergies_Recommendations2
                ? formData?.allergies_Recommendations2?.includes(
                    "caseManagRefFollowupAllergy",
                  )
                  ? "1"
                  : ""
                : "",
              comment:
                formData?.allergies_Recommendations2caseManagRefFollowupAllergyComment
                  ? formData?.allergies_Recommendations2caseManagRefFollowupAllergyComment
                  : "",
            },
            {
              followUpWithClinician: formData?.allergies_Recommendations2
                ? formData?.allergies_Recommendations2?.includes(
                    "followupWithClinicianSpecialistsAllergy",
                  )
                  ? "1"
                  : ""
                : "",
              comment:
                formData?.allergies_Recommendations2followupWithClinicianSpecialistsAllergyComment
                  ? formData?.allergies_Recommendations2followupWithClinicianSpecialistsAllergyComment
                  : "",
            },
            {
              memberToUseDME: formData?.allergies_Recommendations2
                ? formData?.allergies_Recommendations2?.includes(
                    "memberDurableMedicalEquipmentAllergy",
                  )
                  ? "1"
                  : ""
                : "",
              comment:
                formData?.allergies_Recommendations2memberDurableMedicalEquipmentAllergyComment
                  ? formData?.allergies_Recommendations2memberDurableMedicalEquipmentAllergyComment
                  : "",
            },
            {
              other: formData?.allergies_Recommendations2
                ? formData?.allergies_Recommendations2?.includes(
                    "otherAllergy2",
                  )
                  ? "1"
                  : ""
                : "",
              comment: formData?.allergies_Recommendations2otherAllergy2Comment
                ? formData?.allergies_Recommendations2otherAllergy2Comment
                : "",
            },
          ],
        },

        educatedOnImportance: {
          educatedOnImportance:
            formData?.educatedOnImportance?.length > 0 ? "1" : "",
          comment: formData?.educatedOnImportanceComment
            ? formData?.educatedOnImportanceComment
            : "",
        },
      };
    }

    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,officeName
        data: {
          name: "Allergies and Medications",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };

  const onSavePedsVitalData = async () => {
    const formData = autoSavePedsVital;

    const payload = {
      reported: formData?.vital_reported ? formData?.vital_reported?.[0] : "",
      vitalSigns: {
        bloodPressure: {
          systolicbp: formData?.vital_systolicbp
            ? formData?.vital_systolicbp
            : "",
          diastolicbp: formData?.vital_diastolicbp
            ? formData?.vital_diastolicbp
            : "",
        },
        pulse: formData?.vital_pulse ? formData?.vital_pulse : "",
        respiratoryRate: formData?.vital_respiratoryRate
          ? formData?.vital_respiratoryRate
          : "",
        temp: formData?.vital_temp ? formData?.vital_temp : "",
        pulseOximetry: formData?.vital_pulseOximetry
          ? formData?.vital_pulseOximetry
          : "",
        painScale: formData?.vital_painScale ? formData?.vital_painScale : "",
        comment: formData?.vital_vitalSignsComment
          ? formData?.vital_vitalSignsComment
          : "",
      },

      bmi: {
        patientsHeight: {
          feet: formData?.vital_feet ? formData?.vital_feet : "",
          inch: formData?.vital_inch ? formData?.vital_inch : "",
        },
        patientsWeight: formData?.vital_patientsWeight
          ? formData?.vital_patientsWeight
          : "",
        bmi: formData?.vital_bmii ? formData?.vital_bmii : "",
        obesity: "2",
        moderateObesity: "2",
        normalWeight: "",
        overWeight: "",
        underWeight: "",
        morbidObesity: "2",
        malnutrition: "1",
        malnutritionSubAnswer: {
          describe: {
            active: formData?.vital_bmiMalnutritionSubDescribe
              ? formData?.vital_bmiMalnutritionSubDescribe?.includes("active")
                ? "1"
                : ""
              : "",
            historyOf: formData?.vital_bmiMalnutritionSubDescribe
              ? formData?.vital_bmiMalnutritionSubDescribe?.includes(
                  "historyOf",
                )
                ? "1"
                : ""
              : "",
            ruleOut: formData?.vital_bmiMalnutritionSubDescribe
              ? formData?.vital_bmiMalnutritionSubDescribe?.includes("ruleOut")
                ? "1"
                : ""
              : "",
            comment: formData?.vital_bmiMalnutritionSubDescribeComment
              ? formData?.vital_bmiMalnutritionSubDescribeComment
              : "",
          },
          malnutrition: {
            isMalnutrition: formData?.vital_bmiMalnutritionSubMalnutrition
              ? formData?.vital_bmiMalnutritionSubMalnutrition
              : "",
            comment: formData?.vital_bmiMalnutritionSubMalnutritionComment
              ? formData?.vital_bmiMalnutritionSubMalnutritionComment
              : "",
          },
          supportedBy: {
            albumin: formData?.vital_bmiMalnutritionSubSupportedBy
              ? formData?.vital_bmiMalnutritionSubSupportedBy?.includes(
                  "albumin",
                )
                ? "1"
                : ""
              : "",
            muscleWaiting: formData?.vital_bmiMalnutritionSubSupportedBy
              ? formData?.vital_bmiMalnutritionSubSupportedBy?.includes(
                  "muscleWaiting",
                )
                ? "1"
                : ""
              : "",
            historyOfSevereWtLoss: formData?.vital_bmiMalnutritionSubSupportedBy
              ? formData?.vital_bmiMalnutritionSubSupportedBy?.includes(
                  "historyOfSevereWtLoss",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.vital_bmiMalnutritionSubSupportedByComment
              ? formData?.vital_bmiMalnutritionSubSupportedByComment
              : "",
          },
        },
        comment: "",
      },

      foodAllergies: {
        isFoodAllergies: formData?.self_recreaDrugs
          ? formData?.self_recreaDrugs?.[0]
          : "",
        comment: formData?.self_recreaDrugsSubWhich
          ? formData?.self_recreaDrugsSubWhich
          : "",
      },
      specialDiet: {
        heartHealthyDiet: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("heartHealthyDiet")
            ? "1"
            : ""
          : "",
        diabeticDiet: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("diabeticDiet")
            ? "1"
            : ""
          : "",
        renalDiet: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("renalDiet")
            ? "1"
            : ""
          : "",
        vegetarian: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("vegetarian")
            ? "1"
            : ""
          : "",
        vegan: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("vegan")
            ? "1"
            : ""
          : "",
        glutenFree: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("glutenFree")
            ? "1"
            : ""
          : "",
        keto: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("keto")
            ? "1"
            : ""
          : "",
        pescatarian: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("pescatarian")
            ? "1"
            : ""
          : "",
        tubeFeeding: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("tubeFeeding")
            ? "1"
            : ""
          : "",
        regularDiet: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("regularDiet")
            ? "1"
            : ""
          : "",
        none: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("none")
            ? "1"
            : ""
          : "",
        other: formData?.vital_specialDiet
          ? formData?.vital_specialDiet?.includes("other")
            ? "1"
            : ""
          : "",
        otherSubAnswer: {
          describe: formData?.vital_specialDietSubOtherSubDesc
            ? formData?.vital_specialDietSubOtherSubDesc
            : "",
        },
        comment: formData?.vital_specialDietComment
          ? formData?.vital_specialDietComment
          : "",
      },
      lostWeight: {
        none: formData?.vital_lostWeight
          ? formData?.vital_lostWeight?.includes("none")
            ? "1"
            : ""
          : "",
        fiveLbs: formData?.vital_lostWeight
          ? formData?.vital_lostWeight?.includes("fiveLbs")
            ? "1"
            : ""
          : "",
        tenLbs: formData?.vital_lostWeight
          ? formData?.vital_lostWeight?.includes("tenLbs")
            ? "1"
            : ""
          : "",
        fifteenLbs: formData?.vital_lostWeight
          ? formData?.vital_lostWeight?.includes("fifteenLbs")
            ? "1"
            : ""
          : "",
        moreThanFifteenLbs: formData?.vital_lostWeight
          ? formData?.vital_lostWeight?.includes("moreThanFifteenLbs")
            ? "1"
            : ""
          : "",
        tenPercentYourWeight: formData?.vital_lostWeight
          ? formData?.vital_lostWeight?.includes("tenPercentYourWeight")
            ? "1"
            : ""
          : "",
        comment: formData?.vital_lostWeightComment
          ? formData?.vital_lostWeightComment
          : "",
      },
      physicalActivityLevel: formData?.vital_physicalActivityLevel
        ? formData?.vital_physicalActivityLevel
        : "",
      physicalActivityLevelComment: formData?.vital_physicalActivityLevelComment
        ? formData?.vital_physicalActivityLevelComment
        : "",
      nutritionWeightManagementInterest:
        formData?.vital_nutritionWeightManagementInterest
          ? formData?.vital_nutritionWeightManagementInterest
          : "",
      nutritionWeightManagementInterestComment:
        formData?.vital_nutritionWeightManagementInterestComment
          ? formData?.vital_nutritionWeightManagementInterestComment
          : "",
      barriersToChange: {
        educationNeeded: formData?.vital_preventingChanges
          ? formData?.vital_preventingChanges?.includes("educationNeeded")
            ? "1"
            : ""
          : "",
        socialSupport: formData?.vital_preventingChanges
          ? formData?.vital_preventingChanges?.includes("socialSupport")
            ? "1"
            : ""
          : "",
        environmentalFactors: formData?.vital_preventingChanges
          ? formData?.vital_preventingChanges.includes("environmentalFactors")
            ? "1"
            : ""
          : "",
        stress: formData?.vital_preventingChanges
          ? formData?.vital_preventingChanges.includes("stress")
            ? "1"
            : ""
          : "",
        selfConfidence: formData?.vital_preventingChanges
          ? formData?.vital_preventingChanges.includes("selfConfidence")
            ? "1"
            : ""
          : "",
        none: formData?.vital_preventingChanges
          ? formData?.vital_preventingChanges.includes("none")
            ? "1"
            : ""
          : "",
        lackOfMotivation: formData?.vital_preventingChanges
          ? formData?.vital_preventingChanges.includes("lackOfMotivation")
            ? "1"
            : ""
          : "",
        notPriority: formData?.vital_preventingChanges
          ? formData?.vital_preventingChanges.includes("notPriority")
            ? "1"
            : ""
          : "",
        other: formData?.vital_preventingChanges
          ? formData?.vital_preventingChanges.includes("other")
            ? "1"
            : ""
          : "",
        otherSubAnswer: {
          describe: formData?.vital_preventingChangesSubOtherSubDesc
            ? formData?.vital_preventingChangesSubOtherSubDesc
            : "",
        },
        comment: formData?.vital_preventingChangesComment
          ? formData?.vital_preventingChangesComment
          : "",
      },
      recommendations: {
        recommendationsSubAnswers: [
          {
            nutritionManagement: formData?.vital_Recommendations
              ? formData?.vital_Recommendations.includes("nutritionManagement")
                ? "1"
                : ""
              : "",
            comment: formData?.vital_RecommendationsNutriComment
              ? formData?.vital_RecommendationsNutriComment
              : "",
          },
          {
            caseManagementReferral: formData?.vital_Recommendations
              ? formData?.vital_Recommendations.includes(
                  "caseManagementReferral",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.vital_RecommendationsCaseManComment
              ? formData?.vital_RecommendationsCaseManComment
              : "",
          },
          {
            followUpWithClinicianSpecialist: formData?.vital_Recommendations
              ? formData?.vital_Recommendations.includes(
                  "followUpWithClinicianSpecialist",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.vital_RecommendationsFollowUpComment
              ? formData?.vital_RecommendationsFollowUpComment
              : "",
          },
          {
            memberToUseDME: formData?.vital_Recommendations
              ? formData?.vital_Recommendations.includes("memberToUseDME")
                ? "1"
                : ""
              : "",
            comment: formData?.vital_RecommendationsMemberToComment
              ? formData?.vital_RecommendationsMemberToComment
              : "",
          },
          {
            other: formData?.vital_Recommendations
              ? formData?.vital_Recommendations.includes("other")
                ? "1"
                : ""
              : "",
            comment: formData?.vital_RecommendationsOtherComment
              ? formData?.vital_RecommendationsOtherComment
              : "",
          },
        ],
      },
      additionalInformationAboutChild: formData?.additionalInformation
        ? formData?.additionalInformation
        : "",
    };
    let payload2024 = {};
    if (storedYear == "2024") {
      payload2024 = {
        reported: formData?.vital_reported ? formData?.vital_reported?.[0] : "",
        vitalSigns: {
          bloodPressure: {
            systolicbp: formData?.vital_systolicbp
              ? formData?.vital_systolicbp
              : "",
            diastolicbp: formData?.vital_diastolicbp
              ? formData?.vital_diastolicbp
              : "",
          },
          pulse: formData?.vital_pulse ? formData?.vital_pulse : "",
          respiratoryRate: formData?.vital_respiratoryRate
            ? formData?.vital_respiratoryRate
            : "",
          temp: formData?.vital_temp ? formData?.vital_temp : "",
          pulseOximetry: formData?.vital_pulseOximetry
            ? formData?.vital_pulseOximetry
            : "",
          painScale: formData?.vital_painScale ? formData?.vital_painScale : "",
          comment: formData?.vital_vitalSignsComment
            ? formData?.vital_vitalSignsComment
            : "",
        },

        bmi: {
          patientsHeight: {
            feet: formData?.vital_feet ? formData?.vital_feet : "",
            inch: formData?.vital_inch ? formData?.vital_inch : "",
          },
          patientsWeight: formData?.vital_patientsWeight
            ? formData?.vital_patientsWeight
            : "",
          bmi: formData?.vital_bmii ? formData?.vital_bmii : "",
          obesity: formData?.vital_bmi
            ? formData?.vital_bmi.includes("obesity")
              ? "1"
              : ""
            : "",
          moderateObesity: formData?.vital_bmi
            ? formData?.vital_bmi.includes("moderateObesity")
              ? "1"
              : ""
            : "",
          normalWeight: formData?.vital_bmi
            ? formData?.vital_bmi.includes("normalWeight")
              ? "1"
              : ""
            : "",
          overWeight: formData?.vital_bmi
            ? formData?.vital_bmi.includes("overWeight")
              ? "1"
              : ""
            : "",
          underWeight: formData?.vital_bmi
            ? formData?.vital_bmi.includes("underWeight")
              ? "1"
              : ""
            : "",
          morbidObesity: formData?.vital_bmi
            ? formData?.vital_bmi.includes("morbidObesity")
              ? "1"
              : ""
            : "",
          malnutrition: formData?.vital_bmi
            ? formData?.vital_bmi.includes("malnutrition")
              ? "1"
              : ""
            : "",
          malnutritionSubAnswer: {
            describe: {
              active: formData?.vital_bmiMalnutritionSubDescribe
                ? formData?.vital_bmiMalnutritionSubDescribe.includes("active")
                  ? "1"
                  : ""
                : "",
              historyOf: formData?.vital_bmiMalnutritionSubDescribe
                ? formData?.vital_bmiMalnutritionSubDescribe.includes(
                    "historyOf",
                  )
                  ? "1"
                  : ""
                : "",
              ruleOut: formData?.vital_bmiMalnutritionSubDescribe
                ? formData?.vital_bmiMalnutritionSubDescribe.includes("ruleOut")
                  ? "1"
                  : ""
                : "",
              comment: formData?.vital_bmiMalnutritionSubDescribeComment
                ? formData?.vital_bmiMalnutritionSubDescribeComment
                : "",
            },
            malnutrition: {
              isMalnutrition:
                formData?.vital_bmiMalnutritionSubMalnutrition[0] ||
                formData?.vital_bmiMalnutritionSubMalnutrition,
              comment: formData?.vital_bmiMalnutritionSubMalnutritionComment
                ? formData?.vital_bmiMalnutritionSubMalnutritionComment
                : "",
            },
            supportedBy: {
              albumin: formData?.vital_bmiMalnutritionSubSupportedBy
                ? formData?.vital_bmiMalnutritionSubSupportedBy.includes(
                    "albumin",
                  )
                  ? "1"
                  : ""
                : "",
              muscleWaiting: formData?.vital_bmiMalnutritionSubSupportedBy
                ? formData?.vital_bmiMalnutritionSubSupportedBy.includes(
                    "muscleWaiting",
                  )
                  ? "1"
                  : ""
                : "",
              historyOfSevereWtLoss:
                formData?.vital_bmiMalnutritionSubSupportedBy
                  ? formData?.vital_bmiMalnutritionSubSupportedBy.includes(
                      "historyOfSevereWtLoss",
                    )
                    ? "1"
                    : ""
                  : "",
              comment: formData?.vital_bmiMalnutritionSubSupportedByComment
                ? formData?.vital_bmiMalnutritionSubSupportedByComment
                : "",
            },
          },
          comment: formData?.vital_bmiLabelComment
            ? formData?.vital_bmiLabelComment
            : "",
        },

        foodAllergies: {
          isFoodAllergies: formData?.self_recreaDrugs
            ? formData?.self_recreaDrugs?.[0] || formData?.self_recreaDrugs
            : "",
          comment: formData?.self_recreaDrugsSubWhich
            ? formData?.self_recreaDrugsSubWhich
            : "",
        },
        specialDiet: {
          heartHealthyDiet: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("heartHealthyDiet")
              ? "1"
              : ""
            : "",
          diabeticDiet: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("diabeticDiet")
              ? "1"
              : ""
            : "",
          renalDiet: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("renalDiet")
              ? "1"
              : ""
            : "",
          vegetarian: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("vegetarian")
              ? "1"
              : ""
            : "",
          vegan: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("vegan")
              ? "1"
              : ""
            : "",
          glutenFree: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("glutenFree")
              ? "1"
              : ""
            : "",
          keto: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("keto")
              ? "1"
              : ""
            : "",
          pescatarian: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("pescatarian")
              ? "1"
              : ""
            : "",
          tubeFeeding: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("tubeFeeding")
              ? "1"
              : ""
            : "",
          regularDiet: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("regularDiet")
              ? "1"
              : ""
            : "",
          none: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("none")
              ? "1"
              : ""
            : "",
          other: formData?.vital_specialDiet
            ? formData?.vital_specialDiet.includes("other")
              ? "1"
              : ""
            : "",
          otherSubAnswer: {
            describe: formData?.vital_specialDietSubOtherSubDesc
              ? formData?.vital_specialDietSubOtherSubDesc
              : "",
          },
          comment: formData?.vital_specialDietComment
            ? formData?.vital_specialDietComment
            : "",
        },
        lostWeight: {
          none: formData?.vital_lostWeight
            ? formData?.vital_lostWeight.includes("none")
              ? "1"
              : ""
            : "",
          fiveLbs: formData?.vital_lostWeight
            ? formData?.vital_lostWeight.includes("fiveLbs")
              ? "1"
              : ""
            : "",
          tenLbs: formData?.vital_lostWeight
            ? formData?.vital_lostWeight.includes("tenLbs")
              ? "1"
              : ""
            : "",
          fifteenLbs: formData?.vital_lostWeight
            ? formData?.vital_lostWeight.includes("fifteenLbs")
              ? "1"
              : ""
            : "",
          moreThanFifteenLbs: formData?.vital_lostWeight
            ? formData?.vital_lostWeight.includes("moreThanFifteenLbs")
              ? "1"
              : ""
            : "",
          tenPercentYourWeight: formData?.vital_lostWeight
            ? formData?.vital_lostWeight.includes("tenPercentYourWeight")
              ? "1"
              : ""
            : "",
          intentional: formData?.vital_lostWeight
            ? formData?.vital_lostWeight.includes("intentional")
              ? "1"
              : ""
            : "",
          unintentional: formData?.vital_lostWeight
            ? formData?.vital_lostWeight.includes("unintentional")
              ? "1"
              : ""
            : "",
          comment: formData?.vital_lostWeightComment
            ? formData?.vital_lostWeightComment
            : "",
        },
        physicalActivityLevel: formData?.vital_physicalActivityLevel
          ? formData?.vital_physicalActivityLevel
          : "",
        physicalActivityLevelComment:
          formData?.vital_physicalActivityLevelComment
            ? formData?.vital_physicalActivityLevelComment
            : "",
        nutritionWeightManagementInterest:
          formData?.vital_nutritionWeightManagementInterest
            ? formData?.vital_nutritionWeightManagementInterest
            : "",
        nutritionWeightManagementInterestComment:
          formData?.vital_nutritionWeightManagementInterestComment
            ? formData?.vital_nutritionWeightManagementInterestComment
            : "",
        barriersToChange: {
          educationNeeded: formData?.vital_preventingChanges
            ? formData?.vital_preventingChanges.includes("educationNeeded")
              ? "1"
              : ""
            : "",
          socialSupport: formData?.vital_preventingChanges
            ? formData?.vital_preventingChanges.includes("socialSupport")
              ? "1"
              : ""
            : "",
          environmentalFactors: formData?.vital_preventingChanges
            ? formData?.vital_preventingChanges.includes("environmentalFactors")
              ? "1"
              : ""
            : "",
          stress: formData?.vital_preventingChanges
            ? formData?.vital_preventingChanges.includes("stress")
              ? "1"
              : ""
            : "",
          selfConfidence: formData?.vital_preventingChanges
            ? formData?.vital_preventingChanges.includes("selfConfidence")
              ? "1"
              : ""
            : "",
          none: formData?.vital_preventingChanges
            ? formData?.vital_preventingChanges.includes("none")
              ? "1"
              : ""
            : "",
          lackOfMotivation: formData?.vital_preventingChanges
            ? formData?.vital_preventingChanges.includes("lackOfMotivation")
              ? "1"
              : ""
            : "",
          notPriority: formData?.vital_preventingChanges
            ? formData?.vital_preventingChanges.includes("notPriority")
              ? "1"
              : ""
            : "",
          other: formData?.vital_preventingChanges
            ? formData?.vital_preventingChanges.includes("other")
              ? "1"
              : ""
            : "",
          otherSubAnswer: {
            describe: formData?.vital_preventingChangesSubOtherSubDesc
              ? formData?.vital_preventingChangesSubOtherSubDesc
              : "",
          },
          comment: formData?.vital_preventingChangesComment
            ? formData?.vital_preventingChangesComment
            : "",
        },
        recommendations: {
          recommendationsSubAnswers: [
            {
              nutritionManagement: formData?.vital_Recommendations
                ? formData?.vital_Recommendations.includes(
                    "nutritionManagement",
                  )
                  ? "1"
                  : ""
                : "",
              comment: formData?.vital_RecommendationsNutriComment
                ? formData?.vital_RecommendationsNutriComment
                : "",
            },
            {
              caseManagementReferral: formData?.vital_Recommendations
                ? formData?.vital_Recommendations.includes(
                    "caseManagementReferral",
                  )
                  ? "1"
                  : ""
                : "",
              comment: formData?.vital_RecommendationsCaseManComment
                ? formData?.vital_RecommendationsCaseManComment
                : "",
            },
            {
              followUpWithClinicianSpecialist: formData?.vital_Recommendations
                ? formData?.vital_Recommendations.includes(
                    "followUpWithClinicianSpecialist",
                  )
                  ? "1"
                  : ""
                : "",
              comment: formData?.vital_RecommendationsFollowUpComment
                ? formData?.vital_RecommendationsFollowUpComment
                : "",
            },
            {
              memberToUseDME: formData?.vital_Recommendations
                ? formData?.vital_Recommendations.includes("memberToUseDME")
                  ? "1"
                  : ""
                : "",
              comment: formData?.vital_RecommendationsMemberToComment
                ? formData?.vital_RecommendationsMemberToComment
                : "",
            },
            {
              other: formData?.vital_Recommendations
                ? formData?.vital_Recommendations.includes("other")
                  ? "1"
                  : ""
                : "",
              comment: formData?.vital_RecommendationsOtherComment
                ? formData?.vital_RecommendationsOtherComment
                : "",
            },
          ],
        },
        additionalInformationAboutChild: formData?.additionalInformation
          ? formData?.additionalInformation
          : "",
      };
    }

    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,officeName
        data: {
          name: "Vital Signs",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };

  const onSaveValidationOfConsentData = async () => {
    const formData = autoSavePedsVoc;

    const payload = {
      permissionToDiscussPHI: {
        isPermissionToDiscussPHI: formData?.voc_legalGuardianVal
          ? formData?.voc_legalGuardianVal
          : "",
        comment: formData?.voc_legalGuardianCommentVal
          ? formData?.voc_legalGuardianCommentVal
          : "",
      },
      verbalPermissionToShareResults: {
        isVerbalPermissionToShareResults: formData?.voc_shareAssessmentVal
          ? formData?.voc_shareAssessmentVal
          : "",
        comment: formData?.voc_shareAssessmentCommentVal
          ? formData?.voc_shareAssessmentCommentVal
          : "",
      },
      childEnrolledInFosterCare: {
        isChildEnrolledInFosterCare: formData?.voc_fosterCareProgramVal
          ? formData?.voc_fosterCareProgramVal
          : "",
        comment: formData?.voc_fosterCareProgramCommentVal
          ? formData?.voc_fosterCareProgramCommentVal
          : "",
      },

      fullNameOfGuardian: formData?.guardianFullName
        ? formData?.guardianFullName
        : "",
    };
    let payload2024 = {};
    if (storedYear == "2024") {
      payload2024 = {
        permissionToDiscussPHI: {
          isPermissionToDiscussPHI: formData?.voc_legalGuardianVal
            ? formData?.voc_legalGuardianVal
            : "",
          comment: formData?.voc_legalGuardianValComment
            ? formData?.voc_legalGuardianValComment
            : "",
        },
        verbalPermissionToShareResults: {
          isVerbalPermissionToShareResults: formData?.voc_shareAssessmentVal
            ? formData?.voc_shareAssessmentVal
            : "",
          comment: formData?.voc_shareAssessmentValComment
            ? formData?.voc_shareAssessmentValComment
            : "",
        },
        childEnrolledInFosterCare: {
          isChildEnrolledInFosterCare: formData?.voc_fosterCareProgramVal
            ? formData?.voc_fosterCareProgramVal
            : "",
          comment: formData?.voc_fosterCareProgramValComment
            ? formData?.voc_fosterCareProgramValComment
            : "",
        },

        fullNameOfGuardian: formData?.guardianFullName
          ? formData?.guardianFullName
          : "",
      };
    }

    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,officeName
        data: {
          name: "Consent",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };

  const onSavePedsSocialSaftyData = async () => {
    const formData = autoSavePedsSocialSafty;

    const payload = {
      tobaccoUse: {
        current: formData?.self_tobUse
          ? formData?.self_tobUse.includes("current")
            ? "1"
            : ""
          : "",
        currentSubAnswer: {
          type: {
            cigarettes: formData?.self_tobUseSubCurrSubType
              ? formData?.self_tobUseSubCurrSubType.includes("cigarettes")
                ? "1"
                : ""
              : "",
            cigarettesSubAnswer: {
              oneToThree: formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                    "oneToThree",
                  )
                  ? "1"
                  : ""
                : "",
              halfAPack: formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                    "halfAPack",
                  )
                  ? "1"
                  : ""
                : "",
              onePack: formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                    "onePack",
                  )
                  ? "1"
                  : ""
                : "",
              moreThanOne:
                formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                  ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                      "moreThanOne",
                    )
                    ? "1"
                    : ""
                  : "",
              other: formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                    "other",
                  )
                  ? "1"
                  : ""
                : "",
              otherSubAnswer: {
                describe:
                  formData?.self_tobUseSubCurrSubTypeCigaretteSubHowManyOtherSubDesc
                    ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowManyOtherSubDesc
                    : "",
              },
              interestedInSmoking: {
                interestedInSmoking: formData?.self_tobUseSubCurrSubSmokeCess
                  ? formData?.self_tobUseSubCurrSubSmokeCess.includes(
                      "smokingCessation",
                    )
                    ? "1"
                    : ""
                  : "",
                comment: formData?.self_tobUseSubCurrSubSmokeCessComment
                  ? formData?.self_tobUseSubCurrSubSmokeCessComment
                  : "",
              },
              thingsPreventing: {
                educationNeeded: "",
                socialSupport: "",
                environmentalFactors: "",
                stress: "",
                selfConfidence: "",
                lackOfMotivation: "",
                notAPriority: "",
                other: "",
                none: "",
                comment: "",
              },
              comment: "",
            },
            cigars: formData?.self_tobUseSubCurrSubType
              ? formData?.self_tobUseSubCurrSubType.includes("cigars")
                ? "1"
                : ""
              : "",
            chewingTobacco: formData?.self_tobUseSubCurrSubType
              ? formData?.self_tobUseSubCurrSubType.includes("chewingTobacco")
                ? "1"
                : ""
              : "",
            vaping: formData?.self_tobUseSubCurrSubType
              ? formData?.self_tobUseSubCurrSubType.includes("vaping")
                ? "1"
                : ""
              : "",
            other: formData?.self_tobUseSubCurrSubType
              ? formData?.self_tobUseSubCurrSubType.includes("other")
                ? "1"
                : ""
              : "",
            otherSubAnswer: {
              describe: formData?.self_tobUseSubCurrSubTypeOtherSubDesc
                ? formData?.self_tobUseSubCurrSubTypeOtherSubDesc
                : "",
            },
            comment: formData?.self_tobUseSubCurrSubTypeComment
              ? formData?.self_tobUseSubCurrSubTypeComment
              : "",
          },
          smokingCessation: {
            smokingCessation: formData?.self_tobUseSubCurrSubSmokeCess
              ? formData?.self_tobUseSubCurrSubSmokeCess.includes(
                  "smokingCessation",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.self_tobUseSubCurrSubSmokeCessComment
              ? formData?.self_tobUseSubCurrSubSmokeCessComment
              : "",
          },
        },
        former: "",
        formerSubAnswer: {
          when: {
            withinLastYear: formData?.self_tobUseSubFormerSubWhen
              ? formData?.self_tobUseSubFormerSubWhen.includes("withinLastYear")
                ? "1"
                : ""
              : "",
            withinLastThreeYear: formData?.self_tobUseSubFormerSubWhen
              ? formData?.self_tobUseSubFormerSubWhen.includes(
                  "withinLastThreeYear",
                )
                ? "1"
                : ""
              : "",
            moreYearAgo: formData?.self_tobUseSubFormerSubWhen
              ? formData?.self_tobUseSubFormerSubWhen.includes("moreYearAgo")
                ? "1"
                : ""
              : "",
            comment: formData?.self_tobUseSubFormerSubWhenComment
              ? formData?.self_tobUseSubFormerSubWhenComment
              : "",
          },
          type: {
            cigarettes: formData?.self_tobUseSubCurrSubType
              ? formData?.self_tobUseSubCurrSubType.includes("cigarettes")
                ? "1"
                : ""
              : "",
            cigarettesSubAnswer: {
              oneToThree: formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                    "oneToThree",
                  )
                  ? "1"
                  : ""
                : "",
              halfAPack: formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                    "halfAPack",
                  )
                  ? "1"
                  : ""
                : "",
              onePack: formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                    "onePack",
                  )
                  ? "1"
                  : ""
                : "",
              moreThanOne:
                formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                  ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                      "moreThanOne",
                    )
                    ? "1"
                    : ""
                  : "",
              other: formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                    "other",
                  )
                  ? "1"
                  : ""
                : "",
              otherSubAnswer: {
                describe:
                  formData?.self_tobUseSubCurrSubTypeCigaretteSubHowManyOtherSubDesc
                    ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowManyOtherSubDesc
                    : "",
              },
              comment: "",
            },
            cigars: formData?.self_tobUseSubCurrSubType
              ? formData?.self_tobUseSubCurrSubType.includes("cigars")
                ? "1"
                : ""
              : "",
            chewingTobacco: formData?.self_tobUseSubCurrSubType
              ? formData?.self_tobUseSubCurrSubType.includes("chewingTobacco")
                ? "1"
                : ""
              : "",
            vaping: formData?.self_tobUseSubCurrSubType
              ? formData?.self_tobUseSubCurrSubType.includes("vaping")
                ? "1"
                : ""
              : "",
            other: formData?.self_tobUseSubCurrSubType
              ? formData?.self_tobUseSubCurrSubType.includes("other")
                ? "1"
                : ""
              : "",
            otherSubAnswer: {
              describe: formData?.self_tobUseSubCurrSubTypeOtherSubDesc
                ? formData?.self_tobUseSubCurrSubTypeOtherSubDesc
                : "",
            },
            comment: formData?.self_tobUseSubCurrSubTypeComment
              ? formData?.self_tobUseSubCurrSubTypeComment
              : "",
          },
        },
        never: formData?.self_tobUse
          ? formData?.self_tobUse.includes("never")
            ? "1"
            : ""
          : "",
        comment: formData?.self_tobUseComment
          ? formData?.self_tobUseComment
          : "",
      },
      alcoholUse: {
        current: formData?.self_alcUse
          ? formData?.self_alcUse.includes("current")
            ? "1"
            : ""
          : "",
        currentSubAnswer: {
          howMnyTimes: {
            isNone: "",
            oneOrMore: "",
            oneOrMoreSubAnswers: {
              howOften: {
                never: "",
                monthly: "",
                twoTimesMonth: "",
                twoTimesWeek: "",
                fourTimesWeek: "",
                comment: "",
              },
              howManyDrinks: {
                oneOrTwo: "",
                threeOrFour: "",
                fiveOrSix: "",
                sevenEight: "",
                tenOrMore: "",
                comment: "",
              },
              sixOrMore: {
                never: "",
                lessThanMonthly: "",
                monthly: "",
                weekly: "",
                daily: "",
                comment: "",
              },
              auditScore: "",
              weeklyAverage: "",
              comment: "",
            },
            comment: "",
          },
          currentlyInRecovery: {
            isCurrentlyInRecovery: "1",
            comment: "",
          },
        },
        former: "",
        formerSubAnswer: {
          howMnyTimes: {
            isNone: "",
            oneOrMore: "",
            oneOrMoreSubAnswers: {
              howOften: {
                never: "",
                monthly: "",
                twoTimesMonth: "",
                twoTimesWeek: "",
                fourTimesWeek: "",
                comment: "",
              },
              howManyDrinks: {
                oneOrTwo: "",
                threeOrFour: "",
                fiveOrSix: "",
                sevenEight: "",
                tenOrMore: "",
                comment: "",
              },
              sixOrMore: {
                never: "",
                lessThanMonthly: "",
                monthly: "",
                weekly: "",
                daily: "",
                comment: "",
              },
              auditScore: "",
              weeklyAverage: "",
              comment: "",
            },
            comment: "",
          },
          currentlyInRecovery: {
            isCurrentlyInRecovery: "1",
            comment: "",
          },
        },
        never: "",
        comment: "",
      },

      recreationalDrugs: {
        isRecreationalDrugs: formData?.self_recreaDrugs
          ? formData?.self_recreaDrugs?.[0]
          : "",
        isRecreationalDrugsSubAnswer: {
          whichDrugs: formData?.self_recreaDrugsSubWhich
            ? formData?.self_recreaDrugsSubWhich
            : "",
        },
        comment: formData?.self_recreaDrugsComment
          ? formData?.self_recreaDrugsComment
          : "",
      },

      worryAboutFoodRunOut: {
        never: formData?.enoughFoodForFamily
          ? formData?.enoughFoodForFamily.includes("never")
            ? "1"
            : ""
          : "",
        sometimes: formData?.enoughFoodForFamily
          ? formData?.enoughFoodForFamily.includes("sometimes")
            ? "1"
            : ""
          : "",
        always: formData?.enoughFoodForFamily
          ? formData?.enoughFoodForFamily.includes("always")
            ? "1"
            : ""
          : "",
        declinesToAnswer: formData?.enoughFoodForFamily
          ? formData?.enoughFoodForFamily.includes("declinesToAnswer")
            ? "1"
            : ""
          : "",
        comment: formData?.enoughFoodForFamilyComment
          ? formData?.enoughFoodForFamilyComment
          : "",
      },

      awareOfCommunityResources: {
        isAwareOfCommunityResources: formData?.communityResources
          ? formData?.communityResources?.[0]
          : "",
        comment: formData?.communityResourcesComment
          ? formData?.communityResourcesComment
          : "",
      },

      useOfCarSeat: {
        isUseOfCarSeat: formData?.useCarSeat ? formData?.useCarSeat?.[0] : "",
        comment: formData?.useCarSeatComment ? formData?.useCarSeatComment : "",
      },

      useOfSafetyEquipment: {
        isUseOfSafetyEquipment: formData?.equipmentRidingBike
          ? formData?.equipmentRidingBike?.[0]
          : "",
        comment: formData?.equipmentRidingBikeComment
          ? formData?.equipmentRidingBikeComment
          : "",
      },

      useOfSwimmingGear: {
        isUseOfSwimmingGear: formData?.swimmingGear
          ? formData?.swimmingGear?.[0]
          : "",
        comment: formData?.swimmingGearComment
          ? formData?.swimmingGearComment
          : "",
      },

      childDrive: {
        doesChildDrive: formData?.childDrive ? formData?.childDrive?.[0] : "",
        comment: formData?.childDriveComment ? formData?.childDriveComment : "",
      },

      eductaionReview: {
        isEductaionReview: formData?.education_review
          ? formData?.education_review?.[0]
          : "2", //1- selected, 2-unselected
        comment: formData?.education_reviewComment
          ? formData?.education_reviewComment
          : "",
      },

      witnessedEvent: {
        carOrOtherAccident: formData?.Whatwastheevent
          ? formData?.Whatwastheevent.includes("carAccident")
            ? "1"
            : ""
          : "",
        fire: formData?.Whatwastheevent
          ? formData?.Whatwastheevent.includes("fire")
            ? "1"
            : ""
          : "",
        storm: formData?.Whatwastheevent
          ? formData?.Whatwastheevent.includes("storm")
            ? "1"
            : ""
          : "",
        physicalIllnessOrAssault: formData?.Whatwastheevent
          ? formData?.Whatwastheevent.includes("physicalIllness")
            ? "1"
            : ""
          : "",
        sexualAssault: formData?.Whatwastheevent
          ? formData?.Whatwastheevent.includes("sexualAssualt")
            ? "1"
            : ""
          : "",
        comment: formData?.childExperiencedComment
          ? formData?.childExperiencedComment
          : "",
        describe: formData?.describe ? formData?.describe : "",
      },

      attendSchool: {
        isAttendSchool: formData?.attendSchool
          ? formData?.attendSchool?.[0]
          : "",
        isAttendSchoolSubAnswer: {
          whichGrade: formData?.grade ? formData?.grade : "",
        },
        comment: formData?.attendSchoolComment
          ? formData?.attendSchoolComment
          : "",
      },

      hadTroubleWithPeersAtSchool: {
        isHadTroubleWithPeersAtSchool: formData?.troubleWithPeersAtSchool
          ? formData?.troubleWithPeersAtSchool?.[0]
          : "",
        comment: formData?.troublePeersSchoolComments
          ? formData?.troublePeersSchoolComments
          : "",
      },

      childLimited: {
        isChildLimited: formData?.abilityToDoThingsMostChildrenSameAgeCanDo
          ? formData?.abilityToDoThingsMostChildrenSameAgeCanDo?.[0]
          : "",
        comment: formData?.abilityToDoThingsMostChildrenSameAgeCanDoComments
          ? formData?.abilityToDoThingsMostChildrenSameAgeCanDoComments
          : "",
      },

      servicesBeingUsed: {
        physicalTherapy: formData?.serviceBeingUsed
          ? formData?.serviceBeingUsed.includes("physicalTherapy")
            ? "1"
            : ""
          : "",
        occupationalTherapy: formData?.serviceBeingUsed
          ? formData?.serviceBeingUsed.includes("occupationalTherapy")
            ? "1"
            : ""
          : "",
        speechTherapy: formData?.serviceBeingUsed
          ? formData?.serviceBeingUsed.includes("speechTherapy")
            ? "1"
            : ""
          : "",
        behaviouralTherapy: formData?.serviceBeingUsed
          ? formData?.serviceBeingUsed.includes("Behavioral Therapy")
            ? "1"
            : ""
          : "",
        careCoordination: formData?.serviceBeingUsed
          ? formData?.serviceBeingUsed.includes("careCoordination")
            ? "1"
            : ""
          : "",
        psychological: formData?.serviceBeingUsed
          ? formData?.serviceBeingUsed.includes("Psychological")
            ? "1"
            : ""
          : "",
        other: formData?.serviceBeingUsed
          ? formData?.serviceBeingUsed.includes("other")
            ? "1"
            : ""
          : "",
        none: formData?.serviceBeingUsed
          ? formData?.serviceBeingUsed.includes("none")
            ? "1"
            : ""
          : "",
        comment: "",
      },
      recommendations: {
        recommendationsSubAnswer: [
          {
            safetyEquipmentNeeded: formData?.socialSaftyRecommendations
              ? formData?.socialSaftyRecommendations.includes(
                  "SafetyEquipmentNeeded",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.socialSaftyRecommendationsComment
              ? formData?.socialSaftyRecommendationsComment
              : "",
          },
          {
            smokingCessationEducation: formData?.socialSaftyRecommendations
              ? formData?.socialSaftyRecommendations.includes(
                  "SmokingCessationEducationFollowUpProvider",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.socialSaftyRecommendationsComment
              ? formData?.socialSaftyRecommendationsComment
              : "",
          },
          {
            substanceUseCessation: formData?.socialSaftyRecommendations
              ? formData?.socialSaftyRecommendations.includes(
                  "SubstanceUseCessationAssistanceFollowupProvider",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.socialSaftyRecommendationsComment
              ? formData?.socialSaftyRecommendationsComment
              : "",
          },
          {
            communityResourcesNeeded: formData?.socialSaftyRecommendations
              ? formData?.socialSaftyRecommendations.includes(
                  "CommunityResourcesNeededSocialServiceFollowup",
                )
                ? "1"
                : ""
              : "",
            comment: formData?.socialSaftyRecommendationsComment
              ? formData?.socialSaftyRecommendationsComment
              : "",
          },
        ],
        comment: formData?.socialSaftyRecommendationsComment
          ? formData?.socialSaftyRecommendationsComment
          : "",
      },
    };
    let payload2024 = {};
    if (storedYear == "2024") {
      payload2024 = {
        tobaccoUse: {
          current: formData?.self_tobUse
            ? formData?.self_tobUse.includes("current")
              ? "1"
              : ""
            : "",
          currentSubAnswer: {
            type: {
              cigarettes: formData?.self_tobUseSubCurrSubType
                ? formData?.self_tobUseSubCurrSubType.includes("cigarettes")
                  ? "1"
                  : ""
                : "",
              cigarettesSubAnswer: {
                oneToThree:
                  formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                    ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                        "oneToThree",
                      )
                      ? "1"
                      : ""
                    : "",
                halfAPack:
                  formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                    ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                        "halfAPack",
                      )
                      ? "1"
                      : ""
                    : "",
                onePack: formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                  ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                      "onePack",
                    )
                    ? "1"
                    : ""
                  : "",
                moreThanOne:
                  formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                    ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                        "moreThanOne",
                      )
                      ? "1"
                      : ""
                    : "",
                other: formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany
                  ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowMany.includes(
                      "other",
                    )
                    ? "1"
                    : ""
                  : "",
                otherSubAnswer: {
                  describe:
                    formData?.self_tobUseSubCurrSubTypeCigaretteSubHowManyOtherSubDesc
                      ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowManyOtherSubDesc
                      : "",
                },
                interestedInSmoking: {
                  interestedInSmoking: formData?.self_tobUseSubCurrSubSmokeCess
                    ? formData?.self_tobUseSubCurrSubSmokeCess.includes(
                        "smokingCessation",
                      )
                      ? "1"
                      : ""
                    : "",
                  comment: formData?.self_tobUseSubCurrSubSmokeCessComment
                    ? formData?.self_tobUseSubCurrSubSmokeCessComment
                    : "",
                },
                thingsPreventing: {
                  educationNeeded: "",
                  socialSupport: "",
                  environmentalFactors: "",
                  stress: "",
                  selfConfidence: "",
                  lackOfMotivation: "",
                  notAPriority: "",
                  other: "",
                  none: "",
                  comment: "",
                },
                comment:
                  formData?.self_tobUseSubCurrSubTypeCigaretteSubHowManyComment
                    ? formData?.self_tobUseSubCurrSubTypeCigaretteSubHowManyComment
                    : "",
              },
              cigars: formData?.self_tobUseSubCurrSubType
                ? formData?.self_tobUseSubCurrSubType.includes("cigars")
                  ? "1"
                  : ""
                : "",
              chewingTobacco: formData?.self_tobUseSubCurrSubType
                ? formData?.self_tobUseSubCurrSubType.includes("chewingTobacco")
                  ? "1"
                  : ""
                : "",
              vaping: formData?.self_tobUseSubCurrSubType
                ? formData?.self_tobUseSubCurrSubType.includes("vaping")
                  ? "1"
                  : ""
                : "",
              other: formData?.self_tobUseSubCurrSubType
                ? formData?.self_tobUseSubCurrSubType.includes("other")
                  ? "1"
                  : ""
                : "",
              otherSubAnswer: {
                describe: formData?.self_tobUseSubCurrSubTypeOtherSubDesc
                  ? formData?.self_tobUseSubCurrSubTypeOtherSubDesc
                  : "",
              },
              comment: formData?.self_tobUseSubCurrSubTypeComment
                ? formData?.self_tobUseSubCurrSubTypeComment
                : "",
            },
            smokingCessation: {
              smokingCessation: formData?.self_tobUseSubCurrSubSmokeCess
                ? formData?.self_tobUseSubCurrSubSmokeCess.includes(
                    "smokingCessation",
                  )
                  ? "1"
                  : ""
                : "",
              comment: formData?.self_tobUseSubCurrSubSmokeCessComment
                ? formData?.self_tobUseSubCurrSubSmokeCessComment
                : "",
            },
          },
          former: formData?.self_tobUse
            ? formData?.self_tobUse.includes("former")
              ? "1"
              : ""
            : "",
          formerSubAnswer: {
            when: {
              withinLastYear: formData?.self_tobUseSubFormerSubWhen
                ? formData?.self_tobUseSubFormerSubWhen.includes(
                    "withinLastYear",
                  )
                  ? "1"
                  : ""
                : "",
              withinLastThreeYear: formData?.self_tobUseSubFormerSubWhen
                ? formData?.self_tobUseSubFormerSubWhen.includes(
                    "withinLastThreeYear",
                  )
                  ? "1"
                  : ""
                : "",
              moreYearAgo: formData?.self_tobUseSubFormerSubWhen
                ? formData?.self_tobUseSubFormerSubWhen.includes("moreYearAgo")
                  ? "1"
                  : ""
                : "",
              comment: formData?.self_tobUseSubFormerSubWhenComment
                ? formData?.self_tobUseSubFormerSubWhenComment
                : "",
            },
            type: {
              cigarettes: formData?.self_tobUseSubFormerSubType
                ? formData?.self_tobUseSubFormerSubType.includes("cigarettes")
                  ? "1"
                  : ""
                : "",
              cigarettesSubAnswer: {
                oneToThree:
                  formData?.self_tobUseSubFormerSubTypeCigaretteSubHowMany
                    ? formData?.self_tobUseSubFormerSubTypeCigaretteSubHowMany.includes(
                        "oneToThree",
                      )
                      ? "1"
                      : ""
                    : "",
                halfAPack:
                  formData?.self_tobUseSubFormerSubTypeCigaretteSubHowMany
                    ? formData?.self_tobUseSubFormerSubTypeCigaretteSubHowMany.includes(
                        "halfAPack",
                      )
                      ? "1"
                      : ""
                    : "",
                onePack:
                  formData?.self_tobUseSubFormerSubTypeCigaretteSubHowMany
                    ? formData?.self_tobUseSubFormerSubTypeCigaretteSubHowMany.includes(
                        "onePack",
                      )
                      ? "1"
                      : ""
                    : "",
                moreThanOne:
                  formData?.self_tobUseSubFormerSubTypeCigaretteSubHowMany
                    ? formData?.self_tobUseSubFormerSubTypeCigaretteSubHowMany.includes(
                        "moreThanOne",
                      )
                      ? "1"
                      : ""
                    : "",
                other: formData?.self_tobUseSubFormerSubTypeCigaretteSubHowMany
                  ? formData?.self_tobUseSubFormerSubTypeCigaretteSubHowMany.includes(
                      "other",
                    )
                    ? "1"
                    : ""
                  : "",
                otherSubAnswer: {
                  describe:
                    formData?.self_tobUseSubFormerSubTypeCigaretteSubHowManyOtherSubDesc
                      ? formData?.self_tobUseSubFormerSubTypeCigaretteSubHowManyOtherSubDesc
                      : "",
                },
                comment: "",
              },
              cigars: formData?.self_tobUseSubFormerSubType
                ? formData?.self_tobUseSubFormerSubType.includes("cigars")
                  ? "1"
                  : ""
                : "",
              chewingTobacco: formData?.self_tobUseSubFormerSubType
                ? formData?.self_tobUseSubFormerSubType.includes(
                    "chewingTobacco",
                  )
                  ? "1"
                  : ""
                : "",
              vaping: formData?.self_tobUseSubFormerSubType
                ? formData?.self_tobUseSubFormerSubType.includes("vaping")
                  ? "1"
                  : ""
                : "",
              other: formData?.self_tobUseSubFormerSubType
                ? formData?.self_tobUseSubFormerSubType.includes("other")
                  ? "1"
                  : ""
                : "",
              otherSubAnswer: {
                describe: formData?.self_tobUseSubFormerSubTypeOtherSubDesc
                  ? formData?.self_tobUseSubFormerSubTypeOtherSubDesc
                  : "",
              },
              comment: formData?.self_tobUseSubCurrSubTypeComment
                ? formData?.self_tobUseSubCurrSubTypeComment
                : "",
            },
          },
          never: formData?.self_tobUse
            ? formData?.self_tobUse.includes("never")
              ? "1"
              : ""
            : "",
          comment: formData?.self_tobUseComment
            ? formData?.self_tobUseComment
            : "",
        },
        alcoholUse: {
          current: formData?.self_alcUse
            ? formData?.self_alcUse.includes("current")
              ? "1"
              : ""
            : "",
          currentSubAnswer: {
            howOften: {
              daily: formData?.self_alcUseCurrSubHowOften
                ? formData?.self_alcUseCurrSubHowOften == "1"
                  ? "1"
                  : ""
                : "",
              weekly: formData?.self_alcUseCurrSubHowOften
                ? formData?.self_alcUseCurrSubHowOften == "2"
                  ? "1"
                  : ""
                : "",
              monthly: formData?.self_alcUseCurrSubHowOften
                ? formData?.self_alcUseCurrSubHowOften == "3"
                  ? "1"
                  : ""
                : "",
              yearly: formData?.self_alcUseCurrSubHowOften
                ? formData?.self_alcUseCurrSubHowOften == "4"
                  ? "1"
                  : ""
                : "",
              comment: "",
            },
            howManyDrinks: {
              lessThanOne: formData?.self_alcUseCurrSubHowMany
                ? formData?.self_alcUseCurrSubHowMany == "1"
                  ? "1"
                  : ""
                : "",
              one: formData?.self_alcUseCurrSubHowMany
                ? formData?.self_alcUseCurrSubHowMany == "2"
                  ? "1"
                  : ""
                : "",
              two: formData?.self_alcUseCurrSubHowMany
                ? formData?.self_alcUseCurrSubHowMany == "3"
                  ? "1"
                  : ""
                : "",
              three: formData?.self_alcUseCurrSubHowMany
                ? formData?.self_alcUseCurrSubHowMany == "4"
                  ? "1"
                  : ""
                : "",
              four: formData?.self_alcUseCurrSubHowMany
                ? formData?.self_alcUseCurrSubHowMany == "5"
                  ? "1"
                  : ""
                : "",
              fiveToEight: formData?.self_alcUseCurrSubHowMany
                ? formData?.self_alcUseCurrSubHowMany == "6"
                  ? "1"
                  : ""
                : "",
              nineToTwelve: formData?.self_alcUseCurrSubHowMany
                ? formData?.self_alcUseCurrSubHowMany == "7"
                  ? "1"
                  : ""
                : "",
              thirteenToTwenty: formData?.self_alcUseCurrSubHowMany
                ? formData?.self_alcUseCurrSubHowMany == "8"
                  ? "1"
                  : ""
                : "",
              moreThanTwenty: formData?.self_alcUseCurrSubHowMany
                ? formData?.self_alcUseCurrSubHowMany == "9"
                  ? "1"
                  : ""
                : "",
              comment: "",
            },
            comment: "",
          },
          former: formData?.self_alcUse
            ? formData?.self_alcUse.includes("former")
              ? "1"
              : ""
            : "",
          formerSubAnswer: {
            howOften: {
              daily: formData?.self_alcUseFormerSubHowOften
                ? formData?.self_alcUseFormerSubHowOften == "1"
                  ? "1"
                  : ""
                : "",
              weekly: formData?.self_alcUseFormerSubHowOften
                ? formData?.self_alcUseFormerSubHowOften == "2"
                  ? "1"
                  : ""
                : "",
              monthly: formData?.self_alcUseFormerSubHowOften
                ? formData?.self_alcUseFormerSubHowOften == "3"
                  ? "1"
                  : ""
                : "",
              yearly: formData?.self_alcUseFormerSubHowOften
                ? formData?.self_alcUseFormerSubHowOften == "4"
                  ? "1"
                  : ""
                : "",
              comment: "",
            },
            howManyDrinks: {
              lessThanOne: formData?.self_alcUseFormerSubHowMany
                ? formData?.self_alcUseFormerSubHowMany == "1"
                  ? "1"
                  : ""
                : "",
              one: formData?.self_alcUseFormerSubHowMany
                ? formData?.self_alcUseFormerSubHowMany == "2"
                  ? "1"
                  : ""
                : "",
              two: formData?.self_alcUseFormerSubHowMany
                ? formData?.self_alcUseFormerSubHowMany == "3"
                  ? "1"
                  : ""
                : "",
              three: formData?.self_alcUseFormerSubHowMany
                ? formData?.self_alcUseFormerSubHowMany == "4"
                  ? "1"
                  : ""
                : "",
              four: formData?.self_alcUseFormerSubHowMany
                ? formData?.self_alcUseFormerSubHowMany == "5"
                  ? "1"
                  : ""
                : "",
              fiveToEight: formData?.self_alcUseFormerSubHowMany
                ? formData?.self_alcUseFormerSubHowMany == "6"
                  ? "1"
                  : ""
                : "",
              nineToTwelve: formData?.self_alcUseFormerSubHowMany
                ? formData?.self_alcUseFormerSubHowMany == "7"
                  ? "1"
                  : ""
                : "",
              thirteenToTwenty: formData?.self_alcUseFormerSubHowMany
                ? formData?.self_alcUseFormerSubHowMany == "8"
                  ? "1"
                  : ""
                : "",
              moreThanTwenty: formData?.self_alcUseFormerSubHowMany
                ? formData?.self_alcUseFormerSubHowMany == "9"
                  ? "1"
                  : ""
                : "",
              comment: "",
            },
          },
          never: formData?.self_alcUse
            ? formData?.self_alcUse.includes("never")
              ? "1"
              : ""
            : "",
          comment: formData?.self_alcUseComment || "",
        },

        recreationalDrugs: {
          isRecreationalDrugs: formData?.self_recreaDrugs
            ? formData?.self_recreaDrugs?.[0]
            : "",
          isRecreationalDrugsSubAnswer: {
            whichDrugs: formData?.self_recreaDrugsSubWhich
              ? formData?.self_recreaDrugsSubWhich
              : "",
          },
          comment: formData?.self_recreaDrugsComment
            ? formData?.self_recreaDrugsComment
            : "",
        },

        worryAboutFoodRunOut: {
          never: formData?.enoughFoodForFamily
            ? formData?.enoughFoodForFamily.includes("never")
              ? "1"
              : ""
            : "",
          sometimes: formData?.enoughFoodForFamily
            ? formData?.enoughFoodForFamily.includes("sometimes")
              ? "1"
              : ""
            : "",
          always: formData?.enoughFoodForFamily
            ? formData?.enoughFoodForFamily.includes("always")
              ? "1"
              : ""
            : "",
          declinesToAnswer: formData?.enoughFoodForFamily
            ? formData?.enoughFoodForFamily.includes("declinesToAnswer")
              ? "1"
              : ""
            : "",
          comment: formData?.enoughFoodForFamilyComment
            ? formData?.enoughFoodForFamilyComment
            : "",
        },

        awareOfCommunityResources: {
          isAwareOfCommunityResources: formData?.communityResources
            ? formData?.communityResources?.[0]
            : "",
          comment: formData?.communityResourcesComment
            ? formData?.communityResourcesComment
            : "",
        },

        useOfCarSeat: {
          isUseOfCarSeat: formData?.useCarSeat ? formData?.useCarSeat?.[0] : "",
          comment: formData?.useCarSeatComment
            ? formData?.useCarSeatComment
            : "",
        },

        useOfSafetyEquipment: {
          isUseOfSafetyEquipment: formData?.equipmentRidingBike
            ? formData?.equipmentRidingBike?.[0]
            : "",
          comment: formData?.equipmentRidingBikeComment
            ? formData?.equipmentRidingBikeComment
            : "",
        },

        useOfSwimmingGear: {
          isUseOfSwimmingGear: formData?.swimmingGear
            ? formData?.swimmingGear?.[0]
            : "",
          comment: formData?.swimmingGearComment
            ? formData?.swimmingGearComment
            : "",
        },

        childDrive: {
          doesChildDrive: formData?.childDrive ? formData?.childDrive?.[0] : "",
          comment: formData?.childDriveComment
            ? formData?.childDriveComment
            : "",
        },

        eductaionReview: {
          isEductaionReview: formData?.education_review
            ? formData?.education_review?.includes("educationReview")
              ? "1"
              : ""
            : "",
          comment: formData?.education_reviewComment
            ? formData?.education_reviewComment
            : "",
        },

        witnessedEvent: {
          carOrOtherAccident: formData?.Whatwastheevent
            ? formData?.Whatwastheevent.includes("carAccident")
              ? "1"
              : ""
            : "",
          fire: formData?.Whatwastheevent
            ? formData?.Whatwastheevent.includes("fire")
              ? "1"
              : ""
            : "",
          storm: formData?.Whatwastheevent
            ? formData?.Whatwastheevent.includes("storm")
              ? "1"
              : ""
            : "",
          physicalIllnessOrAssault: formData?.Whatwastheevent
            ? formData?.Whatwastheevent.includes("physicalIllness")
              ? "1"
              : ""
            : "",
          sexualAssault: formData?.Whatwastheevent
            ? formData?.Whatwastheevent.includes("sexualAssualt")
              ? "1"
              : ""
            : "",
          comment: formData?.childExperiencedComment
            ? formData?.childExperiencedComment
            : "",
          describe: formData?.describe ? formData?.describe : "",
        },

        attendSchool: {
          isAttendSchool: formData?.attendSchool
            ? formData?.attendSchool?.[0]
            : "",
          isAttendSchoolSubAnswer: {
            whichGrade: formData?.grade ? formData?.grade : "",
          },
          comment: formData?.attendSchoolComment
            ? formData?.attendSchoolComment
            : "",
        },

        hadTroubleWithPeersAtSchool: {
          isHadTroubleWithPeersAtSchool: formData?.troubleWithPeersAtSchool
            ? formData?.troubleWithPeersAtSchool?.[0]
            : "",
          comment: formData?.troublePeersSchoolComments
            ? formData?.troublePeersSchoolComments
            : "",
        },

        childLimited: {
          isChildLimited: formData?.abilityToDoThingsMostChildrenSameAgeCanDo
            ? formData?.abilityToDoThingsMostChildrenSameAgeCanDo?.[0]
            : "",
          comment: formData?.abilityToDoThingsMostChildrenSameAgeCanDoComments
            ? formData?.abilityToDoThingsMostChildrenSameAgeCanDoComments
            : "",
        },

        servicesBeingUsed: {
          physicalTherapy: formData?.serviceBeingUsed
            ? formData?.serviceBeingUsed.includes("physicalTherapy")
              ? "1"
              : ""
            : "",
          occupationalTherapy: formData?.serviceBeingUsed
            ? formData?.serviceBeingUsed.includes("occupationalTherapy")
              ? "1"
              : ""
            : "",
          speechTherapy: formData?.serviceBeingUsed
            ? formData?.serviceBeingUsed.includes("speechTherapy")
              ? "1"
              : ""
            : "",
          behaviouralTherapy: formData?.serviceBeingUsed
            ? formData?.serviceBeingUsed.includes("Behavioral Therapy")
              ? "1"
              : ""
            : "",
          careCoordination: formData?.serviceBeingUsed
            ? formData?.serviceBeingUsed.includes("careCoordination")
              ? "1"
              : ""
            : "",
          psychological: formData?.serviceBeingUsed
            ? formData?.serviceBeingUsed.includes("Psychological")
              ? "1"
              : ""
            : "",
          other: formData?.serviceBeingUsed
            ? formData?.serviceBeingUsed.includes("other")
              ? "1"
              : ""
            : "",
          none: formData?.serviceBeingUsed
            ? formData?.serviceBeingUsed.includes("none")
              ? "1"
              : ""
            : "",
          comment: formData?.serviceBeingUsedComment || "",
        },
        recommendations: {
          recommendationsSubAnswer: [
            {
              safetyEquipmentNeeded: formData?.socialSaftyRecommendations
                ? formData?.socialSaftyRecommendations.includes(
                    "SafetyEquipmentNeeded",
                  )
                  ? "1"
                  : ""
                : "",
              comment:
                formData?.socialSaftyRecommendationsSafetyEquipmentNeededComment
                  ? formData?.socialSaftyRecommendationsSafetyEquipmentNeededComment
                  : "",
            },
            {
              smokingCessationEducation: formData?.socialSaftyRecommendations
                ? formData?.socialSaftyRecommendations.includes(
                    "SmokingCessationEducationFollowUpProvider",
                  )
                  ? "1"
                  : ""
                : "",
              comment:
                formData?.socialSaftyRecommendationsSmokingCessationEducationFollowUpProviderComment
                  ? formData?.socialSaftyRecommendationsSmokingCessationEducationFollowUpProviderComment
                  : "",
            },
            {
              substanceUseCessation: formData?.socialSaftyRecommendations
                ? formData?.socialSaftyRecommendations.includes(
                    "SubstanceUseCessationAssistanceFollowupProvider",
                  )
                  ? "1"
                  : ""
                : "",
              comment:
                formData?.socialSaftyRecommendationsSubstanceUseCessationAssistanceFollowupProviderComment
                  ? formData?.socialSaftyRecommendationsSubstanceUseCessationAssistanceFollowupProviderComment
                  : "",
            },
            {
              communityResourcesNeeded: formData?.socialSaftyRecommendations
                ? formData?.socialSaftyRecommendations.includes(
                    "CommunityResourcesNeededSocialServiceFollowup",
                  )
                  ? "1"
                  : ""
                : "",
              comment:
                formData?.socialSaftyRecommendationsCommunityResourcesNeededSocialServiceFollowupComment
                  ? formData?.socialSaftyRecommendationsCommunityResourcesNeededSocialServiceFollowupComment
                  : "",
            },
          ],
          comment: formData?.socialSaftyRecommendationsComment
            ? formData?.socialSaftyRecommendationsComment
            : "",
        },
      };
    }

    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,officeName
        data: {
          name: "Social History",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };

  const onSaveAssesmentInfoData = async () => {
    const formData = autoSavePedsAssessmentofDiagnosis;
    const payload = {
      ...(formData?.child_conditions?.includes("anxietyDx") && {
        anxiety: {
          isAnxiety: formData?.child_conditions
            ? formData?.child_conditions?.includes("anxietyDx")
              ? "1"
              : ""
            : "2",
          isAnxietySubAnswer: {
            describe: {
              active: formData["ros_neuroPsychSubDiagAnxietySubDesc"]
                ? formData["ros_neuroPsychSubDiagAnxietySubDesc"] == "active"
                  ? "1"
                  : ""
                : "2",
              activeSubAnswer: {
                type: {
                  separationAnxietyDisorder: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]?.includes("separationAnxietyDisorder")
                      ? "1"
                      : ""
                    : "2",
                  generalizedAnxietyDisorder: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]?.includes("generalizedAnxietyDisorder")
                      ? "1"
                      : ""
                    : "2",
                  socialAnxietyDisorder: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]?.includes("socialAnxietyDisorder")
                      ? "1"
                      : ""
                    : "2",
                  panicDisorder: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]?.includes("panicDisorder")
                      ? "1"
                      : ""
                    : "2",
                  ocd: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]?.includes("obsessiveCompulsiveDisorder")
                      ? "1"
                      : ""
                    : "2",
                  phobic: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]?.includes("phobicDisorder")
                      ? "1"
                      : ""
                    : "2",
                  ptsd: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]?.includes("PTSD")
                      ? "1"
                      : ""
                    : "2",
                  // other: formData['ros_neuroPsychSubDiagAnxietySubDescActiveSubType'] ? formData['ros_neuroPsychSubDiagAnxietySubDescActiveSubType']?.includes("other") ? "1" : "" : "2",
                  // comment: ,
                },
                treatment: {
                  medication: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                      ]?.includes("medication")
                      ? "1"
                      : ""
                    : "2",
                  therapy: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                      ]?.includes("medication")
                      ? "1"
                      : ""
                    : "2",
                  other: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                      ]?.includes("medication")
                      ? "1"
                      : ""
                    : "2",
                  otherSubAnswer: {
                    describe: formData[
                      "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatOtherSubDescText"
                    ]
                      ? formData[
                          "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatOtherSubDescText"
                        ]
                      : "2",
                  },
                  noTreatment: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                      ]?.includes("medication")
                      ? "1"
                      : ""
                    : "2",
                  // comment: ,
                },
              },
              historyOf: formData["ros_neuroPsychSubDiagAnxietySubDesc"]
                ? formData["ros_neuroPsychSubDiagAnxietySubDesc"]?.includes(
                    "historyOf",
                  )
                  ? "1"
                  : ""
                : "2",
              // comment: ,
            },
          },
          // comment: formData?.child_conditionsComment ? formData?.child_conditionsComment : "",
        },
      }),

      ...(formData?.child_conditions?.includes("asthma") && {
        asthma: {
          isAsthma: formData?.child_conditions
            ? formData?.child_conditions?.includes("asthma")
              ? "1"
              : "2"
            : "2",
          isAsthmaSubAnswer: {
            describe: {
              active: formData["ros_respiratoryAsthmaSubAnswerDescribe"]
                ? formData["ros_respiratoryAsthmaSubAnswerDescribe"] == "active"
                  ? "1"
                  : "2"
                : "2",
              activeSubAnswer: {
                type: {
                  useOfVentilator: formData[
                    "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]?.includes("useofVentilator")
                      ? "1"
                      : "2"
                    : "2",
                  inhaledOrOralSteroids: formData[
                    "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]?.includes("inhaledOralSteroids")
                      ? "1"
                      : "2"
                    : "2",
                  useRescueInhalerMore: formData[
                    "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]?.includes("useRescue")
                      ? "1"
                      : "2"
                    : "2",
                  // panicDisorder: formData['ros_respiratoryAsthmaActiveTreatmentSubAnswer'] ?  formData['ros_respiratoryAsthmaActiveTreatmentSubAnswer']?.includes("useofVentilator") ? "1" : "2" : "2",
                  none: formData[
                    "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]?.includes("none")
                      ? "1"
                      : "2"
                    : "2",
                  other: formData[
                    "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]?.includes("other")
                      ? "1"
                      : "2"
                    : "2",
                  // comment:
                },
                treatment: {
                  medication: formData[
                    "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]?.includes("medication")
                      ? "1"
                      : "2"
                    : "2",
                  otherTreatment: formData[
                    "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]?.includes("otherTreatment")
                      ? "1"
                      : "2"
                    : "2",
                  otherTreatmentSubAnswer: {
                    describe: formData[
                      "ros_respiratoryAsthmaSubOtherTreatmentSubDesc"
                    ]
                      ? formData[
                          "ros_respiratoryAsthmaSubOtherTreatmentSubDesc"
                        ]
                      : "",
                  },
                  noTreatment: formData[
                    "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]?.includes("noTreatment")
                      ? "1"
                      : "2"
                    : "2",
                  // comment:
                },
              },
              historyOf: formData["ros_respiratoryAsthmaSubAnswerDescribe"]
                ? formData["ros_respiratoryAsthmaSubAnswerDescribe"].includes(
                    "historyOf",
                  )
                  ? "1"
                  : "2"
                : "2",
              comment: formData?.ros_respiratoryAsthmaSubAnswerDescribeComment,
            },
            asthmaActionPlan: {
              isAsthmaActionPlan: formData[
                "ros_respiratoryAsthmaCurrentExacerbation"
              ]
                ? formData["ros_respiratoryAsthmaCurrentExacerbation"]
                : "",
              isAsthmaActionPlanSubAnswer: {
                describe: formData["ros_respiratoryAsthmaActionPlanDescribe"]
                  ? formData["ros_respiratoryAsthmaActionPlanDescribe"]
                  : "",
              },
              asthmaActionPlanNoSubAnswer: {
                childGuardianEducated: formData?.educatedOnImportance
                  ? formData?.educatedOnImportance.includes(
                      "asthmaactionplanoneducatedguardianandchild",
                    )
                    ? formData?.educatedOnImportance[0]
                    : ""
                  : "",
              },
            },
          },
        },
      }),

      ...(formData?.child_conditions?.includes("depressionDx") && {
        depression: {
          isDepression: formData?.child_conditions
            ? formData?.child_conditions?.includes("depressionDx")
              ? "1"
              : ""
            : "2",
          isDepressionSubAnswer: {
            describe: {
              active: formData?.ros_neuroPsychSubDiagDepressDesc
                ? formData?.ros_neuroPsychSubDiagDepressDesc?.includes("active")
                  ? "1"
                  : ""
                : "2",
              activeSubAnswer: {
                type: {
                  major: formData[
                    "ros_neuroPsychSubDiagDepressDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]?.includes("major")
                      ? "1"
                      : ""
                    : "2",
                  majorSubAnswer: {
                    severity: {
                      mild: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("mild")
                          ? "1"
                          : ""
                        : "2",
                      moderate: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("moderate")
                          ? "1"
                          : ""
                        : "2",
                      severeWithoutPsychotic: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("severeWithoutPsychotic")
                          ? "1"
                          : ""
                        : "2",
                      severeWithPsychotic: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("severeWithPsychotic")
                          ? "1"
                          : ""
                        : "2",
                      unSpecified: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("unspecified")
                          ? "1"
                          : ""
                        : "2",
                    },
                  },
                  chronic: formData[
                    "ros_neuroPsychSubDiagDepressDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]?.includes("chronic")
                      ? "1"
                      : ""
                    : "2",
                  // comment:
                  // manic: formData['ros_neuroPsychSubDiagDepressDescActiveSubType'] ? formData['ros_neuroPsychSubDiagDepressDescActiveSubType'].includes("manic") ? "1" : "" : "2",

                  maniacSubAnswer: {
                    severity: {
                      mild: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("mild")
                          ? "1"
                          : ""
                        : "2",
                      moderatelySevere: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("moderatelySevere")
                          ? "1"
                          : ""
                        : "2",
                      moderate: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("moderate")
                          ? "1"
                          : ""
                        : "2",
                      severe: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("severe")
                          ? "1"
                          : ""
                        : "2",
                      unknown: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("unknown")
                          ? "1"
                          : ""
                        : "2",
                      // comment:,
                    },
                  },
                  depressionUnspecified: formData[
                    "ros_neuroPsychSubDiagDepressDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]?.includes("depressionUnspecified")
                      ? "1"
                      : ""
                    : "2",
                  depressionUnspecifiedSubAnswer: {
                    severity: {
                      mild: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("mild")
                          ? "1"
                          : ""
                        : "2",
                      moderatelySevere: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("moderatelySevere")
                          ? "1"
                          : ""
                        : "2",
                      moderate: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("moderate")
                          ? "1"
                          : ""
                        : "2",
                      severe: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("severe")
                          ? "1"
                          : ""
                        : "2",
                      unknown: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("unknown")
                          ? "1"
                          : ""
                        : "2",
                      // comment:,
                    },
                  },
                  other: formData[
                    "ros_neuroPsychSubDiagDepressDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]?.includes("other")
                      ? "1"
                      : ""
                    : "2",
                  otherSubAnswer: {
                    severity: {
                      mild: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("mild")
                          ? "1"
                          : ""
                        : "2",
                      moderatelySevere: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("moderatelySevere")
                          ? "1"
                          : ""
                        : "2",
                      moderate: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("moderate")
                          ? "1"
                          : ""
                        : "2",
                      severe: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("severe")
                          ? "1"
                          : ""
                        : "2",
                      unknown: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]?.includes("unknown")
                          ? "1"
                          : ""
                        : "2",
                      // comment:,
                    },
                  },
                  unknown: formData[
                    "ros_neuroPsychSubDiagDepressDescActiveSubType"
                  ]
                    ? formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]?.includes("unknown")
                      ? "1"
                      : ""
                    : "2",
                  // unknownSubAnswer: {
                  treatment: {
                    medication: formData[
                      "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                    ]
                      ? formData[
                          "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                        ]?.includes("medication")
                        ? "1"
                        : ""
                      : "2",
                    therapy: formData[
                      "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                    ]
                      ? formData[
                          "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                        ]?.includes("therapy")
                        ? "1"
                        : ""
                      : "2",
                    otherTreatment: formData[
                      "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                    ]
                      ? formData[
                          "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                        ]?.includes("other")
                        ? "1"
                        : ""
                      : "2",
                    otherTreatmentSubAnswer: {
                      describe: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubTreatOtherSubDescText"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTreatOtherSubDescText"
                          ]
                        : "",
                    },
                    noTreatment: formData[
                      "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                    ]
                      ? formData[
                          "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                        ]?.includes("noTreatment")
                        ? "1"
                        : ""
                      : "2",
                    // comment:
                    // }
                  },
                },
                // remissionStatus: {
                //   depressionUnderControl: ,
                //   beenSymptomFree: ,
                // },
              },
              // comment:
            },
          },
        },
      }),

      ...(formData?.child_conditions?.includes("diabetes") && {
        diabetes: {
          isDiabetes: formData["child_conditions"]
            ? formData["child_conditions"]?.includes("diabetes")
              ? "1"
              : "2"
            : "2",
          isDiabetesSubAnswer: {
            describe: {
              active:
                formData?.ros_endocrineIsEndocrineSubdiabetesSubdescribeSub
                  ? formData?.ros_endocrineIsEndocrineSubdiabetesSubdescribeSub?.includes(
                      "active",
                    )
                    ? "1"
                    : "2"
                  : "2",
              activeSubAnswer: {
                type: {
                  type1: formData["ros_endocrineIsEndocrineSubdiabetesSubtype"]
                    ? formData[
                        "ros_endocrineIsEndocrineSubdiabetesSubtype"
                      ]?.includes("type1")
                      ? "1"
                      : "2"
                    : "2",
                  type1Point5: formData[
                    "ros_endocrineIsEndocrineSubdiabetesSubtype"
                  ]
                    ? formData[
                        "ros_endocrineIsEndocrineSubdiabetesSubtype"
                      ]?.includes("type15")
                      ? "1"
                      : "2"
                    : "2",
                  type2: formData["ros_endocrineIsEndocrineSubdiabetesSubtype"]
                    ? formData[
                        "ros_endocrineIsEndocrineSubdiabetesSubtype"
                      ]?.includes("type2")
                      ? "1"
                      : "2"
                    : "2",
                  // comment:
                },
                treatment: {
                  medication: formData[
                    "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                      ]?.includes("medication")
                      ? "1"
                      : "2"
                    : "2",
                  otherTreatment: formData[
                    "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                      ]?.includes("medication")
                      ? "1"
                      : "2"
                    : "2",
                  otherTreatmentSubAnswer: {
                    describe: formData[
                      "ros_respiratoryDiabetsTreatSubOtherTreatment"
                    ]
                      ? formData["ros_respiratoryDiabetsTreatSubOtherTreatment"]
                      : "",
                  },
                  noTreatment: formData[
                    "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                      ]?.includes("medication")
                      ? "1"
                      : "2"
                    : "2",
                  // comment:
                },
              },
              historyOf:
                formData?.ros_endocrineIsEndocrineSubdiabetesSubdescribeSub
                  ? formData?.ros_endocrineIsEndocrineSubdiabetesSubdescribeSub?.includes(
                      "historyOf",
                    )
                    ? "1"
                    : "2"
                  : "2",
              // comment: ,
            },
            mostRecentHba1cValue: formData[
              "ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue"
            ]
              ? formData[
                  "ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue"
                ]
              : "",
            date: formData["ros_endocrineIsEndocrineSubdiabetesSubandDate"]
              ? formData["ros_endocrineIsEndocrineSubdiabetesSubandDate"]
              : "",
            testChildBloodSugar: {
              isTestChildBloodSugar: formData[
                "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar"
              ]
                ? formData[
                    "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar"
                  ]
                : "",
              isTestChildBloodSugarSubAnswer: {
                describe: formData[
                  "ros_respiratoryDiabetsSubOtherTreatmentSubDesc"
                ]
                  ? formData["ros_respiratoryDiabetsSubOtherTreatmentSubDesc"]
                  : "",
              },
            },
          },
          // comment:
        },
      }),

      ...(formData?.child_conditions?.includes("sickleCellDisease") && {
        sickleCellDisease: {
          isSickleCellDisease: formData["child_conditions"]
            ? formData["child_conditions"]?.includes("sickleCellDisease")
              ? "1"
              : "2"
            : "2",
          isSickleCellDiseaseSubAnswer: {
            describe: {
              active: formData[
                "ros_hematologySickleCellDiseaseSubAnswerDescribe"
              ]
                ? formData[
                    "ros_hematologySickleCellDiseaseSubAnswerDescribe"
                  ]?.includes("active")
                  ? "1"
                  : ""
                : "2",
              activeSubAnswer: {
                familyHistory: formData[
                  "ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswer"
                ]
                  ? formData[
                      "ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswer"
                    ]?.[0]
                  : "2",
              },
              treatment: {
                medication: formData[
                  "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                ]
                  ? formData[
                      "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                    ]?.includes("active")
                    ? "1"
                    : ""
                  : "2",
                otherTreatment: formData[
                  "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                ]
                  ? formData[
                      "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                    ]?.includes("active")
                    ? "1"
                    : ""
                  : "2",
                otherTreatmentSubAnswer: {
                  describe: formData[
                    "ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc"
                  ]
                    ? formData[
                        "ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc"
                      ]
                    : "",
                },
                noTreatment: formData[
                  "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                ]
                  ? formData[
                      "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                    ]?.includes("active")
                    ? "1"
                    : ""
                  : "2",
                // comment: Joi.string().allow(""),
              },
            },
            historyOf: formData[
              "ros_hematologySickleCellDiseaseSubAnswerDescribe"
            ]
              ? formData[
                  "ros_hematologySickleCellDiseaseSubAnswerDescribe"
                ]?.includes("historyOf")
                ? "1"
                : ""
              : "2",
            // comment: Joi.string().allow(""),
          },
          // comment: Joi.string().allow(""),
        },
      }),

      ...(formData?.child_conditions?.includes("hemophilia") && {
        hemophilia: {
          isHemophilia: formData["child_conditions"]
            ? formData["child_conditions"]?.includes("hemophilia")
              ? "1"
              : ""
            : "2",
          isHemophiliaSubAnswer: {
            describe: {
              active: formData["ros_hematologyHemophiliaSubAnswerDescribe"]
                ? formData[
                    "ros_hematologyHemophiliaSubAnswerDescribe"
                  ]?.includes("active")
                  ? "1"
                  : ""
                : "2",
              activeSubAnswer: {
                treatment: {
                  medication: formData[
                    "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                      ]?.includes("medication")
                      ? "1"
                      : ""
                    : "2",
                  otherTreatment: formData[
                    "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                      ]?.includes("otherTreatment")
                      ? "1"
                      : ""
                    : "2",
                  otherTreatmentSubAnswer: {
                    describe: formData[
                      "ros_hematologyHemophiliaSubOtherTreatmentSubDesc"
                    ]
                      ? formData[
                          "ros_hematologyHemophiliaSubOtherTreatmentSubDesc"
                        ]
                      : "",
                  },
                  noTreatment: formData[
                    "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                      ]?.includes("noTreatment")
                      ? "1"
                      : ""
                    : "2",
                  // comment: ,
                },
              },
              historyOf: formData["ros_hematologyHemophiliaSubAnswerDescribe"]
                ? formData[
                    "ros_hematologyHemophiliaSubAnswerDescribe"
                  ]?.includes("historyOf")
                  ? "1"
                  : ""
                : "2",
              // comment: ,
            },
            // comment: ,
          },
          // comment: Joi.string().allow(""),
        },
      }),

      ...(formData?.child_conditions?.includes("attentionDeficitDisorder") && {
        attentionDeficitDisorder: {
          isAttentionDeficitDisorder: formData["child_conditions"]
            ? formData["child_conditions"]?.includes("attentionDeficitDisorder")
              ? "1"
              : ""
            : "2",
          isAttentionDeficitDisorderSubAnswer: {
            describe: {
              active: formData["ros_hematologyADDSubAnswerDescribe"]
                ? formData["ros_hematologyADDSubAnswerDescribe"]?.includes(
                    "active",
                  )
                  ? "1"
                  : ""
                : "2",
              activeSubAnswer: {
                type: {
                  inAttentive: formData[
                    "ros_hematologyADDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]?.includes("inattentive")
                      ? "1"
                      : ""
                    : "2",
                  hyperActive: formData[
                    "ros_hematologyADDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]?.includes("hyperactive")
                      ? "1"
                      : ""
                    : "2",
                  combined: formData[
                    "ros_hematologyADDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]?.includes("combined")
                      ? "1"
                      : ""
                    : "2",
                  other: formData["ros_hematologyADDActiveTreatmentSubAnswer"]
                    ? formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]?.includes("other")
                      ? "1"
                      : ""
                    : "2",
                  unspecified: formData[
                    "ros_hematologyADDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]?.includes("unspecified")
                      ? "1"
                      : ""
                    : "2",
                  // comment: ,
                },
                treatment: {
                  medication: formData[
                    "ros_hematologyADDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]?.includes("medication")
                      ? "1"
                      : ""
                    : "2",
                  otherTreatment: formData[
                    "ros_hematologyADDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]?.includes("otherTreatment")
                      ? "1"
                      : ""
                    : "2",
                  otherTreatmentSubAnswer: {
                    describe: "teste",
                  },
                  noTreatment: formData[
                    "ros_hematologyADDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]?.includes("noTreatment")
                      ? "1"
                      : ""
                    : "2",
                  // comment: ,
                },
              },
              historyOf: formData["ros_hematologyADDSubAnswerDescribe"]
                ? formData["ros_hematologyADDSubAnswerDescribe"]?.includes(
                    "historyOf",
                  )
                  ? "1"
                  : ""
                : "2",
              // comment: ,
            },
          },
          // comment: ,
        },
      }),

      ...(formData?.child_conditions?.includes(
        "diagnosisofDevelopmentalDisability",
      ) && {
        developmentalDisability: {
          isDevelopementalDisabilityDisorder: formData["child_conditions"]
            ? formData["child_conditions"]?.includes(
                "diagnosisofDevelopmentalDisability",
              )
              ? "1"
              : ""
            : "2",
          isDevelopementalDisabilityDisorderSubAnswer: {
            describe: {
              active: formData["ros_hematologyDDDSubAnswerDescribe"]
                ? formData["ros_hematologyDDDSubAnswerDescribe"]?.includes(
                    "active",
                  )
                  ? "1"
                  : ""
                : "2",
              activeSubAnswer: {
                // type: {
                //   inAttentive: ,
                //   hyperActive: ,
                //   combined: ,
                //   other: ,
                //   unspecified: ,
                //   // comment: Joi.string().allow(""),
                // },
                treatment: {
                  physicalTherapy: formData[
                    "ros_hematologyDDDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]?.includes("physicalTherapy")
                      ? "1"
                      : ""
                    : "2",
                  occupationalTherapy: formData[
                    "ros_hematologyDDDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]?.includes("occupationalTherapy")
                      ? "1"
                      : ""
                    : "2",
                  speechTherapy: formData[
                    "ros_hematologyDDDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]?.includes("speechTherapy")
                      ? "1"
                      : ""
                    : "2",
                  behaviouralTherapy: formData[
                    "ros_hematologyDDDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]?.includes("behavioralTherapy")
                      ? "1"
                      : ""
                    : "2",
                  educationalAssistance: formData[
                    "ros_hematologyDDDActiveTreatmentSubAnswer"
                  ]
                    ? formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]?.includes("educationalAssistance")
                      ? "1"
                      : ""
                    : "2",
                  other: formData["ros_hematologyDDDActiveTreatmentSubAnswer"]
                    ? formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]?.includes("other")
                      ? "1"
                      : ""
                    : "2",
                  otherSubAnswer: formData[
                    "ros_hematologyDDDSubOtherTreatmentSubDesc"
                  ]
                    ? formData["ros_hematologyDDDSubOtherTreatmentSubDesc"]
                    : "",
                  // comment: Joi.string().allow(""),
                },
              },
              historyOf: formData["ros_hematologyDDDSubAnswerDescribe"]
                ? formData["ros_hematologyDDDSubAnswerDescribe"]?.includes(
                    "historyOf",
                  )
                  ? "1"
                  : ""
                : "2",
              // comment: Joi.string().allow(""),
            },
          },
          // comment: Joi.string().allow(""),
        },
      }),

      ...(formData?.child_conditions?.includes("substanceUse") && {
        substanceUse: {
          isSubstanceUse: formData["child_conditions"]
            ? formData["child_conditions"]?.includes("substanceUse")
              ? "1"
              : ""
            : "2",
          isSubstanceUseSubAnswer: {
            describe: {
              active: formData["ros_neuroPsychSubDiagDrugsSubDesc"]
                ? formData["ros_neuroPsychSubDiagDrugsSubDesc"]?.includes(
                    "active",
                  )
                  ? "1"
                  : ""
                : "2",
              // activeSubAnswer: {
              type: {
                episodic: formData["ros_neuroPsychSubDiagDrugsSubType"]
                  ? formData["ros_neuroPsychSubDiagDrugsSubType"]?.includes(
                      "episodic",
                    )
                    ? "1"
                    : ""
                  : "2",
                continuous: formData["ros_neuroPsychSubDiagDrugsSubType"]
                  ? formData["ros_neuroPsychSubDiagDrugsSubType"]?.includes(
                      "continuous",
                    )
                    ? "1"
                    : ""
                  : "2",
                remission: formData["ros_neuroPsychSubDiagDrugsSubType"]
                  ? formData["ros_neuroPsychSubDiagDrugsSubType"]?.includes(
                      "remission",
                    )
                    ? "1"
                    : ""
                  : "2",
                // comment: ,
              },

              supportedBy: {
                useOfRecreationalDrugs: formData[
                  "ros_neuroPsychSubDiagDrugsSubSupp"
                ]
                  ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                      "recreationalDrugs",
                    )
                    ? "1"
                    : ""
                  : "2",
                chronicUseOfPainMedication: formData[
                  "ros_neuroPsychSubDiagDrugsSubSupp"
                ]
                  ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                      "chronicUseOfPainMedication",
                    )
                    ? "1"
                    : ""
                  : "2",
                historyOfHospitalization: formData[
                  "ros_neuroPsychSubDiagDrugsSubSupp"
                ]
                  ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                      "historyOfHospitalization",
                    )
                    ? "1"
                    : ""
                  : "2",
                historyOfOutPatientTreatment: formData[
                  "ros_neuroPsychSubDiagDrugsSubSupp"
                ]
                  ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                      "historyOutpatientTreatment",
                    )
                    ? "1"
                    : ""
                  : "2",
                withDrawlSymptoms: formData["ros_neuroPsychSubDiagDrugsSubSupp"]
                  ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                      "withdrawalSymptoms",
                    )
                    ? "1"
                    : ""
                  : "2",
                abnormalAffect: formData["ros_neuroPsychSubDiagDrugsSubSupp"]
                  ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                      "abnormalAffect",
                    )
                    ? "1"
                    : ""
                  : "2",
                other: formData["ros_neuroPsychSubDiagDrugsSubSupp"]
                  ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                      "other",
                    )
                    ? "1"
                    : ""
                  : "2",
                // comment: ,
              },
              historyOfPsychosis: {
                isHistoryOfPsychosis: formData[
                  "ros_neuroPsychSubDiagDrugsSubHistoryPsych"
                ]
                  ? formData["ros_neuroPsychSubDiagDrugsSubHistoryPsych"]
                  : "",
                // isHistoryOfPsychosisSubAnswer: Joi.object({
                //   whatDrug: Joi.string().allow(""), // Optional field for drug name
                // },
              },
              whatDrug: formData["ros_neuroPsychSubDiagDrugsSubWhatDrugs"]
                ? formData["ros_neuroPsychSubDiagDrugsSubWhatDrugs"]
                : "",
              // }).required(),
              historyOf: formData["ros_neuroPsychSubDiagDrugsSubDesc"]
                ? formData["ros_neuroPsychSubDiagDrugsSubDesc"]?.includes(
                    "historyOf",
                  )
                  ? "1"
                  : ""
                : "2",
              ruleOut: formData["ros_neuroPsychSubDiagDrugsSubDesc"]
                ? formData["ros_neuroPsychSubDiagDrugsSubDesc"]?.includes(
                    "remission",
                  )
                  ? "1"
                  : ""
                : "2",
              // comment: Joi.string().allow(""),
            },
          },
          // comment: Joi.string().allow(""),

          // other : Joi.string().allow(""),
        },
      }),
    };
    let payload2024 = {};
    if (storedYear == "2024") {
      payload2024 = {
        diagnoses: {
          ...(formData?.child_conditions?.includes("anxietyDx") && {
            anxiety: {
              isAnxiety: formData?.child_conditions
                ? formData?.child_conditions?.includes("anxietyDx")
                  ? "1"
                  : ""
                : "2",
              isAnxietySubAnswer: {
                describe: {
                  active: formData["ros_neuroPsychSubDiagAnxietySubDesc"]
                    ? formData["ros_neuroPsychSubDiagAnxietySubDesc"] ==
                      "active"
                      ? "1"
                      : ""
                    : "2",
                  activeSubAnswer: {
                    type: {
                      separationAnxietyDisorder: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                          ]?.includes("separationAnxietyDisorder")
                          ? "1"
                          : ""
                        : "2",
                      generalizedAnxietyDisorder: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                          ]?.includes("generalizedAnxietyDisorder")
                          ? "1"
                          : ""
                        : "2",
                      socialAnxietyDisorder: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                          ]?.includes("socialAnxietyDisorder")
                          ? "1"
                          : ""
                        : "2",
                      panicDisorder: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                          ]?.includes("panicDisorder")
                          ? "1"
                          : ""
                        : "2",
                      ocd: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                          ]?.includes("obsessiveCompulsiveDisorder")
                          ? "1"
                          : ""
                        : "2",
                      phobic: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                          ]?.includes("phobicDisorder")
                          ? "1"
                          : ""
                        : "2",
                      ptsd: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubType"
                          ]?.includes("PTSD")
                          ? "1"
                          : ""
                        : "2",
                      // other: formData['ros_neuroPsychSubDiagAnxietySubDescActiveSubType'] ? formData['ros_neuroPsychSubDiagAnxietySubDescActiveSubType']?.includes("other") ? "1" : "" : "2",
                      comment: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeComment"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeComment"
                          ]
                        : "",
                    },
                    treatment: {
                      medication: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                          ]?.includes("medication")
                          ? "1"
                          : ""
                        : "2",
                      therapy: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                          ]?.includes("therapy")
                          ? "1"
                          : ""
                        : "2",
                      other: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                          ]?.includes("otherTreatment")
                          ? "1"
                          : ""
                        : "2",
                      otherSubAnswer: {
                        describe: formData[
                          "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatOtherSubDescText"
                        ]
                          ? formData[
                              "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatOtherSubDescText"
                            ]
                          : "",
                      },
                      noTreatment: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat"
                          ]?.includes("noTreatment")
                          ? "1"
                          : ""
                        : "2",
                      comment: formData[
                        "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatComment"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatComment"
                          ]
                        : "",
                    },
                  },
                  historyOf: formData["ros_neuroPsychSubDiagAnxietySubDesc"]
                    ? formData["ros_neuroPsychSubDiagAnxietySubDesc"]?.includes(
                        "historyOf",
                      )
                      ? "1"
                      : ""
                    : "2",
                  comment: formData[
                    "ros_neuroPsychSubDiagAnxietySubDescComment"
                  ]
                    ? formData["ros_neuroPsychSubDiagAnxietySubDescComment"]
                    : "",
                },
              },
              // comment: ,
            },
          }),
          ...(formData?.child_conditions?.includes("asthma") && {
            asthma: {
              isAsthma: formData?.child_conditions
                ? formData?.child_conditions?.includes("asthma")
                  ? "1"
                  : "2"
                : "2",
              isAsthmaSubAnswer: {
                describe: {
                  active: formData["ros_respiratoryAsthmaSubAnswerDescribe"]
                    ? formData["ros_respiratoryAsthmaSubAnswerDescribe"] ==
                      "active"
                      ? "1"
                      : "2"
                    : "2",
                  activeSubAnswer: {
                    type: {
                      useOfVentilator: formData[
                        "ros_respiratoryAsthmaIfActiveSubAnswer"
                      ]
                        ? formData[
                            "ros_respiratoryAsthmaIfActiveSubAnswer"
                          ]?.includes("useofVentilator")
                          ? "1"
                          : "2"
                        : "2",
                      inhaledOrOralSteroids: formData[
                        "ros_respiratoryAsthmaIfActiveSubAnswer"
                      ]
                        ? formData[
                            "ros_respiratoryAsthmaIfActiveSubAnswer"
                          ]?.includes("inhaledOralSteroids")
                          ? "1"
                          : "2"
                        : "2",
                      useRescueInhalerMore: formData[
                        "ros_respiratoryAsthmaIfActiveSubAnswer"
                      ]
                        ? formData[
                            "ros_respiratoryAsthmaIfActiveSubAnswer"
                          ]?.includes("useRescue")
                          ? "1"
                          : "2"
                        : "2",
                      // panicDisorder: formData['ros_respiratoryAsthmaActiveTreatmentSubAnswer'] ?  formData['ros_respiratoryAsthmaActiveTreatmentSubAnswer']?.includes("useofVentilator") ? "1" : "2" : "2",
                      none: formData["ros_respiratoryAsthmaIfActiveSubAnswer"]
                        ? formData[
                            "ros_respiratoryAsthmaIfActiveSubAnswer"
                          ]?.includes("none")
                          ? "1"
                          : "2"
                        : "2",
                      other: formData["ros_respiratoryAsthmaIfActiveSubAnswer"]
                        ? formData[
                            "ros_respiratoryAsthmaIfActiveSubAnswer"
                          ]?.includes("other")
                          ? "1"
                          : "2"
                        : "2",
                      comment: formData[
                        "ros_respiratoryAsthmaIfActiveSubAnswerComment"
                      ]
                        ? formData[
                            "ros_respiratoryAsthmaIfActiveSubAnswerComment"
                          ]
                        : "",
                    },
                    treatment: {
                      medication: formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                          ]?.includes("medication")
                          ? "1"
                          : "2"
                        : "2",
                      otherTreatment: formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                          ]?.includes("otherTreatment")
                          ? "1"
                          : "2"
                        : "2",
                      otherTreatmentSubAnswer: {
                        describe: formData[
                          "ros_respiratoryAsthmaSubOtherTreatmentSubDesc"
                        ]
                          ? formData[
                              "ros_respiratoryAsthmaSubOtherTreatmentSubDesc"
                            ]
                          : "",
                      },
                      noTreatment: formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_respiratoryAsthmaActiveTreatmentSubAnswer"
                          ]?.includes("noTreatment")
                          ? "1"
                          : "2"
                        : "2",
                      comment: formData[
                        "ros_respiratoryAsthmaActiveTreatmentSubAnswerComment"
                      ]
                        ? formData[
                            "ros_respiratoryAsthmaActiveTreatmentSubAnswerComment"
                          ]
                        : "",
                    },
                  },
                  historyOf: formData["ros_respiratoryAsthmaSubAnswerDescribe"]
                    ? formData[
                        "ros_respiratoryAsthmaSubAnswerDescribe"
                      ]?.includes("historyOf")
                      ? "1"
                      : "2"
                    : "2",
                  comment: formData[
                    "ros_respiratoryAsthmaSubAnswerDescribeComment"
                  ]
                    ? formData["ros_respiratoryAsthmaSubAnswerDescribeComment"]
                    : "",
                },
                asthmaActionPlan: {
                  isAsthmaActionPlan: formData[
                    "ros_respiratoryAsthmaCurrentExacerbation"
                  ]
                    ? formData[
                        "ros_respiratoryAsthmaCurrentExacerbation"
                      ]?.includes("1")
                      ? "1"
                      : formData[
                          "ros_respiratoryAsthmaCurrentExacerbation"
                        ]?.includes("2")
                      ? "2"
                      : ""
                    : "",
                  comment: formData[
                    "ros_respiratoryAsthmaCurrentExacerbationComment"
                  ]
                    ? formData[
                        "ros_respiratoryAsthmaCurrentExacerbationComment"
                      ]
                    : "",
                  isAsthmaActionPlanSubAnswer: {
                    describe: formData[
                      "ros_respiratoryAsthmaActionPlanDescribe"
                    ]
                      ? formData["ros_respiratoryAsthmaActionPlanDescribe"]
                      : "",
                  },
                  asthmaActionPlanNoSubAnswer: {
                    childGuardianEducated: formData?.educatedOnImportance
                      ? formData?.educatedOnImportance?.includes(
                          "asthmaactionplanoneducatedguardianandchild",
                        )
                        ? formData?.educatedOnImportance?.[1]
                        : ""
                      : "",
                    comment: formData?.educatedOnImportanceComment
                      ? formData?.educatedOnImportanceComment
                      : "",
                  },
                  //comment: needs to add
                },
              },
            },
            // comment: formData?.child_conditionsComment
            //   ? formData?.child_conditionsComment
            //   : "",
          }),

          ...(formData?.child_conditions?.includes("depressionDx") && {
            depression: {
              isDepression: formData?.child_conditions
                ? formData?.child_conditions?.includes("depressionDx")
                  ? "1"
                  : ""
                : "2",
              isDepressionSubAnswer: {
                describe: {
                  active: formData?.ros_neuroPsychSubDiagDepressDesc
                    ? formData?.ros_neuroPsychSubDiagDepressDesc?.includes(
                        "active",
                      )
                      ? "1"
                      : ""
                    : "2",
                  activeSubAnswer: {
                    type: {
                      major: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubType"
                          ]?.includes("major")
                          ? "1"
                          : ""
                        : "2",
                      majorSubAnswer: {
                        cause: formData[
                          "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubCause"
                        ]
                          ? formData[
                              "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubCause"
                            ]
                          : "",
                        episodes: {
                          single: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpi"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpi"
                              ].includes("single")
                              ? "1"
                              : "2"
                            : "",
                          recurrent: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpi"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpi"
                              ].includes("recurrent")
                              ? "1"
                              : "2"
                            : "",
                          comment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpiComment"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpiComment"
                              ]
                            : "",
                        },
                        severity: {
                          mild: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                              ]?.includes("mild")
                              ? "1"
                              : ""
                            : "2",
                          moderate: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                              ]?.includes("moderate")
                              ? "1"
                              : ""
                            : "2",
                          severeWithoutPsychotic: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                              ]?.includes("severeWithoutPsychotic")
                              ? "1"
                              : ""
                            : "2",
                          severeWithPsychotic: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                              ]?.includes("severeWithPsychotic")
                              ? "1"
                              : ""
                            : "2",
                          unspecified: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev"
                              ]?.includes("unspecified")
                              ? "1"
                              : ""
                            : "2",
                          comment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevComment"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevComment"
                              ]
                            : "",
                        },
                        underControl: {
                          isUnderControl: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubUnderCtrl"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubUnderCtrl"
                              ].includes("na")
                              ? "3"
                              : formData[
                                  "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubUnderCtrl"
                                ].includes("no")
                              ? "2"
                              : "1"
                            : "",
                          comment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubUnderCtrlComment"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubUnderCtrlComment"
                              ]
                            : "",
                        },
                        symptomFree: {
                          isSymptomFree: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSympFree"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSympFree"
                              ].includes("na")
                              ? "3"
                              : formData[
                                  "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSympFree"
                                ].includes("n0")
                              ? "2"
                              : "1"
                            : "",
                          comment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSympFreeComment"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSympFreeComment"
                              ]
                            : "",
                        },
                        select: {
                          inRemission: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelect"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelect"
                              ].includes("inRemission")
                              ? "1"
                              : "2"
                            : "",
                          partialRemission: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelect"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelect"
                              ].includes("partialRemission")
                              ? "1"
                              : "2"
                            : "",
                          fullRemission: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelect"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelect"
                              ].includes("fullRemission")
                              ? "1"
                              : "2"
                            : "",
                          NA: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelect"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelect"
                              ].includes("NA")
                              ? "1"
                              : "2"
                            : "",
                          comment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectComment"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectComment"
                              ]
                            : "",
                        },
                      },
                      chronic: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubType"
                          ]?.includes("chronic")
                          ? "1"
                          : ""
                        : "2",

                      manic: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubType"
                          ].includes("manic")
                          ? "1"
                          : ""
                        : "2",

                      manicSubAnswer: {
                        severity: {
                          mild: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev"
                              ]?.includes("mild")
                              ? "1"
                              : ""
                            : "2",
                          moderatelySevere: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev"
                              ]?.includes("moderatelySevere")
                              ? "1"
                              : ""
                            : "2",
                          moderate: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev"
                              ]?.includes("moderate")
                              ? "1"
                              : ""
                            : "2",
                          severe: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev"
                              ]?.includes("severe")
                              ? "1"
                              : ""
                            : "2",
                          unknown: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev"
                              ]?.includes("unknown")
                              ? "1"
                              : ""
                            : "2",
                          comment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevComment"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevComment"
                              ]
                            : "",
                        },
                      },

                      depressionUnspecified: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubType"
                          ]?.includes("depressionUnspecified")
                          ? "1"
                          : ""
                        : "2",
                      depressionUnspecifiedSubAnswer: {
                        severity: {
                          mild: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev"
                              ]?.includes("mild")
                              ? "1"
                              : ""
                            : "2",
                          moderatelySevere: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev"
                              ]?.includes("moderatelySevere")
                              ? "1"
                              : ""
                            : "2",
                          moderate: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev"
                              ]?.includes("moderate")
                              ? "1"
                              : ""
                            : "2",
                          severe: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev"
                              ]?.includes("severe")
                              ? "1"
                              : ""
                            : "2",
                          unknown: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev"
                              ]?.includes("unknown")
                              ? "1"
                              : ""
                            : "2",
                          comment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevComment"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevComment"
                              ]
                            : "",
                        },
                      },
                      other: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubType"
                          ]?.includes("other")
                          ? "1"
                          : ""
                        : "2",
                      otherSubAnswer: {
                        severity: {
                          mild: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev"
                              ]?.includes("mild")
                              ? "1"
                              : ""
                            : "2",
                          moderatelySevere: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev"
                              ]?.includes("moderatelySevere")
                              ? "1"
                              : ""
                            : "2",
                          moderate: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev"
                              ]?.includes("moderate")
                              ? "1"
                              : ""
                            : "2",
                          severe: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev"
                              ]?.includes("severe")
                              ? "1"
                              : ""
                            : "2",
                          unknown: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev"
                              ]?.includes("unknown")
                              ? "1"
                              : ""
                            : "2",
                          comment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevComment"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevComment"
                              ]
                            : "",
                        },
                      },
                      unknown: formData[
                        "ros_neuroPsychSubDiagDepressDescActiveSubType"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubType"
                          ]?.includes("unknown")
                          ? "1"
                          : ""
                        : "2",
                      unknownSubAnswer: {
                        treatment: {
                          medication: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                              ]?.includes("medication")
                              ? "1"
                              : ""
                            : "2",
                          therapy: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                              ]?.includes("therapy")
                              ? "1"
                              : ""
                            : "2",
                          otherTreatment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                              ]?.includes("other")
                              ? "1"
                              : ""
                            : "2",
                          otherTreatmentSubAnswer: {
                            describe: formData[
                              "ros_neuroPsychSubDiagDepressDescActiveSubTreatOtherSubDescText"
                            ]
                              ? formData[
                                  "ros_neuroPsychSubDiagDepressDescActiveSubTreatOtherSubDescText"
                                ]
                              : "",
                          },
                          noTreatment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTreat"
                              ]?.includes("noTreatment")
                              ? "1"
                              : ""
                            : "2",
                          comment: formData[
                            "ros_neuroPsychSubDiagDepressDescActiveSubTreatOtherSubDescText"
                          ]
                            ? formData[
                                "ros_neuroPsychSubDiagDepressDescActiveSubTreatOtherSubDescText"
                              ]
                            : "",
                        },
                      },
                    },
                  },
                  historyOf: formData?.ros_neuroPsychSubDiagDepressDesc
                    ? formData?.ros_neuroPsychSubDiagDepressDesc?.includes(
                        "historyOf",
                      )
                      ? "1"
                      : ""
                    : "2",
                  comment: formData["ros_neuroPsychSubDiagDepressDescComment"]
                    ? formData["ros_neuroPsychSubDiagDepressDescComment"]
                    : "",
                },
              },
            },
          }),

          ...(formData?.child_conditions?.includes("diabetes") && {
            diabetes: {
              isDiabetes: formData["child_conditions"]
                ? formData["child_conditions"]?.includes("diabetes")
                  ? "1"
                  : "2"
                : "2",
              isDiabetesSubAnswer: {
                describe: {
                  active:
                    formData?.ros_endocrineIsEndocrineSubdiabetesSubdescribeSub
                      ? formData?.ros_endocrineIsEndocrineSubdiabetesSubdescribeSub?.includes(
                          "active",
                        )
                        ? "1"
                        : "2"
                      : "2",
                  activeSubAnswer: {
                    type: {
                      type1: formData[
                        "ros_endocrineIsEndocrineSubdiabetesSubtype"
                      ]
                        ? formData[
                            "ros_endocrineIsEndocrineSubdiabetesSubtype"
                          ]?.includes("type1")
                          ? "1"
                          : "2"
                        : "2",
                      type1Point5: formData[
                        "ros_endocrineIsEndocrineSubdiabetesSubtype"
                      ]
                        ? formData[
                            "ros_endocrineIsEndocrineSubdiabetesSubtype"
                          ]?.includes("type15")
                          ? "1"
                          : "2"
                        : "2",
                      type2: formData[
                        "ros_endocrineIsEndocrineSubdiabetesSubtype"
                      ]
                        ? formData[
                            "ros_endocrineIsEndocrineSubdiabetesSubtype"
                          ]?.includes("type2")
                          ? "1"
                          : "2"
                        : "2",
                      comment: formData[
                        "ros_endocrineIsEndocrineSubdiabetesSubtypeComment"
                      ]
                        ? formData[
                            "ros_endocrineIsEndocrineSubdiabetesSubtypeComment"
                          ]
                        : "",
                    },
                    treatment: {
                      medication: formData[
                        "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                          ]?.includes("medication")
                          ? "1"
                          : "2"
                        : "2",
                      otherTreatment: formData[
                        "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                          ]?.includes("medication")
                          ? "1"
                          : "2"
                        : "2",
                      otherTreatmentSubAnswer: {
                        describe: formData[
                          "ros_respiratoryDiabetsTreatSubOtherTreatment"
                        ]
                          ? formData[
                              "ros_respiratoryDiabetsTreatSubOtherTreatment"
                            ]
                          : "",
                      },
                      noTreatment: formData[
                        "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_respiratoryDiabetsActiveTreatmentSubAnswer"
                          ]?.includes("medication")
                          ? "1"
                          : "2"
                        : "2",
                      comment: formData[
                        "ros_respiratoryDiabetsActiveTreatmentSubAnswerComment"
                      ]
                        ? formData[
                            "ros_respiratoryDiabetsActiveTreatmentSubAnswerComment"
                          ]
                        : "",
                    },
                  },
                  historyOf:
                    formData?.ros_endocrineIsEndocrineSubdiabetesSubdescribeSub
                      ? formData?.ros_endocrineIsEndocrineSubdiabetesSubdescribeSub?.includes(
                          "historyOf",
                        )
                        ? "1"
                        : "2"
                      : "2",
                  comment: formData[
                    "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment"
                  ]
                    ? formData[
                        "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment"
                      ]
                    : "",
                },
                mostRecentHba1cValue: formData[
                  "ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue"
                ]
                  ? formData[
                      "ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue"
                    ]
                  : "",
                date: formData["ros_endocrineIsEndocrineSubdiabetesSubandDate"]
                  ? formData["ros_endocrineIsEndocrineSubdiabetesSubandDate"]
                  : "",
                testChildBloodSugar: {
                  isTestChildBloodSugar: formData[
                    "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar"
                  ]
                    ? formData[
                        "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar"
                      ].includes("1")
                      ? "1"
                      : formData[
                          "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar"
                        ].includes("2")
                      ? "2"
                      : ""
                    : "",
                  isTestChildBloodSugarSubAnswer: {
                    describe: formData[
                      "ros_respiratoryDiabetsSubOtherTreatmentSubDesc"
                    ]
                      ? formData[
                          "ros_respiratoryDiabetsSubOtherTreatmentSubDesc"
                        ]
                      : "",
                  },
                },
              },
              comment: formData[
                "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment"
              ]
                ? formData[
                    "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment"
                  ]
                : "",
            },
          }),

          ...(formData?.child_conditions?.includes("sickleCellDisease") && {
            sickleCellDisease: {
              isSickleCellDisease: formData["child_conditions"]
                ? formData["child_conditions"]?.includes("sickleCellDisease")
                  ? "1"
                  : "2"
                : "2",
              isSickleCellDiseaseSubAnswer: {
                describe: {
                  active: formData[
                    "ros_hematologySickleCellDiseaseSubAnswerDescribe"
                  ]
                    ? formData[
                        "ros_hematologySickleCellDiseaseSubAnswerDescribe"
                      ]?.includes("active")
                      ? "1"
                      : ""
                    : "2",
                  activeSubAnswer: {
                    familyHistory: formData[
                      "ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswer"
                    ]
                      ? formData[
                          "ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswer"
                        ]?.[0]
                      : "2",
                  },
                  treatment: {
                    medication: formData[
                      "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                    ]
                      ? formData[
                          "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                        ]?.includes("medication")
                        ? "1"
                        : ""
                      : "2",
                    otherTreatment: formData[
                      "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                    ]
                      ? formData[
                          "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                        ]?.includes("otherTreatment")
                        ? "1"
                        : ""
                      : "2",
                    otherTreatmentSubAnswer: {
                      describe: formData[
                        "ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc"
                      ]
                        ? formData[
                            "ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc"
                          ]
                        : "",
                    },
                    noTreatment: formData[
                      "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                    ]
                      ? formData[
                          "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer"
                        ]?.includes("noTreatment")
                        ? "1"
                        : ""
                      : "2",
                    comment: formData[
                      "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment"
                    ]
                      ? formData[
                          "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment"
                        ]
                      : "",
                  },
                },
                historyOf: formData[
                  "ros_hematologySickleCellDiseaseSubAnswerDescribe"
                ]
                  ? formData[
                      "ros_hematologySickleCellDiseaseSubAnswerDescribe"
                    ]?.includes("historyOf")
                    ? "1"
                    : ""
                  : "2",
                comment: formData[
                  "ros_hematologySickleCellDiseaseSubAnswerDescribeComment"
                ]
                  ? formData[
                      "ros_hematologySickleCellDiseaseSubAnswerDescribeComment"
                    ]
                  : "",
              },
              comment: formData[
                "ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment"
              ]
                ? formData[
                    "ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment"
                  ]
                : "",
            },
          }),

          ...(formData?.child_conditions?.includes("hemophilia") && {
            hemophilia: {
              isHemophilia: formData["child_conditions"]
                ? formData["child_conditions"]?.includes("hemophilia")
                  ? "1"
                  : ""
                : "2",
              isHemophiliaSubAnswer: {
                describe: {
                  active: formData["ros_hematologyHemophiliaSubAnswerDescribe"]
                    ? formData[
                        "ros_hematologyHemophiliaSubAnswerDescribe"
                      ]?.includes("active")
                      ? "1"
                      : ""
                    : "2",
                  activeSubAnswer: {
                    treatment: {
                      medication: formData[
                        "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                          ]?.includes("medication")
                          ? "1"
                          : ""
                        : "2",
                      otherTreatment: formData[
                        "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                          ]?.includes("otherTreatment")
                          ? "1"
                          : ""
                        : "2",
                      otherTreatmentSubAnswer: {
                        describe: formData[
                          "ros_hematologyHemophiliaSubOtherTreatmentSubDesc"
                        ]
                          ? formData[
                              "ros_hematologyHemophiliaSubOtherTreatmentSubDesc"
                            ]
                          : "",
                      },
                      noTreatment: formData[
                        "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyHemophiliaActiveTreatmentSubAnswer"
                          ]?.includes("noTreatment")
                          ? "1"
                          : ""
                        : "2",
                      comment: formData[
                        "ros_hematologyHemophiliaActiveTreatmentSubAnswerComment"
                      ]
                        ? formData[
                            "ros_hematologyHemophiliaActiveTreatmentSubAnswerComment"
                          ]
                        : "",
                    },
                  },
                  historyOf: formData[
                    "ros_hematologyHemophiliaSubAnswerDescribe"
                  ]
                    ? formData[
                        "ros_hematologyHemophiliaSubAnswerDescribe"
                      ]?.includes("historyOf")
                      ? "1"
                      : ""
                    : "2",
                  comment: formData[
                    "ros_hematologyHemophiliaSubAnswerDescribeComment"
                  ]
                    ? formData[
                        "ros_hematologyHemophiliaSubAnswerDescribeComment"
                      ]
                    : "",
                },
              },
            },
          }),

          ...(formData?.child_conditions?.includes(
            "attentionDeficitDisorder",
          ) && {
            attentionDeficitDisorder: {
              isAttentionDeficitDisorder: formData["child_conditions"]
                ? formData["child_conditions"]?.includes(
                    "attentionDeficitDisorder",
                  )
                  ? "1"
                  : ""
                : "2",
              isAttentionDeficitDisorderSubAnswer: {
                describe: {
                  active: formData["ros_hematologyADDSubAnswerDescribe"]
                    ? formData["ros_hematologyADDSubAnswerDescribe"]?.includes(
                        "active",
                      )
                      ? "1"
                      : ""
                    : "2",
                  activeSubAnswer: {
                    type: {
                      inAttentive: formData[
                        "ros_hematologyADDActiveTypeSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyADDActiveTypeSubAnswer"
                          ]?.includes("inattentive")
                          ? "1"
                          : ""
                        : "2",
                      hyperActive: formData[
                        "ros_hematologyADDActiveTypeSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyADDActiveTypeSubAnswer"
                          ]?.includes("hyperactive")
                          ? "1"
                          : ""
                        : "2",
                      combined: formData["ros_hematologyADDActiveTypeSubAnswer"]
                        ? formData[
                            "ros_hematologyADDActiveTypeSubAnswer"
                          ]?.includes("combined")
                          ? "1"
                          : ""
                        : "2",
                      other: formData["ros_hematologyADDActiveTypeSubAnswer"]
                        ? formData[
                            "ros_hematologyADDActiveTypeSubAnswer"
                          ]?.includes("other")
                          ? "1"
                          : ""
                        : "2",
                      unspecified: formData[
                        "ros_hematologyADDActiveTypeSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyADDActiveTypeSubAnswer"
                          ]?.includes("unspecified")
                          ? "1"
                          : ""
                        : "2",
                      comment: formData[
                        "ros_hematologyADDActiveTypeSubAnswerComment"
                      ]
                        ? formData[
                            "ros_hematologyADDActiveTypeSubAnswerComment"
                          ]
                        : "",
                    },
                    treatment: {
                      medication: formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyADDActiveTreatmentSubAnswer"
                          ]?.includes("medication")
                          ? "1"
                          : ""
                        : "2",
                      otherTreatment: formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyADDActiveTreatmentSubAnswer"
                          ]?.includes("otherTreatment")
                          ? "1"
                          : ""
                        : "2",
                      otherTreatmentSubAnswer: {
                        describe: formData[
                          "ros_hematologyADDSubOtherTreatmentSubDesc"
                        ]
                          ? formData[
                              "ros_hematologyADDSubOtherTreatmentSubDesc"
                            ]
                          : "",
                      },
                      noTreatment: formData[
                        "ros_hematologyADDActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyADDActiveTreatmentSubAnswer"
                          ]?.includes("noTreatment")
                          ? "1"
                          : ""
                        : "2",
                      comment: formData[
                        "ros_hematologyADDActiveTreatmentSubAnswerComment"
                      ]
                        ? formData[
                            "ros_hematologyADDActiveTreatmentSubAnswerComment"
                          ]
                        : "",
                    },
                  },
                  historyOf: formData["ros_hematologyADDSubAnswerDescribe"]
                    ? formData["ros_hematologyADDSubAnswerDescribe"]?.includes(
                        "historyOf",
                      )
                      ? "1"
                      : ""
                    : "2",
                  comment: formData["ros_hematologyADDSubAnswerDescribeComment"]
                    ? formData["ros_hematologyADDSubAnswerDescribeComment"]
                    : "",
                },
              },
            },
          }),

          ...(formData?.child_conditions?.includes(
            "diagnosisofDevelopmentalDisability",
          ) && {
            developmentalDisability: {
              isDevelopementalDisabilityDisorder: formData["child_conditions"]
                ? formData["child_conditions"]?.includes(
                    "diagnosisofDevelopmentalDisability",
                  )
                  ? "1"
                  : ""
                : "2",
              isDevelopementalDisabilityDisorderSubAnswer: {
                describe: {
                  active: formData["ros_hematologyDDDSubAnswerDescribe"]
                    ? formData["ros_hematologyDDDSubAnswerDescribe"]?.includes(
                        "active",
                      )
                      ? "1"
                      : ""
                    : "2",
                  activeSubAnswer: {
                    // type: {
                    //   inAttentive: ,
                    //   hyperActive: ,
                    //   combined: ,
                    //   other: ,
                    //   unspecified: ,
                    //   // comment: "",
                    // },
                    treatment: {
                      physicalTherapy: formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyDDDActiveTreatmentSubAnswer"
                          ]?.includes("physicalTherapy")
                          ? "1"
                          : ""
                        : "2",
                      occupationalTherapy: formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyDDDActiveTreatmentSubAnswer"
                          ]?.includes("occupationalTherapy")
                          ? "1"
                          : ""
                        : "2",
                      speechTherapy: formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyDDDActiveTreatmentSubAnswer"
                          ]?.includes("speechTherapy")
                          ? "1"
                          : ""
                        : "2",
                      behaviouralTherapy: formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyDDDActiveTreatmentSubAnswer"
                          ]?.includes("behavioralTherapy")
                          ? "1"
                          : ""
                        : "2",
                      educationalAssistance: formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyDDDActiveTreatmentSubAnswer"
                          ]?.includes("educationalAssistance")
                          ? "1"
                          : ""
                        : "2",
                      other: formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswer"
                      ]
                        ? formData[
                            "ros_hematologyDDDActiveTreatmentSubAnswer"
                          ]?.includes("other")
                          ? "1"
                          : ""
                        : "2",
                      otherSubAnswer: {
                        describe: formData[
                          "ros_hematologyDDDSubOtherTreatmentSubDesc"
                        ]
                          ? formData[
                              "ros_hematologyDDDSubOtherTreatmentSubDesc"
                            ]
                          : "",
                      },
                      comment: formData[
                        "ros_hematologyDDDActiveTreatmentSubAnswerComment"
                      ]
                        ? formData[
                            "ros_hematologyDDDActiveTreatmentSubAnswerComment"
                          ]
                        : "",
                    },
                  },
                  historyOf: formData["ros_hematologyDDDSubAnswerDescribe"]
                    ? formData["ros_hematologyDDDSubAnswerDescribe"]?.includes(
                        "historyOf",
                      )
                      ? "1"
                      : ""
                    : "2",
                  comment: formData["ros_hematologyDDDSubAnswerDescribeComment"]
                    ? formData["ros_hematologyDDDSubAnswerDescribeComment"]
                    : "",
                },
                comment: formData["ros_hematologyDDDSubActiveSubDesc"]
                  ? formData["ros_hematologyDDDSubActiveSubDesc"]
                  : "",
              },
            },
          }),

          ...(formData?.child_conditions?.includes("substanceUse") && {
            substanceUse: {
              isSubstanceUse: formData["child_conditions"]
                ? formData["child_conditions"]?.includes("substanceUse")
                  ? "1"
                  : ""
                : "2",
              isSubstanceUseSubAnswer: {
                describe: {
                  active: formData["ros_neuroPsychSubDiagDrugsSubDesc"]
                    ? formData["ros_neuroPsychSubDiagDrugsSubDesc"]?.includes(
                        "active",
                      )
                      ? "1"
                      : ""
                    : "2",
                  type: {
                    episodic: formData["ros_neuroPsychSubDiagDrugsSubType"]
                      ? formData["ros_neuroPsychSubDiagDrugsSubType"]?.includes(
                          "episodic",
                        )
                        ? "1"
                        : ""
                      : "2",
                    continuous: formData["ros_neuroPsychSubDiagDrugsSubType"]
                      ? formData["ros_neuroPsychSubDiagDrugsSubType"]?.includes(
                          "continuous",
                        )
                        ? "1"
                        : ""
                      : "2",
                    remission: formData["ros_neuroPsychSubDiagDrugsSubType"]
                      ? formData["ros_neuroPsychSubDiagDrugsSubType"]?.includes(
                          "remission",
                        )
                        ? "1"
                        : ""
                      : "2",
                    comment: formData[
                      "ros_neuroPsychSubDiagDrugsSubTypeComment"
                    ]
                      ? formData["ros_neuroPsychSubDiagDrugsSubTypeComment"]
                      : "",
                  },

                  supportedBy: {
                    useOfRecreationalDrugs: formData[
                      "ros_neuroPsychSubDiagDrugsSubSupp"
                    ]
                      ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                          "recreationalDrugs",
                        )
                        ? "1"
                        : ""
                      : "2",
                    chronicUseOfPainMedication: formData[
                      "ros_neuroPsychSubDiagDrugsSubSupp"
                    ]
                      ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                          "chronicUseOfPainMedication",
                        )
                        ? "1"
                        : ""
                      : "2",
                    historyOfHospitalization: formData[
                      "ros_neuroPsychSubDiagDrugsSubSupp"
                    ]
                      ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                          "historyOfHospitalization",
                        )
                        ? "1"
                        : ""
                      : "2",
                    historyOfOutPatientTreatment: formData[
                      "ros_neuroPsychSubDiagDrugsSubSupp"
                    ]
                      ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                          "historyOutpatientTreatment",
                        )
                        ? "1"
                        : ""
                      : "2",
                    withDrawlSymptoms: formData[
                      "ros_neuroPsychSubDiagDrugsSubSupp"
                    ]
                      ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                          "withdrawalSymptoms",
                        )
                        ? "1"
                        : ""
                      : "2",
                    abnormalAffect: formData[
                      "ros_neuroPsychSubDiagDrugsSubSupp"
                    ]
                      ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                          "abnormalAffect",
                        )
                        ? "1"
                        : ""
                      : "2",
                    other: formData["ros_neuroPsychSubDiagDrugsSubSupp"]
                      ? formData["ros_neuroPsychSubDiagDrugsSubSupp"]?.includes(
                          "other",
                        )
                        ? "1"
                        : ""
                      : "2",
                    otherSubAnswer: {
                      describe: formData[
                        "ros_neuroPsychSubDiagDrugsSubSuppOtherSubDesc"
                      ]
                        ? formData[
                            "ros_neuroPsychSubDiagDrugsSubSuppOtherSubDesc"
                          ]
                        : "",
                    },
                    comment: formData[
                      "ros_neuroPsychSubDiagDrugsSubSuppComment"
                    ]
                      ? formData["ros_neuroPsychSubDiagDrugsSubSuppComment"]
                      : "",
                  },
                  historyOfPsychosis: {
                    isHistoryOfPsychosis: formData[
                      "ros_neuroPsychSubDiagDrugsSubHistoryPsych"
                    ]
                      ? formData["ros_neuroPsychSubDiagDrugsSubHistoryPsych"]
                      : "",
                    whatDrug: formData["ros_neuroPsychSubDiagDrugsSubWhatDrugs"]
                      ? formData["ros_neuroPsychSubDiagDrugsSubWhatDrugs"]
                      : "",
                  },
                },
                historyOf: formData["ros_neuroPsychSubDiagDrugsSubDesc"]
                  ? formData["ros_neuroPsychSubDiagDrugsSubDesc"]?.includes(
                      "historyOf",
                    )
                    ? "1"
                    : ""
                  : "2",
                ruleOut: formData["ros_neuroPsychSubDiagDrugsSubDesc"]
                  ? formData["ros_neuroPsychSubDiagDrugsSubDesc"]?.includes(
                      "ruleOut",
                    )
                    ? "1"
                    : ""
                  : "2",
                comment: formData["ros_neuroPsychSubDiagDrugsSubDescComment"]
                  ? formData["ros_neuroPsychSubDiagDrugsSubDescComment"]
                  : "",
              },
              comment: formData[
                "ros_neuroPsychSubDiagDrugsSubHistoryPsychComment"
              ]
                ? formData["ros_neuroPsychSubDiagDrugsSubHistoryPsychComment"]
                : "",
            },
          }),
          other: formData["child_conditions"]
            ? formData["child_conditions"]?.includes("otherVal")
              ? "1"
              : ""
            : "2",
        },
        overPastTwoWeeks: {
          littleInterestOrPleasure: {
            notAtAll: formData["ros_over2WeeksLittleIntrst"]
              ? formData["ros_over2WeeksLittleIntrst"].includes("notAtAll")
                ? "1"
                : "2"
              : "",
            severalDays: formData["ros_over2WeeksLittleIntrst"]
              ? formData["ros_over2WeeksLittleIntrst"].includes("severalDays")
                ? "1"
                : "2"
              : "",
            moreThanHalfTheDays: formData["ros_over2WeeksLittleIntrst"]
              ? formData["ros_over2WeeksLittleIntrst"].includes(
                  "moreThanHalfTheDays",
                )
                ? "1"
                : "2"
              : "",
            nearlyEveryDay: formData["ros_over2WeeksLittleIntrst"]
              ? formData["ros_over2WeeksLittleIntrst"].includes(
                  "nearlyEveryDay",
                )
                ? "1"
                : "2"
              : "",
            comment: formData["ros_over2WeeksLittleIntrstComment"]
              ? formData["ros_over2WeeksLittleIntrstComment"]
              : "",
          },

          feelingDownDepressed: {
            notAtAll: formData["ros_over2WeeksFeelDepressed"]
              ? formData["ros_over2WeeksFeelDepressed"].includes("notAtAll")
                ? "1"
                : "2"
              : "",
            severalDays: formData["ros_over2WeeksFeelDepressed"]
              ? formData["ros_over2WeeksFeelDepressed"].includes("severalDays")
                ? "1"
                : "2"
              : "",
            moreThanHalfTheDays: formData["ros_over2WeeksFeelDepressed"]
              ? formData["ros_over2WeeksFeelDepressed"].includes(
                  "moreThanHalfTheDays",
                )
                ? "1"
                : "2"
              : "",
            nearlyEveryDay: formData["ros_over2WeeksFeelDepressed"]
              ? formData["ros_over2WeeksFeelDepressed"].includes(
                  "nearlyEveryDay",
                )
                ? "1"
                : "2"
              : "",
            comment: formData["ros_over2WeeksFeelDepressedComment"]
              ? formData["ros_over2WeeksFeelDepressedComment"]
              : "",
          },
          phq2score: {
            lessThanThree: formData["ros_over2WeeksPhq"]
              ? formData["ros_over2WeeksPhq"].includes("lessThanThree")
                ? "1"
                : "2"
              : "",
            threeOrMore: formData["ros_over2WeeksPhq"]
              ? formData["ros_over2WeeksPhq"].includes("threeOrMore")
                ? "1"
                : "2"
              : "",
            threeOrMoreSubAnswer: {
              depressionScreening: {
                havingLittleInterest: {
                  notAtAll: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst"
                      ].includes("notAtAll")
                      ? "1"
                      : "2"
                    : "",
                  several: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst"
                      ].includes("severalDays")
                      ? "1"
                      : "2"
                    : "",
                  moreThanHalfTheDays: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst"
                      ].includes("moreThanHalfTheDays")
                      ? "1"
                      : "2"
                    : "",
                  nearlyEveryDay: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst"
                      ].includes("nearlyEveryDay")
                      ? "1"
                      : "2"
                    : "",
                  comment: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstComment"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstComment"
                      ]
                    : "",
                },
                feelingDownDepressed: {
                  notAtAll: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress"
                      ].includes("notAtAll")
                      ? "1"
                      : "2"
                    : "",
                  several: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress"
                      ].includes("severalDays")
                      ? "1"
                      : "2"
                    : "",
                  moreThanHalfTheDays: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress"
                      ].includes("moreThanHalfTheDays")
                      ? "1"
                      : "2"
                    : "",
                  nearlyEveryDay: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress"
                      ].includes("nearlyEveryDay")
                      ? "1"
                      : "2"
                    : "",
                  comment: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressComment"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressComment"
                      ]
                    : "",
                },
                troubleFalling: {
                  notAtAll: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall"
                      ].includes("notAtAll")
                      ? "1"
                      : "2"
                    : "",
                  several: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall"
                      ].includes("severalDays")
                      ? "1"
                      : "2"
                    : "",
                  moreThanHalfTheDays: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall"
                      ].includes("moreThanHalfTheDays")
                      ? "1"
                      : "2"
                    : "",
                  nearlyEveryDay: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall"
                      ].includes("nearlyEveryDay")
                      ? "1"
                      : "2"
                    : "",
                  comment: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallComment"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallComment"
                      ]
                    : "",
                },
                feelingTired: {
                  notAtAll: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired"
                      ].includes("notAtAll")
                      ? "1"
                      : "2"
                    : "",
                  several: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired"
                      ].includes("severalDays")
                      ? "1"
                      : "2"
                    : "",
                  moreThanHalfTheDays: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired"
                      ].includes("moreThanHalfTheDays")
                      ? "1"
                      : "2"
                    : "",
                  nearlyEveryDay: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired"
                      ].includes("nearlyEveryDay")
                      ? "1"
                      : "2"
                    : "",
                  comment: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredComment"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredComment"
                      ]
                    : "",
                },
                poorAppetite: {
                  notAtAll: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite"
                      ].includes("notAtAll")
                      ? "1"
                      : "2"
                    : "",
                  several: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite"
                      ].includes("severalDays")
                      ? "1"
                      : "2"
                    : "",
                  moreThanHalfTheDays: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite"
                      ].includes("moreThanHalfTheDays")
                      ? "1"
                      : "2"
                    : "",
                  nearlyEveryDay: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite"
                      ].includes("nearlyEveryDay")
                      ? "1"
                      : "2"
                    : "",
                  comment: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteComment"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteComment"
                      ]
                    : "",
                },
                feelingBadAboutYourself: {
                  notAtAll: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad"
                      ].includes("notAtAll")
                      ? "1"
                      : "2"
                    : "",
                  several: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad"
                      ].includes("severalDays")
                      ? "1"
                      : "2"
                    : "",
                  moreThanHalfTheDays: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad"
                      ].includes("moreThanHalfTheDays")
                      ? "1"
                      : "2"
                    : "",
                  nearlyEveryDay: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad"
                      ].includes("nearlyEveryDay")
                      ? "1"
                      : "2"
                    : "",
                  comment: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadComment"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadComment"
                      ]
                    : "",
                },
                troubleConcentratingOnThings: {
                  notAtAll: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc"
                      ].includes("notAtAll")
                      ? "1"
                      : "2"
                    : "",
                  several: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc"
                      ].includes("severalDays")
                      ? "1"
                      : "2"
                    : "",
                  moreThanHalfTheDays: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc"
                      ].includes("moreThanHalfTheDays")
                      ? "1"
                      : "2"
                    : "",
                  nearlyEveryDay: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc"
                      ].includes("nearlyEveryDay")
                      ? "1"
                      : "2"
                    : "",
                  comment: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcComment"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcComment"
                      ]
                    : "",
                },
                movingOrSpeaking: {
                  notAtAll: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak"
                      ].includes("notAtAll")
                      ? "1"
                      : "2"
                    : "",
                  several: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak"
                      ].includes("severalDays")
                      ? "1"
                      : "2"
                    : "",
                  moreThanHalfTheDays: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak"
                      ].includes("moreThanHalfTheDays")
                      ? "1"
                      : "2"
                    : "",
                  nearlyEveryDay: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak"
                      ].includes("nearlyEveryDay")
                      ? "1"
                      : "2"
                    : "",
                  comment: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakComment"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakComment"
                      ]
                    : "",
                },
                hurtingYourself: {
                  notAtAll: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself"
                      ].includes("notAtAll")
                      ? "1"
                      : "2"
                    : "",
                  several: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself"
                      ].includes("severalDays")
                      ? "1"
                      : "2"
                    : "",
                  moreThanHalfTheDays: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself"
                      ].includes("moreThanHalfTheDays")
                      ? "1"
                      : "2"
                    : "",
                  nearlyEveryDay: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself"
                      ].includes("nearlyEveryDay")
                      ? "1"
                      : "2"
                    : "",
                  comment: formData[
                    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfComment"
                  ]
                    ? formData[
                        "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfComment"
                      ]
                    : "",
                },
                difficultProblems: {
                  notDifficultAtAll: "",
                  someWhatDifficult: "",
                  veryDifficult: "",
                  extremelyDifficult: "",
                  other: "",
                  notApplicable: "",
                  comment: "",
                },
                phq9score: formData["ros_over2WeeksPhq9"]
                  ? formData["ros_over2WeeksPhq9"]
                  : formData["ros_over2WeeksPhq9Score"]
                  ? formData["ros_over2WeeksPhq9Score"]
                  : "",
                comment: formData[
                  "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment"
                ]
                  ? formData[
                      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment"
                    ]
                  : "",
              },
            },
            phq2score: formData["ros_over2WeeksPhq"]
              ? formData["ros_over2WeeksPhq"].includes("lessThanThree")
                ? "lessThanThree"
                : formData["ros_over2WeeksPhq"].includes("threeOrMore")
                ? "threeOrMore"
                : ""
              : "",
          },
        },
        comment: formData["child_conditionsComment"]
          ? formData["child_conditionsComment"]
          : "",
      };
    }
    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,officeName
        data: {
          name: "Assessment of Diagnosis",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };

  return (
    <>
      <UserContext.Provider
        value={{
          user,
          setUser,
          sidebarData,
          setSidebarData,
          clientData,
          setClientData,
          userCategories,
          handleClientDataChange,
          handleClientDataSubmit,
          setCurrentColor,
          currentColor,
          selectedTimeZone,
          setSelectedTimeZone,
          assessmentData,
          updateAssessmentData,
          getAssessmentData: () => getAssessmentData,
          // getAssessmentData,
          setGetAssessmentData,
          getAssessment,
          handleSelectionDataSubmit,
          handleSelectionDataChange,
          handleBadgeClick,
          isDialogOpen,
          setIsDialogOpen,
          programTypesOptions,
          lobTypesOptions,
          hasLoggedIn,
          setHasLoggedIn,
          roles,
          updateRoles,
          memberIdForFormNames,
          _memberIdForQueryForm,
          memberDatasForNames,
          apiResponseData,
          setApiResponseData,
          setSelectedClientIdContext,
          selectedClientIdContext,
          setSelectedMultipleRowId,
          selectedMultipleRowId,
          lobOptionData,
          setLobOptionData,
          clinicianDataList,
          setClinicianDataList,
          hraStatusData,
          setHraStatusData,
          visitTypeOptionsData,
          setVisitTypeOptionsData,
          selectedTimeZoneUtc,
          setSelectedTimeZoneUtc,
          _setMemberIdForQueryForm,
          autoSaveRosData,
          setAutoSaveRosData,
          autoSavePainData,
          setAutoSavePainData,
          autoSaveCovidScreeningData,
          setAutoSaveCovidScreeningData,
          autoSaveSelfAssessmentData,
          setAutoSaveSelfAssessmentData,
          autoSaveFamilyHistoryData,
          setAutoSaveFamilyHistoryData,
          autoSaveMedicalHistory,
          setAutoSaveMedicalHistory,
          autoSaveActivitiesData,
          setAutoSaveActivitiesData,
          autoSaveVitalData,
          setAutoSaveVitalData,
          autoSaveMinicogData,
          setAutoSaveMiniCogData,
          autoSaveScreeningNeeded,
          setAutoSaveScreeningNeeded,
          autoSaveExamReviewData,
          setAutoSaveExamReviewData,
          autoSaveHomeSafetyData,
          setAutoSaveHomeSafety,
          autoSavePatientData,
          setAutoSavePatientData,
          autoSavePreventiveCareData,
          setAutoSavePreventiveCareData,
          autoSaveAllergiesData,
          setAutoSaveAllergiesData,
          autoSaveDemographicsData,
          setAutoSaveDemographicsData,
          autoSavePrevDocData,
          setAutoSavePrevDocData,
          currSection,
          setCurrSection,
          toasterMessage,
          setToasterMessage,
          toasterSeverity,
          setToasterSeverity,
          toasterOpen,
          setToasterOpen,
          saveSectionFormData,
          sideBarSectionDisable,
          setSideBarSectionDisable,
          autoSavePedsDemographicsData,
          setAutoSavePedsDemographicsData,
          autoSavePedsAssessInfo,
          setAutoSavePedsAssessInfo,
          autoSavePedsPreventativeCare,
          setAutoSavePedsPreventativeCare,
          autoSavePedsSocialSafty,
          setAutoSavePedsSocialSafty,
          setAutoSavePedsVital,
          autoSavePedsVital,
          autoSavePedsPatientSumm,
          setAutoSavePedsPatientSumm,
          autoSavepedsallergiesRowData,
          setAutoSavePedsAllergiesRowData,
          autoSavefirstLevelCommentObj,
          setAutoSavePedsFirstLevelCommentObj,
          autoSavecounterMedicationsRowData,
          setAutoSavePedsCounterMedicationsRowData,
          autoSavesecondLevelTextFieldAnswer,
          setAutoSavePedsSecondLevelTextFieldAnswer,
          autoSavecounterMedicationsRowDataQues15,
          setAutoSavePedsCounterMedicationsRowDataQues15,
          autoSavesecondLevelCommentObj,
          setAutoSavePedsSecondLevelCommentObj,
          autoSavePedsAssessmentofDiagnosis,
          setAutoSavePedsAssessmentofDiagnosis,
          onSavepedsDemographicsData,
          onSavePedsAssesmentInfoData,
          onSavePatientSummaryData,
          schedulerDataList,
          setSchedulerDataList,
          clinicianPrimary,
          setClinicianPrimary,
          dateOfRefNurse,
          setDateOfRefNurse,
          adminPrimary,
          setAdminPrimary,
          dateOfRefAdmin,
          setDateOfRefAdmin,
          onSaveValidationOfConsentData,
          onSavePedsSocialSaftyData,
          onSaveAssesmentInfoData,
          autoSavePedsVoc,
          setAutoSavePedsVoc,
          onSavePedsPreventativeCareData,
          onSavePedsAllergiesData,
          onSavePedsVitalData,
          onSavePedsCovidScreeningData,
          onSavePedsCovidScreeningDatas,
          autoSavePedsCovidScreening,
          setAutoSavePedsCovidScreening,

          profileImg,
          setProfileImg,
          userClients,
          setUserClients,
          setSelectedClientId,
          selectedClientId,
          setClientPlan,
          clientPlan,
          clientYear,
          setClientYear,
          activeNav,
          setActiveNav,
          clientPlanLabel,
          setClientPlanLabel,
          password,
          setPassword, // Provide the setter function
          getMaskedPassword,
        }}
      >
        {children}
      </UserContext.Provider>
      {isLoading && <Loader />}
    </>
  );
};
