import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { Suspense, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Toaster } from "react-hot-toast";

import { DragNDropProvider } from "./contexts/DragNDropContext";
import { SessionProvider } from "./contexts/SessionContext";
import { FillHraFormProvider } from "./contexts/fillHraFormContext";
import { SpeechProvider } from "./contexts/SpeechContext";
import AppRoutes from "./routes/routesIndex";
import { RedirectToLogin } from "./routes/redirectPageRouting";
import { useUserContext } from "./contexts/UserContext";

import "./App.scss";
import "./components/MaterialTable/table.scss";
import "./pages/SchedulerDashboard/scheduler.scss";
import "./pages/QaDashboard/qalist.css";
import "./pages/CoderDashboard/coderList.css";
import "./pages/NurseDashboard/nurse.scss";
import Loader from "./components/Loader/Loader";
import { Provider } from "react-redux";
import { store } from "./store/store";

function App() {
  const { clientPlan, clientYear, setClientPlan, setClientYear } =
    useUserContext();
  const roleId = sessionStorage.getItem("roleId"); // Fetch roleId here

  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }

  console.error = console.warn = () => {};

  const navigate = useNavigate();
  const redirectToPageNotFound = () => {
    const splitURLToText = window.location.pathname.split("/");
    //titleHandler(splitURLToText.pop());

    const homePage = sessionStorage.getItem("homePage");

    // if (
    //   splitURLToText[1] &&
    //   homePage &&
    //   !splitURLToText.includes(homePage.split("/")[1])
    // ) {
    //   navigate("*");
    // } else {
    //   // localStorage.clear();
    //   // sessionStorage.clear();
    //   // navigate("/");
    // }
    if (
      splitURLToText[1] &&
      homePage &&
      !splitURLToText.includes(homePage.split("/")[1])
    ) {
      navigate("*");
    } else {
      if (!window.location.pathname.includes("PriorAssessment")) {
        localStorage.clear();
        sessionStorage.clear();
        setClientPlan("");
        setClientYear("");
        navigate("/");
      }
    }
  };
  useEffect(() => {
    redirectToPageNotFound();
  }, []);

  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SpeechProvider>
            <SessionProvider>
              <FillHraFormProvider>
                <DragNDropProvider>
                  <div className="App">
                    <Suspense fallback={<Loader />}>
                      <AppRoutes />

                      {/* {roleId == 11 && (
                       
      
        <Routes>
          <Route path="/MemberPortal/Login" element={<Login />} />
          <Route path="/MemberPortal/Register" element= {<Register />} />
          <Route path='/MemberPortal/Profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path='/MemberPortal/Dashboard' element={<PrivateRoute><MainDash /></PrivateRoute>} />
          <Route path="/MemberPortal" element={<Navigate to="/login" />} />
        </Routes>
    
     )} */}
                    </Suspense>
                    <Toaster
                      position="top-right"
                      reverseOrder={false}
                      gutter={8}
                      toastOptions={{
                        style: {
                          fontFamily: "Inter sans-serif !important",
                          border: "0.2px solid black",
                        },
                        success: {
                          duration: 3000,
                          style: {
                            border: "1px solid green",
                            // background:"#363636",
                            color: "green",
                            // fontWeight:"bold",
                          },
                        },
                        error: {
                          duration: 5000,
                          style: {
                            border: "1px solid tomato",
                            // background:"#363636",
                            color: "tomato",
                          },
                        },
                      }}
                    />
                  </div>
                </DragNDropProvider>
              </FillHraFormProvider>
            </SessionProvider>
          </SpeechProvider>
        </LocalizationProvider>
      </DndProvider>
    </Provider>
  );
}

export default App;

RedirectToLogin.propTypes = {
  moveToLogin: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
