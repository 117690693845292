import React, { createContext, useState, useContext } from "react";

const SessionContext = createContext();

export function SessionProvider({ children }) {
  const [OtpSessionExpired, setOtpSessionExpired] = useState(false);
  const [resetSessionExpired, setResetSessionExpired] = useState(false);
  const [changeSessionExpired, setChangeSessionExpired] = useState(false);
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const [clientList, setClientList] = useState([]);

  return (
    <SessionContext.Provider
      value={{
        OtpSessionExpired,
        setOtpSessionExpired,
        resetSessionExpired,
        setResetSessionExpired,
        changeSessionExpired,
        setChangeSessionExpired,
        logoutSuccess,
        setLogoutSuccess,
        clientList,
        setClientList,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  return useContext(SessionContext);
}
