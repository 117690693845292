import {
  // deleteSubTreeData,
  getObjectData,
  // getOptionsSelected,
  getOptionsSelectedYesNo,
  // getSubTreeString,
  setObjectData,
  // setOptionsSelected,
  setOptionsSelectedYesNo,
} from "../../../../../../utils/helpers.DynamicForm";
import lodash from "lodash";
const screenings_flatmap = [
  // microAlbumin screening
  ["microAlbuminVal", "microAlbumin.isMicroAlbumin", ["2023", "2024", "2025"]],

  [
    "screen_microAlbuminSubStatusComment",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.comment",
    ["2023", "2024", "2025"],
  ],
  // [
  //   "screen_microAlbuminSubStatusmemberRefusedComment",
  //   "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.comment",
  //   ["2023", "2024"],
  // ],
  [
    "screen_microAlbuminSubStatusmemberRefusedmemberRecentlyCompleted",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.memberRefusedSubAnswers.memberRecentlyCompleted",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_microAlbuminSubStatusmemberRefusedscheduledToComplete",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.memberRefusedSubAnswers.scheduledToComplete",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_microAlbuminSubStatusmemberRefusedmemberApprehension",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.memberRefusedSubAnswers.memberApprehension",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_microAlbuminSubStatusmemberRefusednotInterested",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.memberRefusedSubAnswers.notInterested",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_microAlbuminSubStatusmemberRefusedother",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.memberRefusedSubAnswers.other",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_microAlbuminSubStatusmemberRefusedotherdescribe",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.memberRefusedSubAnswers.otherSubAnswer.describe",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_microAlbuminSubStatusmemberRefusedComment",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],

  [
    "screen_microAlbuminSubStatus_memberRefused",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.memberRefused",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_microAlbuminSubStatus_leftKit",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.leftKit",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_microAlbuminSubStatus_collectedSample",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.collectedSample",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_microAlbuminSubStatus_mailKit",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.mailKit",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_microAlbuminSubStatus_resultReceived",
    "microAlbumin.isMicroAlbuminSubAnswers.statusOptions.resultReceived",
    ["2023", "2024", "2025"],
  ],
  ["microAlbuminComment", "microAlbumin.comment", ["2023", "2024", "2025"]],
  [
    "screenmicroAlbuminTBarcode",
    "microAlbumin.isMicroAlbuminSubAnswers.TBarcode",
    ["2023", "2024", "2025"],
  ],
  [
    "screenmicroAlbuminexamDate",
    "microAlbumin.isMicroAlbuminSubAnswers.examDate",
    ["2023", "2024", "2025"],
  ],
  [
    "screenmicroAlbuminsResult",
    "microAlbumin.isMicroAlbuminSubAnswers.sResult",
    ["2023", "2024", "2025"],
  ],
  [
    "screenmicroAlbumindiagnosis",
    "microAlbumin.isMicroAlbuminSubAnswers.diagnosis",
    ["2023", "2024", "2025"],
  ],
  [
    "screenmicroAlbumincomments",
    "microAlbumin.isMicroAlbuminSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],

  [
    "screenmicroAlbuminScreeningPerformedBy",
    "microAlbumin.isMicroAlbuminSubAnswers.screeningPerformed",
    ["2024", "2025"],
  ],

  [
    // fobt screening

    ("fobtVal", "fobt.isFobt", ["2023"]),
  ],

  [
    "screen_fobtSubStatusComment",
    "fobt.isFobtSubAnswers.statusOptions.comment",
    ["2023"],
  ],

  [
    "screen_fobtSubStatusmemberRefusedmemberRecentlyCompleted",
    "fobt.isFobtSubAnswers.statusOptions.memberRefusedSubAnswers.memberRecentlyCompleted",
    ["2023"],
  ],
  [
    "screen_fobtSubStatusmemberRefusedscheduledToComplete",
    "fobt.isFobtSubAnswers.statusOptions.memberRefusedSubAnswers.scheduledToComplete",
    ["2023"],
  ],
  [
    "screen_fobtSubStatusmemberRefusedmemberApprehension",
    "fobt.isFobtSubAnswers.statusOptions.memberRefusedSubAnswers.memberApprehension",
    ["2023"],
  ],
  [
    "screen_fobtSubStatusmemberRefusednotInterested",
    "fobt.isFobtSubAnswers.statusOptions.memberRefusedSubAnswers.notInterested",
    ["2023"],
  ],
  [
    "screen_fobtAlbuminSubStatusmemberRefusedother",
    "fobt.isFobtSubAnswers.statusOptions.memberRefusedSubAnswers.other",
    ["2023"],
  ],
  [
    "screen_fobtSubStatusmemberRefusedotherdescribe",
    "fobt.isFobtSubAnswers.statusOptions.memberRefusedSubAnswers.otherSubAnswer.describe",
    ["2023"],
  ],
  [
    "screen_fobtSubStatusmemberRefusedReasonComment",
    "fobt.isFobtSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2023"],
  ],

  [
    "screen_fobtSubStatus_memberRefused",
    "fobt.isFobtSubAnswers.statusOptions.memberRefused",
    ["2023"],
  ],
  [
    "screen_fobtSubStatus_leftKit",
    "fobt.isFobtSubAnswers.statusOptions.leftKit",
    ["2023"],
  ],
  [
    "screen_fobtSubStatus_collectedSample",
    "fobt.isFobtSubAnswers.statusOptions.collectedSample",
    ["2023"],
  ],
  [
    "screen_fobtSubStatus_mailKit",
    "fobt.isFobtSubAnswers.statusOptions.mailKit",
    ["2023"],
  ],
  [
    "screen_fobtSubStatus_resultReceived",
    "fobt.isFobtSubAnswers.statusOptions.resultReceived",
    ["2023"],
  ],
  ["fobtComment", "fobt.comment", ["2023"]],

  ["screenfobtTBarcode", "fobt.isFobtSubAnswers.TBarcode", ["2023"]],
  ["screenfobtexamDate", "fobt.isFobtSubAnswers.examDate", ["2023"]],
  ["screenfobtResult", "fobt.isFobtSubAnswers.sResult", ["2023"]],
  ["screenfobtdiagnosis", "fobt.isFobtSubAnswers.diagnosis", ["2023"]],
  ["screenfobtcomment", "fobt.isFobtSubAnswers.comment", ["2023"]],

  ["fit", "fit.isFit", ["2024", "2025"]],

  [
    "screenfitSubStatusComment",
    "fit.isFitSubAnswers.statusOptions.comment",
    ["2024", "2025"],
  ],

  [
    "screenfitSubStatusmemberRefusedmemberRecentlyCompleted",
    "fit.isFitSubAnswers.statusOptions.memberRefusedSubAnswers.memberRecentlyCompleted",
    ["2024", "2025"],
  ],
  [
    "screenfitSubStatusmemberRefusedscheduledToComplete",
    "fit.isFitSubAnswers.statusOptions.memberRefusedSubAnswers.scheduledToComplete",
    ["2024", "2025"],
  ],
  [
    "screenfitSubStatusmemberRefusedmemberApprehension",
    "fit.isFitSubAnswers.statusOptions.memberRefusedSubAnswers.memberApprehension",
    ["2024", "2025"],
  ],
  [
    "screenfitSubStatusmemberRefusednotInterested",
    "fit.isFitSubAnswers.statusOptions.memberRefusedSubAnswers.notInterested",
    ["2024", "2025"],
  ],
  [
    "screenfitSubStatusmemberRefusedOthero",
    "fit.isFitSubAnswers.statusOptions.memberRefusedSubAnswers.other",
    ["2024", "2025"],
  ],
  [
    "screenfitSubStatusmemberRefusedotherdescribe",
    "fit.isFitSubAnswers.statusOptions.memberRefusedSubAnswers.otherSubAnswer.describe",
    ["2024", "2025"],
  ],
  [
    "screenfitSubStatusmemberRefusedReasonComment",
    "fit.isFitSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2024", "2025"],
  ],

  [
    "screenfitSubStatusmemberRefused",
    "fit.isFitSubAnswers.statusOptions.memberRefused",
    ["2024", "2025"],
  ],
  [
    "screenfitSubStatusleftKit",
    "fit.isFitSubAnswers.statusOptions.leftKit",
    ["2024", "2025"],
  ],
  [
    "screenfitSubStatuscollectedSample",
    "fit.isFitSubAnswers.statusOptions.collectedSample",
    ["2024", "2025"],
  ],
  [
    "screenfitSubStatusmailKit",
    "fit.isFitSubAnswers.statusOptions.mailKit",
    ["2024", "2025"],
  ],
  [
    "screenfitSubStatusresultReceived",
    "fit.isFitSubAnswers.statusOptions.resultReceived",
    ["2024", "2025"],
  ],
  ["fitComment", "fit.comment", ["2024", "2025"]],

  ["screenfitTBarcode", "fit.isFitSubAnswers.fitTBarcode", ["2024", "2025"]],
  ["screenfitexamDate", "fit.isFitSubAnswers.examDate", ["2024", "2025"]],
  ["screenfitResult", "fit.isFitSubAnswers.sResult", ["2024", "2025"]],
  ["screenfitdiagnosis", "fit.isFitSubAnswers.diagnosis", ["2024", "2025"]],
  ["screenfitcomment", "fit.isFitSubAnswers.comment", ["2024", "2025"]],

  [
    "screenfitScreeningPerformedBy",
    "fit.isFitSubAnswers.screeningPerformed",
    ["2024", "2025"],
  ],

  ["aOneC", "aOneC.isAOneC", ["2023", "2024", "2025"]],

  [
    "screen_aOneCSubStatus",
    "aOneC.isAOneCSubAnswers.statusOptions",
    ["2023", "2024", "2025"],
  ],

  [
    "screen_aOneCSubStatusComment",
    "aOneC.isAOneCSubAnswers.statusOptions.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "screenaOneCSubStatus_memberRefusedComment",
    "aOneC.isAOneCSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "screenaOneCSubStatusmemberRefusedmemberRecentlyCompleted",
    "aOneC.isAOneCSubAnswers.statusOptions.memberRefusedSubAnswers.memberRecentlyCompleted",
    ["2023", "2024", "2025"],
  ],
  [
    "screenaOneCSubStatusmemberRefusedscheduledToComplete",
    "aOneC.isAOneCSubAnswers.statusOptions.memberRefusedSubAnswers.scheduledToComplete",
    ["2023", "2024", "2025"],
  ],
  [
    "screenaOneCSubStatusmemberRefusedmemberApprehension",
    "aOneC.isAOneCSubAnswers.statusOptions.memberRefusedSubAnswers.memberApprehension",
    ["2023", "2024", "2025"],
  ],
  [
    "screenaOneCSubStatusmemberRefusednotInterested",
    "aOneC.isAOneCSubAnswers.statusOptions.memberRefusedSubAnswers.notInterested",
    ["2023", "2024", "2025"],
  ],

  [
    "screenaOneCSubStatusmemberRefusedother",
    "aOneC.isAOneCSubAnswers.statusOptions.memberRefusedSubAnswers.other",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_aOneCSubStatusmemberRefusedotherdescribe",
    "aOneC.isAOneCSubAnswers.statusOptions.memberRefusedSubAnswers.otherSubAnswer.describe",
    ["2023", "2024", "2025"],
  ],

  [
    "screenaOneCSubStatus_memberRefusedComment",
    "aOneC.isAOneCSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_aOneCSubStatus_memberRefused",
    "aOneC.isAOneCSubAnswers.statusOptions.memberRefused",
    ["2023", "2024", "2025"],
  ],

  [
    "screen_aOneCSubStatus_leftKit",
    "aOneC.isAOneCSubAnswers.statusOptions.leftKit",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_aOneCSubStatus_collectedSample",
    "aOneC.isAOneCSubAnswers.statusOptions.collectedSample",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_aOneCSubStatus_mailKit",
    "aOneC.isAOneCSubAnswers.statusOptions.mailKit",
    ["2023", "2024", "2025"],
  ],
  [
    "screen_aOneCSubStatus_resultReceived",
    "aOneC.isAOneCSubAnswers.statusOptions.resultReceived",
    ["2023", "2024", "2025"],
  ],
  ["aOneCComment", "aOneC.comment", ["2023", "2024", "2025"]],
  [
    "screenaOneCTBarcode",
    "aOneC.isAOneCSubAnswers.TBarcode",
    ["2023", "2024", "2025"],
  ],
  [
    "screenaOneCexamDate",
    "aOneC.isAOneCSubAnswers.examDate",
    ["2023", "2024", "2025"],
  ],
  [
    "screenaOneCsResult",
    "aOneC.isAOneCSubAnswers.sResult",
    ["2023", "2024", "2025"],
  ],
  [
    "screenaOneCdiagnosis",
    "aOneC.isAOneCSubAnswers.diagnosis",
    ["2023", "2024", "2025"],
  ],
  [
    "screenaOneCComment",
    "aOneC.isAOneCSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],

  [
    "screenaOneCScreeningPerformedBy",
    "aOneC.isAOneCSubAnswers.screeningPerformed",
    ["2024", "2025"],
  ],

  //ldl

  ["ldlVal", "ldl.isLdl", ["2023", "2024", "2025"]],
  [
    "ldlSubStatusComment",
    "ldl.isLdlSubAnswers.statusOptions.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatusmemberRefusedComment",
    "ldl.isLdlSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatusmemberRefusedmemberRecentlyCompleted",
    "ldl.isLdlSubAnswers.statusOptions.memberRefusedSubAnswers.memberRecentlyCompleted",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatusmemberRefusedscheduledToComplete",
    "ldl.isLdlSubAnswers.statusOptions.memberRefusedSubAnswers.scheduledToComplete",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatusmemberRefusedmemberApprehension",
    "ldl.isLdlSubAnswers.statusOptions.memberRefusedSubAnswers.memberApprehension",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatusmemberRefusednotInterested",
    "ldl.isLdlSubAnswers.statusOptions.memberRefusedSubAnswers.notInterested",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatusmemberRefusedother",
    "ldl.isLdlSubAnswers.statusOptions.memberRefusedSubAnswers.other",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatusmemberRefusedotherdescribe",
    "ldl.isLdlSubAnswers.statusOptions.memberRefusedSubAnswers.otherSubAnswer.describe",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatusmemberRefusedComment",
    "ldl.isLdlSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatus_memberRefused",
    "ldl.isLdlSubAnswers.statusOptions.memberRefused",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatus_leftKit",
    "ldl.isLdlSubAnswers.statusOptions.leftKit",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatus_collectedSample",
    "ldl.isLdlSubAnswers.statusOptions.collectedSample",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatus_mailKit",
    "ldl.isLdlSubAnswers.statusOptions.mailKit",
    ["2023", "2024", "2025"],
  ],
  [
    "ldlSubStatus_resultReceived",
    "ldl.isLdlSubAnswers.statusOptions.resultReceived",
    ["2023", "2024", "2025"],
  ],
  ["ldlComment", "ldl.comment", ["2023", "2024", "2025"]],
  ["ldlTBarcode", "ldl.isLdlSubAnswers.TBarcode", ["2023", "2024", "2025"]],
  ["ldlexamDate", "ldl.isLdlSubAnswers.examDate", ["2023", "2024", "2025"]],
  ["ldlsResult", "ldl.isLdlSubAnswers.sResult", ["2023", "2024", "2025"]],
  ["ldldiagnosis", "ldl.isLdlSubAnswers.diagnosis", ["2023", "2024", "2025"]],
  ["ldlcomment", "ldl.isLdlSubAnswers.comment", ["2023", "2024", "2025"]],

  [
    "ldlScreeningPerformedBy",
    "ldl.isLdlSubAnswers.screeningPerformed",
    ["2024", "2025"],
  ],

  //dexa

  ["dexaVal", "dexa.isDexa", ["2023", "2024", "2025"]],
  [
    "dexaExamSubStatusComment",
    "dexa.isDexaExamSubAnswers.statusOptions.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatusmemberRefusedComment",
    "dexa.isDexaExamSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatusmemberRefusedmemberRecentlyCompleted",
    "dexa.isDexaExamSubAnswers.statusOptions.memberRefusedSubAnswers.memberRecentlyCompleted",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatusmemberRefusedscheduledToComplete",
    "dexa.isDexaExamSubAnswers.statusOptions.memberRefusedSubAnswers.scheduledToComplete",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatusmemberRefusedmemberApprehension",
    "dexa.isDexaExamSubAnswers.statusOptions.memberRefusedSubAnswers.memberApprehension",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatusmemberRefusednotInterested",
    "dexa.isDexaExamSubAnswers.statusOptions.memberRefusedSubAnswers.notInterested",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatusmemberRefusedother",
    "dexa.isDexaExamSubAnswers.statusOptions.memberRefusedSubAnswers.other",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatusmemberRefusedotherdescribe",
    "dexa.isDexaExamSubAnswers.statusOptions.memberRefusedSubAnswers.otherSubAnswer.describe",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatus_memberRefused",
    "dexa.isDexaExamSubAnswers.statusOptions.memberRefused",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatus_examCompleted",
    "dexa.isDexaExamSubAnswers.statusOptions.examCompleted",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatus_environmentalIssue",
    "dexa.isDexaExamSubAnswers.statusOptions.environmentalIssue",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaExamSubStatus_resultReceived",
    "dexa.isDexaExamSubAnswers.statusOptions.resultReceived",
    ["2023", "2024", "2025"],
  ],
  ["dexaComment", "dexa.comment", ["2023", "2024", "2025"]],
  [
    "dexaExamDate",
    "dexa.isDexaExamSubAnswers.examDate",
    ["2023", "2024", "2025"],
  ],
  [
    "dexasResult",
    "dexa.isDexaExamSubAnswers.sResult",
    ["2023", "2024", "2025"],
  ],

  ["dexasTScore", "dexa.isDexaExamSubAnswers.tScore", ["2024", "2025"]],
  ["dexasZScore", "dexa.isDexaExamSubAnswers.zScore", ["2024", "2025"]],
  // ["dexasSosResult", "dexa.isDexaExamSubAnswers.sosResult", ["2024"]],

  [
    "dexadiagnosis",
    "dexa.isDexaExamSubAnswers.diagnosis",
    ["2023", "2024", "2025"],
  ],
  [
    "dexaSubStatusComment",
    "dexa.isDexaExamSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],

  [
    "dexaScreeningPerformedBy",
    "dexa.isDexaExamSubAnswers.screeningPerformed",
    ["2024", "2025"],
  ],

  //retinalExam

  ["retinalExamVal", "retinalExam.isRetinalExam", ["2023", "2024", "2025"]],
  [
    "retinalExamSubStatusComment",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatusmemberRefusedComment",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatusmemberRefusedmemberRecentlyCompleted",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.memberRefusedSubAnswers.memberRecentlyCompleted",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatusmemberRefusedscheduledToComplete",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.memberRefusedSubAnswers.scheduledToComplete",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatusmemberRefusedmemberApprehension",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.memberRefusedSubAnswers.memberApprehension",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatusmemberRefusednotInterested",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.memberRefusedSubAnswers.notInterested",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatusmemberRefusedother",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.memberRefusedSubAnswers.other",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatusmemberRefusedotherdescribe",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.memberRefusedSubAnswers.otherSubAnswer.describe",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatus_memberRefused",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.memberRefused",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatus_examCompleted",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.examCompleted",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatus_environmentalIssue",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.environmentalIssue",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatus_resultReceived",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.resultReceived",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatusresultReceivedSubAnswersvalidImage",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.resultReceivedSubAnswers.validImage",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatusresultReceivedSubAnswersinvalidImage",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.resultReceivedSubAnswers.invalidImage",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamSubStatusresultReceivedSubAnswersComment",
    "retinalExam.isRetinalExamSubAnswers.statusOptions.resultReceivedSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamComments",
    "retinalExam.isRetinalExamSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamExamDate",
    "retinalExam.isRetinalExamSubAnswers.examDate",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamsResult",
    "retinalExam.isRetinalExamSubAnswers.sResult",
    ["2023", "2024", "2025"],
  ],
  [
    "retinalExamdiagnosis",
    "retinalExam.isRetinalExamSubAnswers.diagnosis",
    ["2023", "2024", "2025"],
  ],
  ["retinalExamComment", "retinalExam.comment", ["2023", "2024", "2025"]],

  [
    "retinalExamsLeResult",
    "retinalExam.isRetinalExamSubAnswers.leftEyeResult",
    ["2024", "2025"],
  ],
  [
    "retinalExamsRgResult",
    "retinalExam.isRetinalExamSubAnswers.rightEyeResult",
    ["2024", "2025"],
  ],

  [
    "retinalExamScreeningPerformedBy",
    "retinalExam.isRetinalExamSubAnswers.screeningPerformed",
    ["2024", "2025"],
  ],

  //pad
  ["padVal", "pad.isPad", ["2023", "2024", "2025"]],
  [
    "padSubStatusComment",
    "pad.isPadSubAnswers.statusOptions.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatusmemberRefusedComment",
    "pad.isPadSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatusmemberRefusedmemberRecentlyCompleted",
    "pad.isPadSubAnswers.statusOptions.memberRefusedSubAnswers.memberRecentlyCompleted",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatusmemberRefusedscheduledToComplete",
    "pad.isPadSubAnswers.statusOptions.memberRefusedSubAnswers.scheduledToComplete",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatusmemberRefusedmemberApprehension",
    "pad.isPadSubAnswers.statusOptions.memberRefusedSubAnswers.memberApprehension",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatusmemberRefusednotInterested",
    "pad.isPadSubAnswers.statusOptions.memberRefusedSubAnswers.notInterested",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatusmemberRefusedother",
    "pad.isPadSubAnswers.statusOptions.memberRefusedSubAnswers.other",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatusmemberRefusedotherdescribe",
    "pad.isPadSubAnswers.statusOptions.memberRefusedSubAnswers.otherSubAnswer.describe",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatus_memberRefused",
    "pad.isPadSubAnswers.statusOptions.memberRefused",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatus_examCompleted",
    "pad.isPadSubAnswers.statusOptions.examCompleted",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatus_environmentalIssue",
    "pad.isPadSubAnswers.statusOptions.environmentalIssue",
    ["2023", "2024", "2025"],
  ],
  [
    "padSubStatus_resultReceived",
    "pad.isPadSubAnswers.statusOptions.resultReceived",
    ["2023", "2024", "2025"],
  ],

  [
    "padScreeningPerformedBy",
    "pad.isPadSubAnswers.screeningPerformed",
    ["2024", "2025"],
  ],

  [
    "peripheralResultLeftComment",
    "pad.isPadSubAnswers.peripheralResultLeft.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultRightComment",
    "pad.isPadSubAnswers.peripheralResultRight.comment",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultLeftNormal",
    "pad.isPadSubAnswers.peripheralResultLeft.normal",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultLeftModerate",
    "pad.isPadSubAnswers.peripheralResultLeft.moderate",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultLeftSiginificant",
    "pad.isPadSubAnswers.peripheralResultLeft.siginificant",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultLeftSevere",
    "pad.isPadSubAnswers.peripheralResultLeft.severe",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultLeftMild",
    "pad.isPadSubAnswers.peripheralResultLeft.mild",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultRightNormal",
    "pad.isPadSubAnswers.peripheralResultRight.normal",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultRightModerate",
    "pad.isPadSubAnswers.peripheralResultRight.moderate",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultRightSiginificant",
    "pad.isPadSubAnswers.peripheralResultRight.siginificant",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultRightSevere",
    "pad.isPadSubAnswers.peripheralResultRight.severe",
    ["2023", "2024", "2025"],
  ],
  [
    "peripheralResultRightMild",
    "pad.isPadSubAnswers.peripheralResultRight.mild",
    ["2023", "2024", "2025"],
  ],
  ["padComments", "pad.isPadSubAnswers.comment", ["2023", "2024", "2025"]],
  ["padExamDate", "pad.isPadSubAnswers.examDate", ["2023", "2024", "2025"]],
  [
    "padExamsResultRight",
    "pad.isPadSubAnswers.padResultRight",
    ["2023", "2024", "2025"],
  ],

  [
    "padExamsResultLeft",
    "pad.isPadSubAnswers.padResultLeft",
    ["2023", "2024", "2025"],
  ],
  ["padTestingEligibility", "pad.padTestingEligibility", ["2024", "2025"]],
  ["padDiagnosis", "pad.isPadSubAnswers.diagnosis", ["2023", "2024", "2025"]],
  ["padValComment", "pad.comment", ["2023", "2024", "2025"]],
  ["educated", "isMemberEducated.isMemberEducated", ["2023"]],
  ["educatedComment", "isMemberEducated.comment", ["2023"]],

  ["educated", "memberEducated.isMemberEducated", ["2024", "2025"]],
  ["educatedComment", "memberEducated.comment", ["2024", "2025"]],

  // ["padSubStatus_resultReceivedSubAnswers_validImage", "pad.isPadSubAnswers.statusOptions.resultReceivedSubAnswers.validImage"],
  // ["padSubStatus_resultReceivedSubAnswers_invalidImage", "pad.isPadSubAnswers.statusOptions.resultReceivedSubAnswers.invalidImage"],
  // ["padSubStatus_resultReceivedSubAnswers_comment", "pad.isPadSubAnswers.statusOptions.resultReceivedSubAnswers.comment"],

  // ["peripheralResultLeft", "pad.isPadSubAnswers.peripheralResultLeft"],
  // ["rfpadtright", "pad.isPadSubAnswers.peripheralResultRight"],

  // ["peripheralResultLeft","pad.peripheralResultLeft"],
  // ["peripheralResultRight", "pad.isPadSubAnswers.peripheralResultRight"],

  //ked

  ["ked", "ked.isKed", ["2024", "2025"]],
  [
    "screenkedSubStatusComment",
    "ked.isKedSubAnswers.statusOptions.comment",
    ["2024", "2025"],
  ],
  [
    "screen_kedSubStatusmemberRefusedmemberRecentlyCompleted",
    "ked.isKedSubAnswers.statusOptions.memberRefusedSubAnswers.memberRecentlyCompleted",
    ["2024", "2025"],
  ],
  [
    "screen_kedSubStatusmemberRefusedscheduledToComplete",
    "ked.isKedSubAnswers.statusOptions.memberRefusedSubAnswers.scheduledToComplete",
    ["2024", "2025"],
  ],
  [
    "screen_kedSubStatusmemberRefusedmemberApprehension",
    "ked.isKedSubAnswers.statusOptions.memberRefusedSubAnswers.memberApprehension",
    ["2024", "2025"],
  ],
  [
    "screen_kedSubStatusmemberRefusednotInterested",
    "ked.isKedSubAnswers.statusOptions.memberRefusedSubAnswers.notInterested",
    ["2024", "2025"],
  ],
  [
    "screen_kedSubStatusmemberRefusedother",
    "ked.isKedSubAnswers.statusOptions.memberRefusedSubAnswers.other",
    ["2024", "2025"],
  ],
  [
    "screen_kedSubStatusmemberRefusedotherdescribe",
    "ked.isKedSubAnswers.statusOptions.memberRefusedSubAnswers.otherSubAnswer.describe",
    ["2024", "2025"],
  ],
  [
    "screen_kedSubStatusmemberRefusedComment",
    "ked.isKedSubAnswers.statusOptions.memberRefusedSubAnswers.comment",
    ["2024", "2025"],
  ],
  [
    "screenkedSubStatusmemberRefused",
    "ked.isKedSubAnswers.statusOptions.memberRefused",
    ["2024", "2025"],
  ],
  [
    "screenkedSubStatusleftKit",
    "ked.isKedSubAnswers.statusOptions.leftKit",
    ["2024", "2025"],
  ],
  [
    "screenkedSubStatuscollectedSample",
    "ked.isKedSubAnswers.statusOptions.collectedSample",
    ["2024", "2025"],
  ],
  [
    "screenkedSubStatusmailKit",
    "ked.isKedSubAnswers.statusOptions.mailKit",
    ["2024", "2025"],
  ],
  [
    "screenkedSubStatusresultReceived",
    "ked.isKedSubAnswers.statusOptions.resultReceived",
    ["2024", "2025"],
  ],
  ["kedComment", "ked.comment", ["2024", "2025"]],
  [
    "screenkedUrineAlbumin",
    "ked.isKedSubAnswers.urineAlbumin",
    ["2024", "2025"],
  ],
  [
    "screenkedUrineCreatinine",
    "ked.isKedSubAnswers.urineCreatinine",
    ["2024", "2025"],
  ],
  ["screenkedCreatinine", "ked.isKedSubAnswers.creatinine", ["2024", "2025"]],
  ["screenkedUrineALB", "ked.isKedSubAnswers.urineALB", ["2024", "2025"]],
  ["screenkedeGFR", "ked.isKedSubAnswers.eGFR", ["2024", "2025"]],

  ["screenkedTBarcode", "ked.isKedSubAnswers.TBarcode", ["2024", "2025"]],
  ["screenkedexamDate", "ked.isKedSubAnswers.examDate", ["2024", "2025"]],
  ["screenkeddiagnosis", "ked.isKedSubAnswers.diagnosis", ["2024", "2025"]],
  ["screenkedcomment", "ked.isKedSubAnswers.comment", ["2024", "2025"]],

  [
    "screenkedScreeningPerformedBy",
    "ked.isKedSubAnswers.screeningPerformed",
    ["2024", "2025"],
  ],

  // ["educated2", "memberEducated.isMemberEducated", ["2024"]],
  // ["educated2Comment", "memberEducated.comment", ["2024"]],

  ["providerNotified", "providerNotified.isProviderNotified", ["2024", "2025"]],
  ["providerNotifiedComment", "providerNotified.comment", ["2024", "2025"]],

  ["cMReferral", "cMReferralSent.isCMReferralSent", ["2024", "2025"]],
  ["cMReferralComment", "cMReferralSent.comment", ["2024", "2025"]],
];

const screenings_optionsTreeMappers2023 = {
  screen_microAlbuminSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  screen_microAlbuminSubStatusmemberRefusedscheduledToComplete:
    "scheduledToComplete",
  screen_microAlbuminSubStatusmemberRefusedmemberApprehension:
    "memberApprehension",
  screen_microAlbuminSubStatusmemberRefusednotInterested: "notInterested",
  screen_microAlbuminSubStatusmemberRefusedother: "other",

  screen_microAlbuminSubStatus_leftKit: "leftkit",
  screen_microAlbuminSubStatus_collectedSample: "collectedSample",
  screen_microAlbuminSubStatus_mailKit: "mailKitDirectToMember",
  screen_microAlbuminSubStatus_resultReceived: "resultReceived",
  screen_microAlbuminSubStatus_memberRefused: "memberRefused",

  screen_fobtSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  screen_fobtSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  screen_fobtSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  screen_fobtSubStatusmemberRefusednotInterested: "notInterested",
  screen_fobtAlbuminSubStatusmemberRefusedother: "other",

  screen_fobtSubStatus_leftKit: "leftKit",
  screen_fobtSubStatus_collectedSample: "collectedSample",
  screen_fobtSubStatus_mailKit: "mailKitDirectToMember",
  screen_fobtSubStatus_resultReceived: "resultReceived",
  screen_fobtSubStatus_memberRefused: "memberRefused",

  screenaOneCSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  screenaOneCSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  screenaOneCSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  screenaOneCSubStatusmemberRefusednotInterested: "notInterested",
  screenaOneCSubStatusmemberRefusedother: "other",

  screen_aOneCSubStatus_leftKit: "leftKit",
  screen_aOneCSubStatus_collectedSample: "collectedSample",
  screen_aOneCSubStatus_mailKit: "mailKitDirectToMember",
  screen_aOneCSubStatus_resultReceived: "resultReceived",
  screen_aOneCSubStatus_memberRefused: "memberRefused",

  ldlSubStatusmemberRefusedmemberRecentlyCompleted: "memberRecentlyCompleted",
  ldlSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  ldlSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  ldlSubStatusmemberRefusednotInterested: "notInterested",
  ldlSubStatusmemberRefusedother: "other",

  dexaExamSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  dexaExamSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  dexaExamSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  dexaExamSubStatusmemberRefusednotInterested: "notInterested",
  dexaExamSubStatusmemberRefusedother: "other",

  retinalExamSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  retinalExamSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  retinalExamSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  retinalExamSubStatusmemberRefusednotInterested: "notInterested",
  retinalExamSubStatusmemberRefusedother: "other",

  ldlSubStatus_memberRefused: "memberRefused",
  ldlSubStatus_leftKit: "leftKit",
  ldlSubStatus_collectedSample: "collectedSample",
  ldlSubStatus_mailKit: "mailKitDirectToMember",
  ldlSubStatus_resultReceived: "resultReceived",

  dexaExamSubStatus_memberRefused: "memberRefused",
  dexaExamSubStatus_examCompleted: "examCompleted",
  dexaExamSubStatus_environmentalIssue: "environmentalIssue",
  dexaExamSubStatus_resultReceived: "resultReceived",

  retinalExamSubStatus_memberRefused: "memberRefused",
  retinalExamSubStatus_examCompleted: "examCompleted",
  retinalExamSubStatus_environmentalIssue: "environmentalIssue",
  retinalExamSubStatus_resultReceived: "resultReceived",

  padSubStatus_memberRefused: "memberRefused",
  padSubStatus_examCompleted: "examCompleted",
  padSubStatus_environmentalIssue: "environmentalIssue",
  padSubStatus_resultReceived: "resultReceived",

  padSubStatusmemberRefusedmemberRecentlyCompleted: "memberRecentlyCompleted",
  padSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  padSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  padSubStatusmemberRefusednotInterested: "notInterested",
  padSubStatusmemberRefusedother: "other",
  retinalExamSubStatusresultReceivedSubAnswersvalidImage: "validImage",
  retinalExamSubStatusresultReceivedSubAnswersinvalidImage: "invalidImage",
};

const screenings_optionsTreeWrappers2023 = [
  [
    "screen_microAlbuminSubStatusmemberRefused",
    [
      "screen_microAlbuminSubStatusmemberRefusedmemberRecentlyCompleted",
      "screen_microAlbuminSubStatusmemberRefusedscheduledToComplete",
      "screen_microAlbuminSubStatusmemberRefusedmemberApprehension",
      "screen_microAlbuminSubStatusmemberRefusednotInterested",
      "screen_microAlbuminSubStatusmemberRefusedother",
    ],
  ],

  [
    "screen_microAlbuminSubStatus",
    [
      "screen_microAlbuminSubStatus_leftKit",
      "screen_microAlbuminSubStatus_collectedSample",
      "screen_microAlbuminSubStatus_mailKit",
      "screen_microAlbuminSubStatus_resultReceived",
      "screen_microAlbuminSubStatus_memberRefused",
    ],
  ],
  [
    "screen_fobtSubStatusmemberRefusedReason",
    [
      "screen_fobtSubStatusmemberRefusedmemberRecentlyCompleted",
      "screen_fobtSubStatusmemberRefusedscheduledToComplete",
      "screen_fobtSubStatusmemberRefusedmemberApprehension",
      "screen_fobtSubStatusmemberRefusednotInterested",
      "screen_fobtAlbuminSubStatusmemberRefusedother",
    ],
  ],

  [
    "screen_fobtSubStatus",
    [
      "screen_fobtSubStatus_leftKit",
      "screen_fobtSubStatus_collectedSample",
      "screen_fobtSubStatus_mailKit",
      "screen_fobtSubStatus_resultReceived",
      "screen_fobtSubStatus_memberRefused",
    ],
  ],

  [
    "screenaOneCSubStatus_memberRefused",
    [
      "screenaOneCSubStatusmemberRefusedmemberRecentlyCompleted",
      "screenaOneCSubStatusmemberRefusedscheduledToComplete",
      "screenaOneCSubStatusmemberRefusedmemberApprehension",
      "screenaOneCSubStatusmemberRefusednotInterested",
      "screenaOneCSubStatusmemberRefusedother",
    ],
  ],

  [
    "screen_aOneCSubStatus",
    [
      "screen_aOneCSubStatus_leftKit",
      "screen_aOneCSubStatus_collectedSample",
      "screen_aOneCSubStatus_mailKit",
      "screen_aOneCSubStatus_resultReceived",
      "screen_aOneCSubStatus_memberRefused",
    ],
  ],

  [
    "ldlSubStatus",
    [
      "ldlSubStatus_memberRefused",
      "ldlSubStatus_leftKit",
      "ldlSubStatus_collectedSample",
      "ldlSubStatus_mailKit",
      "ldlSubStatus_resultReceived",
    ],
  ],
  [
    "ldlSubStatusmemberRefused",
    [
      "ldlSubStatusmemberRefusedmemberRecentlyCompleted",
      "ldlSubStatusmemberRefusedscheduledToComplete",
      "ldlSubStatusmemberRefusedmemberApprehension",
      "ldlSubStatusmemberRefusednotInterested",
      "ldlSubStatusmemberRefusedother",
    ],
  ],

  [
    "dexaExamSubStatusmemberRefused",
    [
      "dexaExamSubStatusmemberRefusedmemberRecentlyCompleted",
      "dexaExamSubStatusmemberRefusedscheduledToComplete",
      "dexaExamSubStatusmemberRefusedmemberApprehension",
      "dexaExamSubStatusmemberRefusednotInterested",
      "dexaExamSubStatusmemberRefusedother",
    ],
  ],
  [
    "retinalExamSubStatusmemberRefused",
    [
      "retinalExamSubStatusmemberRefusedmemberRecentlyCompleted",
      "retinalExamSubStatusmemberRefusedscheduledToComplete",
      "retinalExamSubStatusmemberRefusedmemberApprehension",
      "retinalExamSubStatusmemberRefusednotInterested",
      "retinalExamSubStatusmemberRefusedother",
    ],
  ],
  [
    "retinalExamSubStatusresultReceivedSubAnswers",
    [
      "retinalExamSubStatusresultReceivedSubAnswersvalidImage",
      "retinalExamSubStatusresultReceivedSubAnswersinvalidImage",
    ],
  ],
  [
    "dexaExamSubStatus",
    [
      "dexaExamSubStatus_memberRefused",
      "dexaExamSubStatus_examCompleted",
      "dexaExamSubStatus_environmentalIssue",
      "dexaExamSubStatus_resultReceived",
    ],
  ],
  [
    "retinalExamSubStatus",
    [
      "retinalExamSubStatus_memberRefused",
      "retinalExamSubStatus_examCompleted",
      "retinalExamSubStatus_environmentalIssue",
      "retinalExamSubStatus_resultReceived",
    ],
  ],

  [
    "padSubStatus",
    [
      "padSubStatus_memberRefused",
      "padSubStatus_examCompleted",
      "padSubStatus_environmentalIssue",
      "padSubStatus_resultReceived",
    ],
  ],
  [
    "padSubStatusmemberRefused",
    [
      "padSubStatusmemberRefusedmemberRecentlyCompleted",
      "padSubStatusmemberRefusedscheduledToComplete",
      "padSubStatusmemberRefusedmemberApprehension",
      "padSubStatusmemberRefusednotInterested",
      "padSubStatusmemberRefusedother",
    ],
  ],
];

const screenings_optionsTreeMappers2024 = {
  screen_microAlbuminSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  screen_microAlbuminSubStatusmemberRefusedscheduledToComplete:
    "scheduledToComplete",
  screen_microAlbuminSubStatusmemberRefusedmemberApprehension:
    "memberApprehension",
  screen_microAlbuminSubStatusmemberRefusednotInterested: "notInterested",
  screen_microAlbuminSubStatusmemberRefusedother: "other",
  screen_microAlbuminSubStatus_leftKit: "leftkit",
  screen_microAlbuminSubStatus_collectedSample: "collectedSample",
  screen_microAlbuminSubStatus_mailKit: "mailKitDirectToMember",
  screen_microAlbuminSubStatus_resultReceived: "resultReceived",
  screen_microAlbuminSubStatus_memberRefused: "memberRefused",
  screenaOneCSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  screenaOneCSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  screenaOneCSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  screenaOneCSubStatusmemberRefusednotInterested: "notInterested",
  screenaOneCSubStatusmemberRefusedother: "other",
  screen_aOneCSubStatus_leftKit: "leftKit",
  screen_aOneCSubStatus_collectedSample: "collectedSample",
  screen_aOneCSubStatus_mailKit: "mailKitDirectToMember",
  screen_aOneCSubStatus_resultReceived: "resultReceived",
  screen_aOneCSubStatus_memberRefused: "memberRefused",
  ldlSubStatusmemberRefusedmemberRecentlyCompleted: "memberRecentlyCompleted",
  ldlSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  ldlSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  ldlSubStatusmemberRefusednotInterested: "notInterested",
  ldlSubStatusmemberRefusedother: "other",
  dexaExamSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  dexaExamSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  dexaExamSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  dexaExamSubStatusmemberRefusednotInterested: "notInterested",
  dexaExamSubStatusmemberRefusedother: "other",
  retinalExamSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  retinalExamSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  retinalExamSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  retinalExamSubStatusmemberRefusednotInterested: "notInterested",
  retinalExamSubStatusmemberRefusedother: "other",
  ldlSubStatus_memberRefused: "memberRefused",
  ldlSubStatus_leftKit: "leftKit",
  ldlSubStatus_collectedSample: "collectedSample",
  ldlSubStatus_mailKit: "mailKitDirectToMember",
  ldlSubStatus_resultReceived: "resultReceived",
  dexaExamSubStatus_memberRefused: "memberRefused",
  dexaExamSubStatus_examCompleted: "examCompleted",
  dexaExamSubStatus_environmentalIssue: "environmentalIssue",
  dexaExamSubStatus_resultReceived: "resultReceived",
  retinalExamSubStatus_memberRefused: "memberRefused",
  retinalExamSubStatus_examCompleted: "examCompleted",
  retinalExamSubStatus_environmentalIssue: "environmentalIssue",
  retinalExamSubStatus_resultReceived: "resultReceived",
  padSubStatus_memberRefused: "memberRefused",
  padSubStatus_examCompleted: "examCompleted",
  padSubStatus_environmentalIssue: "environmentalIssue",
  padSubStatus_resultReceived: "resultReceived",
  padSubStatusmemberRefusedmemberRecentlyCompleted: "memberRecentlyCompleted",
  padSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  padSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  padSubStatusmemberRefusednotInterested: "notInterested",
  padSubStatusmemberRefusedother: "other",
  screen_kedSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  screen_kedSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  screen_kedSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  screen_kedSubStatusmemberRefusednotInterested: "notInterested",
  // screen_kedSubStatusmemberRefusedother: "other",
  screen_kedSubStatusmemberRefusedother: "other",
  screenkedSubStatusleftKit: "leftKit",
  screenkedSubStatuscollectedSample: "collectedSample",
  screenkedSubStatusmailKit: "mailKitDirectToMember",
  screenkedSubStatusresultReceived: "resultReceived",
  screenkedSubStatusmemberRefused: "memberRefused",
  screenfitSubStatusmemberRefusedmemberRecentlyCompleted:
    "memberRecentlyCompleted",
  screenfitSubStatusmemberRefusedscheduledToComplete: "scheduledToComplete",
  screenfitSubStatusmemberRefusedmemberApprehension: "memberApprehension",
  screenfitSubStatusmemberRefusednotInterested: "notInterested",
  screenfitSubStatusmemberRefusedOthero: "other",
  screenfitSubStatusleftKit: "leftKit",
  screenfitSubStatuscollectedSample: "collectedSample",
  screenfitSubStatusmailKit: "mailKitDirectToMember",
  screenfitSubStatusresultReceived: "resultReceived",
  screenfitSubStatusmemberRefused: "memberRefused",
  retinalExamSubStatusresultReceivedSubAnswersvalidImage: "validImage",
  retinalExamSubStatusresultReceivedSubAnswersinvalidImage: "invalidImage",
};

const screenings_optionsTreeWrappers2024 = [
  [
    "screen_microAlbuminSubStatusmemberRefused",
    [
      "screen_microAlbuminSubStatusmemberRefusedmemberRecentlyCompleted",
      "screen_microAlbuminSubStatusmemberRefusedscheduledToComplete",
      "screen_microAlbuminSubStatusmemberRefusedmemberApprehension",
      "screen_microAlbuminSubStatusmemberRefusednotInterested",
      "screen_microAlbuminSubStatusmemberRefusedother",
    ],
  ],
  [
    "screen_microAlbuminSubStatus",
    [
      "screen_microAlbuminSubStatus_leftKit",
      "screen_microAlbuminSubStatus_collectedSample",
      "screen_microAlbuminSubStatus_mailKit",
      "screen_microAlbuminSubStatus_resultReceived",
      "screen_microAlbuminSubStatus_memberRefused",
    ],
  ],

  [
    "screenaOneCSubStatus_memberRefused",
    [
      "screenaOneCSubStatusmemberRefusedmemberRecentlyCompleted",
      "screenaOneCSubStatusmemberRefusedscheduledToComplete",
      "screenaOneCSubStatusmemberRefusedmemberApprehension",
      "screenaOneCSubStatusmemberRefusednotInterested",
      "screenaOneCSubStatusmemberRefusedother",
    ],
  ],

  [
    "screen_aOneCSubStatus",
    [
      "screen_aOneCSubStatus_leftKit",
      "screen_aOneCSubStatus_collectedSample",
      "screen_aOneCSubStatus_mailKit",
      "screen_aOneCSubStatus_resultReceived",
      "screen_aOneCSubStatus_memberRefused",
    ],
  ],

  [
    "ldlSubStatus",
    [
      "ldlSubStatus_memberRefused",
      "ldlSubStatus_leftKit",
      "ldlSubStatus_collectedSample",
      "ldlSubStatus_mailKit",
      "ldlSubStatus_resultReceived",
    ],
  ],
  [
    "ldlSubStatusmemberRefused",
    [
      "ldlSubStatusmemberRefusedmemberRecentlyCompleted",
      "ldlSubStatusmemberRefusedscheduledToComplete",
      "ldlSubStatusmemberRefusedmemberApprehension",
      "ldlSubStatusmemberRefusednotInterested",
      "ldlSubStatusmemberRefusedother",
    ],
  ],

  [
    "dexaExamSubStatusmemberRefused",
    [
      "dexaExamSubStatusmemberRefusedmemberRecentlyCompleted",
      "dexaExamSubStatusmemberRefusedscheduledToComplete",
      "dexaExamSubStatusmemberRefusedmemberApprehension",
      "dexaExamSubStatusmemberRefusednotInterested",
      "dexaExamSubStatusmemberRefusedother",
    ],
  ],
  [
    "retinalExamSubStatusmemberRefused",
    [
      "retinalExamSubStatusmemberRefusedmemberRecentlyCompleted",
      "retinalExamSubStatusmemberRefusedscheduledToComplete",
      "retinalExamSubStatusmemberRefusedmemberApprehension",
      "retinalExamSubStatusmemberRefusednotInterested",
      "retinalExamSubStatusmemberRefusedother",
    ],
  ],

  [
    "dexaExamSubStatus",
    [
      "dexaExamSubStatus_memberRefused",
      "dexaExamSubStatus_examCompleted",
      "dexaExamSubStatus_environmentalIssue",
      "dexaExamSubStatus_resultReceived",
    ],
  ],
  [
    "retinalExamSubStatus",
    [
      "retinalExamSubStatus_memberRefused",
      "retinalExamSubStatus_examCompleted",
      "retinalExamSubStatus_environmentalIssue",
      "retinalExamSubStatus_resultReceived",
    ],
  ],

  [
    "padSubStatus",
    [
      "padSubStatus_memberRefused",
      "padSubStatus_examCompleted",
      "padSubStatus_environmentalIssue",
      "padSubStatus_resultReceived",
    ],
  ],
  [
    "padSubStatusmemberRefused",
    [
      "padSubStatusmemberRefusedmemberRecentlyCompleted",
      "padSubStatusmemberRefusedscheduledToComplete",
      "padSubStatusmemberRefusedmemberApprehension",
      "padSubStatusmemberRefusednotInterested",
      "padSubStatusmemberRefusedother",
    ],
  ],
  [
    "screen_kedSubStatusmemberRefused",
    [
      "screen_kedSubStatusmemberRefusedmemberRecentlyCompleted",
      "screen_kedSubStatusmemberRefusedscheduledToComplete",
      "screen_kedSubStatusmemberRefusedmemberApprehension",
      "screen_kedSubStatusmemberRefusednotInterested",
      "screen_kedSubStatusmemberRefusedother",
    ],
  ],

  [
    "screenkedSubStatus",
    [
      "screenkedSubStatusleftKit",
      "screenkedSubStatuscollectedSample",
      "screenkedSubStatusmailKit",
      "screenkedSubStatusresultReceived",
      "screenkedSubStatusmemberRefused",
    ],
  ],
  [
    "screenfitSubStatusmemberRefusedReason",
    [
      "screenfitSubStatusmemberRefusedmemberRecentlyCompleted",
      "screenfitSubStatusmemberRefusedscheduledToComplete",
      "screenfitSubStatusmemberRefusedmemberApprehension",
      "screenfitSubStatusmemberRefusednotInterested",
      "screenfitSubStatusmemberRefusedOthero",
    ],
  ],
  [
    "retinalExamSubStatusresultReceivedSubAnswers",
    [
      "retinalExamSubStatusresultReceivedSubAnswersvalidImage",
      "retinalExamSubStatusresultReceivedSubAnswersinvalidImage",
    ],
  ],
  [
    "screenfitSubStatus",
    [
      "screenfitSubStatusleftKit",
      "screenfitSubStatuscollectedSample",
      "screenfitSubStatusmailKit",
      "screenfitSubStatusresultReceived",
      "screenfitSubStatusmemberRefused",
    ],
  ],
];

export const getScreeningsData = (apiData, year) => {
  let nestedData = {};
  let flattenData = {};
  let flatmap = [];

  // screenings_flatmap.forEach((questionPair) => {
  //   nestedData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  // });
  screenings_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });
  flatmap.forEach((questionPair) => {
    nestedData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });
  let treeWrappers = getTreeWrapperByYear(year);

  treeWrappers.forEach((pointerLabels) => {
    nestedData[pointerLabels[0]] =
      getWrapper(nestedData, pointerLabels[1], year) || "";
  });

  // screenings_optionsTreeWrappers.forEach((pointerLabels) => {
  //   flattenData[pointerLabels[0]] =
  //     getWrapper(nestedData, pointerLabels[1]) || "";
  // });

  const microAlbumin = getOptionsSelectedYesNo(nestedData["microAlbuminVal"]);

  const microAlbuminComment = nestedData["microAlbuminComment"];

  const screen_microAlbuminSubStatusmemberRefusedComment =
    nestedData["screen_microAlbuminSubStatusmemberRefusedComment"];

  const screen_microAlbuminSubStatusComment =
    nestedData["screen_microAlbuminSubStatusComment"];

  const screen_microAlbuminSubStatusmemberRefusedotherdescribe =
    nestedData["screen_microAlbuminSubStatusmemberRefusedotherdescribe"];

  const screenmicroAlbuminTBarcode = nestedData["screenmicroAlbuminTBarcode"];

  const screenmicroAlbumincomments = nestedData["screenmicroAlbumincomments"];
  const screenmicroAlbumindiagnosis = nestedData["screenmicroAlbumindiagnosis"];

  const screenmicroAlbuminScreeningPerformedBy =
    nestedData["screenmicroAlbuminScreeningPerformedBy"];
  const screenfitScreeningPerformedBy =
    nestedData["screenfitScreeningPerformedBy"];
  const screenaOneCScreeningPerformedBy =
    nestedData["screenaOneCScreeningPerformedBy"];
  const ldlScreeningPerformedBy = nestedData["ldlScreeningPerformedBy"];
  const dexaScreeningPerformedBy = nestedData["dexaScreeningPerformedBy"];
  const retinalExamScreeningPerformedBy =
    nestedData["retinalExamScreeningPerformedBy"];
  const padScreeningPerformedBy = nestedData["padScreeningPerformedBy"];
  const screenkedScreeningPerformedBy =
    nestedData["screenkedScreeningPerformedBy"];

  const screenmicroAlbuminsResult = nestedData["screenmicroAlbuminsResult"];
  const screenmicroAlbuminexamDate = nestedData["screenmicroAlbuminexamDate"];

  const aOneC = getOptionsSelectedYesNo(nestedData["aOneC"]);
  const aOneCComment = nestedData["aOneCComment"];

  const screen_aOneCSubStatusComment =
    nestedData["screen_aOneCSubStatusComment"];
  const screenaOneCSubStatus_memberRefusedComment =
    nestedData["screenaOneCSubStatus_memberRefusedComment"];

  const screen_aOneCSubStatusmemberRefusedotherdescribe =
    nestedData["screen_aOneCSubStatusmemberRefusedotherdescribe"];

  const screenaOneCTBarcode = nestedData["screenaOneCTBarcode"];

  const screenaOneCexamDate = nestedData["screenaOneCexamDate"];

  const screenaOneCsResult = nestedData["screenaOneCsResult"];

  const screenaOneCdiagnosis = nestedData["screenaOneCdiagnosis"];
  const screenaOneCComment = nestedData["screenaOneCComment"];

  const ldl = getOptionsSelectedYesNo(nestedData["ldlVal"]);
  const ldlComment = nestedData["ldlComment"];

  const retinalExam = getOptionsSelectedYesNo(nestedData["retinalExamVal"]);
  const retinalExamComment = nestedData["retinalExamComment"];

  const dexa = getOptionsSelectedYesNo(nestedData["dexaVal"]);

  const dexaComment = nestedData["dexaComment"];

  const retinalExamSubStatusmemberRefusedComment =
    nestedData["retinalExamSubStatusmemberRefusedComment"];
  const dexaExamSubStatusmemberRefusedComment =
    nestedData["dexaExamSubStatusmemberRefusedComment"];
  const ldlSubStatusmemberRefusedComment =
    nestedData["ldlSubStatusmemberRefusedComment"];

  const dexaExamSubStatusComment = nestedData["dexaExamSubStatusComment"];
  const retinalExamSubStatusComment = nestedData["retinalExamSubStatusComment"];
  const ldlSubStatusComment = nestedData["ldlSubStatusComment"];

  const ldlTBarcode = nestedData["ldlTBarcode"];
  const ldlexamDate = nestedData["ldlexamDate"];

  const ldlsResult = nestedData["ldlsResult"];
  const ldldiagnosis = nestedData["ldldiagnosis"];
  const ldlcomment = nestedData["ldlcomment"];

  const ldlSubStatusmemberRefusedotherdescribe =
    nestedData["ldlSubStatusmemberRefusedotherdescribe"];

  const retinalExamSubStatusmemberRefusedotherdescribe =
    nestedData["retinalExamSubStatusmemberRefusedotherdescribe"];
  const dexaExamSubStatusmemberRefusedotherdescribe =
    nestedData["dexaExamSubStatusmemberRefusedotherdescribe"];

  const dexaExamDate = nestedData["dexaExamDate"];
  const dexasResult = nestedData["dexasResult"];

  const dexasTScore = nestedData["dexasTScore"];
  const dexasZScore = nestedData["dexasZScore"];
  // const dexasSosResult = nestedData["dexasSosResult"];

  const dexadiagnosis = nestedData["dexadiagnosis"];
  const dexaSubStatusComment = nestedData["dexaSubStatusComment"];

  const retinalExamsResult = nestedData["retinalExamsResult"];
  const retinalExamExamDate = nestedData["retinalExamExamDate"];
  const retinalExamdiagnosis = nestedData["retinalExamdiagnosis"];

  const retinalExamsLeResult = nestedData["retinalExamsLeResult"];
  const retinalExamsRgResult = nestedData["retinalExamsRgResult"];

  const retinalExamComments = nestedData["retinalExamComments"];
  const retinalExamSubStatusresultReceivedSubAnswersComment =
    nestedData["retinalExamSubStatusresultReceivedSubAnswersComment"];
  const padVal = getOptionsSelectedYesNo(nestedData["padVal"]);
  const padValComment = nestedData["padValComment"];

  const padSubStatusComment = nestedData["padSubStatusComment"];

  const padSubStatusmemberRefusedComment =
    nestedData["padSubStatusmemberRefusedComment"];

  const padComments = nestedData["padComments"];
  const padExamDate = nestedData["padExamDate"];
  const padExamsResultRight = nestedData["padExamsResultRight"];
  const padExamsResultLeft = nestedData["padExamsResultLeft"];
  const padDiagnosis = nestedData["padDiagnosis"];

  const peripheralResultLeft = getSelectedCheckboxesOther(
    [
      nestedData["peripheralResultLeftNormal"],
      nestedData["peripheralResultLeftModerate"],
      nestedData["peripheralResultLeftSiginificant"],
      nestedData["peripheralResultLeftSevere"],
      nestedData["peripheralResultLeftMild"],
    ],
    ["normal", "moderate", "siginificant", "severe", "mild"],
  );

  const peripheralResultRight = getSelectedCheckboxesOther(
    [
      nestedData["peripheralResultRightNormal"],
      nestedData["peripheralResultRightModerate"],
      nestedData["peripheralResultRightSiginificant"],
      nestedData["peripheralResultRightSevere"],
      nestedData["peripheralResultRightMild"],
    ],
    ["normal", "moderate", "siginificant", "severe", "mild"],
  );

  const peripheralResultLeftNormal = nestedData["peripheralResultLeftNormal"];

  const peripheralResultLeftModerate =
    nestedData["peripheralResultLeftModerate"];
  const peripheralResultLeftSiginificant =
    nestedData["peripheralResultLeftSiginificant"];
  const peripheralResultLeftSevere = nestedData["peripheralResultLeftSevere"];
  const peripheralResultLeftMild = nestedData["peripheralResultLeftMild"];

  const peripheralResultRightNormal = nestedData["peripheralResultRightNormal"];

  const peripheralResultRightModerate =
    nestedData["peripheralResultRightModerate"];
  const peripheralResultRightSiginificant =
    nestedData["peripheralResultRightSiginificant"];
  const peripheralResultRightSevere = nestedData["peripheralResultRightSevere"];
  const peripheralResultRightMild = nestedData["peripheralResultRightMild"];

  const padSubStatusmemberRefusedotherdescribe =
    nestedData["padSubStatusmemberRefusedotherdescribe"];
  const peripheralResultLeftComment = nestedData["peripheralResultLeftComment"];
  const peripheralResultRightComment =
    nestedData["peripheralResultRightComment"];
  nestedData = {
    ...nestedData,
    microAlbumin: microAlbumin,
    microAlbuminComment,
    screen_microAlbuminSubStatusComment,
    screen_microAlbuminSubStatusmemberRefusedComment,
    screen_microAlbuminSubStatusmemberRefusedotherdescribe,
    screenmicroAlbuminTBarcode,
    screenmicroAlbumincomments,
    screenmicroAlbumindiagnosis,
    screenmicroAlbuminsResult,
    screenmicroAlbuminexamDate,

    screenmicroAlbuminScreeningPerformedBy,
    screenfitScreeningPerformedBy,
    screenaOneCScreeningPerformedBy,
    ldlScreeningPerformedBy,
    dexaScreeningPerformedBy,
    retinalExamScreeningPerformedBy,
    padScreeningPerformedBy,
    screenkedScreeningPerformedBy,

    aOneC,
    aOneCComment,
    screen_aOneCSubStatusComment,
    screenaOneCSubStatus_memberRefusedComment,
    screen_aOneCSubStatusmemberRefusedotherdescribe,
    screenaOneCTBarcode,
    screenaOneCsResult,
    screenaOneCComment,
    screenaOneCdiagnosis,
    screenaOneCexamDate,

    ldl,
    ldlComment,
    retinalExam,
    retinalExamComment,
    dexa,
    dexaComment,
    retinalExamSubStatusmemberRefusedComment,
    dexaExamSubStatusmemberRefusedComment,
    ldlSubStatusmemberRefusedComment,
    dexaExamSubStatusComment,
    retinalExamSubStatusComment,
    ldlSubStatusComment,
    ldlTBarcode,
    ldlexamDate,
    ldlsResult,
    ldldiagnosis,
    ldlcomment,
    ldlSubStatusmemberRefusedotherdescribe,
    retinalExamSubStatusmemberRefusedotherdescribe,
    dexaExamSubStatusmemberRefusedotherdescribe,

    dexaExamDate,
    dexasResult,

    dexasTScore,
    dexasZScore,
    //dexasSosResult,

    dexadiagnosis,

    retinalExamsResult,

    retinalExamsRgResult,
    retinalExamsLeResult,

    retinalExamExamDate,
    retinalExamdiagnosis,
    dexaSubStatusComment,
    retinalExamComments,
    retinalExamSubStatusresultReceivedSubAnswersComment,
    padVal,
    padValComment,
    padSubStatusComment,
    padSubStatusmemberRefusedComment,
    padComments,
    padExamDate,
    padExamsResultRight,
    padExamsResultLeft,
    padDiagnosis,
    peripheralResultLeft,
    peripheralResultRight,
    // educatedComment,
    peripheralResultLeftNormal,
    peripheralResultLeftModerate,
    peripheralResultLeftSiginificant,
    peripheralResultLeftSevere,
    peripheralResultLeftMild,
    peripheralResultRightNormal,
    peripheralResultRightModerate,
    peripheralResultRightSiginificant,
    peripheralResultRightSevere,
    peripheralResultRightMild,
    // educated,
    padSubStatusmemberRefusedotherdescribe,
    peripheralResultLeftComment,
    peripheralResultRightComment,
  };

  if (year === "2024" || year === "2025") {
    const ked = getOptionsSelectedYesNo(nestedData["ked"]);

    const kedComment = nestedData["kedComment"];

    const screenkedSubStatusComment = nestedData["screenkedSubStatusComment"];

    const screen_kedSubStatusmemberRefusedComment =
      nestedData["screen_kedSubStatusmemberRefusedComment"];

    const screen_kedSubStatusmemberRefusedotherdescribe =
      nestedData["screen_kedSubStatusmemberRefusedotherdescribe"];

    const screenkedTBarcode = nestedData["screenkedTBarcode"];

    const screenkedcomment = nestedData["screenkedcomment"];
    const screenkeddiagnosis = nestedData["screenkeddiagnosis"];

    const screenkedexamDate = nestedData["screenkedexamDate"];
    const fit = getOptionsSelectedYesNo(nestedData["fit"]);

    const fitComment = nestedData["fitComment"];

    const screenfitSubStatusComment = nestedData["screenfitSubStatusComment"];

    const screenfitSubStatusmemberRefusedReasonComment =
      nestedData["screenfitSubStatusmemberRefusedReasonComment"];

    const screenfitSubStatusmemberRefusedotherdescribe =
      nestedData["screenfitSubStatusmemberRefusedotherdescribe"];

    const screenfitTBarcode = nestedData["screenfitTBarcode"];

    const screenfitcomment = nestedData["screenfitcomment"];
    const screenfitdiagnosis = nestedData["screenfitdiagnosis"];
    const screenfitResult = nestedData["screenfitResult"];
    const screenfitexamDate = nestedData["screenfitexamDate"];

    const educated =
      nestedData["educated"] === "1"
        ? ["membereducatedonresultsverbalizedunderstanding"]
        : "";
    const educatedComment = nestedData["educatedComment"];
    const providerNotified =
      nestedData["providerNotified"] === "1" ? ["providerNotifieds"] : "";

    const providerNotifiedComment = nestedData["providerNotifiedComment"];
    const cMReferral = nestedData["cMReferral"] === "1" ? ["cMReferral"] : "";

    const cMReferralComment = nestedData["cMReferralComment"];

    const screenkedUrineAlbumin = nestedData["screenkedUrineAlbumin"];
    const screenkedUrineCreatinine = nestedData["screenkedUrineCreatinine"];
    const screenkedCreatinine = nestedData["screenkedCreatinine"];
    const screenkedUrineALB = nestedData["screenkedUrineALB"];
    const screenkedeGFR = nestedData["screenkedeGFR"];
    const padTestingEligibility = nestedData["padTestingEligibility"];
    nestedData = {
      ...nestedData,
      ked: ked,
      kedComment,
      screenkedSubStatusComment,
      screen_kedSubStatusmemberRefusedComment,
      screen_kedSubStatusmemberRefusedotherdescribe,
      screenkedTBarcode,
      screenkedcomment,
      screenkeddiagnosis,

      screenkedexamDate,
      fit,
      fitComment,
      screenfitSubStatusComment,
      screenfitSubStatusmemberRefusedReasonComment,
      screenfitSubStatusmemberRefusedotherdescribe,
      screenfitTBarcode,
      screenfitcomment,
      screenfitdiagnosis,
      screenfitResult,
      screenfitexamDate,
      educated,
      educatedComment,
      providerNotified,
      providerNotifiedComment,
      cMReferral,
      cMReferralComment,
      screenkedUrineAlbumin,
      screenkedUrineCreatinine,
      screenkedUrineALB,
      screenkedeGFR,
      screenkedCreatinine,
      padTestingEligibility,
    };
  } else if (year === "2023") {
    const educated =
      nestedData["educated"] === "1"
        ? ["membereducatedonresultsverbalizedunderstanding"]
        : "";
    const educatedComment = nestedData["educatedComment"];
    const fobt = getOptionsSelectedYesNo(nestedData["fobtVal"]);

    const fobtComment = nestedData["fobtComment"];

    const screen_fobtSubStatusComment =
      nestedData["screen_fobtSubStatusComment"];

    const screen_fobtSubStatusmemberRefusedReasonComment =
      nestedData["screen_fobtSubStatusmemberRefusedReasonComment"];

    const screen_fobtSubStatusmemberRefusedotherdescribe =
      nestedData["screen_fobtSubStatusmemberRefusedotherdescribe"];

    const screenfobtTBarcode = nestedData["screenfobtTBarcode"];

    const screenfobtcomment = nestedData["screenfobtcomment"];
    const screenfobtdiagnosis = nestedData["screenfobtdiagnosis"];
    const screenfobtResult = nestedData["screenfobtResult"];
    const screenfobtexamDate = nestedData["screenfobtexamDate"];

    nestedData = {
      ...nestedData,
      educated,
      educatedComment,
      fobt,
      fobtComment,
      screen_fobtSubStatusComment,
      screen_fobtSubStatusmemberRefusedReasonComment,
      screen_fobtSubStatusmemberRefusedotherdescribe,
      screenfobtTBarcode,
      screenfobtcomment,
      screenfobtdiagnosis,
      screenfobtResult,
      screenfobtexamDate,
    };
  }
  return nestedData;
};

export const setScreeningsData = (formData, year) => {
  let tempData = {};

  let treeWrappers = getTreeWrapperByYear(year);
  treeWrappers.forEach((pointerLabels) => {
    tempData = {
      ...tempData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1], year),
    };
  });

  let payloadData = {};

  const microAlbuminVal = setOptionsSelectedYesNo(formData["microAlbumin"]);

  const microAlbuminComment = formData["microAlbuminComment"];

  const screen_microAlbuminSubStatusmemberRefusedComment =
    formData["screen_microAlbuminSubStatusmemberRefusedComment"];

  const screen_microAlbuminSubStatusComment =
    formData["screen_microAlbuminSubStatusComment"];

  const screen_microAlbuminSubStatusmemberRefusedotherdescribe =
    formData["screen_microAlbuminSubStatusmemberRefusedotherdescribe"];

  const screenmicroAlbuminTBarcode = formData["screenmicroAlbuminTBarcode"];

  const screenmicroAlbumincomments = formData["screenmicroAlbumincomments"];
  const screenmicroAlbumindiagnosis = formData["screenmicroAlbumindiagnosis"];

  const screenmicroAlbuminScreeningPerformedBy =
    formData["screenmicroAlbuminScreeningPerformedBy"];
  const screenfitScreeningPerformedBy =
    formData["screenfitScreeningPerformedBy"];
  const screenaOneCScreeningPerformedBy =
    formData["screenaOneCScreeningPerformedBy"];
  const ldlScreeningPerformedBy = formData["ldlScreeningPerformedBy"];
  const dexaScreeningPerformedBy = formData["dexaScreeningPerformedBy"];
  const retinalExamScreeningPerformedBy =
    formData["retinalExamScreeningPerformedBy"];
  const padScreeningPerformedBy = formData["padScreeningPerformedBy"];
  const screenkedScreeningPerformedBy =
    formData["screenkedScreeningPerformedBy"];

  const screenmicroAlbuminsResult = formData["screenmicroAlbuminsResult"];
  const screenmicroAlbuminexamDate = formData["screenmicroAlbuminexamDate"];

  const aOneC = setOptionsSelectedYesNo(formData["aOneC"]);
  const aOneCComment = formData["aOneCComment"];

  const screen_aOneCSubStatusComment = formData["screen_aOneCSubStatusComment"];
  const screenaOneCSubStatus_memberRefusedComment =
    formData["screenaOneCSubStatus_memberRefusedComment"];

  const screen_aOneCSubStatusmemberRefusedotherdescribe =
    formData["screen_aOneCSubStatusmemberRefusedotherdescribe"];

  const screenaOneCTBarcode = formData["screenaOneCTBarcode"];

  const screenaOneCexamDate = formData["screenaOneCexamDate"];

  const screenaOneCsResult = formData["screenaOneCsResult"];

  const screenaOneCdiagnosis = formData["screenaOneCdiagnosis"];
  const screenaOneCComment = formData["screenaOneCComment"];

  const ldlVal = setOptionsSelectedYesNo(formData["ldl"]);
  const ldlComment = formData["ldlComment"];

  const retinalExamVal = setOptionsSelectedYesNo(formData["retinalExam"]);
  const retinalExamComment = formData["retinalExamComment"];

  const dexaVal = setOptionsSelectedYesNo(formData["dexa"]);

  const dexaComment = formData["dexaComment"];

  const retinalExamSubStatusmemberRefusedComment =
    formData["retinalExamSubStatusmemberRefusedComment"];
  const dexaExamSubStatusmemberRefusedComment =
    formData["dexaExamSubStatusmemberRefusedComment"];
  const ldlSubStatusmemberRefusedComment =
    formData["ldlSubStatusmemberRefusedComment"];

  const dexaExamSubStatusComment = formData["dexaExamSubStatusComment"];
  const retinalExamSubStatusComment = formData["retinalExamSubStatusComment"];
  const ldlSubStatusComment = formData["ldlSubStatusComment"];

  const ldlTBarcode = formData["ldlTBarcode"];
  const ldlexamDate = formData["ldlexamDate"];

  const ldlsResult = formData["ldlsResult"];
  const ldldiagnosis = formData["ldldiagnosis"];
  const ldlcomment = formData["ldlcomment"];

  const ldlSubStatusmemberRefusedotherdescribe =
    formData["ldlSubStatusmemberRefusedotherdescribe"];

  const retinalExamSubStatusmemberRefusedotherdescribe =
    formData["retinalExamSubStatusmemberRefusedotherdescribe"];
  const dexaExamSubStatusmemberRefusedotherdescribe =
    formData["dexaExamSubStatusmemberRefusedotherdescribe"];

  const dexaExamDate = formData["dexaExamDate"];
  const dexasResult = formData["dexasResult"];

  const dexasTScore = formData["dexasTScore"];
  const dexasZScore = formData["dexasZScore"];
  //const dexasSosResult = formData["dexasSosResult"];

  const dexadiagnosis = formData["dexadiagnosis"];
  const dexaSubStatusComment = formData["dexaSubStatusComment"];

  const retinalExamsResult = formData["retinalExamsResult"];

  const retinalExamsLeResult = formData["retinalExamsLeResult"];
  const retinalExamsRgResult = formData["retinalExamsRgResult"];

  const retinalExamExamDate = formData["retinalExamExamDate"];
  const retinalExamdiagnosis = formData["retinalExamdiagnosis"];

  const retinalExamComments = formData["retinalExamComments"];
  const retinalExamSubStatusresultReceivedSubAnswersComment =
    formData["retinalExamSubStatusresultReceivedSubAnswersComment"];
  const padVal = setOptionsSelectedYesNo(formData["padVal"]);
  const padValComment = formData["padValComment"];

  const padSubStatusComment = formData["padSubStatusComment"];

  const padSubStatusmemberRefusedComment =
    formData["padSubStatusmemberRefusedComment"];

  const padComments = formData["padComments"];
  const padExamDate = formData["padExamDate"];
  const padExamsResultRight = formData["padExamsResultRight"];
  const padExamsResultLeft = formData["padExamsResultLeft"];
  const padDiagnosis = formData["padDiagnosis"];

  const educatedComment = formData["educatedComment"];

  const peripheralResultRightSelected = formData["peripheralResultRight"];
  const peripheralResultLeftSelected = formData["peripheralResultLeft"];
  const peripheralResultLeft = setSelectedCheckboxesOther(
    peripheralResultLeftSelected,

    [
      "peripheralResultLeftNormal",
      "peripheralResultLeftModerate",
      "peripheralResultLeftSiginificant",
      "peripheralResultLeftSevere",
      "peripheralResultLeftMild",
    ],
    ["normal", "moderate", "siginificant", "severe", "mild"],
  );

  const peripheralResultRight = setSelectedCheckboxesOther(
    peripheralResultRightSelected,
    [
      "peripheralResultRightNormal",
      "peripheralResultRightModerate",
      "peripheralResultRightSiginificant",
      "peripheralResultRightSevere",
      "peripheralResultRightMild",
    ],
    ["normal", "moderate", "siginificant", "severe", "mild"],
  );

  const educatedSelected = formData["educated"];
  // const educated = setSelectedCheckboxes(
  //   educatedSelected,
  //   ["educated"],
  //   ["membereducatedonresultsverbalizedunderstanding"],
  // );
  const educated = educatedSelected?.includes(
    "membereducatedonresultsverbalizedunderstanding",
  )
    ? "1"
    : "";

  const padSubStatusmemberRefusedotherdescribe =
    formData["padSubStatusmemberRefusedotherdescribe"];

  const peripheralResultLeftComment = formData["peripheralResultLeftComment"];
  const peripheralResultRightComment = formData["peripheralResultRightComment"];
  tempData = {
    ...tempData,
    ...educated,
    educatedComment,
    microAlbuminVal,
    microAlbuminComment,
    screen_microAlbuminSubStatusComment,
    screen_microAlbuminSubStatusmemberRefusedComment,
    screen_microAlbuminSubStatusmemberRefusedotherdescribe,
    screenmicroAlbuminTBarcode,
    screenmicroAlbumincomments,
    screenmicroAlbumindiagnosis,

    screenmicroAlbuminScreeningPerformedBy,
    screenfitScreeningPerformedBy,
    screenaOneCScreeningPerformedBy,
    ldlScreeningPerformedBy,
    dexaScreeningPerformedBy,
    retinalExamScreeningPerformedBy,
    padScreeningPerformedBy,
    screenkedScreeningPerformedBy,

    screenmicroAlbuminsResult,
    screenmicroAlbuminexamDate,
    padSubStatusmemberRefusedotherdescribe,

    aOneC,
    aOneCComment,
    screen_aOneCSubStatusComment,
    screenaOneCSubStatus_memberRefusedComment,
    screen_aOneCSubStatusmemberRefusedotherdescribe,
    screenaOneCTBarcode,
    screenaOneCsResult,
    screenaOneCComment,
    screenaOneCdiagnosis,
    screenaOneCexamDate,

    ldlVal,
    ldlComment,
    retinalExamVal,
    retinalExamComment,
    dexaVal,
    dexaComment,
    retinalExamSubStatusmemberRefusedComment,
    dexaExamSubStatusmemberRefusedComment,
    ldlSubStatusmemberRefusedComment,
    dexaExamSubStatusComment,
    retinalExamSubStatusComment,
    ldlSubStatusComment,
    ldlTBarcode,
    ldlexamDate,
    ldlsResult,
    ldldiagnosis,
    ldlcomment,
    ldlSubStatusmemberRefusedotherdescribe,
    retinalExamSubStatusmemberRefusedotherdescribe,
    dexaExamSubStatusmemberRefusedotherdescribe,

    dexaExamDate,
    dexasResult,

    dexasTScore,
    dexasZScore,
    //dexasSosResult,

    dexadiagnosis,

    retinalExamsResult,

    retinalExamsLeResult,
    retinalExamsRgResult,

    retinalExamExamDate,
    retinalExamdiagnosis,
    dexaSubStatusComment,
    retinalExamComments,
    retinalExamSubStatusresultReceivedSubAnswersComment,
    padVal,
    padValComment,
    padSubStatusComment,
    padSubStatusmemberRefusedComment,
    padComments,
    padExamDate,
    padExamsResultRight,
    padExamsResultLeft,
    padDiagnosis,
    ...peripheralResultLeft,
    ...peripheralResultRight,
    peripheralResultLeftComment,
    peripheralResultRightComment,
  };

  if (year === "2023") {
    const educatedSelected = formData["educated"];
    // const educated = setSelectedCheckboxes(
    //   educatedSelected,
    //   ["educated"],
    //   ["membereducatedonresultsverbalizedunderstanding"],
    // );
    const educated = educatedSelected?.includes(
      "membereducatedonresultsverbalizedunderstanding",
    )
      ? "1"
      : "";
    const educatedComment = formData["educatedComment"];
    const fobtVal = setOptionsSelectedYesNo(formData["fobt"]);

    const fobtComment = formData["fobtComment"];

    const screen_fobtSubStatusComment = formData["screen_fobtSubStatusComment"];

    const screen_fobtSubStatusmemberRefusedReasonComment =
      formData["screen_fobtSubStatusmemberRefusedReasonComment"];

    const screen_fobtSubStatusmemberRefusedotherdescribe =
      formData["screen_fobtSubStatusmemberRefusedotherdescribe"];

    const screenfobtTBarcode = formData["screenfobtTBarcode"];

    const screenfobtcomment = formData["screenfobtcomment"];
    const screenfobtdiagnosis = formData["screenfobtdiagnosis"];
    const screenfobtResult = formData["screenfobtResult"];
    const screenfobtexamDate = formData["screenfobtexamDate"];
    tempData = {
      ...tempData,
      educated,
      educatedComment,
      fobtVal,
      fobtComment,
      screen_fobtSubStatusComment,
      screen_fobtSubStatusmemberRefusedReasonComment,
      screen_fobtSubStatusmemberRefusedotherdescribe,
      screenfobtTBarcode,
      screenfobtcomment,
      screenfobtdiagnosis,
      screenfobtResult,
      screenfobtexamDate,
    };
  } else if (year === "2024" || year === "2025") {
    const ked = setOptionsSelectedYesNo(formData["ked"]);
    const kedComment = formData["kedComment"];
    const screenkedSubStatusComment = formData["screenkedSubStatusComment"];
    const screen_kedSubStatusmemberRefusedComment =
      formData["screen_kedSubStatusmemberRefusedComment"];
    const screen_kedSubStatusmemberRefusedotherdescribe =
      formData["screen_kedSubStatusmemberRefusedotherdescribe"];
    const screenkedTBarcode = formData["screenkedTBarcode"];
    const screenkedcomment = formData["screenkedcomment"];
    const screenkeddiagnosis = formData["screenkeddiagnosis"];

    const screenkedexamDate = formData["screenkedexamDate"];
    const fit = setOptionsSelectedYesNo(formData["fit"]);
    const fitComment = formData["fitComment"];
    const screenfitSubStatusComment = formData["screenfitSubStatusComment"];
    const screenfitSubStatusmemberRefusedReasonComment =
      formData["screenfitSubStatusmemberRefusedReasonComment"];
    const screenfitSubStatusmemberRefusedotherdescribe =
      formData["screenfitSubStatusmemberRefusedotherdescribe"];
    const screenfitTBarcode = formData["screenfitTBarcode"];
    const screenfitcomment = formData["screenfitcomment"];
    const screenfitdiagnosis = formData["screenfitdiagnosis"];
    const screenfitResult = formData["screenfitResult"];
    const screenfitexamDate = formData["screenfitexamDate"];

    const providerNotifiedSel = formData["providerNotified"];
    const educatedSel = formData["educated"];

    const providerNotified = providerNotifiedSel?.includes("providerNotifieds")
      ? "1"
      : "";
    const providerNotifiedComment = formData["providerNotifiedComment"];

    const educated = educatedSel?.includes(
      "membereducatedonresultsverbalizedunderstanding",
    )
      ? "1"
      : "";
    const educatedComment = formData["educatedComment"];

    const cMReferralSel = formData["cMReferral"];

    const cMReferral = cMReferralSel?.includes("cMReferral") ? "1" : "";

    const cMReferralComment = formData["cMReferralComment"];

    const screenkedUrineAlbumin = formData["screenkedUrineAlbumin"];
    const screenkedUrineCreatinine = formData["screenkedUrineCreatinine"];
    const screenkedCreatinine = formData["screenkedCreatinine"];
    const screenkedUrineALB = formData["screenkedUrineALB"];
    const screenkedeGFR = formData["screenkedeGFR"];
    const padTestingEligibility = formData["padTestingEligibility"];

    tempData = {
      ...tempData,
      ked: ked,
      kedComment,
      screenkedSubStatusComment,
      screen_kedSubStatusmemberRefusedComment,
      screen_kedSubStatusmemberRefusedotherdescribe,
      screenkedTBarcode,
      screenkedcomment,
      screenkeddiagnosis,

      screenkedexamDate,
      fit,
      fitComment,
      screenfitSubStatusComment,
      screenfitSubStatusmemberRefusedReasonComment,
      screenfitSubStatusmemberRefusedotherdescribe,
      screenfitTBarcode,
      screenfitcomment,
      screenfitdiagnosis,
      screenfitResult,
      screenfitexamDate,
      educated,
      educatedComment,
      providerNotified,
      providerNotifiedComment,
      cMReferral,
      cMReferralComment,
      screenkedUrineAlbumin,
      screenkedUrineCreatinine,
      screenkedUrineALB,
      screenkedeGFR,
      screenkedCreatinine,
      padTestingEligibility: padTestingEligibility || {},
    };
  }

  let flatmap = [];

  screenings_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  flatmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getTreeMapperByYear = (year) => {
  let temp = {};
  if (year === "2023") {
    temp = { ...screenings_optionsTreeMappers2023 };
  } else if (year === "2024" || year === "2025") {
    temp = { ...screenings_optionsTreeMappers2024 };
  }

  return temp;
};

const getTreeWrapperByYear = (year) => {
  let temp = [];
  if (year === "2023") {
    temp = [...screenings_optionsTreeWrappers2023];
  } else if (year === "2024" || year === "2025") {
    temp = [...screenings_optionsTreeWrappers2024];
  }

  return temp;
};
export const getOptionsSelected = (optionObject, optionArray, year) => {
  let treeMappers = getTreeMapperByYear(year);
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption = selectedVariable.map((variable) => {
    return treeMappers[variable];
  });

  return selectedOption;
};

export const setOptionsSelected = (optionsSelected, optionArray, year) => {
  let temp = {};
  let treeMappers = getTreeMapperByYear(year);

  optionArray.forEach((option) => {
    temp[option] =
      optionsSelected &&
      optionsSelected.length > 0 &&
      optionsSelected.includes(treeMappers[option])
        ? "1"
        : "";
  });
  return temp;
};

const getWrapper = (tempData, keyArray, year) => {
  let temp = getOptionsSelected(tempData, keyArray, year);
  return temp;
};

const setWrapper = (tempData, keyArray, year) => {
  let temp = setOptionsSelected(tempData, keyArray, year);
  return temp;
};

export const getSubTree = (optionName, optionValue, year) => {
  let temp;
  let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  const tempIndex = lodash.findIndex(treeWrappers, function (optTree) {
    return optTree[0] === optionName;
  });

  const tempTreeNodes = treeWrappers?.[tempIndex]
    ? treeWrappers[tempIndex][1]
    : [];

  tempTreeNodes.forEach((node, index) => {
    if (treeMappers[node] === optionValue) {
      temp = node;
    }
  });

  temp = temp && temp !== "" ? temp.substring(0, temp.length - 3) : "";
  temp = temp && temp !== "" ? `${temp}Sub` : "";

  return temp;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let treeKeys = Object.keys(formData);
  treeKeys.forEach((node) => {
    if (node.includes(subTreeRegex)) {
      delete formData[node];
    }
  });
  return formData;
};

export const getSelectedCheckboxesOther = (optionKeyArr, optionValueArr) => {
  let temp = "";

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      temp = optionValueArr[index] || "";
    }
  });

  return temp;
};

export const setSelectedCheckboxesOther = (
  optionArr,
  optionKeyArr,
  optionValueArr,
) => {
  let temp = {};

  optionValueArr.forEach((ele, index) => {
    temp[optionKeyArr[index]] = optionArr?.includes(ele) ? "1" : "";
  });

  return temp;
};
