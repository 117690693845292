import { AUTH_TYPES } from "../types/auth_types";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = {
  isAuthenticated: !!user,
  user: user || null,
  loading: false,
  error: null,
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_TYPES.LOGIN_REQUEST:
    case AUTH_TYPES.REGISTER_REQUEST:
    case AUTH_TYPES.SEND_OTP_REQUEST:
    case AUTH_TYPES.VALIDATE_OTP_REQUEST:
    case AUTH_TYPES.UPDATE_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };

    case AUTH_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
        error: null,
      };

    case AUTH_TYPES.REGISTER_SUCCESS:
    case AUTH_TYPES.SEND_OTP_SUCCESS:
    case AUTH_TYPES.VALIDATE_OTP_SUCCESS:
    case AUTH_TYPES.UPDATE_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null };

    case AUTH_TYPES.LOGIN_FAILURE:
    case AUTH_TYPES.REGISTER_FAILURE:
    case AUTH_TYPES.SEND_OTP_FAILURE:
    case AUTH_TYPES.VALIDATE_OTP_FAILURE:
    case AUTH_TYPES.UPDATE_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case AUTH_TYPES.LOGOUT:
      return {
        isAuthenticated: false,
        user: null,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}
