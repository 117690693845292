import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getChangeSuccess,
  getResetSuccess,
  setChangeSuccess,
  setResetSuccess,
} from "../../GlobalFunction";
import BG from "../../assets/focuscares_bg.jpg";
import Logo from "../../assets/focuscares_logo.png";
import Button from "../../components/button/button";
import Image from "../../components/image/image";
import Input from "../../components/input/input";
import Toaster from "../../components/toaster/toaster";
import { useSession } from "../../contexts/SessionContext";
import { useUserContext } from "../../contexts/UserContext";
import Labels from "../../resource_files/Labels.resx";
import ValidationMessage from "../../resource_files/ValidationMessage.resx";
import { getApi, postApi } from "../../utils/axiosApiWrapper";
import "./login.css";
import Loader from "../../components/Loader/Loader";
import axiosWrapper from "../../api/axiosWrapper";

const Login = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(<Visibility />);
  const [toasterOpen, settoasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterMessage, setToasterMessage] = useState("");
  const [inlineMessageOpen, setinlineMessageOpen] = useState(false);
  const [inlineMessage, setInlineMessage] = useState("");
  const [inlineMessageType, setInlineMessageType] = useState(""); // e.g., "error" or "success"
  const resetSuccess = getResetSuccess();
  const changeSuccess = getChangeSuccess();
  const [userNameError, setUserNameError] = useState("");
  const [isCaptchaRequired, setIsCaptchaRequired] = useState(false);
  const [captchaCode, setCaptchaCode] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [captchaDisplay, setCaptchaDisplay] = useState({
    show: false,
    code: "",
  });
  const [showLimitExceeded, setShowLimitExceeded] = useState(false);

  const {
    OtpSessionExpired,
    resetSessionExpired,
    changeSessionExpired,
    logoutSuccess,
  } = useSession();
  const {
    setUser,
    setHasLoggedIn,
    updateRoles,
    setProfileImg,
    setUserClients,
  } = useUserContext();
  const [messageDisplayed, setMessageDisplayed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const path = window.location.pathname;
  const pathName = path.substring(path.lastIndexOf("/") + 1);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {}, [userName, password, error]);
  const passwordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };
  useEffect(() => {
    if (!passwordVisible) {
      setPasswordIcon(<VisibilityOff />);
    } else {
      setPasswordIcon(<Visibility />);
    }
  }, [passwordVisible]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    const hasMessageBeenDisplayed = localStorage.getItem("messageDisplayed");

    if (OtpSessionExpired && !messageDisplayed && !hasMessageBeenDisplayed) {
      setInlineMessageType("error");
      setInlineMessage(ValidationMessage.OTPSession_Expired);
      setMessageDisplayed(true);
      setinlineMessageOpen(true);
      localStorage.setItem("messageDisplayed", "true");
    } else if (
      resetSessionExpired &&
      !messageDisplayed &&
      !hasMessageBeenDisplayed
    ) {
      // Show the error toaster message for Reset Password Session Expired
      setInlineMessageType("error");
      setInlineMessage(ValidationMessage.Reset_Password_Session_Expired);
      setMessageDisplayed(true);
      setinlineMessageOpen(true);

      // Store in local storage to remember the message was displayed
      localStorage.setItem("messageDisplayed", "true");
    } else if (logoutSuccess && !messageDisplayed) {
    }
  }, [OtpSessionExpired, messageDisplayed, resetSessionExpired, logoutSuccess]);

  useEffect(() => {
    if (resetSuccess) {
      setinlineMessageOpen(true);
      setInlineMessageType("success");
      setInlineMessage(ValidationMessage.Password_Reset_Success);
      setResetSuccess(false);
    }
    if (changeSuccess) {
      setinlineMessageOpen(true);
      setInlineMessageType("success");
      setInlineMessage(ValidationMessage.Password_Change_Success);
      setChangeSuccess(false);
    }
  }, [resetSuccess, changeSuccess]);

  const onChangeHandler = (event) => {
    if (event.target.name === Labels.Username) {
      setUsername(event.target.value);
      if (!event.target.value.trim()) {
        setError({ ...error, Username: ValidationMessage.Username_Required });
      } else {
        setError({ ...error, Username: "" });
      }
    } else {
      setPassword(event.target.value);
      if (!event.target.value.trim()) {
        setError({ ...error, Password: ValidationMessage.Password_Required });
      } else {
        setError({ ...error, Password: "" });
      }
    }
  };

  const handleForgotPasswordButton = async () => {
    try {
      navigate(`/Account/ForgotPassword`);
    } catch (error) {
      return;
    }
  };

  const handleClientLogin = async () => {
    sessionStorage.setItem("clientLogin", true);
    try {
      navigate(`/Client`);
    } catch (error) {
      return;
    }
  };

  const handleMemberLogin = async () => {
    // sessionStorage.setItem("clientLogin", true);
    try {
      navigate(`/MemberPortal/Login`);
      sessionStorage.setItem("roleId", 11);
    } catch (error) {
      return;
    }
  };

  const handleUserLogin = async () => {
    sessionStorage.setItem("clientLogin", false);
    try {
      navigate(`/`);
    } catch (error) {
      return;
    }
  };

  const handleRegistration = async () => {
    try {
      setIsLoading(true);

      const url =
        pathName === "Client"
          ? `${process.env.REACT_APP_API_BASE_URL}/users/client-login`
          : `${process.env.REACT_APP_API_BASE_URL}/users/login`;

      let payload;

      if (pathName === "Client") {
        payload = {
          email: userName,
          password: password,
          ...(isCaptchaRequired && { captchaCode: userCaptchaInput }),
        };
      } else {
        payload = {
          userName: userName,
          password: password,
          ...(isCaptchaRequired && { captchaCode: userCaptchaInput }),
        };
      }

      const response = await axios.post(url, payload);

      if (response.data.message === "Invalid captcha. Please try again.") {
        settoasterOpen(true);
        setToasterMessage("Invalid captcha. Please try again.");
        setToasterSeverity("error");
        setIsLoading(false);
        return;
      }

      if (
        response.data.status === 403 ||
        response.status === 403 ||
        response.data.message === "Account locked. Contact administrator."
      ) {
        console.log("403 status detected");
        setShowLimitExceeded(true);
        console.log("showLimitExceeded state updated to true.");
        setIsLoading(false);
        return;
      }
      if (response.data.isCaptchaRequired && response.data.captchaCode) {
        setCaptchaDisplay({
          show: true,
          code: response.data.captchaCode,
        });
        setCaptchaError(response.data.message); // "Invalid captcha. Please try again."
        setInlineMessageType("error");
        setInlineMessage("Security Check: CAPTCHA required");
        setinlineMessageOpen(true);
        setIsLoading(false);
        return;
      }

      if (response.status === 200) {
        const {
          roleId,
          roleName,
          email,
          firstName,
          lastName,
          isFirstLogin,
          homePage,
          signatureStatus,
          covidFlag,
          userClients,
          clientName,
          profileImage,
          speciality,
          providerSignature,
          _id,
        } = response.data.data;

        setProfileImg(profileImage);
        setUserClients(userClients); // Update userClients in context
        // console.log(userClients,"userClients")

        const jwtToken = response.headers.authorization.split(" ")[1];
        sessionStorage.setItem("roleId", roleId);
        sessionStorage.setItem("lastName", lastName);
        sessionStorage.setItem("token", jwtToken);
        sessionStorage.setItem("homePage", homePage);
        sessionStorage.setItem("emailId", email);
        sessionStorage.setItem("_id", _id);
        sessionStorage.setItem("isFirstLogin", isFirstLogin);
        // console.log(isFirstLogin);
        // if (isFirstLogin) {
        //   setTimeout(() => {
        //     axios
        //       .get(`/Account/ChangePassword`)
        //       .then(() => {
        //         navigate(`/Account/ChangePassword`);
        //       })
        //       .catch((error) => {
        //         return;
        //       });
        //   }, 3000);
        //   return;
        // }
        if (roleId !== 10) {
          sessionStorage.setItem("firstName", firstName);
          // sessionStorage.setItem("userClient", JSON.stringify(userClients));
        } else {
          sessionStorage.setItem("lastName", clientName);
        }
        axiosWrapper.defaults.headers.Authorization = `Bearer ${jwtToken}`;
        if (roleId === 5 || roleId === 12) {
          // Add null checks and provide default values
          const speciality = response.data.data.speciality || null;
          const providerSignature =
            response.data.data.providerSignature?.signature || null;

          // Only set these if they exist
          if (speciality) {
            sessionStorage.setItem("speciality", speciality);
          }
          if (providerSignature) {
            sessionStorage.setItem("providerSignature", providerSignature);
          }
          sessionStorage.setItem("signatureStatus", signatureStatus);
          sessionStorage.setItem("covidFlag", covidFlag);
          sessionStorage.setItem("speciality", speciality);
          sessionStorage.setItem("providerSignature", providerSignature);
          sessionStorage.setItem("profileImage", profileImage);
        }
        // Password is correct, navigate to OTP screen
        setHasLoggedIn(true);

        setUser((prevUser) => ({
          ...prevUser,
          email,
          lastName,
          firstName,
          roleId,
          roleName,
          homePage,
          signatureStatus,
          _id,
        }));

        setTimeout(() => {
          navigate(homePage);
        }, 1000);

        const rolesResponse = await getApi("/roles/list");

        if (rolesResponse.status === 200) {
          const rolesData = rolesResponse.data.data;
          updateRoles(rolesData);
        } else {
          return;
        }
      } else if (response.data.message === ValidationMessage.Password_Expired) {
        // Password has expired, show the toast message
        setInlineMessage(ValidationMessage.Password_Expired);
        setinlineMessageOpen(true);
        // Redirect to the forgot password page to reset the password
        setTimeout(() => {
          axios
            .get(`/Account/ForgotPassword`)
            .then(() => {
              navigate(`/Account/ForgotPassword`);
            })
            .catch((error) => {
              return;
            });
        }, 3000);
      } else if (response.data.message === ValidationMessage.Invalid_EmailId) {
        //Invalid EmailID
        setInlineMessage(ValidationMessage.Invalid_EmailId);
        setinlineMessageOpen(true);
      } else if (response.data.message === ValidationMessage.Reset_Password) {
        //first time login
        setInlineMessage(ValidationMessage.Reset_Password);
        setinlineMessageOpen(true);
        // Redirect to the reset password page
        setTimeout(() => {
          axios
            .get(`/Account/ResetPassword`)
            .then(() => {
              navigate(`/Account/ResetPassword`, { state: { userName } });
            })
            .catch((error) => {
              return;
            });
        }, 3000);
      } else {
        // Handle unsuccessful login
        if (response.data.updateResult === false) {
          setInlineMessage(response.data.message);
          setinlineMessageOpen(true);
        } else {
          if (response.data.loginAttempts < 4) {
            setInlineMessage(
              ValidationMessage.Login_Attempt_1 +
                response.data.loginAttempts +
                ValidationMessage.Login_Attempt_2,
            );
            setinlineMessageOpen(true);
          } else {
            setInlineMessage(response.data.message);
            setinlineMessageOpen(true);
            setTimeout(() => {
              axios
                .get(`/Account/ForgotPassword`)
                .then(() => {
                  navigate(`/Account/ForgotPassword`);
                })
                .catch((error) => {
                  return;
                });
            }, 3000);
          }
        }
      }
    } catch (error) {
      if (
        error.response?.status === 307 ||
        error.response?.data?.message === "Password change required."
      ) {
        try {
          setInlineMessageType("error");
          setInlineMessage("Password change required.");
          setinlineMessageOpen(true);
          sessionStorage.setItem("emailId", userName);
          sessionStorage.setItem("username", userName);
          // sessionStorage.setItem("roleId", roleId);
          await sendOTP(userName, error.response.data.roleId);
          navigate("/Account/Otp", {
            state: {
              username: userName,
              fromForgotPassword: true,
              roleId: String(error.response.data.roleId),
            },
          });
        } catch (otpError) {
          console.error("OTP Error:", otpError);
          setInlineMessageType("error");
          setInlineMessage("Failed to send OTP. Please try again.");
          setinlineMessageOpen(true);
        }
        setIsLoading(false);
        return;
      }

      if (
        error.response?.status === 403 ||
        error.response?.data?.message ===
          "Account locked. Contact administrator."
      ) {
        console.log("403 status detected");
        setShowLimitExceeded(true);
        setIsLoading(false);
        return;
      }
      // Handle other errors
      console.error("Login error:", error);

      if (error.response?.data?.isCaptchaRequired) {
        setIsCaptchaRequired(true);
        if (error.response.data.captchaCode) {
          setCaptchaCode(error.response.data.captchaCode);
          setCaptchaDisplay({
            show: true,
            code: error.response.data.captchaCode,
          });
        }

        setInlineMessageType("error");
        setInlineMessage(
          error.response.data.message || "Security Check: Password incorrect",
        );
        setinlineMessageOpen(true);
      }
      if (
        error.response?.data?.message === "Invalid captcha. Please try again."
      ) {
        settoasterOpen(true);
        setToasterMessage("Invalid captcha. Please try again.");
        setToasterSeverity("error");
        setIsLoading(false);
        return;
      } else {
        setInlineMessageType("error");
        setInlineMessage(error?.response?.data?.message || "Login Failed");
        setinlineMessageOpen(true);
      }
      setIsLoading(false);
    }
  };

  const handleContinue = async () => {
    try {
      setIsLoading(true);
      sessionStorage.setItem("emailId", userName);
      sessionStorage.setItem("username", userName);

      const url = sessionStorage.getItem("clientLogin")
        ? "/users/client-updatePassword/forgot/sendOTP"
        : "/users/updatePassword/forgot/sendOTP";

      const payload = {
        email: userName,
        // If we have roleId in session/state, include it
        ...(sessionStorage.getItem("roleId") && {
          roleId: sessionStorage.getItem("roleId"),
        }),
      };

      const response = await postApi(url, payload);

      if (response.status === 200 || response.data.status === 200) {
        // Store necessary session data
        sessionStorage.setItem("emailId", userName);
        sessionStorage.setItem("otpType", "forgotPassword");

        setInlineMessageType("success");
        setInlineMessage("OTP sent successfully");
        setinlineMessageOpen(true);

        // Navigate to OTP page after brief delay
        setTimeout(() => {
          navigate("/Account/Otp", {
            state: {
              userName: userName,
              fromForgotPassword: true,
            },
          });
        }, 1500);
      }
    } catch (error) {
      setInlineMessageType("error");
      setInlineMessage(error.response?.data?.message || "Failed to send OTP");
      setinlineMessageOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  ////MFA
  const sendOTPLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/Account/SendOtpAfterLogin`,
        {
          email: userName,
        },
      );

      if (response.data.success) {
        // Navigate to the OTP screen
        setTimeout(() => {
          navigate(`/Account/Otp`, { state: { userName, fromLogin: true } });
        }, 1000);
      } else {
        setInlineMessageType("error");
        setInlineMessage(response.data.message);
        setinlineMessageOpen(true);
      }
    } catch (error) {
      setInlineMessageType("error");
      setInlineMessage(error.message);
      setinlineMessageOpen(true);
    }
  };

  const onCloseToaster = () => {
    setinlineMessageOpen(false);
    setInlineMessageType("success"); // Reset severity for the next toaster
    setInlineMessage("");
  };

  const sendOTP = async (userName, roleId = null) => {
    try {
      const url = sessionStorage.getItem("clientLogin")
        ? "/users/client-updatePassword/forgot/sendOTP"
        : "/users/updatePassword/forgot/sendOTP";

      // Clean and validate roleId
      let cleanedRoleId = null;
      if (roleId) {
        // Convert to number if it's a numeric string
        const parsedRoleId = parseInt(roleId, 10);
        if (!isNaN(parsedRoleId)) {
          cleanedRoleId = parsedRoleId;
        } else {
          // If roleId is not numeric, we need to fetch it from the server
          try {
            const roleResponse = await postApi("/users/get-role-by-username", {
              email: userName,
            });
            if (roleResponse?.data?.roleId) {
              cleanedRoleId = roleResponse.data.roleId;
            }
          } catch (error) {
            console.error("Failed to fetch roleId:", error);
            throw new Error("Invalid role ID format");
          }
        }
      }

      const payload = {
        email: userName,
        ...(cleanedRoleId && { roleId: String(cleanedRoleId) }),
      };

      const response = await postApi(url, payload);
      console.log(response);
      if (response.status === 200 || response.data.status === 200) {
        return {
          success: true,
          data: response.data,
        };
      }

      // Handle multiple roles response
      if (response.status === 300) {
        return {
          success: false,
          multipleRoles: true,
          roles: response.data.data,
        };
      }

      throw new Error(response.data.message || "Failed to send OTP");
    } catch (error) {
      console.error("SendOTP Error:", error);
      return {
        success: false,
        error: error.message || "Failed to send OTP",
        multipleRoles: false,
      };
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!userName) {
      setError({ ...error, Username: ValidationMessage.Username_Required });
    } else if (!password) {
      setError({ ...error, Password: ValidationMessage.Password_Required });
    } else if (isCaptchaRequired && !captchaCode) {
      setCaptchaError("CAPTCHA is required");
    } else {
      setCaptchaCode(userCaptchaInput);
      handleRegistration();
    }
  };

  if (showLimitExceeded) {
    return (
      <div className="login">
        <div className="top-ripple"></div>
        <div className="bottom-ripple"></div>
        <div className="image-container" style={{ position: "relative" }}>
          <Image imgClass="bgImg" imgPath={BG} imgName="Focuscare Background" />
          <div
            style={{
              position: "absolute",
              bottom: "60px",
              backgroundColor: "rgba(237, 142, 34, .8)",
              height: "auto",
              padding: "2px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h2 style={{ color: "#fff", fontSize: "2.5rem", fontWeight: 500 }}>
              HEALTHCARE MADE PERSONAL
            </h2>
          </div>
        </div>
        <div className="input-container">
          <Image imgClass="logoImg" imgPath={Logo} imageName="Focuscare Logo" />
          <div
            className="flex flex-col items-center justify-center space-y-6 p-8"
            style={{ width: "400px" }}
          >
            <h2 className="text-xl font-semibold text-red-600">
              Attempt Limit Exceeded
            </h2>
            <p className="text-center text-gray-700">
              You exceeded the limit. Please click the "Continue" button to
              receive OTP in your email in order to reset password.
            </p>
            <Button
              onClick={handleContinue}
              width="200px"
              height="40px"
              color="white"
              background="#1076BC"
              fontSize="16px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              dataXpath="continue_button"
              cursor="pointer"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="login">
      <div className="top-ripple"></div>
      <div className="bottom-ripple"></div>
      <div className="image-container" style={{ position: "relative" }}>
        <Image imgClass="bgImg" imgPath={BG} imgName="Focuscare Background" />
        <div
          style={{
            position: "absolute",
            bottom: "60px",
            backgroundColor: "rgba(237, 142, 34, .8)",
            height: "auto",
            padding: "2px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h2 style={{ color: "#fff", fontSize: "2.5rem", fontWeight: 500 }}>
            HEALTHCARE MADE PERSONAL
          </h2>
        </div>
      </div>
      <div className="input-container">
        <Image imgClass="logoImg" imgPath={Logo} imageName="Focuscare Logo" />
        <h2 style={{ color: "#1076BC" }}>WELCOME BACK!</h2>
        <form onSubmit={submitHandler}>
          <Input
            error={error["Username"] || userNameError}
            inputtype="iconInput"
            name="Username"
            dataXpath="login_username"
            value={userName}
            inputIcon={<PersonIcon />}
            onChangeHandler={onChangeHandler}
            mb="30px"
            placeholder="Username"
            inputWidth="336px"
            width="400px"
            height="55px"
          />

          <div style={{ width: "300px" }}>
            <Input
              type={showPassword ? "text" : "password"}
              inputtype="iconInput"
              name="Password"
              dataXpath="login_password"
              value={password}
              inputIcon={<LockIcon />}
              onChangeHandler={onChangeHandler}
              inputEndIcon={
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="Password"
              inputWidth="258px"
              width="400px"
              height="55px"
              mb={isCaptchaRequired ? "0" : "40px"}
            />
            {(error["Password"] || (error["Password"] === "" && !password)) && (
              <h3
                className="password"
                style={{
                  color: "#d32f2f",
                  lineHeight: "14px",
                  width: "400px",
                  marginTop: "-42px",
                }}
              >
                Password is required
              </h3>
            )}
          </div>

          {isCaptchaRequired && (
            <div
              style={{
                marginBottom: "10px",
                textAlign: "center",
                padding: "15px 0 0",
                borderRadius: "8px",
              }}
            >
              {captchaDisplay.show && (
                <div
                  style={{
                    padding: "10px 10px 0",
                    marginBottom: "15px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    color: "#1a1a1a",
                    fontSize: "24px",
                  }}
                >
                  {captchaDisplay.code}
                </div>
              )}
              <Input
                inputtype="iconInput"
                name="CaptchaCode"
                value={userCaptchaInput}
                onChangeHandler={(e) => {
                  setUserCaptchaInput(e.target.value);
                  setCaptchaError("");
                }}
                placeholder="Enter CAPTCHA Code"
                inputWidth="336px"
                width="400px"
                height="55px"
              />
              {captchaError && (
                <h3
                  style={{
                    color: "#d32f2f",
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  {captchaError}
                </h3>
              )}
            </div>
          )}

          {inlineMessage && (
            <div
              style={{
                color: inlineMessageType === "error" ? "#d32f2f" : "#1076BC",
                marginBottom: "20px",
                width: "400px",
                textAlign: "left",
                padding: "0 16px",
                fontSize: "12px",
              }}
            >
              {inlineMessage}
            </div>
          )}

          <Button
            type="submit"
            onClick={submitHandler}
            width="400px"
            mb="20px"
            height="40px"
            color="white"
            cursor={
              isCaptchaRequired && !userCaptchaInput ? "not-allowed" : "pointer"
            }
            background={
              isCaptchaRequired && !userCaptchaInput ? "#a0a0a0" : "#1076BC"
            }
            fontSize="16px"
            border="none"
            borderRadius="100px"
            fontFamily="Inter,sans-serif"
            dataXpath="login_submit"
            disabled={isCaptchaRequired && !userCaptchaInput}
          >
            LOGIN
          </Button>
        </form>
        <div className="button-container">
          <Button
            type="button"
            onClick={handleForgotPasswordButton}
            color="black"
            background="transparent"
            fontSize="16px"
            border="none"
            borderRadius="100px"
            fontFamily="Inter,sans-serif"
            dataXpath="login_forgot_password"
          >
            Forgot Password?
          </Button>
        </div>
        <div className="button-container">
          {pathName !== "Client" ? (
            <Button
              type="button"
              onClick={handleClientLogin}
              color="black"
              background="transparent"
              fontSize="16px"
              border="none"
              borderRadius="100px"
              marginTop="10px"
              fontFamily="Inter,sans-serif"
            >
              Login as a Client
            </Button>
          ) : (
            <Button
              type="button"
              onClick={handleUserLogin}
              color="black"
              background="transparent"
              fontSize="16px"
              border="none"
              borderRadius="100px"
              marginTop="10px"
              fontFamily="Inter,sans-serif"
            >
              Login as a User
            </Button>
          )}

          <Button
            type="button"
            onClick={handleMemberLogin}
            color="black"
            background="transparent"
            fontSize="16px"
            border="none"
            borderRadius="100px"
            marginTop="10px"
            fontFamily="Inter,sans-serif"
          >
            Login as a Member
          </Button>
        </div>
      </div>
      {/* <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      /> */}
      {isLoading && <Loader />}
    </div>
  );
};

export default Login;
