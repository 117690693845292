import lodash from "lodash";

import {
  getObjectData,
  setObjectData,
} from "../../../../../../utils/helpers.DynamicForm";

const covidScreening_flatmap = [
  [
    "covid_travelInternationalVal",
    "lastFourteenDays.travelInternational.travelInternational",
    ["2023"],
  ],
  [
    "covid_travelInternationalCommentVal",
    "lastFourteenDays.travelInternational.comment",
    ["2023"],
  ],

  [
    "covid_dignosedWithCoronaVal",
    "lastFourteenDays.dignosedWithCorona.dignosedWithCorona",
    ["2023"],
  ],
  [
    "covid_dignosedWithCoronaCommentVal",
    "lastFourteenDays.dignosedWithCorona.comment",
    ["2023"],
  ],

  [
    "covid_closeContactVal",
    "lastFourteenDays.closeContact.closeContact",
    ["2023"],
  ],
  [
    "covid_closeContactCommentVal",
    "lastFourteenDays.closeContact.comment",
    ["2023"],
  ],

  [
    "covid_developFeverVal",
    "lastFourteenDays.developFever.developFever",
    ["2023"],
  ],
  [
    "covid_developFeverCommentVal",
    "lastFourteenDays.developFever.comment",
    ["2023"],
  ],

  [
    "covid_developCoughVal",
    "lastFourteenDays.developCough.developCough",
    ["2023"],
  ],
  [
    "covid_developCoughCommentVal",
    "lastFourteenDays.developCough.comment",
    ["2023"],
  ],

  [
    "covid_developSymptomsVal",
    "lastFourteenDays.developSymptoms.developSymptoms",
    ["2023"],
  ],
  [
    "covid_developSymptomsCommentVal",
    "lastFourteenDays.developSymptoms.comment",
    ["2023"],
  ],

  [
    "covid_developBreathVal",
    "lastFourteenDays.developBreath.developBreath",
    ["2023"],
  ],
  [
    "covid_developBreathCommentVal",
    "lastFourteenDays.developBreath.comment",
    ["2023"],
  ],

  [
    "covid_dignosedWithCoronaVal",
    "lastSevenDays.coronaVirus.isCoronaVirus",
    ["2024", "2025"],
  ],
  [
    "covid_dignosedWithCoronaCommentVal",
    "lastSevenDays.coronaVirus.comment",
    ["2024", "2025"],
  ],

  [
    "covid_highRiskVal",
    "lastSevenDays.highRiskTravel.isHighRiskTravel",
    ["2024", "2025"],
  ],
  [
    "covid_highRiskCommentVal",
    "lastSevenDays.highRiskTravel.comment",
    ["2024", "2025"],
  ],

  [
    "covid_symptomsFeverOrChillsVal",
    "lastSevenDays.symptomsSubAnswer.feverOrChills",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsCoughVal",
    "lastSevenDays.symptomsSubAnswer.cough",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsShortnessOfBreathVal",
    "lastSevenDays.symptomsSubAnswer.shortnessOfBreath",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsFatigueVal",
    "lastSevenDays.symptomsSubAnswer.fatigue",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsMuscleOrBodyAchesVal",
    "lastSevenDays.symptomsSubAnswer.muscleOrBodyAches",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsHeadacheVal",
    "lastSevenDays.symptomsSubAnswer.headache",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsLossOfTasteVal",
    "lastSevenDays.symptomsSubAnswer.lossOfTaste",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsSoreThroatVal",
    "lastSevenDays.symptomsSubAnswer.soreThroat",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsCongestionVal",
    "lastSevenDays.symptomsSubAnswer.congestion",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsNauseaVal",
    "lastSevenDays.symptomsSubAnswer.nausea",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsDiarrheaVal",
    "lastSevenDays.symptomsSubAnswer.diarrhea",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsFluVal",
    "lastSevenDays.symptomsSubAnswer.flu",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsNoneVal",
    "lastSevenDays.symptomsSubAnswer.none",
    ["2024", "2025"],
  ],
  [
    "covid_symptomsCommentVal",
    "lastSevenDays.symptomsSubAnswer.comment",
    ["2024", "2025"],
  ],
];

const covidScreening_optionsTreeMapObject2023 = {};

const covidScreening_optionsTreeMapObject2024 = {
  covid_symptomsFeverOrChillsVal: "feverOrChills",
  covid_symptomsCoughVal: "cough",
  covid_symptomsShortnessOfBreathVal: "shortnessOfBreath",
  covid_symptomsFatigueVal: "fatigue",
  covid_symptomsMuscleOrBodyAchesVal: "muscleOrBodyAches",
  covid_symptomsHeadacheVal: "headache",
  covid_symptomsLossOfTasteVal: "lossOfTaste",
  covid_symptomsSoreThroatVal: "soreThroat",
  covid_symptomsCongestionVal: "congestion",
  covid_symptomsNauseaVal: "nausea",
  covid_symptomsDiarrheaVal: "diarrhea",
  covid_symptomsFluVal: "flu",
  covid_symptomsNoneVal: "none",
};

const covidScreeningOptionsTreeWrappers2023 = [];

const covidScreeningOptionsTreeWrappers2024 = [
  [
    "covid_symptoms",
    [
      "covid_symptomsFeverOrChillsVal",
      "covid_symptomsCoughVal",
      "covid_symptomsShortnessOfBreathVal",
      "covid_symptomsFatigueVal",
      "covid_symptomsMuscleOrBodyAchesVal",
      "covid_symptomsHeadacheVal",
      "covid_symptomsLossOfTasteVal",
      "covid_symptomsSoreThroatVal",
      "covid_symptomsCongestionVal",
      "covid_symptomsNauseaVal",
      "covid_symptomsDiarrheaVal",
      "covid_symptomsFluVal",
      "covid_symptomsNoneVal",
    ],
  ],
];

export const getCovidScreeningData = (apiData, year) => {
  let nestedChildsData = {};
  let flattenData = {};

  let flatmap = [];

  covidScreening_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  let treeWrappers = getTreeWrapperByYear(year);

  flatmap.forEach((questionPair) => {
    nestedChildsData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  treeWrappers.forEach((pointerLabels) => {
    flattenData[pointerLabels[0]] =
      getWrapper(nestedChildsData, pointerLabels[1], year) || "";
  });

  /*

    common fields for all years

    tempdb

    if(2023){

      tempdb
    }else if(2024){

      tempdb
    }


    */

  if (year === "2023") {
    const covid_travelInternational = getOptionsSelectedYesNo(
      nestedChildsData["covid_travelInternationalVal"],
    );
    const covid_travelInternationalComment =
      nestedChildsData["covid_travelInternationalCommentVal"];

    const covid_dignosedWithCorona = getOptionsSelectedYesNo(
      nestedChildsData["covid_dignosedWithCoronaVal"],
    );
    const covid_dignosedWithCoronaComment =
      nestedChildsData["covid_dignosedWithCoronaCommentVal"];

    const covid_closeContact = getOptionsSelectedYesNo(
      nestedChildsData["covid_closeContactVal"],
    );
    const covid_closeContactComment =
      nestedChildsData["covid_closeContactCommentVal"];

    const covid_developFever = getOptionsSelectedYesNo(
      nestedChildsData["covid_developFeverVal"],
    );
    const covid_developFeverComment =
      nestedChildsData["covid_developFeverCommentVal"];

    const covid_developCough = getOptionsSelectedYesNo(
      nestedChildsData["covid_developCoughVal"],
    );
    const covid_developCoughComment =
      nestedChildsData["covid_developCoughCommentVal"];

    const covid_developSymptoms = getOptionsSelectedYesNo(
      nestedChildsData["covid_developSymptomsVal"],
    );
    const covid_developSymptomsComment =
      nestedChildsData["covid_developSymptomsCommentVal"];

    const covid_developBreath = getOptionsSelectedYesNo(
      nestedChildsData["covid_developBreathVal"],
    );
    const covid_developBreathComment =
      nestedChildsData["covid_developBreathCommentVal"];

    flattenData = {
      ...flattenData,
      covid_travelInternational: covid_travelInternational || "",
      covid_travelInternationalComment: covid_travelInternationalComment || "",
      covid_dignosedWithCorona: covid_dignosedWithCorona || "",
      covid_dignosedWithCoronaComment: covid_dignosedWithCoronaComment || "",
      covid_closeContact: covid_closeContact || "",

      covid_closeContactComment: covid_closeContactComment || "",
      covid_developFever: covid_developFever || "",
      covid_developFeverComment: covid_developFeverComment || "",
      covid_developCough: covid_developCough || "",
      covid_developCoughComment: covid_developCoughComment || "",

      covid_developSymptoms: covid_developSymptoms || "",
      covid_developSymptomsComment: covid_developSymptomsComment || "",
      covid_developBreath: covid_developBreath || "",
      covid_developBreathComment: covid_developBreathComment || "",
    };
  } else if (year === "2024" || year === "2025") {
    const covid_dignosedWithCorona = getOptionsSelectedYesNo(
      nestedChildsData["covid_dignosedWithCoronaVal"],
    );
    const covid_dignosedWithCoronaComment =
      nestedChildsData["covid_dignosedWithCoronaCommentVal"];

    const covid_highRisk = getOptionsSelectedYesNo(
      nestedChildsData["covid_highRiskVal"],
    );
    const covid_highRiskComment = nestedChildsData["covid_highRiskCommentVal"];

    const covid_symptomsComment = nestedChildsData["covid_symptomsCommentVal"];

    flattenData = {
      ...flattenData,
      covid_dignosedWithCorona: covid_dignosedWithCorona || "",
      covid_dignosedWithCoronaComment: covid_dignosedWithCoronaComment || "",
      covid_highRisk: covid_highRisk || "",
      covid_highRiskComment: covid_highRiskComment || "",
      covid_symptomsComment: covid_symptomsComment || "",
    };
  }

  return flattenData;
};

export const setCovidScreeningData = (formData, year) => {
  let flattenData = {};

  let treeWrappers = getTreeWrapperByYear(year);

  treeWrappers.forEach((pointerLabels) => {
    flattenData = {
      ...flattenData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1], year),
    };
  });

  let payloadData = {};

  if (year === "2023") {
    const covid_travelInternationalVal = setOptionsSelectedYesNo(
      formData["covid_travelInternational"],
    );
    const covid_travelInternationalCommentVal =
      formData["covid_travelInternationalComment"];

    const covid_dignosedWithCoronaVal = setOptionsSelectedYesNo(
      formData["covid_dignosedWithCorona"],
    );
    const covid_dignosedWithCoronaCommentVal =
      formData["covid_dignosedWithCoronaComment"];

    const covid_closeContactVal = setOptionsSelectedYesNo(
      formData["covid_closeContact"],
    );
    const covid_closeContactCommentVal = formData["covid_closeContactComment"];

    const covid_developFeverVal = setOptionsSelectedYesNo(
      formData["covid_developFever"],
    );
    const covid_developFeverCommentVal = formData["covid_developFeverComment"];

    const covid_developCoughVal = setOptionsSelectedYesNo(
      formData["covid_developCough"],
    );
    const covid_developCoughCommentVal = formData["covid_developCoughComment"];

    const covid_developSymptomsVal = setOptionsSelectedYesNo(
      formData["covid_developSymptoms"],
    );
    const covid_developSymptomsCommentVal =
      formData["covid_developSymptomsComment"];

    const covid_developBreathVal = setOptionsSelectedYesNo(
      formData["covid_developBreath"],
    );
    const covid_developBreathCommentVal =
      formData["covid_developBreathComment"];

    flattenData = {
      ...flattenData,
      covid_travelInternationalVal,
      covid_travelInternationalCommentVal,
      covid_dignosedWithCoronaVal,
      covid_dignosedWithCoronaCommentVal,
      covid_closeContactVal,
      covid_closeContactCommentVal,
      covid_developFeverVal,
      covid_developFeverCommentVal,
      covid_developCoughVal,
      covid_developCoughCommentVal,
      covid_developSymptomsVal,
      covid_developSymptomsCommentVal,
      covid_developBreathVal,
      covid_developBreathCommentVal,
    };
  } else if (year === "2024" || year === "2025") {
    const covid_dignosedWithCoronaVal = setOptionsSelectedYesNo(
      formData["covid_dignosedWithCorona"],
    );
    const covid_dignosedWithCoronaCommentVal =
      formData["covid_dignosedWithCoronaComment"];

    const covid_highRiskVal = setOptionsSelectedYesNo(
      formData["covid_highRisk"],
    );
    const covid_highRiskCommentVal = formData["covid_highRiskComment"];

    const covid_symptomsCommentVal = formData["covid_symptomsComment"];

    flattenData = {
      ...flattenData,
      covid_dignosedWithCoronaVal: covid_dignosedWithCoronaVal || "",
      covid_dignosedWithCoronaCommentVal:
        covid_dignosedWithCoronaCommentVal || "",
      covid_highRiskVal: covid_highRiskVal || "",
      covid_highRiskCommentVal: covid_highRiskCommentVal || "",
      covid_symptomsCommentVal: covid_symptomsCommentVal || "",
    };
  }

  let flatmap = [];

  covidScreening_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  flatmap.forEach((questionPair) => {
    // if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], flattenData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getTreeMapperByYear = (year) => {
  let temp = {};
  if (year === "2023") {
    temp = { ...covidScreening_optionsTreeMapObject2023 };
  } else if (year === "2024" || year === "2025") {
    temp = { ...covidScreening_optionsTreeMapObject2024 };
  }

  return temp;
};

const getTreeWrapperByYear = (year) => {
  let temp = [];
  if (year === "2023") {
    temp = [...covidScreeningOptionsTreeWrappers2023];
  } else if (year === "2024" || year === "2025") {
    temp = [...covidScreeningOptionsTreeWrappers2024];
  }

  return temp;
};

const getWrapper = (tempData, keyArray, year) => {
  let temp = getOptionsSelected(tempData, keyArray, year);
  return temp;
};

const setWrapper = (tempData, keyArray, year) => {
  let temp = setOptionsSelected(tempData, keyArray, year);
  return temp;
};

const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const setOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

// const getOptionsSelected = (optionObject, optionArray, multiSelect = false) => {
//   const selectedVariable = optionArray?.filter((option) => {
//     return optionObject[option] === "1";
//   });

//   let selectedOption;
//   if (multiSelect) {
//     selectedOption = selectedVariable.map((variable) => {
//       return covidScreening_optionsTreeMapObject[variable];
//     });
//   } else {
//     selectedOption = covidScreening_optionsTreeMapObject[selectedVariable[0]];
//   }
//   return selectedOption;
// };

// const setOptionsSelected = (
//   optionsSelected,
//   optionArray,
//   multiSelect = false,
// ) => {
//   let temp = {};

//   if (multiSelect) {
//     optionArray.forEach((option) => {
//       temp[option] = optionsSelected.includes(
//         covidScreening_optionsTreeMapObject[option],
//       )
//         ? "1"
//         : "";
//     });
//   } else {
//     optionArray.forEach((option) => {
//       temp[option] =
//         covidScreening_optionsTreeMapObject[option] === optionsSelected
//           ? "1"
//           : "";
//     });
//   }
//   return temp;
// };

export const getOptionsSelected = (optionObject, optionArray, year) => {
  let treeMappers = getTreeMapperByYear(year);

  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption = selectedVariable.map((variable) => {
    return treeMappers[variable];
  });

  return selectedOption;
};

export const setOptionsSelected = (optionsSelected, optionArray, year) => {
  let temp = {};
  let treeMappers = getTreeMapperByYear(year);

  optionArray.forEach((option) => {
    temp[option] =
      optionsSelected &&
      optionsSelected.length > 0 &&
      optionsSelected.includes(treeMappers[option])
        ? "1"
        : "";
  });
  return temp;
};

export const getSubTree = (optionName, optionValue, year) => {
  let temp;
  let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeMapperByYear(year);

  const tempIndex = lodash.findIndex(treeWrappers, function (optTree) {
    return optTree[0] === optionName;
  });

  const tempTreeNodes = treeWrappers?.[tempIndex]
    ? treeWrappers[tempIndex][1]
    : [];

  tempTreeNodes.forEach((node, index) => {
    if (treeMappers[node] === optionValue) {
      temp = node;
    }
  });

  temp = temp && temp !== "" ? temp.substring(0, temp.length - 3) : "";
  temp = temp && temp !== "" ? `${temp}Sub` : "";

  return temp;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let treeKeys = Object.keys(formData);
  treeKeys.forEach((node) => {
    if (node.includes(subTreeRegex)) {
      delete formData[node];
    }
  });
  return formData;
};
