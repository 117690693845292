import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../store/actions/auth_action";
import "./static_files/css/mpapp.css";

const styles = `
  .login-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
    padding: 20px 0;
  }

  .login-card {
    background: white;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .login-card:hover {
    transform: translateY(-5px);
  }

  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label {
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    color: #0d6efd;
  }

  .form-control:focus {
    border-color: #0d6efd;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.15);
  }

  .btn-primary {
    background: #0D6EFD;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    padding: 20px;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
  }

  .btn-primary:hover {
    background: #0b5ed7;
  }
  
  .btn-primary:active {
    background: #0a58ca;
  }

  .btn-primary:disabled {
    background: rgba(13, 110, 253, 0.65);
    cursor: not-allowed;
  }

  /* Custom checkbox styling */
  .form-check-input {
    border: 1.5px solid #6B7280;
    border-radius: 4px;
    width: 16px;
    height: 16px;
  }

  /* Link styling */
  .auth-link {
    color: #0D6EFD;
    text-decoration: none;
    font-size: 14px;
  }
  
  .auth-link:hover {
    text-decoration: underline;
  }

  .text-muted-custom {
    color: #6B7280;
    font-size: 14px;
  }

  .divider {
    height: 1px;
    background: #e9ecef;
    margin: 1.5rem 0;
  }

  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  .alert {
    border-radius: 10px;
    border: none;
  }

  @media (max-width: 576px) {
    .card-body {
      padding: 1.5rem !important;
    }
  }
`;

function MPLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await dispatch(authActions.login(email, password));
      navigate("/MemberPortal/Profile");
    } catch (error) {
      setErrorMessage(error.message || "Login failed");
    }
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    setOtpSent(false);
    setOtpValidated(false);
    setErrorMessage("");
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      await dispatch(authActions.sendOtp(email, password));
      setOtpSent(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Error sending OTP");
    }
  };

  const handleValidateOtp = async (e) => {
    e.preventDefault();
    try {
      await dispatch(authActions.validateOtp(email, otp, password));
      setOtpValidated(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Invalid OTP");
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      if (newPassword.length < 8) {
        setErrorMessage("Password must be at least 8 characters long.");
        return;
      }
      await dispatch(
        authActions.updatePassword(email, password, newPassword, otp),
      );

      // Reset all states
      setEmail("");
      setPassword("");
      setOtp("");
      setNewPassword("");
      setIsForgotPassword(false);
      setOtpSent(false);
      setOtpValidated(false);
      setErrorMessage("");

      alert(
        "Password updated successfully. Please login with your new password.",
      );
      navigate("/MemberPortal/MPLogin");
    } catch (error) {
      alert(error);
      navigate("/MemberPortal/MPLogin");
    }
  };

  const handleMembersLogin = async () => {
    // sessionStorage.setItem("clientLogin", true);
    try {
      navigate(`/MemberPortal/Register`);
    } catch (error) {
      return;
    }
  };

  const renderForgotPasswordForm = () => {
    if (!otpSent) {
      return (
        <>
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="floatingEmail"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="floatingEmail">Enter your registered email</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Current password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="floatingPassword">
              Enter your current password
            </label>
          </div>
          <button
            className="btn btn-primary w-100 py-2 mb-3"
            onClick={handleSendOtp}
            disabled={loading}
          >
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Sending OTP...
              </>
            ) : (
              "Send OTP"
            )}
          </button>
        </>
      );
    }

    if (!otpValidated) {
      return (
        <>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingOtp"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <label htmlFor="floatingOtp">Enter OTP</label>
          </div>
          <button
            className="btn btn-primary w-100 py-2 mb-3"
            onClick={handleValidateOtp}
            disabled={loading}
          >
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Validating...
              </>
            ) : (
              "Validate OTP"
            )}
          </button>
          <p className="text-center mt-3">
            <a
              href="#"
              onClick={handleSendOtp}
              className="text-decoration-none"
            >
              <i className="fas fa-redo-alt me-2"></i>Resend OTP
            </a>
          </p>
        </>
      );
    }

    return (
      <>
        <div className="form-floating mb-3">
          <input
            type="password"
            className="form-control"
            id="floatingNewPassword"
            placeholder="New password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <label htmlFor="floatingNewPassword">New Password</label>
        </div>
        <button
          className="btn btn-primary w-100 py-2 mb-3"
          onClick={handleUpdatePassword}
          disabled={loading}
        >
          {loading ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              Updating...
            </>
          ) : (
            "Update Password"
          )}
        </button>
      </>
    );
  };

  return (
    <>
      <style>{styles}</style>
      <div className="login-container d-flex align-items-center">
        <div className="containers">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
              <div className="login-card card border-0">
                <div className="card-body p-4 p-sm-5">
                  <div className="text-center mb-4">
                    <img
                      src="https://focuscares.com/wp-content/uploads/elementor/thumbs/logo-pcsu71jmplrr1f3b7mtv083rbyula7p5imfik70y8o.png"
                      alt="Logo"
                      className="img-fluid mb-4"
                      style={{ maxWidth: "180px", height: "auto" }}
                    />
                    {!isForgotPassword && (
                      <h4 className="mb-0 text-secondary">Welcome Back</h4>
                    )}
                  </div>

                  {errorMessage && (
                    <div
                      className="alert alert-danger d-flex align-items-center mb-4"
                      role="alert"
                    >
                      <i className="fas fa-exclamation-circle me-2"></i>
                      <small>{errorMessage}</small>
                    </div>
                  )}

                  {!isForgotPassword ? (
                    <form onSubmit={handleLogin} className="needs-validation">
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">
                          <i className="fas fa-envelope me-2 text-muted"></i>
                          Email address
                        </label>
                      </div>

                      <div className="form-floating mb-3">
                        <input
                          type="password"
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingPassword">
                          <i className="fas fa-lock me-2 text-muted"></i>
                          Password
                        </label>
                      </div>

                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rememberMe"
                          />
                          <label
                            className="form-check-label text-muted-custom"
                            htmlFor="rememberMe"
                          >
                            Remember me
                          </label>
                        </div>
                        <a
                          href="#"
                          onClick={handleForgotPassword}
                          className="auth-link"
                        >
                          Forgot password?
                        </a>
                      </div>

                      <button
                        className="btn btn-primary w-100 mb-4"
                        style={{ height: "auto" }}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Signing in...
                          </>
                        ) : (
                          <>
                            <i
                              className="fas fa-sign-in-alt me-2"
                              style={{ color: "darkblue" }}
                            ></i>
                            Sign In
                          </>
                        )}
                      </button>

                      <div className="divider"></div>

                      <div className="text-center">
                        <p className="mb-3 text-muted-custom">
                          Not a member?{" "}
                          <span
                            onClick={handleMembersLogin}
                            style={{
                              color: "#007bff", // Link color
                              cursor: "pointer", // Pointer cursor for interactivity
                              fontSize: "16px",
                              fontFamily: "Inter, sans-serif",
                            }}
                          >
                            Register
                          </span>
                        </p>

                        {/* Not a member? <a href="/MemberPortal/Register" className="auth-link">Register</a> */}
                        {/* </p> */}
                        <a href="/" target="_blank" className="auth-link">
                          Login as user
                        </a>
                      </div>
                    </form>
                  ) : (
                    <div>
                      {renderForgotPasswordForm()}
                      <div className="divider"></div>
                      <p className="text-center mt-3">
                        <a
                          href="#"
                          onClick={() => setIsForgotPassword(false)}
                          className="text-decoration-none text-secondary"
                        >
                          <i className="fas fa-arrow-left me-2"></i>
                          <small>Back to Login</small>
                        </a>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MPLogin;
