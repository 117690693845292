import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const Sdoh = ({ data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    school: {
      lessThanThirdGrade: "",
      lessThanEighthGrade: "",
      lessThanTwelthGrade: "",
      completedTwelthGrade: "",
      attendedOrgraduated: "",
      comment: "",
    },
    difficultyReading: {
      fillingMedicalForms: "",
      providerInstructions: "",
      prescriptionInstructions: "",
      comment: "",
    },
    concernAboutTransportation: {
      isConcernAboutTransportation: "",
      comment: "",
    },
    social: {
      rateYourHealth: {
        excellent: "",
        good: "",
        fair: "",
        poor: "",
        comment: "",
      },
      relyOn: {
        isRelyOn: "",
        comment: "",
      },
      socialActivities: {
        often: "",
        sometimes: "",
        almostNever: "",
        never: "",
        comment: "",
      },
      goOutToMeet: {
        often: "",
        sometimes: "",
        almostNever: "",
        never: "",
        comment: "",
      },
      careGiver: {
        isCareGiver: "",
        comment: "",
      },
    },
    currentlyLiveSituation: {
      currentlyLiveSituation: "",
      comment: "",
    },
    currentLive: {
      home: "",
      apartment: "",
      assistedLiving: "",
      nursingHome: "",
      homeless: "",
      other: "",
      homelessSubAnswer: {
        describe: "",
      },
      otherSubAnswer: {
        describe: "",
      },
      comment: "",
    },
    thinkAboutPlace: {
      bugsAntsMice: "",
      mold: "",
      leadPaintOrPipes: "",
      lackOfHeat: "",
      ovenOrStove: "",
      waterLeaks: "",
      none: "",
      comment: "",
    },
    currentlyLivingWith: {
      alone: "",
      spouse: "",
      partner: "",
      relative: "",
      family: "",
      friend: "",
      personalCareWorker: "",
      aloneSubAnswer: {
        describe: "",
      },
      comment: "",
    },
    lastTwelveMonths: {
      isLastTwelveMonths: "",
      lastTwelveMonthsSubAnswers: {
        lastTwelveMonthSelect: "",
        comment: "",
      },
      comment: "",
    },
    substanceUse: {
      tobaccoUse: {
        current: "",
        former: "",
        never: "",
        comment: "",
        currentSubAnswer: {
          type: {
            cigarettes: "",
            cigars: "",
            chewingTobacco: "",
            vaping: "",
            other: "",
            comment: "",
          },
        },
        formerSubAnswer: {
          when: {
            withinLastYear: "",
            withinLastThreeYear: "",
            moreYearAgo: "",
            comment: "",
          },
        },
      },
      alcoholUse: {
        current: "",
        former: "",
        never: "",
        comment: "",
      },
      educationProvided: {
        isEducationProvided: "",
        comment: "",
      },
    },
    employement: {
      employmentStatus: "",
      comment: "",
    },
    advanceCarePlanning: {
      healthCareProxy: {
        isHealthCareProxy: "",
        comment: "",
      },
      powerAttorney: {
        isPowerAttorney: "",
        comment: "",
      },
      advanceDirective: {
        isAdvanceDirective: "",
        comment: "",
      },
      educatedOnAdvanceCarePlanning: {
        isEducatedOnAdvanceCarePlanning: "",
        comment: "",
      },
      declinesDiscussion: {
        isDeclinesDiscussion: "",
        comment: "",
      },
    },
    food: {
      foodWouldRunOut: {
        oftenTrue: "",
        sometimesTrue: "",
        neverTrue: "",
        oftenTrueSubAnswers: {
          foodWeBought: "",
          dontHaveMoney: "",
          comment: "",
        },
        sometimesTrueSubAnswers: {
          foodWeBought: "",
          dontHaveMoney: "",
          comment: "",
        },
        comment: "",
      },
    },
    recommendations: {
      recommendationsSubAnswer: [],
      comment: "",
    },
  });

  useEffect(() => {
    if (data && data.data) {
      setFormData(data.data);
    }
  }, [data]);

  const handleRadioChange = (section, subsection, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [subsection]: value,
      },
    }));
  };

  const handleCancel = () => {
    if (data && data.data) {
      setFormData(data.data);
    }
    setIsEditing(false);
    setMessage("");
  };

  const handleNestedRadioChange = (section, subsection, field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [subsection]: {
          ...prev[section][subsection],
          [field]: value,
        },
      },
    }));
  };

  const handleCheckboxChange = (section, field) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: prev[section][field] === "1" ? "" : "1",
      },
    }));
  };
  const handleSubmit = async () => {
    // Removed e parameter
    const numYear = user.year;
    //handle any exceptions that occur due to year value
    const yearToUse = (function () {
      if (!numYear) return new Date().getFullYear();

      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);

      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();
    try {
      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "SDOH",
        data: formData,
      };

      const token = localStorage.getItem("token");
      const response = await axios.put(
        "https://hradev-node.focuscares.com/memberPortal/update-self-assessment",
        payload,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.code === 200) {
        setMessage("SDOH information updated successfully!");
        setIsEditing(false);
      } else {
        setMessage(
          "Failed to update SDOH: " +
            (response.data.message || "Unknown error"),
        );
      }
    } catch (error) {
      console.error("Error updating SDOH:", error);
      setMessage(
        "Failed to update SDOH. Please check your network connection and try again.",
      );
    }
  };

  const { user = {} } = useSelector((state) => state?.auth ?? {});

  return (
    <div
      className="tab-pane fade show active"
      id="pills-sdoh"
      role="tabpanel"
      aria-labelledby="pills-sdoh-tab"
    >
      <div className="container mt-5">
        {message && (
          <div
            className={`alert ${
              message.includes("successfully")
                ? "alert-success"
                : "alert-danger"
            } mb-4`}
          >
            {message}
          </div>
        )}

        <form onSubmit={(e) => e.preventDefault()}>
          <h2 className="mb-4">SDOH</h2>
          <hr />

          {/* Literacy Section */}
          <section className="mb-5">
            <h2 className="h4 mb-3">Literacy</h2>
            <div className="mb-3">
              <label className="form-label">
                How much school have you completed?
              </label>
              <div className="d-flex flex-wrap">
                {[
                  {
                    key: "lessThanThirdGrade",
                    label: "Completed less than 3rd grade",
                  },
                  {
                    key: "lessThanEighthGrade",
                    label: "Completed less than 6th grade",
                  },
                  {
                    key: "lessThanTwelthGrade",
                    label: "Completed less than 12th grade",
                  },
                  {
                    key: "completedTwelthGrade",
                    label: "Completed 12th grade",
                  },
                  {
                    key: "attendedOrgraduated",
                    label: "Attended/Graduated College",
                  },
                ].map((option) => (
                  <div key={option.key} className="w-100 mb-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="school_completed" // Changed name to be more specific
                      id={`school_${option.key}`} // Added prefix to ensure unique IDs
                      checked={formData.school[option.key] === "1"}
                      onChange={() => {
                        if (!isEditing) return;
                        const updatedSchool = {
                          lessThanThirdGrade: "",
                          lessThanEighthGrade: "",
                          lessThanTwelthGrade: "",
                          completedTwelthGrade: "",
                          attendedOrgraduated: "",
                        };
                        updatedSchool[option.key] = "1";
                        setFormData((prev) => ({
                          ...prev,
                          school: {
                            ...prev.school,
                            ...updatedSchool,
                          },
                        }));
                      }}
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary w-100"
                      htmlFor={`school_${option.key}`}
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <hr />

          {/* Housing and Transportation Section */}
          <section className="mb-5">
            <h2 className="h4 mb-3">Housing and Transportation</h2>
            <div className="mb-3">
              <label className="form-label">
                Choose the statement below that best describes your current
                living situation:
              </label>
              <div>
                {[
                  { value: "1", label: "I have a steady place to live" },
                  {
                    value: "2",
                    label:
                      "I have a place to live today, but I am worried about losing it in the future",
                  },
                  { value: "3", label: "I do not have a steady place to live" },
                ].map((option) => (
                  <div className="form-check mb-2" key={option.value}>
                    <input
                      type="radio"
                      className="form-check-input"
                      name="living_situation"
                      id={`living_situation_${option.value}`}
                      value={option.value}
                      checked={
                        formData.currentlyLiveSituation
                          .currentlyLiveSituation === option.value
                      }
                      onChange={() => {
                        if (!isEditing) return;
                        setFormData((prev) => ({
                          ...prev,
                          currentlyLiveSituation: {
                            ...prev.currentlyLiveSituation,
                            currentlyLiveSituation: option.value,
                          },
                        }));
                      }}
                      disabled={!isEditing}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`living_situation_${option.value}`}
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <hr />

          {/* Social Section */}
          <section className="mb-5">
            <h2 className="h4 mb-3">Social</h2>

            {/* Health Rating Question */}
            <div className="mb-3">
              <label className="form-label">
                How would you rate your health compared to other persons your
                age?
              </label>
              <div className="btn-group w-100">
                {[
                  { key: "excellent", label: "EXCELLENT" },
                  { key: "good", label: "GOOD" },
                  { key: "fair", label: "FAIR" },
                  { key: "poor", label: "POOR" },
                ].map((option) => (
                  <React.Fragment key={option.key}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="health_rating"
                      id={`health_${option.key}`}
                      checked={
                        formData.social.rateYourHealth[option.key] === "1"
                      }
                      onChange={() => {
                        if (!isEditing) return;
                        const updatedHealth = {
                          excellent: "",
                          good: "",
                          fair: "",
                          poor: "",
                        };
                        updatedHealth[option.key] = "1";
                        setFormData((prev) => ({
                          ...prev,
                          social: {
                            ...prev.social,
                            rateYourHealth: {
                              ...prev.social.rateYourHealth,
                              ...updatedHealth,
                            },
                          },
                        }));
                      }}
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={`health_${option.key}`}
                    >
                      {option.label}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <hr />

            {/* Rely On Question */}
            <div className="mb-3">
              <label className="form-label">
                Do you have someone you can rely on to help if you are sick or
                have problems you need to discuss?
              </label>
              <div className="btn-group">
                {[
                  { value: "1", label: "YES" },
                  { value: "2", label: "NO" },
                ].map((option) => (
                  <React.Fragment key={option.value}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="rely_on"
                      id={`rely_${option.value}`}
                      checked={formData.social.relyOn.isRelyOn === option.value}
                      onChange={() => {
                        if (!isEditing) return;
                        setFormData((prev) => ({
                          ...prev,
                          social: {
                            ...prev.social,
                            relyOn: {
                              ...prev.social.relyOn,
                              isRelyOn: option.value,
                            },
                          },
                        }));
                      }}
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={`rely_${option.value}`}
                    >
                      {option.label}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <hr />

            {/* Meet Family/Friends Question */}
            <div className="mb-3">
              <label className="form-label">
                How often do you go out to meet with family or friends?
              </label>
              <div className="btn-group w-100">
                {[
                  { key: "often", label: "OFTEN" },
                  { key: "sometimes", label: "SOMETIMES" },
                  { key: "never", label: "NEVER" },
                ].map((option) => (
                  <React.Fragment key={option.key}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="meet_frequency"
                      id={`meet_${option.key}`}
                      checked={formData.social.goOutToMeet[option.key] === "1"}
                      onChange={() => {
                        if (!isEditing) return;
                        const updatedMeet = {
                          often: "",
                          sometimes: "",
                          never: "",
                        };
                        updatedMeet[option.key] = "1";
                        setFormData((prev) => ({
                          ...prev,
                          social: {
                            ...prev.social,
                            goOutToMeet: {
                              ...prev.social.goOutToMeet,
                              ...updatedMeet,
                            },
                          },
                        }));
                      }}
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={`meet_${option.key}`}
                    >
                      {option.label}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </section>
          <hr />

          {/* Employment Section */}
          <section className="mb-5">
            <h2 className="h4 mb-3">Employment</h2>
            <div className="mb-3">
              <label className="form-label">Are you currently employed?</label>
              <div className="btn-group">
                {[
                  { value: "1", label: "YES" },
                  { value: "2", label: "NO" },
                ].map((option) => (
                  <React.Fragment key={option.value}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="employment_status"
                      id={`emp_${option.value}`}
                      checked={
                        formData.employement.employmentStatus === option.value
                      }
                      onChange={() => {
                        if (!isEditing) return;
                        setFormData((prev) => ({
                          ...prev,
                          employement: {
                            ...prev.employement,
                            employmentStatus: option.value,
                          },
                        }));
                      }}
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={`emp_${option.value}`}
                    >
                      {option.label}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </section>
          <hr />

          {/* Substance Use Section */}
          <section className="mb-5">
            <h2 className="h4 mb-3">Substance Use</h2>

            {/* Tobacco Use */}
            <div className="mb-3">
              <label className="form-label">Tobacco use</label>
              <div className="btn-group d-flex flex-wrap">
                {[
                  { key: "current", label: "Current" },
                  { key: "former", label: "Former" },
                  { key: "never", label: "Never" },
                ].map((option) => (
                  <React.Fragment key={option.key}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="tobacco_use"
                      id={`tob_${option.key}`}
                      checked={
                        formData.substanceUse.tobaccoUse[option.key] === "1"
                      }
                      onChange={() => {
                        if (!isEditing) return;
                        const updatedTobacco = {
                          current: "",
                          former: "",
                          never: "",
                        };
                        updatedTobacco[option.key] = "1";
                        setFormData((prev) => ({
                          ...prev,
                          substanceUse: {
                            ...prev.substanceUse,
                            tobaccoUse: {
                              ...prev.substanceUse.tobaccoUse,
                              ...updatedTobacco,
                              comment: prev.substanceUse.tobaccoUse.comment,
                            },
                          },
                        }));
                      }}
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={`tob_${option.key}`}
                    >
                      {option.label}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <hr />

            {/* Alcohol Use */}
            <div className="mb-3">
              <label className="form-label">
                Have you ever consumed alcohol?
              </label>
              <div className="btn-group d-flex flex-wrap">
                {[
                  { key: "current", label: "Current" },
                  { key: "former", label: "Former" },
                  { key: "never", label: "Never" },
                ].map((option) => (
                  <React.Fragment key={option.key}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="alcohol_use" // Different name from tobacco use
                      id={`alc_${option.key}`}
                      checked={
                        formData.substanceUse.alcoholUse[option.key] === "1"
                      }
                      onChange={() => {
                        if (!isEditing) return;
                        const updatedAlcohol = {
                          current: "",
                          former: "",
                          never: "",
                        };
                        updatedAlcohol[option.key] = "1";
                        setFormData((prev) => ({
                          ...prev,
                          substanceUse: {
                            ...prev.substanceUse,
                            alcoholUse: {
                              ...prev.substanceUse.alcoholUse,
                              ...updatedAlcohol,
                              comment: prev.substanceUse.alcoholUse.comment,
                            },
                          },
                        }));
                      }}
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={`alc_${option.key}`}
                    >
                      {option.label}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <hr />

            {/* Education Provided Checkbox */}
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="substance_education"
                checked={
                  formData.substanceUse.educationProvided
                    .isEducationProvided === "1"
                }
                onChange={() => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    substanceUse: {
                      ...prev.substanceUse,
                      educationProvided: {
                        ...prev.substanceUse.educationProvided,
                        isEducationProvided:
                          prev.substanceUse.educationProvided
                            .isEducationProvided === "1"
                            ? ""
                            : "1",
                      },
                    },
                  }));
                }}
                disabled={!isEditing}
              />
              <label className="form-check-label" htmlFor="substance_education">
                Education provided regarding alcohol use
              </label>
            </div>
          </section>
          <hr />

          {/* Advanced Care Planning Section */}
          <section className="mb-5">
            <h2 className="h4 mb-3">Advanced Care Planning</h2>
            <div className="mb-3">
              <label className="form-label">
                Do you have a Healthcare Proxy?
              </label>
              <div className="btn-group d-flex flex-wrap">
                {[
                  { key: "1", label: "Yes" },
                  { key: "2", label: "No" },
                  { key: "3", label: "Don't Know" },
                ].map((option) => (
                  <React.Fragment key={option.key}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="healthcare_proxy"
                      id={`proxy${option.key}`}
                      checked={
                        formData.advanceCarePlanning.healthCareProxy
                          .isHealthCareProxy === option.key
                      }
                      onChange={() =>
                        handleNestedRadioChange(
                          "advanceCarePlanning",
                          "healthCareProxy",
                          "isHealthCareProxy",
                          option.key,
                        )
                      }
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={`proxy${option.key}`}
                    >
                      {option.label}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Do you have a Durable Power of Attorney?
              </label>
              <div className="btn-group d-flex flex-wrap">
                {[
                  { key: "1", label: "Yes" },
                  { key: "2", label: "No" },
                  { key: "3", label: "Don't Know" },
                ].map((option) => (
                  <React.Fragment key={option.key}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="durable_power"
                      id={`power${option.key}`}
                      checked={
                        formData.advanceCarePlanning.powerAttorney
                          .isPowerAttorney === option.key
                      }
                      onChange={() =>
                        handleNestedRadioChange(
                          "advanceCarePlanning",
                          "powerAttorney",
                          "isPowerAttorney",
                          option.key,
                        )
                      }
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={`power${option.key}`}
                    >
                      {option.label}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Do you have an Advance Directive?
              </label>
              <div className="btn-group d-flex flex-wrap">
                {[
                  { key: "1", label: "Yes" },
                  { key: "2", label: "No" },
                  { key: "3", label: "Don't Know" },
                ].map((option) => (
                  <React.Fragment key={option.key}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="advance_directive"
                      id={`directive${option.key}`}
                      checked={
                        formData.advanceCarePlanning.advanceDirective
                          .isAdvanceDirective === option.key
                      }
                      onChange={() =>
                        handleNestedRadioChange(
                          "advanceCarePlanning",
                          "advanceDirective",
                          "isAdvanceDirective",
                          option.key,
                        )
                      }
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={`directive${option.key}`}
                    >
                      {option.label}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="care_planning_education"
                checked={
                  formData.advanceCarePlanning.educatedOnAdvanceCarePlanning
                    .isEducatedOnAdvanceCarePlanning === "1"
                }
                onChange={() =>
                  handleNestedRadioChange(
                    "advanceCarePlanning",
                    "educatedOnAdvanceCarePlanning",
                    "isEducatedOnAdvanceCarePlanning",
                    formData.advanceCarePlanning.educatedOnAdvanceCarePlanning
                      .isEducatedOnAdvanceCarePlanning === "1"
                      ? ""
                      : "1",
                  )
                }
                disabled={!isEditing}
              />
              <label
                className="form-check-label"
                htmlFor="care_planning_education"
              >
                Member educated on advance care planning
              </label>
            </div>

            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="decline_discussion"
                checked={
                  formData.advanceCarePlanning.declinesDiscussion
                    .isDeclinesDiscussion === "1"
                }
                onChange={() =>
                  handleNestedRadioChange(
                    "advanceCarePlanning",
                    "declinesDiscussion",
                    "isDeclinesDiscussion",
                    formData.advanceCarePlanning.declinesDiscussion
                      .isDeclinesDiscussion === "1"
                      ? ""
                      : "1",
                  )
                }
                disabled={!isEditing}
              />
              <label className="form-check-label" htmlFor="decline_discussion">
                Declined discussion at this time
              </label>
            </div>
          </section>
          <hr />

          {/* Food Section */}
          <section className="mb-5">
            <h2 className="h4 mb-3">Food</h2>
            <div className="mb-3">
              <label className="form-label">
                Within the past 12 months we worried whether our food would run
                out before we got money to buy more. Was that ____ for your
                household?
              </label>
              <div className="btn-group d-flex flex-wrap">
                {[
                  { key: "oftenTrue", label: "Often True" },
                  { key: "sometimesTrue", label: "Sometimes True" },
                  { key: "neverTrue", label: "Never True" },
                ].map((option) => (
                  <React.Fragment key={option.key}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="food_worry"
                      id={`food_${option.key}`}
                      checked={
                        formData.food.foodWouldRunOut[option.key] === "1"
                      }
                      onChange={() =>
                        handleNestedRadioChange(
                          "food",
                          "foodWouldRunOut",
                          option.key,
                          "1",
                        )
                      }
                      disabled={!isEditing}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={`food_${option.key}`}
                    >
                      {option.label}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </section>

          <div className="col-md-12 mt-4 text-center">
            {!isEditing ? (
              // Edit button - only changes editing state
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => setIsEditing(true)}
              >
                Edit
              </button>
            ) : (
              <>
                <button
                  type="button" // Changed from 'submit' to 'button'
                  className="btn btn-success me-2"
                  onClick={handleSubmit} // API call only happens here
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Sdoh;
