import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

const Familyhis = ({ data }) => {
  const [formData, setFormData] = useState({
    familyHistory: {
      isFamilyHistory: "",
      familyHistorySubAnswers: [],
      comment: "",
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [newRow, setNewRow] = useState({
    familyMember: "",
    medicalCondition: "",
    causeofDeath: "",
  });

  useEffect(() => {
    if (data && data.data) {
      setFormData(data.data);
    }
  }, [data]);

  const handleFamilyHistoryChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      familyHistory: {
        ...prev.familyHistory,
        isFamilyHistory: value,
      },
    }));
  };

  const handleCommentChange = (e) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      familyHistory: {
        ...prev.familyHistory,
        comment: e.target.value,
      },
    }));
  };

  const handleNewRowChange = (field, value) => {
    setNewRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const addNewRow = () => {
    if (!newRow.familyMember || !newRow.medicalCondition) {
      setMessage("Please fill in at least Family Member and Medical Condition");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      familyHistory: {
        ...prev.familyHistory,
        familyHistorySubAnswers: [
          ...prev.familyHistory.familyHistorySubAnswers,
          { ...newRow },
        ],
      },
    }));

    setNewRow({
      familyMember: "",
      medicalCondition: "",
      causeofDeath: "",
    });
  };

  const deleteRow = (index) => {
    setFormData((prev) => ({
      ...prev,
      familyHistory: {
        ...prev.familyHistory,
        familyHistorySubAnswers:
          prev.familyHistory.familyHistorySubAnswers.filter(
            (_, i) => i !== index,
          ),
      },
    }));
  };

  const handleSubmit = async () => {
    const numYear = user.year;
    //handle any exceptions that occur due to year value
    const yearToUse = (function () {
      if (!numYear) return new Date().getFullYear();

      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);

      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();
    try {
      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "FamilyHistory",
        data: formData,
      };

      const token = localStorage.getItem("token");
      const response = await axios.put(
        "https://hradev-node.focuscares.com/memberPortal/update-family-history",
        payload,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.code === 200) {
        setMessage("Family history updated successfully!");
        setIsEditing(false);
      } else {
        setMessage(
          "Failed to update family history: " +
            (response.data.message || "Unknown error"),
        );
      }
    } catch (error) {
      console.error("Error updating family history:", error);
      setMessage(
        "Failed to update family history. Please check your network connection and try again.",
      );
    }
  };

  const user = useSelector((state) => state.auth.user);

  return (
    <div
      className="tab-pane fade show active"
      id="pills-fh"
      role="tabpanel"
      aria-labelledby="pills-fh-tab"
    >
      <div className="container mt-5">
        {message && (
          <Alert
            severity={message.includes("successfully") ? "success" : "error"}
            className="mb-4"
          >
            {message ||
              (message.includes("successfully")
                ? "Here is a gentle confirmation that your action was successful."
                : "Something went wrong. Please try again.")}
          </Alert>
        )}
        <h2 className="mb-4">Family History</h2>

        <div className="card border-primary">
          <div className="card-body">
            <h5 className="card-title">
              <strong>Do you have a Family History *</strong>
            </h5>

            <div className="form-group">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="familyHistoryYes"
                  name="familyHistory"
                  checked={formData.familyHistory.isFamilyHistory === "1"}
                  onChange={() => handleFamilyHistoryChange("1")}
                  disabled={!isEditing}
                />
                <label className="form-check-label" htmlFor="familyHistoryYes">
                  Yes
                </label>
              </div>

              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="radio"
                  id="familyHistoryNo"
                  name="familyHistory"
                  checked={formData.familyHistory.isFamilyHistory === "2"}
                  onChange={() => handleFamilyHistoryChange("2")}
                  disabled={!isEditing}
                />
                <label className="form-check-label" htmlFor="familyHistoryNo">
                  No
                </label>
              </div>

              {formData.familyHistory.isFamilyHistory === "1" && (
                <div className="mt-3">
                  <h6>
                    <strong>Data Table *</strong>
                  </h6>
                  <table className="table table-bordered">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Family Member</th>
                        <th scope="col">Medical Condition</th>
                        <th scope="col">Cause of Death</th>
                        {isEditing && <th scope="col">Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {formData.familyHistory.familyHistorySubAnswers.map(
                        (row, index) => (
                          <tr key={index}>
                            <td>{row.familyMember}</td>
                            <td>{row.medicalCondition}</td>
                            <td>{row.causeofDeath}</td>
                            {isEditing && (
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => deleteRow(index)}
                                >
                                  Delete
                                </button>
                              </td>
                            )}
                          </tr>
                        ),
                      )}
                      {isEditing && (
                        <tr>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={newRow.familyMember}
                              onChange={(e) =>
                                handleNewRowChange(
                                  "familyMember",
                                  e.target.value,
                                )
                              }
                              placeholder="Family Member"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={newRow.medicalCondition}
                              onChange={(e) =>
                                handleNewRowChange(
                                  "medicalCondition",
                                  e.target.value,
                                )
                              }
                              placeholder="Medical Condition"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={newRow.causeofDeath}
                              onChange={(e) =>
                                handleNewRowChange(
                                  "causeofDeath",
                                  e.target.value,
                                )
                              }
                              placeholder="Cause of Death"
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={addNewRow}
                            >
                              Add
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="form-group mt-3">
                <label className="form-label">Comments</label>
                <textarea
                  className="form-control"
                  value={formData.familyHistory.comment}
                  onChange={handleCommentChange}
                  disabled={!isEditing}
                  rows="3"
                />
              </div>
            </div>

            <div className="col-md-12 mt-4 text-center">
              {!isEditing ? (
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-success me-2"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      if (data && data.data) {
                        setFormData(data.data);
                      }
                      setIsEditing(false);
                      setMessage("");
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Familyhis;
