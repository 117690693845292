import React from "react";

const NotFoundTrap = React.lazy(() =>
  import("../pages/NotFoundTrap/NotFoundTrap"),
);
const ForgotPassword = React.lazy(() =>
  import("../pages/Login/ForgotPassword/ForgotPassword"),
);
const ResetPassword = React.lazy(() =>
  import("../pages/Login/ResetPassword/ResetPassword"),
);
const ChangePassword = React.lazy(() =>
  import("../pages/Login/ChangePassword/ChangePassword"),
);
const OTPScreen = React.lazy(() => import("../pages/OTP/OTPScreen"));

const ClientLogin = React.lazy(() => import("../pages/Login/login"));
const MemberLogin = React.lazy(() => import("../pages/MemberPortal/MPLogin"));
const MemberRegister = React.lazy(() =>
  import("../pages/MemberPortal/Register"),
);
const MemberProfile = React.lazy(() => import("../pages/MemberPortal/Profile"));
const MemberMainDash = React.lazy(() =>
  import("../pages/MemberPortal/MainDash"),
);
// const MemberLogin = React.lazy(() => import("../pages/MemberPortal/Login"));

// const GoogleMapView=React.lazy(()=>import ('../components/Modal/GoogleMapView.js'));

export const PublicRoutes = [
  { path: "*", component: <NotFoundTrap />, title: "Not Found" },
  { path: `/Account/ForgotPassword`, component: <ForgotPassword /> },
  { path: `/Account/Otp`, component: <OTPScreen /> },
  { path: `/Account/ChangePassword`, component: <ChangePassword /> },
  { path: `/Account/ResetPassword`, component: <ResetPassword /> },
  { path: `/Client`, component: <ClientLogin /> },
  { path: `/MemberPortal/Login`, component: <MemberLogin /> },
  { path: `/MemberPortal/Register`, component: <MemberRegister /> },

  { path: `/MemberPortal/Profile`, component: <MemberProfile /> },

  { path: `/MemberPortal/Dashboard`, component: <MemberMainDash /> },

  // { path: `/MemberPortal`, component: <Navigate to="/login" /> },

  // {path:`/GoogleMapView`,component:<GoogleMapView/>},
];
