import React, { useState } from "react";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

const Phq2 = ({ data }) => {
  const user = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    worryTooMuch: {
      isWorryTooMuch: "",
      comment: "",
    },
    feelAfraid: {
      isFeelAfraid: "",
      comment: "",
    },
    overPastTwoWeeks: {
      littleInterestOrPleasure: {
        notAtAll: "",
        severalDays: "",
        moreThanHalfTheDays: "",
        nearlyEveryDay: "",
        comment: "",
      },
      feelingDownDepressed: {
        notAtAll: "",
        severalDays: "",
        moreThanHalfTheDays: "",
        nearlyEveryDay: "",
        comment: "",
      },
      phq2score: {
        lessThanThree: "",
        threeOrMore: "",
        phq2score: "0",
        threeOrMoreSubAnswer: {
          depressionScreening: {
            phq9score: "0",
            havingLittleInterest: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            feelingDownDepressed: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            troubleFalling: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            feelingTired: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            poorAppetite: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            feelingBadAboutYourself: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            troubleConcentratingOnThings: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            movingOrSpeaking: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            hurtingYourself: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            difficultProblems: {
              notDifficultAtAll: "",
              someWhatDifficult: "",
              veryDifficult: "",
              extremelyDifficult: "",
              other: "",
              notApplicable: "",
              comment: "",
            },
            comment: "",
          },
        },
      },
    },
  });

  const [message, setMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const phq9Questions = {
    havingLittleInterest: "Little interest or pleasure in doing things?",
    feelingDownDepressed: "Feeling down, depressed, or hopeless?",
    troubleFalling: "Trouble falling or staying asleep, or sleeping too much?",
    feelingTired: "Feeling tired or having little energy?",
    poorAppetite: "Poor appetite or overeating?",
    feelingBadAboutYourself:
      "Feeling bad about yourself - or that you are a failure or have let yourself or your family down?",
    troubleConcentratingOnThings:
      "Trouble concentrating on things, such as reading the newspaper or watching television?",
    movingOrSpeaking:
      "Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual?",
    hurtingYourself:
      "Thoughts that you would be better off dead or of hurting yourself in some way?",
  };

  // Cancel editing
  const handleCancel = () => {
    if (data && data.data) {
      setFormData(data.data);
    }
    setIsEditing(false);
    setMessage("");
  };

  const calculatePHQ2Score = (data) => {
    const scoreMap = {
      notAtAll: 0,
      severalDays: 1,
      moreThanHalfTheDays: 2,
      nearlyEveryDay: 3,
    };

    let totalScore = 0;

    // Calculate score for littleInterestOrPleasure
    Object.entries(data.overPastTwoWeeks.littleInterestOrPleasure).forEach(
      ([key, value]) => {
        if (key !== "comment" && value === "yes") {
          totalScore += scoreMap[key];
        }
      },
    );

    // Calculate score for feelingDownDepressed
    Object.entries(data.overPastTwoWeeks.feelingDownDepressed).forEach(
      ([key, value]) => {
        if (key !== "comment" && value === "yes") {
          totalScore += scoreMap[key];
        }
      },
    );

    return totalScore;
  };

  const calculatePHQ9Score = (data) => {
    const scoreMap = {
      notAtAll: 0,
      several: 1,
      moreThanHalfTheDays: 2,
      nearlyEveryDay: 3,
    };

    let totalScore = 0;
    const screening =
      data.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening;

    Object.keys(phq9Questions).forEach((question) => {
      Object.entries(screening[question]).forEach(([key, value]) => {
        if (key !== "comment" && value === "yes") {
          totalScore += scoreMap[key];
        }
      });
    });

    return totalScore;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [section, ...parts] = name.split("-");

    setFormData((prevData) => {
      let newData = { ...prevData };

      if (section === "overPastTwoWeeks") {
        const [question, answer] = parts;

        // Reset all options for this question first
        const options = [
          "notAtAll",
          "severalDays",
          "moreThanHalfTheDays",
          "nearlyEveryDay",
        ];
        options.forEach((opt) => {
          newData.overPastTwoWeeks[question][opt] = "";
        });

        // Set the selected option
        if (answer === "comment") {
          newData.overPastTwoWeeks[question].comment = value;
        } else {
          newData.overPastTwoWeeks[question][answer] = value;
        }

        // Calculate PHQ-2 score
        const score = calculatePHQ2Score(newData);
        newData.overPastTwoWeeks.phq2score.phq2score = score.toString();
        newData.overPastTwoWeeks.phq2score.lessThanThree =
          score < 3 ? "true" : "";
        newData.overPastTwoWeeks.phq2score.threeOrMore =
          score >= 3 ? "true" : "";
      } else if (section === "phq9") {
        const [question, answer] = parts;

        if (answer === "comment") {
          if (question === "comment") {
            newData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.comment =
              value;
          } else {
            newData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening[
              question
            ].comment = value;
          }
        } else {
          // Reset all options for this question first
          const options = [
            "notAtAll",
            "several",
            "moreThanHalfTheDays",
            "nearlyEveryDay",
          ];
          options.forEach((opt) => {
            newData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening[
              question
            ][opt] = "";
          });

          // Set the selected option
          newData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening[
            question
          ][answer] = value;
        }

        // Calculate PHQ-9 score
        const phq9Score = calculatePHQ9Score(newData);
        newData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.phq9score =
          phq9Score.toString();
      }

      return newData;
    });
  };

  const handleSubmit = async () => {
    const numYear = user.year;
    //handle any exceptions that occur due to year value
    const yearToUse = (function () {
      if (!numYear) return new Date().getFullYear();

      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);

      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();
    const payload = {
      memberId: user.memberId,
      year: yearToUse,
      name: "Phq2",
      data: formData,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        "https://hradev-node.focuscares.com/memberPortal/update-phq2score",
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setMessage("Form updated successfully!");
    } catch (error) {
      console.error("Error updating PHQ-2 score:", error);
      setMessage("Failed to update form. Please try again.");
    }
  };

  const handleAdditionalFieldsChange = (e) => {
    const { name, value } = e.target;
    const [field, type] = name.split("-");

    setFormData((prevData) => {
      const newData = { ...prevData };

      // For worry too much field
      if (field === "worryTooMuch") {
        // Initialize if not exists
        if (!newData.worryTooMuch) {
          newData.worryTooMuch = { isWorryTooMuch: "", comment: "" };
        }

        // Handle radio button or comment
        if (type === "isWorryTooMuch") {
          newData.worryTooMuch.isWorryTooMuch = value;
        } else if (type === "comment") {
          newData.worryTooMuch.comment = value;
        }
      }

      // For feel afraid field
      if (field === "feelAfraid") {
        // Initialize if not exists
        if (!newData.feelAfraid) {
          newData.feelAfraid = { isFeelAfraid: "", comment: "" };
        }

        // Handle radio button or comment
        if (type === "isFeelAfraid") {
          newData.feelAfraid.isFeelAfraid = value;
        } else if (type === "comment") {
          newData.feelAfraid.comment = value;
        }
      }

      return newData;
    });
  };

  const renderQuestionOptions = (section, question, options) => {
    const getValue = (option) => {
      try {
        if (
          section === "overPastTwoWeeks" &&
          formData?.overPastTwoWeeks?.[question]
        ) {
          return formData.overPastTwoWeeks[question][option] === "yes";
        } else if (
          section === "phq9" &&
          formData?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
            ?.depressionScreening?.[question]
        ) {
          return (
            formData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
              .depressionScreening[question][option] === "yes"
          );
        }
        return false;
      } catch (error) {
        console.error("Error in getValue:", error);
        return false;
      }
    };

    return (
      <div className="d-flex justify-content-start">
        {options.map((option) => (
          <div className="form-check me-3" key={option}>
            <input
              className="form-check-input"
              type="radio"
              name={`${section}-${question}-${option}`}
              value="yes"
              checked={getValue(option)}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
            <label className="form-check-label">
              {option
                .replace(/([A-Z])/g, " $1")
                .toLowerCase()
                .replace(/^./, (str) => str.toUpperCase())}
            </label>
          </div>
        ))}
      </div>
    );
  };

  // If no data is passed, return null
  if (!data) return null;

  return (
    <div
      className="tab-pane fade show active"
      id="pills-phq2"
      role="tabpanel"
      aria-labelledby="pills-phq2-tab"
    >
      <div className="container mt-5">
        {message && (
          <Alert
            severity={message.includes("successfully") ? "success" : "error"}
            className="mb-4"
          >
            {message ||
              (message.includes("successfully")
                ? "Here is a gentle confirmation that your action was successful."
                : "Something went wrong. Please try again.")}
          </Alert>
        )}
        <h2 className="mb-4">PHQ2/PHQ9</h2>
        <hr />

        {/* Worry Too Much Question */}
        <div className="mb-4">
          <label className="form-label">Do you worry too much?</label>
          <div className="d-flex justify-content-start">
            <div className="form-check me-3">
              <input
                type="radio"
                name="worryTooMuch-isWorryTooMuch"
                value="yes"
                checked={formData.worryTooMuch?.isWorryTooMuch === "yes"}
                onChange={handleAdditionalFieldsChange}
                disabled={!isEditing}
                className="form-check-input"
              />
              <label className="form-check-label">Yes</label>
            </div>
            <div className="form-check me-3">
              <input
                type="radio"
                name="worryTooMuch-isWorryTooMuch"
                value="no"
                checked={formData.worryTooMuch?.isWorryTooMuch === "no"}
                onChange={handleAdditionalFieldsChange}
                disabled={!isEditing}
                className="form-check-input"
              />
              <label className="form-check-label">No</label>
            </div>
          </div>
          <textarea
            name="worryTooMuch-comment"
            placeholder="Add a comment..."
            value={formData.worryTooMuch?.comment || ""}
            onChange={handleAdditionalFieldsChange}
            disabled={!isEditing}
            className="form-control mt-2"
          />
        </div>

        {/* Feel Afraid Question */}
        <div className="mb-4">
          <label className="form-label">Do you feel afraid?</label>
          <div className="d-flex justify-content-start">
            <div className="form-check me-3">
              <input
                type="radio"
                name="feelAfraid-isFeelAfraid"
                value="yes"
                checked={formData.feelAfraid?.isFeelAfraid === "yes"}
                onChange={handleAdditionalFieldsChange}
                disabled={!isEditing}
                className="form-check-input"
              />
              <label className="form-check-label">Yes</label>
            </div>
            <div className="form-check me-3">
              <input
                type="radio"
                name="feelAfraid-isFeelAfraid"
                value="no"
                checked={formData.feelAfraid?.isFeelAfraid === "no"}
                onChange={handleAdditionalFieldsChange}
                disabled={!isEditing}
                className="form-check-input"
              />
              <label className="form-check-label">No</label>
            </div>
          </div>
          <textarea
            name="feelAfraid-comment"
            placeholder="Add a comment..."
            value={formData.feelAfraid?.comment || ""}
            onChange={handleAdditionalFieldsChange}
            disabled={!isEditing}
            className="form-control mt-2"
          />
        </div>
        {/* PHQ-2 Questions */}
        <div className="mb-4">
          <h4 className="mb-3">PHQ-2 Screening</h4>
          <div className="mb-4">
            <label className="form-label">
              Over the past 2 weeks, how often have you been bothered by little
              interest or pleasure in doing things?
            </label>
            {renderQuestionOptions(
              "overPastTwoWeeks",
              "littleInterestOrPleasure",
              [
                "notAtAll",
                "severalDays",
                "moreThanHalfTheDays",
                "nearlyEveryDay",
              ],
            )}
            <textarea
              name="overPastTwoWeeks-littleInterestOrPleasure-comment"
              placeholder="Add a comment..."
              value={formData.overPastTwoWeeks.littleInterestOrPleasure.comment}
              onChange={handleInputChange}
              className="form-control mt-2"
              disabled={!isEditing}
            />
          </div>

          <div className="mb-4">
            <label className="form-label">
              Feeling down, depressed, or hopeless?
            </label>
            {renderQuestionOptions("overPastTwoWeeks", "feelingDownDepressed", [
              "notAtAll",
              "severalDays",
              "moreThanHalfTheDays",
              "nearlyEveryDay",
            ])}
            <textarea
              name="overPastTwoWeeks-feelingDownDepressed-comment"
              placeholder="Add a comment..."
              value={formData.overPastTwoWeeks.feelingDownDepressed.comment}
              onChange={handleInputChange}
              className="form-control mt-2"
              disabled={!isEditing}
            />
          </div>

          {/* PHQ-2 Score Display */}
          <div className="mb-4">
            <label className="form-label">
              PHQ-2 Score: {formData.overPastTwoWeeks.phq2score.phq2score}
            </label>
            <div>
              <button
                type="button"
                className={`btn me-2 ${
                  formData.overPastTwoWeeks.phq2score.lessThanThree
                    ? "btn-primary"
                    : "btn-light"
                }`}
              >
                Less than 3
              </button>
              <button
                type="button"
                className={`btn ${
                  formData.overPastTwoWeeks.phq2score.threeOrMore
                    ? "btn-primary"
                    : "btn-light"
                }`}
              >
                3 or more
              </button>
            </div>
          </div>
        </div>

        {/* PHQ-9 Questions (shown when PHQ-2 score is 3 or more) */}
        {formData.overPastTwoWeeks.phq2score.threeOrMore && (
          <div className="mt-4">
            <h4 className="mb-3">PHQ-9 Screening</h4>
            {Object.entries(phq9Questions).map(([key, question]) => (
              <div className="mb-4" key={key}>
                <label className="form-label">{question}</label>
                {renderQuestionOptions("phq9", key, [
                  "notAtAll",
                  "several",
                  "moreThanHalfTheDays",
                  "nearlyEveryDay",
                ])}
                <textarea
                  name={`phq9-${key}-comment`}
                  placeholder="Add a comment..."
                  value={
                    formData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
                      .depressionScreening[key].comment
                  }
                  onChange={handleInputChange}
                  className="form-control mt-2"
                />
              </div>
            ))}

            {/* Difficulty Question */}
            <div className="mb-4">
              <label className="form-label">
                If you checked off any problems, how difficult have these
                problems made it for you to do your work, take care of things at
                home, or get along with other people?
              </label>
              <div className="d-flex justify-content-start">
                {[
                  "notDifficultAtAll",
                  "someWhatDifficult",
                  "veryDifficult",
                  "extremelyDifficult",
                ].map((option) => (
                  <div className="form-check me-3" key={option}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`phq9-difficultProblems-${option}`}
                      value="yes"
                      checked={
                        formData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
                          .depressionScreening.difficultProblems[option] ===
                        "yes"
                      }
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label">
                      {option
                        .replace(/([A-Z])/g, " $1")
                        .toLowerCase()
                        .replace(/^./, (str) => str.toUpperCase())}
                    </label>
                  </div>
                ))}
              </div>
              <textarea
                name="phq9-difficultProblems-comment"
                placeholder="Add a comment..."
                value={
                  formData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
                    .depressionScreening.difficultProblems.comment
                }
                onChange={handleInputChange}
                className="form-control mt-2"
              />
            </div>

            <div className="mb-4">
              <label className="form-label">
                PHQ-9 Score:{" "}
                {
                  formData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
                    .depressionScreening.phq9score
                }
              </label>
            </div>

            {/* General comment for PHQ-9 */}
            <div className="mb-4">
              <label className="form-label">Additional Comments</label>
              <textarea
                name="phq9-comment"
                placeholder="Add any additional comments..."
                value={
                  formData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
                    .depressionScreening.comment
                }
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>
        )}

        <div className="col-md-12 mt-4 text-center">
          {!isEditing ? (
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-success me-2"
                onClick={handleSubmit}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Phq2;
