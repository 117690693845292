import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

const Medicalhistory = ({ data }) => {
  const [formData, setFormData] = useState({
    assistiveDevices: {
      isNone: "",
      comment: "",
      subAnswers: {
        isCane: "",
        isWalker: "",
        isProsthesis: "",
        isOxygen: "",
        isWheelChair: "",
        isurinal: "",
        isBedPan: "",
        isCPAP: "",
        isOther: "",
        isBedsideCommode: "",
        comment: "",
        oxygenSubAnswers: {
          describe: {
            isPRN: "",
            isContinuous: "",
            isDay: "",
            isNight: "",
            comment: "",
          },
          Litres: "",
        },
        otherSubAnswers: {
          describe: "",
        },
      },
    },
    specialists: {
      isSpecialists: "",
      specialistsSubAnswers: [],
      comment: "",
    },
    pastTwelveMonths: {
      pastTwelveMonthsSubAnswers: {
        pcp: {
          isNone: "",
          isOne: "",
          isTwo: "",
          isThree: "",
          isFour: "",
          isFiveOrMore: "",
          comment: "",
        },
        emergencyRoom: {
          isNone: "",
          isOne: "",
          isTwo: "",
          isThree: "",
          isFour: "",
          isFiveOrMore: "",
          comment: "",
        },
        hospitalOvernight: {
          isNone: "",
          isOne: "",
          isTwo: "",
          isThree: "",
          isFour: "",
          isFiveOrMore: "",
          comment: "",
        },
        nursingHome: {
          isNone: "",
          isOne: "",
          isTwo: "",
          isThree: "",
          isFour: "",
          isFiveOrMore: "",
          comment: "",
        },
        hadSurgery: {
          isNone: "",
          isOne: "",
          isTwo: "",
          isThree: "",
          isFour: "",
          isFiveOrMore: "",
          comment: "",
        },
      },
    },
    hospitalizedPrior: {
      isHospitalizedPrior: "",
      hospitalizedPriorSubAnswers: {
        describe: "",
      },
      comment: "",
    },
    healthServices: {
      physicalTherapist: "",
      occupationalTherapist: "",
      dietician: "",
      socialWorker: "",
      pharmacist: "",
      speechTherapist: "",
      shiropractor: "",
      personalCareWorker: "",
      mealsOnWheels: "",
      adultDayCare: "",
      eyeExam: "",
      dentalExam: "",
      none: "",
      comment: "",
    },
    treatments: {
      chemotherapy: "",
      catheterCare: "",
      oxygen: "",
      woundCare: "",
      regularInjections: "",
      tubeFeedings: "",
      none: "",
      comment: "",
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (data && data.data) {
      setFormData(data.data);
    }
  }, [data]);

  const handleAssistiveDeviceClick = (device) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      assistiveDevices: {
        ...prev.assistiveDevices,
        subAnswers: {
          ...prev.assistiveDevices.subAnswers,
          [device]: prev.assistiveDevices.subAnswers[device] === "1" ? "" : "1",
        },
      },
    }));
  };

  const handleRadioChange = (section, subsection, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      pastTwelveMonths: {
        ...prev.pastTwelveMonths,
        pastTwelveMonthsSubAnswers: {
          ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
          [section]: {
            ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers[section],
            ...Object.fromEntries(
              [
                "isNone",
                "isOne",
                "isTwo",
                "isThree",
                "isFour",
                "isFiveOrMore",
              ].map((key) => [key, key === value ? "1" : ""]),
            ),
          },
        },
      },
    }));
  };

  const handleServiceClick = (service) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      healthServices: {
        ...prev.healthServices,
        [service]: prev.healthServices[service] === "1" ? "" : "1",
      },
    }));
  };

  const handleTreatmentClick = (treatment) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      treatments: {
        ...prev.treatments,
        [treatment]: prev.treatments[treatment] === "1" ? "" : "1",
      },
    }));
  };
  const { user = {} } = useSelector((state) => state?.auth ?? {});

  const handleSubmit = async () => {
    const numYear = user.year;
    //handle any exceptions that occur due to year value
    const yearToUse = (function () {
      if (!numYear) return new Date().getFullYear();

      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);

      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();
    try {
      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "MedicalHistory",
        data: formData,
      };

      const token = localStorage.getItem("token");
      const response = await axios.put(
        "https://hradev-node.focuscares.com/memberPortal/update-medical-history",
        payload,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.code === 200) {
        setMessage("Medical history updated successfully!");
        setIsEditing(false);
      } else {
        setMessage(
          "Failed to update medical history: " +
            (response.data.message || "Unknown error"),
        );
      }
    } catch (error) {
      console.error("Error updating medical history:", error);
      setMessage(
        "Failed to update medical history. Please check your network connection and try again.",
      );
    }
  };

  const getButtonClass = (value) => {
    return `btn ${value === "1" ? "btn-primary" : "btn-outline-primary"}`;
  };

  return (
    <div
      className="tab-pane fade show active"
      id="pills-mh"
      role="tabpanel"
      aria-labelledby="pills-mh-tab"
    >
      <div className="container mt-5">
        {message && (
          <Alert
            severity={message.includes("successfully") ? "success" : "error"}
            className="mb-4"
          >
            {message ||
              (message.includes("successfully")
                ? "Here is a gentle confirmation that your action was successful."
                : "Something went wrong. Please try again.")}
          </Alert>
        )}

        <h1 className="mb-4">Detailed Medical Questionnaire</h1>
        <form onSubmit={(e) => e.preventDefault()}>
          {/* Assistive Devices */}
          <div className="mb-3">
            <label className="form-label">
              Do you use any assistive devices or DME?
            </label>
            <div className="d-flex flex-wrap gap-2">
              {[
                { key: "isNone", label: "None" },
                { key: "isCane", label: "Cane" },
                { key: "isWalker", label: "Walker" },
                { key: "isProsthesis", label: "Prosthesis" },
                { key: "isOxygen", label: "Oxygen" },
                { key: "isWheelChair", label: "Wheel Chair" },
                { key: "isBedsideCommode", label: "Bedside Commode" },
                { key: "isurinal", label: "Urinal" },
                { key: "isBedPan", label: "Bed Pan" },
                { key: "isCPAP", label: "CPAP" },
                { key: "isOther", label: "Other" },
              ].map((device) => (
                <button
                  key={device.key}
                  type="button"
                  className={getButtonClass(
                    formData.assistiveDevices.subAnswers[device.key],
                  )}
                  onClick={() => handleAssistiveDeviceClick(device.key)}
                  disabled={!isEditing}
                >
                  {device.label}
                </button>
              ))}
            </div>
          </div>

          {/* Past 12 Months Table */}
          <div className="mb-3">
            <label className="form-label">
              In the past 12 months how many times have you?
            </label>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>None</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5 or more</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    { key: "pcp", label: "Seen your PCP" },
                    {
                      key: "emergencyRoom",
                      label: "Visited the Emergency Room",
                    },
                    {
                      key: "hospitalOvernight",
                      label: "Stayed in the hospital overnight",
                    },
                    { key: "nursingHome", label: "Been in a nursing home" },
                    { key: "hadSurgery", label: "Had Surgery" },
                  ].map((row) => (
                    <tr key={row.key}>
                      <td>{row.label}</td>
                      {[
                        "isNone",
                        "isOne",
                        "isTwo",
                        "isThree",
                        "isFour",
                        "isFiveOrMore",
                      ].map((value) => (
                        <td key={value}>
                          <input
                            type="radio"
                            checked={
                              formData.pastTwelveMonths
                                .pastTwelveMonthsSubAnswers[row.key][value] ===
                              "1"
                            }
                            onChange={() =>
                              handleRadioChange(row.key, value, value)
                            }
                            disabled={!isEditing}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Health Services */}
          <div className="mb-3">
            <label className="form-label">
              Please choose the following health services you have received in
              the last year:
            </label>
            <div className="d-flex flex-wrap gap-2">
              {[
                { key: "physicalTherapist", label: "Physical Therapist" },
                {
                  key: "occupationalTherapist",
                  label: "Occupational Therapist",
                },
                { key: "dietician", label: "Dietician" },
                { key: "socialWorker", label: "Social Worker" },
                { key: "pharmacist", label: "Pharmacist" },
                { key: "speechTherapist", label: "Speech Therapist" },
                { key: "shiropractor", label: "Chiropractor" },
                { key: "personalCareWorker", label: "Personal Care Worker" },
                { key: "mealsOnWheels", label: "Meals on Wheels" },
                { key: "adultDayCare", label: "Adult Day Care" },
                { key: "eyeExam", label: "Eye Exam" },
                { key: "dentalExam", label: "Dental Exam" },
                { key: "none", label: "None" },
              ].map((service) => (
                <button
                  key={service.key}
                  type="button"
                  className={getButtonClass(
                    formData.healthServices[service.key],
                  )}
                  onClick={() => handleServiceClick(service.key)}
                  disabled={!isEditing}
                >
                  {service.label}
                </button>
              ))}
            </div>
          </div>

          {/* Treatments */}
          <div className="mb-3">
            <label className="form-label">
              Please choose the following treatments you have received in the
              last year:
            </label>
            <div className="d-flex flex-wrap gap-2">
              {[
                { key: "chemotherapy", label: "Chemotherapy" },
                { key: "catheterCare", label: "Catheter Care" },
                { key: "oxygen", label: "Oxygen" },
                { key: "woundCare", label: "Wound Care" },
                { key: "regularInjections", label: "Regular Injections" },
                { key: "tubeFeedings", label: "Tube Feedings" },
                { key: "none", label: "None" },
              ].map((treatment) => (
                <button
                  key={treatment.key}
                  type="button"
                  className={getButtonClass(formData.treatments[treatment.key])}
                  onClick={() => handleTreatmentClick(treatment.key)}
                  disabled={!isEditing}
                >
                  {treatment.label}
                </button>
              ))}
            </div>
          </div>

          {/* Edit/Save/Cancel Buttons */}
          <div className="col-md-12 mt-4 text-center">
            {!isEditing ? (
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => setIsEditing(true)}
              >
                Edit
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-success me-2"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    if (data && data.data) {
                      setFormData(data.data);
                    }
                    setIsEditing(false);
                    setMessage("");
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Medicalhistory;
