import {
  getObjectData,
  setObjectData,
} from "../../../../../../utils/helpers.DynamicForm";

const minicog_hashmap = [
  ["minicog_wordList", "threeWordRecall.wordList", ["2023"]],
  ["miniCogSeeAttachedSheetComment", "comment", ["2023"]],
  ["minicog_wordListVer", "threeWordRecall.wordList", ["2024", "2025"]],

  ["minicog_personAnswersVal", "threeWordRecall.personsAnswers", ["2023"]],
  [
    "minicog_wordRecallVal",
    "threeWordRecall.wordRecall",
    ["2023", "2024", "2025"],
  ],
  [
    "minicog_clockDrawVal",
    "threeWordRecall.clockDraw",
    ["2023", "2024", "2025"],
  ],
  [
    "minicog_totalScoreVal",
    "threeWordRecall.totalScore",
    ["2023", "2024", "2025"],
  ],
  [
    "minicog_furtherCognitiveVal",
    "recommendations.furtherCognitive.furtherCognitive",
    ["2023"],
  ],
  [
    "minicog_furtherCognitiveVal",
    "recommendations.furtherCognitive.isFurtherCognitive",
    ["2024", "2025"],
  ],
  [
    "minicog_furtherCognitiveCommentVal",
    "recommendations.furtherCognitive.comment",
    ["2023", "2024", "2025"],
  ],
  ["minicog_otherVal", "recommendations.other.other", ["2023"]],
  [
    "minicog_otherCommentVal",
    "recommendations.other.comment",
    ["2023", "2024", "2025"],
  ],

  ["minicog_Na", "NA.isNA", ["2024", "2025"]],
  ["minicog_NaComment", "NA.comment", ["2024", "2025"]],
  ["minicog_attachedComment", "miniCog.comment", ["2024", "2025"]],

  [
    "minicog_caseManagementReferral",
    "recommendations.caseManagementReferral.isCaseManagementReferral",
    ["2024", "2025"],
  ],
  [
    "minicog_caseManagementReferralComment",
    "recommendations.caseManagementReferral.comment",
    ["2024", "2025"],
  ],

  [
    "minicog_followUpWithClinician",
    "recommendations.followUpWithClinician.isFollowUpWithClinician",
    ["2024", "2025"],
  ],
  [
    "minicog_followUpWithClinicianComment",
    "recommendations.followUpWithClinician.comment",
    ["2024", "2025"],
  ],

  [
    "minicog_memberToUseDME",
    "recommendations.memberToUseDME.isMemberToUseDME",
    ["2024", "2025"],
  ],
  [
    "minicog_memberToUseDMEComment",
    "recommendations.memberToUseDME.comment",
    ["2024", "2025"],
  ],
  ["minicog_otherVal", "recommendations.other.isOther", ["2024", "2025"]],
];

export const getMinicogData = (apiData, year) => {
  let tempDbData = {};
  let flatmap = [];

  minicog_hashmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList?.includes(year)) {
      flatmap.push(quesData);
    }
  });

  flatmap.forEach((questionPair) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  // const minicog_wordList = tempDbData["minicog_wordListVal"];
  // const minicog_personAnswers = tempDbData["minicog_personAnswersVal"];
  const minicog_wordRecall = tempDbData["minicog_wordRecallVal"];
  const minicog_clockDraw = tempDbData["minicog_clockDrawVal"];
  const minicog_totalScore = tempDbData["minicog_totalScoreVal"];
  const minicog_furtherCognitiveComment =
    tempDbData["minicog_furtherCognitiveCommentVal"];
  const minicog_otherComment = tempDbData["minicog_otherCommentVal"];

  const minicog_furtherCognitive =
    tempDbData["minicog_furtherCognitiveVal"] == "1"
      ? ["furtherCognitive"]
      : [];
  const minicog_other = tempDbData["minicog_otherVal"] == "1" ? ["other"] : [];

  tempDbData = {
    ...tempDbData,
    // minicog_wordList: minicog_wordList || "",
    // minicog_personAnswers: minicog_personAnswers || "",
    minicog_wordRecall: minicog_wordRecall || "",
    minicog_clockDraw: minicog_clockDraw || "",
    minicog_totalScore: minicog_totalScore || "",
    minicog_furtherCognitive: minicog_furtherCognitive || "",
    minicog_furtherCognitiveComment: minicog_furtherCognitiveComment || "",
    minicog_other: minicog_other || "",
    minicog_otherComment: minicog_otherComment || "",
  };

  if (year === "2024" || year === "2025") {
    const minicog_Na = tempDbData["minicog_Na"] == "1" ? ["notApplicable"] : "";

    const minicog_NaComment = tempDbData["minicog_NaComment"];
    const minicog_attachedComment = tempDbData["minicog_attachedComment"];
    const minicog_caseManagementReferral =
      tempDbData["minicog_caseManagementReferral"] == "1"
        ? ["casemangerefforfoll"]
        : "";
    const minicog_caseManagementReferralComment =
      tempDbData["minicog_caseManagementReferralComment"];
    const minicog_followUpWithClinician =
      tempDbData["minicog_followUpWithClinician"] == "1"
        ? ["followupClincianSpec"]
        : [];
    const minicog_followUpWithClinicianComment =
      tempDbData["minicog_followUpWithClinicianComment"];
    const minicog_memberToUseDME =
      tempDbData["minicog_memberToUseDME"] == "1" ? ["durableEquip"] : [];
    const minicog_memberToUseDMEComment =
      tempDbData["minicog_memberToUseDMEComment"];
    const minicog_furtherCognitive =
      tempDbData["minicog_furtherCognitiveVal"] == "1"
        ? ["furtherCognitive"]
        : "";
    const minicog_other =
      tempDbData["minicog_otherVal"] == "1" ? ["other"] : [];
    const minicog_wordListVer = tempDbData["minicog_wordListVer"];
    tempDbData = {
      ...tempDbData,
      minicog_Na,
      minicog_NaComment,
      minicog_attachedComment,
      minicog_caseManagementReferral,
      minicog_caseManagementReferralComment,
      minicog_followUpWithClinician,
      minicog_followUpWithClinicianComment,
      minicog_memberToUseDME,
      minicog_memberToUseDMEComment,
      minicog_furtherCognitive,
      minicog_other,
      minicog_wordListVer,
    };
  } else if (year === "2023") {
    const minicog_wordList = tempDbData["minicog_wordList"];
    const minicog_personAnswers = tempDbData["minicog_personAnswersVal"];
    const miniCogSeeAttachedSheetComment =
      tempDbData["miniCogSeeAttachedSheetComment"];
    tempDbData = {
      ...tempDbData,
      minicog_wordList: minicog_wordList || "",
      minicog_personAnswers: minicog_personAnswers || "",
      miniCogSeeAttachedSheetComment: miniCogSeeAttachedSheetComment || "",
    };
  }

  return tempDbData;
};

export const setMinicogData = (formData, year) => {
  let tempData = {};

  let payloadData = {};

  // const minicog_wordListVal = formData["minicog_wordList"];
  // const minicog_personAnswersVal = formData["minicog_personAnswers"];
  const minicog_wordRecallVal = formData["minicog_wordRecall"];
  const minicog_clockDrawVal = formData["minicog_clockDraw"];
  const minicog_totalScoreVal = formData["minicog_totalScore"];
  const minicog_furtherCognitiveCommentVal =
    formData["minicog_furtherCognitiveComment"];
  const minicog_otherCommentVal = formData["minicog_otherComment"];

  const minicog_furtherCognitiveSelected = formData["minicog_furtherCognitive"];
  const minicog_furtherCognitiveVal =
    minicog_furtherCognitiveSelected?.includes("furtherCognitive") ? "1" : "";

  const minicog_otherSelected = formData["minicog_other"];
  const minicog_otherVal = minicog_otherSelected?.includes("other") ? "1" : "";
  // const minicog_other =
  //   [tempDbData["minicog_otherVal"]] == "1" ? ["other"] : [];

  tempData = {
    ...tempData,
    // minicog_wordListVal: minicog_wordListVal || "",
    // minicog_personAnswersVal: minicog_personAnswersVal || "",
    minicog_wordRecallVal: minicog_wordRecallVal || "",
    minicog_clockDrawVal: minicog_clockDrawVal || "",
    minicog_totalScoreVal: minicog_totalScoreVal || "",
    minicog_furtherCognitiveVal: minicog_furtherCognitiveVal || "",
    minicog_furtherCognitiveCommentVal:
      minicog_furtherCognitiveCommentVal || "",
    minicog_otherVal: minicog_otherVal || "",
    minicog_otherCommentVal: minicog_otherCommentVal || "",
  };
  if (year === "2023") {
    const minicog_wordList = formData["minicog_wordList"];
    const minicog_personAnswersVal = formData["minicog_personAnswers"];
    const miniCogSeeAttachedSheetComment =
      formData["miniCogSeeAttachedSheetComment"];
    tempData = {
      ...tempData,
      minicog_wordList: minicog_wordList || "",
      minicog_personAnswersVal: minicog_personAnswersVal || "",
      miniCogSeeAttachedSheetComment: miniCogSeeAttachedSheetComment || "",
    };
  } else if (year === "2024" || year === "2025") {
    const minicog_NaSelected = formData["minicog_Na"];
    const minicog_Na = minicog_NaSelected?.includes("notApplicable") ? "1" : "";
    const minicog_otherVal = minicog_otherSelected?.includes("other")
      ? "1"
      : "";

    const minicog_NaComment = formData["minicog_NaComment"];
    const minicog_attachedComment = formData["minicog_attachedComment"];
    const minicog_caseManagementReferralSel =
      formData["minicog_caseManagementReferral"];
    const minicog_caseManagementReferral =
      minicog_caseManagementReferralSel?.includes("casemangerefforfoll")
        ? "1"
        : "";
    const minicog_caseManagementReferralComment =
      formData["minicog_caseManagementReferralComment"];
    const minicog_followUpWithClinicianSel =
      formData["minicog_followUpWithClinician"];
    const minicog_followUpWithClinician =
      minicog_followUpWithClinicianSel?.includes("followupClincianSpec")
        ? "1"
        : "";

    const minicog_followUpWithClinicianComment =
      formData["minicog_followUpWithClinicianComment"];

    const minicog_memberToUseDMESel = formData["minicog_memberToUseDME"];
    const minicog_memberToUseDME = minicog_memberToUseDMESel?.includes(
      "durableEquip",
    )
      ? "1"
      : "";
    const minicog_memberToUseDMEComment =
      formData["minicog_memberToUseDMEComment"];
    const minicog_furtherCognitiveSelected =
      formData["minicog_furtherCognitive"];
    const minicog_furtherCognitive = minicog_furtherCognitiveSelected?.includes(
      "{furtherCognitive",
    )
      ? "1"
      : "";
    const minicog_wordListVer = formData["minicog_wordListVer"];
    tempData = {
      ...tempData,
      minicog_Na,
      minicog_otherVal,
      minicog_NaComment,
      minicog_attachedComment,
      minicog_caseManagementReferral,
      minicog_caseManagementReferralComment,
      minicog_followUpWithClinician,
      minicog_followUpWithClinicianComment,
      minicog_memberToUseDME,
      minicog_memberToUseDMEComment,
      minicog_furtherCognitive,
      minicog_wordListVer,
    };
  }

  let flatmap = [];

  minicog_hashmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList?.includes(year)) {
      flatmap.push(quesData);
    }
  });
  flatmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
    //}
  });

  return payloadData;
};
