import React from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  Avatar,
  useTheme,
  alpha,
} from "@mui/material";
import {
  Person as PersonIcon,
  Phone as PhoneIcon,
  LocationOn as AddressIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function Myprofile() {
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const navigate = useNavigate();

  // Function to generate initials
  const getInitials = (firstName, lastName) => {
    return `${firstName ? firstName[0].toUpperCase() : ""}${
      lastName ? lastName[0].toUpperCase() : ""
    }`;
  };

  return (
    <Grid item xs={12}>
      <Card
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.01)",
            boxShadow: theme.shadows[4],
          },
        }}
        elevation={2}
      >
        <Box
          sx={{
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            p: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <PersonIcon color="primary" sx={{ mr: 2 }} />
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{ fontWeight: 600 }}
          >
            Demographics
          </Typography>
        </Box>

        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  width: 120,
                  height: 120,
                  backgroundColor: theme.palette.primary.main,
                  fontSize: "3rem",
                  boxShadow: theme.shadows[2],
                }}
                src={user.profilePicture} // If you have a profile picture URL
              >
                {getInitials(user.firstName, user.lastName)}
              </Avatar>
            </Grid>

            <Grid item xs={12} sm={9} md={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 2,
                      fontWeight: 600,
                      color: theme.palette.text.primary,
                    }}
                  >
                    {user.firstName} {user.lastName}
                  </Typography>

                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <AddressIcon color="primary" sx={{ mr: 1, fontSize: 20 }} />
                    <Typography variant="body1">
                      <strong>Address:</strong> {user.address}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <PhoneIcon color="primary" sx={{ mr: 1, fontSize: 20 }} />
                      <Typography variant="body1">
                        <strong>Phone:</strong> {user.phoneNumber}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <PhoneIcon color="primary" sx={{ mr: 1, fontSize: 20 }} />
                      <Typography variant="body1">
                        <strong>Mobile:</strong> {user.phoneNumber}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <PhoneIcon color="primary" sx={{ mr: 1, fontSize: 20 }} />
                      <Typography variant="body1">
                        <strong>Other Contact:</strong>{" "}
                        {user?.alternateContact || "None"}
                      </Typography>
                    </Box>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate("/MemberPortal/Dashboard")}
                      // href="/MemberPortal/Dashboard"
                      startIcon={<EditIcon />}
                      sx={{
                        textTransform: "none",
                        borderRadius: 2,
                        px: 3,
                      }}
                    >
                      View/Edit Profile
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Myprofile;
